import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { withStyles, WithStyles } from '@material-ui/styles'
import CloseIconSVG from '@material-ui/icons/Close';
import EmailIconSVG from '@material-ui/icons/Email';
import DragIconSVG from '@material-ui/icons/OpenWith';
import SmartphoneIconSVG from '@material-ui/icons/Smartphone';
import { Acteur } from 'classes/Acteur.class';
import { B64File } from 'classes/B64File.class';
import B64FilePickerComponent from 'components/filePicker/B64FilePicker.component';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { RootState } from 'store/types';
import { colors } from 'Theme';
import style from './SortableContact.style';



const DragHandle = SortableHandle(() => <DragIconSVG />);

const stateToProps = (state: RootState) => ({

})

const dispatchToProps = {

}

interface SortableContactLocalProps {
    contact: Acteur;
    class: string;
    removeItem: (() => void);
    updateParent?: () => void;
}

interface SortableContactState {
    stateAvatar: B64File
}

type SortableContactProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & SortableContactLocalProps;

class SortableContact extends Component<SortableContactProps, SortableContactState> {

    readonly state: SortableContactState = {
        stateAvatar: null
    }

    componentDidMount() {
        if (this.props.contact.fileAvatar) {
            this.setState({
                stateAvatar: this.props.contact.fileAvatar
            })
        }
    }

    componentDidUpdate() {
    }

    render() {
        const { contact, classes, removeItem } = this.props;
        const { stateAvatar } = this.state;
        return (
            <Box marginBottom={2} borderRadius={5}>
                <Box overflow="hidden" display='flex' flexDirection='column' width='100%' borderRadius={5} border={`1px solid ${colors.lighterGrey.light}`}>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' bgcolor={colors.grey.dark} alignItems='center' alignContent='center' /*borderRadius={'5px'}*/>
                        <Box marginLeft={1} width={24} height={24} color={colors.white.main}>
                            <DragHandle />
                        </Box>
                        <Box bgcolor={colors.grey.main} color={colors.white.main}>
                            <IconButton onClick={() => removeItem()}>
                                <CloseIconSVG style={{ color: 'white' }} />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box width='100%' display='flex' flexDirection='column' padding={4} bgcolor='white'>
                        <Box display='flex' flexDirection='row' flexBasis='50%' marginBottom={2}>
                            <Box width={132} height={132} marginRight={2}>
                                <B64FilePickerComponent round value={this.state.stateAvatar} onChange={(f) => { this.state.stateAvatar = f ?? null; this.props.contact.avatar = f ? JSON.stringify(f) : null; this.props.contact.customAvatar = !!f; this.props.updateParent() }} emptyInnerZone />
                            </Box>
                            <Box display='flex' flexDirection='column' justifyContent='space-between' width={300} height={132}>
                                <Box display='flex'>
                                    <TextField required className={classes.textFieldPrenom} label='Prénom' value={this.props.contact.prenom} onChange={(e) => { this.props.contact.prenom = e.target.value; this.props.updateParent() }} />
                                    <TextField required label='Nom' value={this.props.contact.nom} onChange={(e) => { this.props.contact.nom = e.target.value; this.props.updateParent() }} />
                                </Box>
                                <TextField required label='Intitulé' value={this.props.contact.role} onChange={(e) => { this.props.contact.role = e.target.value; this.props.updateParent() }} />
                            </Box>
                        </Box>
                        <Box display='flex' flexDirection='row'>
                            <TextField
                                value={this.props.contact.mobile} onChange={(e) => { this.props.contact.mobile = e.target.value; this.props.updateParent() }}
                                label='Mobile'
                                placeholder='Mobile'
                                fullWidth
                                type='tel'
                                className={classes.textFieldMobile}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SmartphoneIconSVG />
                                        </InputAdornment>
                                    )
                                }} />
                            <TextField
                                value={this.props.contact.idActeur} onChange={(e) => { this.props.contact.idActeur = e.target.value; this.props.updateParent() }}
                                label='E-mail'
                                placeholder='E-mail'
                                type='email'
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIconSVG />
                                        </InputAdornment>
                                    )
                                }} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default SortableElement(withStyles(style)(connect(stateToProps, dispatchToProps)(SortableContact)));