import createStyles from "@material-ui/styles/createStyles";
import { colors, Theme } from "Theme";

export default ({ spacing, palette }: typeof Theme) =>
    createStyles({
        cover : {
            backgroundSize : 'cover',
            backgroundPosition : 'center',
            width : '100%',
            height : '56vw',
            borderRadius : 5
        },
        titre: {
            fontSize: 18,
            fontWeight: 700,
            marginBottom: spacing(2),
            marginTop: spacing(3),
            textAlign: "center",
            whiteSpace: 'pre-line'
        },
        accroche: {
            fontSize: 14,
            color: colors.grey.main,
            fontWeight: 700,
            marginBottom: spacing(2),
            textAlign: "center",
            whiteSpace: 'pre-line'
        },
        descriptif: {
            fontSize: 14,
            color: colors.grey.main,
            textAlign: "center",
            marginBottom: spacing(3),
            whiteSpace: 'pre-line'
        },
        avatar: {
            width: 60,
            height: 60,
            marginBottom: spacing(1),
            borderRadius: "50%"
        },
        nom: {
            fontSize: 14,
            fontWeight: 700,
            marginBottom: spacing(1),
            textAlign: "center",
        },
        role: {
            fontSize: 14,
            color: colors.grey.main,
            marginBottom: spacing(3),
            textAlign: "center",
        }
    });
