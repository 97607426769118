import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { WithStyles, withStyles } from '@material-ui/styles';
import DeleteSVG from '@material-ui/icons/Delete'
import LocationOnSVG from '@material-ui/icons/LocationOn'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { CarnetActivity } from 'classes/carnet_voyage/programme/CarnetActivity.class'
import { CarnetCategory } from 'classes/carnet_voyage/programme/CarnetCategory.class'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'store/types'
import style from './ProgrammeActivity.style'

const stateToProps = ({ carnetVoyage: { scheduleCategories } }: RootState) => ({
    scheduleCategories
})

const dispatchToProps = {

}

interface ProgrammeActivityLocalProps {
    activity: CarnetActivity,
    index: number,
    removeActivity: () => void,
    updateParent: () => void
}

interface ProgrammeActivityState {
}

export type FieldName = keyof Pick<CarnetActivity, 'title' | 'description' | 'location'>;


type ProgrammeActivityProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & ProgrammeActivityLocalProps;

class ProgrammeActivity extends Component<ProgrammeActivityProps, ProgrammeActivityState> {
    onChangeTextField(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: FieldName) {
        this.props.activity[fieldName] = e.target.value;
        this.props.updateParent();
    }

    render() {
        const { activity, classes, index, removeActivity, scheduleCategories } = this.props;
        return (
            <Box display='flex' flexDirection='column' height={360} maxWidth={500} justifyContent='space-between' marginY={2}>
                <Box display='flex' flexDirection='row'>
                    <KeyboardTimePicker
                        value={this.props.activity.hour || null}
                        onChange={(m) => { this.props.activity.hour = m, this.props.updateParent() }}
                        ampm={false}
                        placeholder='Heure'
                        cancelLabel={"Annuler"}
                        clearLabel={"Vider"}
                        okLabel={"Valider"}
                        todayLabel={"Aujourd'hui"}
                        invalidDateMessage='Format de date invalide'
                        inputProps={{ required: true }}
                    />
                    <Select inputProps={{ required: true }} value={this.props.activity.idCategory} onChange={(e) => { this.props.activity.idCategory = e.target.value as number; this.props.updateParent() }} defaultValue='Choisir catégorie' variant='outlined' style={{ width: 250, marginLeft: 10 }}>

                        <MenuItem value={0} selected>
                            Choisir catégorie
                                </MenuItem>
                        {
                            scheduleCategories.map((category, index) =>
                                <MenuItem key={category.idCategory} value={category.idCategory}>
                                    <Box display='flex'>
                                        <Box width={20} height={20} marginRight={1} bgcolor={CarnetCategory.CarnetCategoryColor[category.idCategory]} borderRadius='50%' />
                                        {category.name}
                                    </Box>
                                </MenuItem>
                            )
                        }
                    </Select>
                    <Button onClick={() => removeActivity()} className={classes.buttonDelete} variant='contained' color='secondary' style={index === 0 ? { visibility: 'hidden' } : {}}>
                        <DeleteSVG />
                    </Button>
                </Box>
                <TextField
                    defaultValue={this.props.activity.title}
                    label='Activité'
                    required
                    InputProps={{ style: { fontWeight: 700 } }}
                    onChange={(e) => { this.onChangeTextField(e, 'title') }} />
                <TextField onChange={(e) => { this.onChangeTextField(e, 'description') }} multiline rows={3} value={this.props.activity.description ?? ''} label='Description' />
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LocationOnSVG className={classes.iconLocation} />
                            </InputAdornment>
                        )
                    }}
                    value={this.props.activity.location ?? ''}
                    label='Localisation'
                    onChange={(e) => { this.onChangeTextField(e, 'location') }} />
            </Box>
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(ProgrammeActivity));