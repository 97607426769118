import { combineEpics } from 'redux-observable';
import { from, of, iif, empty, EMPTY } from 'rxjs';
import { catchError, filter, map, switchMap, tap, take } from 'rxjs/operators';
import { RootEpic } from 'store/types';
import { isActionOf } from 'typesafe-actions';
import { loadMailContactAsync } from './actions';
import { push } from 'connected-react-router';


const loadMailContactEpics: RootEpic = (action$, state$, { parametresService }) => action$.pipe(
    filter(isActionOf(loadMailContactAsync.request)),
    switchMap(() => from(parametresService.loadMailContact()).pipe(
        map(loadMailContactAsync.success),
        catchError(message => of(loadMailContactAsync.failure(message))),
    )),
);



const ContactEpics = combineEpics(
    loadMailContactEpics,
);

export default ContactEpics;