import { B64File } from 'classes/B64File.class';
import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import { setProgressPreUploadedVideo, resetPreUploadVideo } from 'features/murs/src/store/actions';
import { preUploadVideo } from 'features/video/src/store/actions';


const preUploadVideoProgress = createReducer<number, RootAction>(100)
    .handleAction(preUploadVideo.request, (_state, action) => 0) // Regarder les actions, le back ne renvoie plus le fichier mais un idFile (de la vidéo) que
    .handleAction(preUploadVideo.success, (_state, action) => 100) // l'on doit renvoyer ensuite lors de publishPost // IF 100 => on envoie le post (si videoIdFile on envoie avec sinon rien, le back fait iesset(_POST['idFile']))
    .handleAction(setProgressPreUploadedVideo, (_state, action) => action.payload)
    .handleAction(resetPreUploadVideo, (_state, action) => 100)
    .handleAction(preUploadVideo.cancel, (_state, action) => 100)

const preUploadedVideoB64File = createReducer<B64File, RootAction>(null)
    .handleAction(preUploadVideo.request, (_state, action) => null) // Regarder les actions, le back ne renvoie plus le fichier mais un idFile (de la vidéo) que
    .handleAction(preUploadVideo.success, (_state, action) => B64File.rowsToFile(action.payload))
    .handleAction(resetPreUploadVideo, (_state, action) => null)
    .handleAction(preUploadVideo.cancel, (_state, action) => null)


export const videoReducers = combineReducers({
    preUploadVideoProgress,
    preUploadedVideoB64File,
});

export default videoReducers;