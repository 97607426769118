import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import {
    loadFicheListAsync,
    getFicheAsync,
    setSelectedFiche,
    loadListFicheCaraAsync,
    loadListFicheProduitAsync,
    loadListFicheLibreCaraAsync,
    loadListFicheFromMarqueAsync,
    loadCommentairesAsync,
    setCurrentPage,
    setTableParams,
} from './actions';
import { Fiche } from 'classes/level_up/fiches/Fiche.class';
import { FicheCaracteristique } from 'classes/level_up/fiches/FicheCaracteristique.class';
import { FicheProduit } from 'classes/level_up/fiches/FicheProduit.class';
import { FicheLibreGroupeCara } from 'classes/level_up/fiches/FicheLibreGroupeCara.class';
import cloneDeep from 'lodash/cloneDeep'
import { FicheCommentaire } from 'classes/level_up/fiches/FicheCommentaire.class';
import { FilterOptions } from 'components/tables/TeamUpTable.component';

const fiches = createReducer<Fiche[], RootAction>([])
    .handleAction(loadFicheListAsync.success, (_state, action) => cloneDeep(action.payload.liste))
    .handleAction(loadListFicheFromMarqueAsync.success, (_state, action) => cloneDeep(action.payload.liste));

const nbFiches = createReducer<number, RootAction>(0)
    .handleAction(loadFicheListAsync.success, (_state, action) => cloneDeep(action.payload.count));

const isLoadingFiche = createReducer<boolean, RootAction>(false)
    .handleAction([loadFicheListAsync.request, getFicheAsync.request, loadListFicheFromMarqueAsync.request], () => true)
    .handleAction([
        loadFicheListAsync.success, 
        loadFicheListAsync.failure, 
        getFicheAsync.success, 
        getFicheAsync.failure, 
        loadListFicheFromMarqueAsync.success, 
        loadListFicheFromMarqueAsync.failure
    ], () => false);

const selectedFiche = createReducer<Fiche | null, RootAction>(null)
    .handleAction(setSelectedFiche, (_state, action) => action.payload)
    .handleAction(getFicheAsync.success, (_state, action) => cloneDeep(action.payload.fiche))
    .handleAction(loadCommentairesAsync.success, (_state, action) => {
        _state.nbCommentaires = action.payload.length;
        return cloneDeep(_state);
    });

const listeFicheCara = createReducer<FicheCaracteristique[], RootAction>([])
    .handleAction(loadListFicheCaraAsync.success, (_state, action) => action.payload)
    .handleAction(getFicheAsync.success, (_state, action) => cloneDeep(action.payload.listeCaraP_Fiche));

const listeFicheProduit = createReducer<FicheProduit[], RootAction>([])
    .handleAction(loadListFicheProduitAsync.success, (_state, action) => action.payload)
    .handleAction(getFicheAsync.success, (_state, action) => cloneDeep(action.payload.listeProduits));

const listeFicheLibreCara = createReducer<FicheLibreGroupeCara[], RootAction>([])
    .handleAction(loadListFicheLibreCaraAsync.success, (_state, action) => action.payload)
    .handleAction(getFicheAsync.success, (_state, action) => cloneDeep(action.payload.listeGroupesCaraL));

const commentaires = createReducer<FicheCommentaire[], RootAction>([])
    .handleAction(loadCommentairesAsync.success, (_state, action) => cloneDeep(action.payload));

const currentPage = createReducer<number, RootAction>(1)
    .handleAction(setCurrentPage, (_state, action) => action.payload);

const currentTableParams = createReducer<FilterOptions, RootAction>(null)
    .handleAction(setTableParams, (_state, action) => cloneDeep(action.payload));

export const levelUpFichesReducers = combineReducers({
    fiches,
    isLoadingFiche,
    selectedFiche,
    listeFicheCara,
    listeFicheProduit,
    listeFicheLibreCara,
    commentaires,
    currentPage,
    currentTableParams,
    nbFiches
});

export default levelUpFichesReducers;