import { API } from 'classes/level_up/API';
import { Marque } from 'classes/level_up/Marque.class';
import { Famille } from 'classes/level_up/Famille.class';
import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import {
    loadApiListAsync,
    getApiAsync,
    setSelectedAPI,
    loadMarquesAsync,
    loadFamillesAsync,
    loadCategoriesAsync,
    setSelectedCategorie,
    getCategorieAsync,
    loadGroupesCaraAsync,
    sortGroupesCaraAsync,
    getGroupeCaraAsync,
    getFamilleAsync,
    setSelectedGroupeCaracteristique,
    loadCaracteristiquesAsync,
    getCaracteristiqueAsync,
    setSelectedCaracteristique,
    sortCaraAsync,
    sortCaraCleAsync,
    loadCaracteristiquesCleAsync,
    loadCaraFromFamilleAsync,
    loadListeCanauxAsync
} from './actions';
import { getFicheAsync } from 'features/level_up/src/fiches/store/actions';
import { GroupeCaracteristique } from 'classes/level_up/GroupeCaracteristique.class';
import { Categorie } from 'classes/level_up/Categorie';
import { Caracteristique } from 'classes/level_up/Caracteristique';
import cloneDeep from 'lodash/cloneDeep'
import { Metier } from 'classes/referentiel/Metier.class';

const apiList = createReducer<API[], RootAction>([])
    .handleAction(loadApiListAsync.success, (_state, action) => action.payload);

const isLoadingAPI = createReducer<boolean, RootAction>(false)
    .handleAction([loadApiListAsync.request, getApiAsync.request], () => true)
    .handleAction([loadApiListAsync.success, loadApiListAsync.failure, getApiAsync.success, getApiAsync.failure], () => false);

const selectedAPI = createReducer<API | null, RootAction>(null)
    .handleAction(setSelectedAPI, (_state, action) => action.payload)
    .handleAction(getApiAsync.success, (_state, action) => action.payload)

const marques = createReducer<Marque[], RootAction>([])
    .handleAction(loadMarquesAsync.success, (_state, action) => cloneDeep(action.payload))
    .handleAction(getFicheAsync.success, (_state, action) => cloneDeep(action.payload.marque));

const isLoadingMarques = createReducer<boolean, RootAction>(false)
    .handleAction(loadMarquesAsync.request, (_state, action) => true)
    .handleAction([loadMarquesAsync.success, loadMarquesAsync.failure], (_state, action) => false);

const categories = createReducer<Categorie[], RootAction>([])
    .handleAction(loadCategoriesAsync.success, (_state, action) => action.payload);

const isLoadingCategories = createReducer<boolean, RootAction>(false)
    .handleAction([loadCategoriesAsync.request, getCategorieAsync.request], () => true)
    .handleAction([loadCategoriesAsync.success, loadCategoriesAsync.failure, getCategorieAsync.success, getCategorieAsync.failure], () => false);

const selectedCategorie = createReducer<Categorie | null, RootAction>(null)
    .handleAction(setSelectedCategorie, (_state, action) => action.payload)
    .handleAction(getCategorieAsync.success, (_state, action) => action.payload)

const familles = createReducer<Famille[], RootAction>([])
    .handleAction(loadFamillesAsync.success, (_state, action) => action.payload)
    .handleAction(getFamilleAsync.success, (_state, action) => [..._state, action.payload]);

const groupesCara = createReducer<GroupeCaracteristique[], RootAction>([])
    .handleAction(sortGroupesCaraAsync.success, (_state, action) => action.payload)
    .handleAction(loadGroupesCaraAsync.success, (_state, action) => action.payload)
    .handleAction(getGroupeCaraAsync.success, (_state, action) => [..._state, action.payload])
    .handleAction(getFicheAsync.success, (_state, action) => cloneDeep(action.payload.listeGroupesCaraP));

const selectedGroupeCaracteristique = createReducer<GroupeCaracteristique | null, RootAction>(null)
    .handleAction(setSelectedGroupeCaracteristique, (_state, action) => action.payload)
    .handleAction(getGroupeCaraAsync.success, (_state, action) => action.payload)

const caracteristiques = createReducer<Caracteristique[], RootAction>([])
    .handleAction(getFicheAsync.success, (_state, action) => cloneDeep(action.payload.listeCaraP))
    .handleAction([
        loadCaracteristiquesAsync.success,
        sortCaraAsync.success,
        loadCaracteristiquesCleAsync.success,
        sortCaraCleAsync.success,
        loadCaraFromFamilleAsync.success
    ], (_state, action) => action.payload.sort((a, b) => a.ordre - b.ordre));

const isLoadingCaracteristiques = createReducer<boolean, RootAction>(false)
    .handleAction([
        loadCaracteristiquesAsync.request, 
        getCaracteristiqueAsync.request,
        sortCaraAsync.request,
        sortCaraCleAsync.request,
        loadCaracteristiquesCleAsync.request,
        loadCaraFromFamilleAsync.request
    ], () => true)
    .handleAction([
        loadCaracteristiquesAsync.success,
        loadCaracteristiquesAsync.failure,
        getCaracteristiqueAsync.success,
        getCaracteristiqueAsync.failure,
        sortCaraAsync.success,
        sortCaraAsync.failure,
        sortCaraCleAsync.success,
        sortCaraCleAsync.failure,
        loadCaracteristiquesCleAsync.success,
        loadCaracteristiquesCleAsync.failure,
        loadCaraFromFamilleAsync.failure
    ], () => false);

const selectedCaracteristique = createReducer<Caracteristique | null, RootAction>(null)
    .handleAction(setSelectedCaracteristique, (_state, action) => action.payload)
    .handleAction(getCaracteristiqueAsync.success, (_state, action) => action.payload)

const canaux = createReducer<Metier[], RootAction>([])
    .handleAction(loadListeCanauxAsync.success, (_state, action) => action.payload);

export const levelUpParametresReducers = combineReducers({
    apiList,
    isLoadingAPI,
    selectedAPI,
    marques,
    categories,
    isLoadingCategories,
    selectedCategorie,
    familles,
    groupesCara,
    selectedGroupeCaracteristique,
    caracteristiques,
    isLoadingCaracteristiques,
    selectedCaracteristique,
    canaux,
    isLoadingMarques
});

export default levelUpParametresReducers;