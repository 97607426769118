import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { colors } from 'Theme';

export default ({ spacing, palette }: typeof Theme) => createStyles({

    paperNbrParticipants : {
        height : 55,
        padding : `${spacing(1.5)}px ${spacing(3)}px`
    },
    nbrParticipantsText : {
        fontSize : 18
    },
    subheaderLetter : {
        height : 35,
        fontWeight : 700,
        color : colors.grey.main,
        fontSize : 18,
        verticalAlign : 'middle',
        paddingLeft : spacing(3),
        display: 'inline'
    },
    listParticipants : {
        padding : 0
    },
    listItemParticipant : {
        padding : spacing(1)
    },
    listItemIconAvatar : {
        height : '100%'
    },
    listItemTextParticipant : {
        fontSize : 14,
        fontWeight : 700
    },
    listItemLeftIcon: {
        minWidth: 30
    }
});