import * as React from 'react';
import { JWTToken } from './classes/auth/JWTToken.class';

type TypeAuthContext = {
  loading: boolean,
  token: string,
  data: JWTToken,
  updateAuth? :  (auth : JWTToken) => Promise<void>
}

/**
* Identical to the Session type, but without the `issued` and `expires` properties.
*/
export type PartialSession = Omit<JWTToken, "issued" | "expires">;

export type DecodeResult =
  | {
    type: "valid";
    session: JWTToken;
  }
  | {
    type: "integrity-error";
  }
  | {
    type: "invalid-token";
  };

export type ExpirationStatus = "expired" | "active" | "grace";

export const AuthContext = React.createContext<TypeAuthContext>({
  loading: false,
  token: undefined,
  data: null
});

const getDataFromToken = (token: string) => {
  if (token) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (error) {
      // ignore
    }
  }

  return null;
};

type AuthProviderProps = {
  children ?: React.ReactNode
}
export const AuthProvider = ({ children } : AuthProviderProps) => {
  const [state, setState] = React.useReducer((oldState: TypeAuthContext, newState: TypeAuthContext) => newState, {
    loading: true,
    token: undefined,
    data: null,
  });

  const updateAuth = async (auth : JWTToken) => {
    const token = (await auth.getToken()) || null;
    if (token !== state.token) {
      setState({
        token,
        loading: false,
        data: getDataFromToken(token),
      });
    }
  };

  return (
    <AuthContext.Provider value={{ ...state, updateAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
