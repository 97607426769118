import React, { useState, useEffect } from 'react';
import { useSelector } from 'store/rootStore';
import isEqual from 'lodash/isEqual';
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import VisibilitySVG from '@material-ui/icons/Visibility';
import VisibilityOffSVG from '@material-ui/icons/VisibilityOff';
import useStyle from './SettingDrawer.style';
import { Setting } from './Setting.interface';
import moment, { isMoment } from 'moment';
import { KeyboardDatePicker } from "@material-ui/pickers";
import classNames from 'classnames';
import { Typography } from '@material-ui/core';


interface SettingDrawerProps {
    setting: Setting,
    onClose: (save: boolean) => void
}

const SettingDrawer: React.FC<SettingDrawerProps> = props => {
    const classes = useStyle();

    const [currentValue, setCurrentValue] = useState(null);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {

        if (props.setting.type === 'date') {
            let momentDate = moment(props.setting.value, "YYYYMMDD");
            if (!isMoment(momentDate) || isMoment(momentDate) && !momentDate.isValid()) {
                setCurrentValue(moment());
            } else {
                setCurrentValue(momentDate);
            }

        } else if (props.setting.type === 'password') {

            setCurrentValue( {
                    fields: {
                        old: "",
                        new: "",
                        confirm: ""
                    },
                    visibility: {
                        old: false,
                        new: false,
                        confirm: false
                    },
                    focus: {
                        old: false,
                        new: false,
                        confirm: false
                    }

                }
            )
        } else {
            setCurrentValue(props.setting.value);
        }
    }, []);

    useEffect(() => {
        setError(props.setting.regex ? !props.setting.regex.test(currentValue) : false);
    }, [currentValue]);

    function renderInputField() : JSX.Element {
        if (props.setting.type === 'date') {
            return <KeyboardDatePicker
                autoFocus
                fullWidth
                autoOk
                inputVariant="outlined"
                label={props.setting.name}
                format="DD/MM/YYYY"
                value={currentValue}
                onChange={date => setCurrentValue(date)}
                cancelLabel={"Annuler"}
                clearLabel={"Vider"}
                okLabel={"Valider"}
                todayLabel={"Aujourd'hui"}
                onBlur={() => { }}
                onFocus={() => { }}
            />;

        } else if (props.setting.type === 'password') {

            return <Box height={200} display='flex' flexDirection='column' justifyContent='space-between'>
                <TextField
                    label={"Ancien " + props.setting.name.toLowerCase()}
                    autoFocus
                    fullWidth
                    className={classes.drawerInfosLabel}
                    onFocus={() => setCurrentValue( { ...currentValue, focus: { old: true, new: false, confirm: false } } )}
                    variant="outlined"
                    value={currentValue.fields.old || ''}
                    onChange={(event) => setCurrentValue({
                        fields: {
                            ...currentValue.fields,
                            old: event.target.value
                        },
                        visibility: {
                            ...currentValue.visibility
                        },
                        focus: {
                            ...currentValue.focus
                        }
                    })}
                    type={currentValue.visibility.old ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (

                            <InputAdornment position="end">
                                {currentValue.focus.old &&
                                    <IconButton onClick={() => setCurrentValue( { ...currentValue, visibility: { ...currentValue.visibility, old: !currentValue.visibility.old } })}>
                                        {currentValue.visibility.old ?
                                            <VisibilitySVG />
                                            :
                                            <VisibilityOffSVG />
                                        }
                                    </IconButton>
                                }
                            </InputAdornment>
                        )
                    }
                    }
                />
                <TextField
                    label={"Nouveau " + props.setting.name.toLowerCase()}
                    fullWidth
                    className={classes.drawerInfosLabel}
                    variant="outlined"
                    onFocus={() => setCurrentValue( { ...currentValue, focus: { old: false, new: true, confirm: false } } )}
                    value={currentValue.fields.new || ''}
                    onChange={(event) => setCurrentValue({
                        fields: {
                            ...currentValue.fields,
                            new: event.target.value,
                        },
                        visibility: {
                            ...currentValue.visibility
                        },
                        focus: {
                            ...currentValue.focus
                        }
                    })}
                    type={currentValue.visibility.new ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {currentValue.focus.new &&
                                    <IconButton onClick={() => setCurrentValue( { ...currentValue, visibility: { ...currentValue.visibility, new: !currentValue.visibility.new } } )}>
                                        {currentValue.visibility.new ?
                                            <VisibilitySVG />
                                            :
                                            <VisibilityOffSVG />
                                        }
                                    </IconButton>
                                }
                            </InputAdornment>
                        )
                    }
                    }
                />
                <Typography className={classes.drawerInfosLabel}>Votre mot de passe doit comporter 8 caractères minimum et 16 maximum avec une majuscule, une minuscule, un chiffre.</Typography>
                <TextField
                    label={"Confirmation"}
                    fullWidth
                    className={classes.drawerInfosLabel}
                    variant="outlined"
                    onFocus={() => setCurrentValue( { ...currentValue, focus: { old: false, new: false, confirm: true } } )}
                    value={currentValue.fields.confirm || ''}
                    onChange={(event) => setCurrentValue({
                        fields: {
                            ...currentValue.fields,
                            confirm: event.target.value
                        },
                        visibility: {
                            ...currentValue.visibility
                        },
                        focus: {
                            ...currentValue.focus
                        }
                    })}
                    type={currentValue.visibility.confirm ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {currentValue.focus.confirm &&
                                    <IconButton onClick={() => setCurrentValue({ ...currentValue, visibility: { ...currentValue.visibility, confirm: !currentValue.visibility.confirm } })}>
                                        {currentValue.visibility.confirm ?
                                            <VisibilitySVG />
                                            :
                                            <VisibilityOffSVG />
                                        }
                                    </IconButton>
                                }
                            </InputAdornment>
                        )
                    }
                    }
                />
            </Box>
        } else {

            return <TextField
                label={props.setting.name}
                autoFocus
                fullWidth
                variant="outlined"
                value={currentValue || ''}
                onChange={(event) => setCurrentValue(event.target.value)}
                helperText={props.setting.errorMessage}
            />;
        }
    }

    return (
        <div>
            <Drawer anchor="bottom" open onClose={() => props.onClose(false)}>

            <div className={classes.centerDiv}>
                <div className={classes.innerDiv}>

                <form onSubmit={(e) => {
                    e.preventDefault()
                    if (!error) {
                        props.setting.value = currentValue;
                        props.onClose(true);
                    }
                }}>
                    <h2 className={classes.drawerTitle}>{props.setting.action == 'Ajouter' ? "Ajouter" : "Modifier"} {props.setting.prefix} {props.setting.name.toLowerCase()}</h2>
                    {(currentValue || currentValue == '') && renderInputField()}
                    
                    <div className={classNames(classes.centerDiv, classes.drawerButtons)}>
                        <div className={classes.innerDiv}>

                        <Button variant="contained" size="large" className={classNames(classes.drawerCancel, classes.drawerButton)} onClick={() => props.onClose(false)}>
                            Annuler
                        </Button>
                        <Button variant="contained" size="large" className={classNames(classes.drawerValidate, classes.drawerButton)} type="submit" color="primary" disabled={error}>
                            Valider
                        </Button>

                        </div>
                    </div>
                    
                    
                </form>

                </div>
            </div>

            </Drawer>
        </div>
    );
}

export default SettingDrawer;