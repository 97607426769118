import { from, of } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { catchError, filter, first, map, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { RootEpic } from 'store/types';
import { isActionOf } from 'typesafe-actions';
import { loadPcPostsAsync } from './actions';
import { Post } from "classes/animations/concoursPhoto/Post.class";

const loadPcPostsEpic: RootEpic = (action$, state$, { concoursPhotoService } ) => action$.pipe(
    filter(isActionOf(loadPcPostsAsync.request)),
    switchMap(action => from(concoursPhotoService.loadPosts(action.payload[0], action.payload.slice(1,action.payload.length-1), action.payload[action.payload.length-1])).pipe(
        map(loadPcPostsAsync.success),
        catchError(message => of(loadPcPostsAsync.failure(message)))
    ))
);



const AnimationsConcoursPhotoEpics = combineEpics(loadPcPostsEpic);

export default AnimationsConcoursPhotoEpics;