import { DeepPartial } from 'redux';
import { ContenusService } from 'services';
import { DeepRawify } from 'types';
import { v4 } from 'uuid'

/**

Pour ajouter un type
- Ajouter le bloc dans le const typesBlocs de ContenusBlocComponent en atttribuant un ID et un nom
- Créer sa classe dans le back (classes/contenus/types_blocs)
- L'ajouter dans le switch dans rowToObject de ContenuBloc avec l'ID choisi (penser à l'import de la classe)
- L'ajouter dans le switch dans SAVE_BLOC de la rub_admin_contenus avec l'ID choisi (penser à l'import de la classe)
- Créer la classe en TS (front/react/classes/contenus/types_blocs/)
- L'ajouter dans la fonction mapToBloc du service (services/contenus/)
- Ajout dans ContenuBlocComponent : import, renderBloc(), createBloc(), getBlocIcon() -> ajouter l'icone svg, et getModifyForm()

*/

/**
 * Super type de tous les contenus (dans types_blocs/*.class.ts)
 */
export abstract class ContenuBloc {

    public uid: string = v4();

    public idBloc : number;

    public laLigne : number;

    public indexBloc : number;

    public idTypeBloc : number;

    public contenusService = ContenusService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<ContenuBloc>>) {
        if (row) {
            const { idBloc,
                laLigne,
                indexBloc,
                idTypeBloc
            } = row;
            this.idBloc = idBloc;
            this.laLigne =laLigne;
            this.indexBloc = indexBloc;
            this.idTypeBloc = idTypeBloc;
        }
    }

    /* Implémentées dans les sous types */

    public abstract toRaw() : {};

    public abstract save(): Promise<number>;

    public abstract delete(): Promise<Boolean>;

    public abstract isEmpty() : boolean;

}
