import Axios from "axios";
import qs from "qs";
import { SignalementsFilterState, SignalementsFiltersInfos } from "features/admin/signalements/src/store/types";
import { Signalement } from "classes/signalements/Signalement.class";
import { DeepRawify } from "types";

export class SignalementsService {
    private static instance: SignalementsService;

    public static getInstance(): SignalementsService {
        if (!SignalementsService.instance) {
            SignalementsService.instance = new SignalementsService();
        }

        return SignalementsService.instance;
    }

    private static rubriqueMur = IS_ADMIN ? 260 : 26;

    public async loadSignalements(signalementsFilters: SignalementsFilterState): Promise<Signalement[]> {
        const params = {
            rub: SignalementsService.rubriqueMur,
            filters: JSON.stringify(signalementsFilters),
            p: 1
        };

        return Axios.get<{ content: DeepRawify<Signalement>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: signalements } }) => signalements.map((signalement) => new Signalement(signalement)));
    }

    public async loadSignalementsInfos(): Promise<SignalementsFiltersInfos> {
        const params = {
            rub: SignalementsService.rubriqueMur,
            p: 2
        };

        return Axios.get<{ content: SignalementsFiltersInfos }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content);
    }

    public saveSignalement(signalement: Signalement): Promise<void> {
        return Axios.post(`index.php?${qs.stringify({
            rub: SignalementsService.rubriqueMur,
            p: 3
        })}`, qs.stringify({ signalement: JSON.stringify(signalement.toDatabaseObject()) }));
    }


    public async signalerPost(typeMur: number, idMurPost: number, raison: number, description: string): Promise<boolean> {
        return Axios.post(`index.php?${qs.stringify({
            rub: SignalementsService.rubriqueMur,
            p: 4
        })}`, qs.stringify({ typeMur, idMurPost, raison, description }))
            .then(() => true)
            .catch(() => false);
    }



    public async loadSignalementsCount(): Promise<number> {
        return Axios.get<{ content: number }>(`index.php?${qs.stringify({
            rub: SignalementsService.rubriqueMur,
            p: 5
        })}`)
            .then(({ data: { content: signalementsCount } }) => signalementsCount);
    }

    public async signalerComment(typeMur: number, idMurCommentaire: number, raison: number, description: string): Promise<boolean> {
        return Axios.post(`index.php?${qs.stringify({
            rub: SignalementsService.rubriqueMur,
            p: 6
        })}`, qs.stringify({
            typeMur,
            idMurCommentaire,
            raison,
            description
        }))
            .then(() => true)
            .catch(() => false);
    }
}
