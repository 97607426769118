import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { StyleRules } from '@material-ui/styles';
import { colors } from 'Theme';
import color from '@material-ui/core/colors/lime';

export default ({ spacing }: typeof Theme): StyleRules => createStyles({

    statisticDisplayer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems : 'center',
        width: '100%'
    },
    statistic: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems : 'center',
        margin : 'auto'
    },
    text: {
        /* Style for "Score moye" */
        color: colors.grey.main,
        fontSize: 12,
        fontWeight: 400
    },
    value : {
        margin : spacing(1, 0, 0, 0)
    }
});