
import Axios from 'axios';
import { Personne } from 'classes/messagerie/Personne.class';
import { Groupe } from 'classes/messagerie/Groupe.class';
import { Message } from 'classes/messagerie/Message.class';
import { MessageGroupe } from 'classes/messagerie/MessageGroupe.class';
import { MessagePersonne } from 'classes/messagerie/MessagePersonne.class';
import qs from 'qs';
import { JWTResult, LoadMessagerieResult, SearchMessageParams } from 'features/messagerie/src/store/types';
import { DeepRawify } from 'types';

export class MessagerieService {
    private static instance: MessagerieService;

    private static rubModule: number = 665;

    public static getInstance(): MessagerieService {
        if (!MessagerieService.instance) {
            MessagerieService.instance = new MessagerieService();
        }

        return MessagerieService.instance;
    }

    /**
     * Chargement des informations d’un groupe
     * @param leGroupe identifiant du groupe à charger
     * @returns un objet Groupe
     */
    public loadGroupe(leGroupe: number): Promise<Groupe> {
        const params = {
            rub: MessagerieService.rubModule,
            action: "LOAD_GROUPE",
            leGroupe
        };

        return Axios.get<{ content: DeepRawify<Groupe> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: groupe } }) => new Groupe(groupe));
    }

    /**
     * TODO Tester transfert de Personne vers Personne 
     * 
     * Chargement de la liste des Personnes d’un groupe
     * @param leGroupe identifiant du groupe
     * @returns une liste de Personnes
     */
    public loadListePersonnes(leGroupe: number): Promise<Personne[]> {
        const params = {
            rub: MessagerieService.rubModule,
            action: "LOAD_MEMBER_LIST",
            leGroupe
        };

        return Axios.get<{ content: DeepRawify<Personne>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map((personne) => new Personne(personne)));
    }

    /**
     * Retirer une personne d’un groupe
     * @param leGroupe identifiant du groupe
     * @param leMembre identifiant de l'utilisateur
     * @returns succès de l'opération
     */
    public kickMember(leMembre: string, leGroupe: number): Promise<Boolean> {
        const params = {
            rub: MessagerieService.rubModule,
            action: "KICK_MEMBER",
            leGroupe,
            leMembre
        };

        return Axios.get(`index.php?${qs.stringify(params)}`)
    }

    /**
     * Ajouter des personnes à un groupe
     * @param leGroupe identifiant du groupe
     * @param leMembre identifiant de l'utilisateur
     * @returns succès de l'opération
     */
    public addMembers(postMembres: string[], leGroupe: number): Promise<Boolean> {
        const params = {
            rub: MessagerieService.rubModule,
            action: "ADD_MEMBERS"
        };

        return Axios.post(`index.php?${qs.stringify(params)}`, qs.stringify({
            leGroupe,
            postMembres: JSON.stringify(postMembres),
        }))
    }

    /**
     * Suppression d’un groupe
     * @param leGroupe identifiant du groupe
     * @returns succès de l'opération
     */
    public deleteGroup(leGroupe: number): Promise<Boolean> {
        const params = {
            rub: MessagerieService.rubModule,
            action: "SUPPRESSION_GROUPE",
            leGroupe
        };

        return Axios.get(`index.php?${qs.stringify(params)}`)
    }


    /**
     * Création d’un groupe
     * @param postGroupe Objet Groupe à sauvegarder
     * @returns succès de l'opération
     */
    public createGroup(postGroupe: Groupe): Promise<number> {
        const params = {
            rub: MessagerieService.rubModule,
            action: "CREATION_GROUPE"
        };

        return Axios.post<{ content: number }>(`index.php?${qs.stringify(params)}`, qs.stringify({ groupe: JSON.stringify(postGroupe.toRaw()) }))
            .then(({ data: { content: id } }) => id);
    }

    /**
     * Recherche de messages
     * @param row paramètres de la recherche
     * @returns liste des messages correspondants
     */
    public searchMessages(row: SearchMessageParams): Promise<(MessageGroupe | MessagePersonne)[]> {
        const params = {
            rub: MessagerieService.rubModule,
            action: "SEARCH_MESSAGES"
        };

        return Axios.post<{ content: (DeepRawify<MessageGroupe | MessagePersonne>)[] }>(
            `index.php?${qs.stringify(params)}`,
            qs.stringify(row)
        )
            .then(({ data: { content } }) =>
                content.map((message) => "idMessageGroupe" in message ? new MessageGroupe(message) : new MessagePersonne(message))
            );
    }

    /**
     * Envoie et sauvegarde un message de groupe
     * @param message données du message à envoyer
     * @returns les données du message (contenant l'ID)
     */
    public sendMessageGroupe(message: MessageGroupe): Promise<MessageGroupe> {
        const params = {
            rub: MessagerieService.rubModule,
            action: "SEND_MESSAGE_GROUP"
        };

        return Axios.post<{ content: number }>(`index.php?${qs.stringify(params)}`, qs.stringify({ message: JSON.stringify(message.toRaw()) }))
            .then(({ data: { content: id } }) => {
                message.idMessage = message.idMessageGroupe = id;

                return message;
            });
    }

    /**
     * Envoie et sauvegarde un message personnel
     * @param message données du message à envoyer
     * @returns les données du message (contenant l'ID)
     */
    public sendMessagePersonne(message: MessagePersonne): Promise<MessagePersonne> {
        const params = {
            rub: MessagerieService.rubModule,
            action: "SEND_MESSAGE_PERSO"
        };

        return Axios.post<{ content: number }>(`index.php?${qs.stringify(params)}`, qs.stringify({ message: JSON.stringify(message.toRaw()) }))
            .then(({ data: { content: id } }) => {
                message.idMessage = message.idMessagePersonne = id;

                return message;
            });
    }

    /**
     * Supprime un message
     * @param idMessageGroupe ID du message de groupe à supprimer
     * @param idMessagePersonne ID du message perso à supprimer
     * @returns 
     */
    public deleteMessage(
        idMessageGroupe?: number,
        idMessagePersonne?: number
    ): Promise<Boolean> {
        const params = {
            rub: MessagerieService.rubModule,
            action: "DELETE_MESSAGE",
            idMessageGroupe,
            idMessagePersonne
        };

        return Axios.get<{ content: boolean }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: ok } }) => ok);
    }

    /**
     * Chargement des données de la messagerie (appel groupé)
     * @returns données de base
     */
    public loadMessagerie(): Promise<LoadMessagerieResult> {
        const params = {
            rub: MessagerieService.rubModule,
            action: "LOAD_MESSAGERIE"
        };

        return Axios.get<{ content: DeepRawify<LoadMessagerieResult> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => ({
                personnes: content.personnes.map((personne) => new Personne(personne)),
                groupes: content.groupes.map((groupe) => new Groupe(groupe)),
                me: new Personne(content.me)
            }));
    }

    /**
     * Supprime tous les messages envoyé par un utilisateur dans une conversation
     * @param recepteur destinataire des messages à supprimer
     * @param inGroup si ce sont des messages de groupe ou perso
     * @returns succès de l'opération
     */
    public deleteAll(recepteur: number | string, inGroup: boolean): Promise<Boolean> {
        const params = {
            rub: MessagerieService.rubModule,
            action: "DELETE_ALL",
            recepteur,
            inGroup
        };
        return Axios.get<{ content: boolean }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: ok } }) => ok);
    }

    /**
     * Chargement des infos d'une personne
     * @param leMembre identifiant de la personne
     * @returns un objet Personne
     */
    public loadPersonne(leMembre: string): Promise<Personne> {
        const params = {
            rub: MessagerieService.rubModule,
            action: "LOAD",
            leMembre
        };

        return Axios.get<{ content: DeepRawify<Personne> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: personne } }) => new Personne(personne));
    }

    /**
     * Recherche d'une personne à partir de son nom
     * @param query texte de la recherche
     * @returns un objet Personne
     */
    public search(query = ''): Promise<Personne[]> {
        const params = {
            rub: MessagerieService.rubModule,
            action: "SEARCH",
            query
        };

        return Axios.get<{ content: DeepRawify<Personne>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map((personne) => new Personne(personne)));
    }

    /**
     * Définit une conversation comme lue
     * @param idConversation identifiant de la conversation
     * @param group est-ce dans un groupe ?
     * @returns l'identifiant de la conversation
     */
    public setConversationRead(idConversation: number | string, group: boolean): Promise<number> {
        const params = {
            rub: MessagerieService.rubModule,
            action: "SET_CONVERSATION_READ",
            idConversation,
            group
        };

        return Axios.get<{ content: number }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: ok } }) => ok);
    }

    /**
     * Bloque une personne X
     * @param leMembre identifiant de la personne
     */
    public creationBlocage(leMembre: string): Promise<Boolean> {
        const params = {
            rub: MessagerieService.rubModule,
            action: "CREATION_BLOCAGE",
            leMembre
        };
        return Axios.get<{ content: boolean }>(`/index.php?${qs.stringify(params)}`)
            .then(({ data: { content: ok } }) => ok);
    }

    /**
     * Débloque une personne X
     * @param leMembre identifiant de la personne
     */
    public suppressionBlocage(leMembre: string): Promise<Boolean> {
        const params = {
            rub: MessagerieService.rubModule,
            action: "SUPPRESSION_BLOCAGE",
            leMembre
        };

        return Axios.get<{ content: boolean }>(`/index.php?${qs.stringify(params)}`)
            .then(({ data: { content: ok } }) => ok);
    }

    /**
     * Demande le chargement du JSON Web Token servant à se connecter au WebSocket
     */
    public loadJWT(): Promise<JWTResult> {
        const params = {
            rub: MessagerieService.rubModule,
            action: "LOAD_JWT"
        };

        return Axios.get<{ content: JWTResult }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: jwtr } }) => jwtr);
    }
}
