import Typography from '@material-ui/core/Typography';
import { WithStyles } from '@material-ui/styles';
import withStyles from '@material-ui/styles/withStyles';
import React from 'react';
import style from './StatisticsDisplayer.style';

export interface StatisticsDisplayerBaseProps {
    statistics: Array<{
        icon: string,
        value: string,
        text: string,
        className?: string,
        iconClassName? : string
    }>,
    className?: string;
}

type StatisticsDisplayerProps = StatisticsDisplayerBaseProps & WithStyles<typeof style>;

class StatisticsDisplayer extends React.Component<StatisticsDisplayerProps> {

    render() {
        const { classes } = this.props;
        var statistiques = this.props.statistics.map((statistic, index) => {
            return (
                <div className={statistic.className ? statistic.className : classes.statistic} key={index}>
                    <img src={statistic.icon} className={statistic.iconClassName ? statistic.iconClassName : ''} />
                    <Typography className={classes.value}>{statistic.value}</Typography>
                    <Typography className={classes.text}>{statistic.text}</Typography>
                </div>
            )
        
        });
        var elem = (
            <div className={this.props.className ? this.props.className : classes.statisticDisplayer}>
                {statistiques}
            </div>
        )
        return elem;
    }
}

export default withStyles(style)(StatisticsDisplayer);
