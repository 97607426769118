import 'emoji-picker-element';
import fr from 'emoji-picker-element/i18n/fr';
import React from 'react';

type EmojiPickerProps = {
    onClick: (emoji: string) => void;
}

const EmojiPicker: React.FC<EmojiPickerProps> = (props) => {
    const ref = React.useRef(null)
    
    React.useEffect(() => {
        ref.current.addEventListener('emoji-click', (event: CustomEvent) => {
            props.onClick(event.detail.unicode)
        })
        ref.current.i18n = fr;
    }, []);

    return React.createElement('emoji-picker', { ref }) 
}

export default EmojiPicker;