import Axios from 'axios';
import { B64File } from 'classes/B64File.class';
import { API } from 'classes/level_up/API';
import { Caracteristique } from 'classes/level_up/Caracteristique';
import { Categorie } from 'classes/level_up/Categorie';
import { Famille } from 'classes/level_up/Famille.class';
import { Fiche } from 'classes/level_up/fiches/Fiche.class';
import { FicheCaracteristique } from 'classes/level_up/fiches/FicheCaracteristique.class';
import { FicheCommentaire } from 'classes/level_up/fiches/FicheCommentaire.class';
import { FicheLibreGroupeCara } from 'classes/level_up/fiches/FicheLibreGroupeCara.class';
import { FicheProduit } from 'classes/level_up/fiches/FicheProduit.class';
import { GroupeCaracteristique } from 'classes/level_up/GroupeCaracteristique.class';
import { Marque } from 'classes/level_up/Marque.class';
import { Metier } from 'classes/referentiel/Metier.class';
import { SortParams } from 'features/level_up/src/parametres/store/types';
import { FicheDetails, FicheListRequest, FicheListResult } from 'features/level_up/src/fiches/store/types';
import qs from 'qs';
import { DeepRawify } from 'types';
import { FicheQuestion } from 'classes/level_up/quiz/FicheQuestion.class';
import { FicheQuestionGroupe } from 'classes/level_up/quiz/FicheQuestionGroupe.class';
import { ClassementAnswer, QuizInfos, ClassementRequest } from 'features/level_up/src/quiz/store/types';
import { Acteur } from 'classes/Acteur.class';
import { FichesStatistiquesParameters, LevelUpStatistiques } from 'features/admin/level_up/src/statistiques/store/types';
import { Animation } from 'classes/level_up/animations/Animation.class';
import { Brique } from 'classes/level_up/animations/Brique.class';
import { Battle } from 'classes/level_up/battles/Battle.class';
import { InfosBattles, InfosRecap, Opponent } from 'features/level_up/src/battles/store/types';
import moment from 'moment';

// Level Up est séparé en 4 rubriques pour ne pas avoir des fichiers trop 
// longs. 4 rub BO et 1 rub FO. C'est le service qui choisit la bonne rub 
// et les bonnes pages en fonction de la variable IS_ADMIN. Les types de 
// retour sont les mêmes pour les actions BO et FO vu qu'on utilise les
// mêmes reducer, actions, etc...

// En revanche je n'ai pas split le service (bonne lecture)

const rub_parametres = IS_ADMIN ? 110 : 14;
const rub_fiches = IS_ADMIN ? 120 : 14;
const rub_quiz = IS_ADMIN ? 140 : 14;
const rub_animations = IS_ADMIN ? 150 : 14;
const rub_statistiques = IS_ADMIN ? 160 : 14;
const rub_battle = IS_ADMIN ? "" : 15;

export class LevelUpService {
    private static instance: LevelUpService;

    public static getInstance(): LevelUpService {
        if (!LevelUpService.instance) {
            LevelUpService.instance = new LevelUpService();
        }

        return LevelUpService.instance;
    }

    /**
     * Parametres
     */

    public async loadListAPI(): Promise<API[]> {
        const params = {
            rub: rub_parametres,
            p: 1
        };

        return Axios.get<{ content: DeepRawify<API>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: apiList } }) => apiList.map(api => new API(api)));
    }

    public async findAPI(idApi: number): Promise<API> {
        const params = {
            rub: rub_parametres,
            p: 2,
            idApi,
        };

        return Axios.get<{ content: DeepRawify<API> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: api } }) => new API(api));
    }

    public async saveAPI(api: API): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_parametres,
                p: 3
            })}`,
            qs.stringify({
                api: JSON.stringify(api.toRaw())
            })
        )
            .then(() => true)
            .catch(() => false);
    }

    public async deleteAPI(idApi: number): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_parametres,
                p: 4,
            })}`,
            qs.stringify({ idApi })
        )
            .then(() => true)
            .catch(() => false);
    }

    /**
     * Obtenir la liste des marques
     */
    public async listMarques(): Promise<Marque[]> {
        const params = {
            rub: rub_parametres,
            p: IS_ADMIN ? 10 : 2
        };

        return Axios.get<{ content: DeepRawify<Marque>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: marques } }) => marques.map((marque) => new Marque(marque)));
    }

    /**
     * Sauvegarder une marque
     */
    public async saveMarque(m: Marque): Promise<Marque> {
        const params = {
            rub: rub_parametres,
            p: 11,
        };

        return Axios.post<{ content: DeepRawify<Marque> }>(
            `index.php?${qs.stringify(params)}`,
            qs.stringify({ marque: JSON.stringify(m.toRaw()) })
        ).then(({ data: { content: marque } }) => new Marque(marque));
    }

    /**
     * Supprimer une marque
     */
    public async deleteMarque(idMarque: number): Promise<boolean> {
        const params = {
            rub: rub_parametres,
            p: 12,
            idMarque
        };

        return Axios.get<{ content: boolean }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: ok } }) => ok);
    }

    /**
     * Import de marques
     */
    public async importMarques(replace: boolean, file: B64File): Promise<boolean> {
        return Axios.post<{ content: string }>(
            `index.php?${qs.stringify({
                rub: rub_parametres,
                p: 13
            })}`,
            qs.stringify({
                replace: replace ? 1 : 0,
                file: JSON.stringify(file)
            })
        )
            .then(({ data: { content: message } }) => true)
            .catch(({ response: { data: { message } } }) => false);
    }

    public async loadCategories(): Promise<Categorie[]> {
        const params = {
            rub: rub_parametres,
            p: IS_ADMIN ? 5 : 1
        };

        return Axios.get<{ content: DeepRawify<Categorie>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: categories } }) => categories.map(categorie => new Categorie(categorie)));
    }

    public async findCategorie(idCategorie: number): Promise<Categorie> {
        const params = {
            rub: rub_parametres,
            p: 6,
            idCategorie,
        };

        return Axios.get<{ content: DeepRawify<Categorie> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: categorie } }) => new Categorie(categorie));
    }

    public async saveCategorie(categorie: Categorie): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_parametres,
                p: 7
            })}`,
            qs.stringify({
                categorie: JSON.stringify(categorie.toRaw())
            })
        )
            .then(() => true)
            .catch(() => false);
    }

    public async deleteCategorie(idCategorie: number): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_parametres,
                p: 8,
            })}`,
            qs.stringify({ idCategorie })
        )
            .then(() => true)
            .catch(() => false);
    }

    /**
     * Obtenir la liste des familles
     * @param idCategorie Catégorie parente
     */
    public async listFamilles(idCategorie: number): Promise<Famille[]> {
        const params = {
            rub: rub_parametres,
            p: 20,
            idCategorie
        };

        return Axios.get<{ content: DeepRawify<Famille>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: familles } }) => familles.map((famille) => new Famille(famille)));
    }

    /**
     * Sauvegarder une famille
     */
    public async saveFamille(m: Famille): Promise<Famille> {
        const params = {
            rub: rub_parametres,
            p: 21,
        };

        return Axios.post<{ content: DeepRawify<Famille> }>(
            `index.php?${qs.stringify(params)}`,
            qs.stringify({ famille: JSON.stringify(m.toRaw()) })
        ).then(({ data: { content: famille } }) => new Famille(famille));
    }

    /**
     * Supprimer une famille
     */
    public async deleteFamille(idFamille: number): Promise<boolean> {
        const params = {
            rub: rub_parametres,
            p: 22,
            idFamille
        };

        return Axios.get<{ content: boolean }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: ok } }) => ok);
    }

    /**
     * Obtenir une famille
     */
    public async getFamille(idFamille: number): Promise<Famille> {
        const params = {
            rub: rub_parametres,
            p: 24,
            idFamille
        };

        return Axios.get<{ content: DeepRawify<Famille> }>(
            `index.php?${qs.stringify(params)}`
        ).then(({ data: { content: f } }) => new Famille(f));
    }

    /**
     * Obtenir la liste des groupes caracteristiques
     * @param idFamille Famille parente
     */
    public async listGroupesCaracteristiques(idFamille: number): Promise<GroupeCaracteristique[]> {
        const params = {
            rub: rub_parametres,
            p: 30,
            idFamille
        };

        return Axios.get<{ content: DeepRawify<GroupeCaracteristique>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: groupesCara } }) => groupesCara.map((groupeCara) => new GroupeCaracteristique(groupeCara)));
    }

    /**
     * Sauvegarder une groupecaracteristique
     */
    public async saveGroupeCaracteristique(m: GroupeCaracteristique): Promise<GroupeCaracteristique> {
        const params = {
            rub: rub_parametres,
            p: 31,
        };

        return Axios.post<{ content: DeepRawify<GroupeCaracteristique> }>(
            `index.php?${qs.stringify(params)}`,
            qs.stringify({ groupecaracteristique: JSON.stringify(m.toRaw()) })
        ).then(({ data: { content: groupeCara } }) => new GroupeCaracteristique(groupeCara));
    }

    /**
     * Supprimer une groupecaracteristique
     */
    public async deleteGroupeCaracteristique(idGroupeCaracteristique: number): Promise<boolean> {
        const params = {
            rub: rub_parametres,
            p: 32,
            idGroupeCaracteristique
        };

        return Axios.get<{ content: boolean }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: ok } }) => ok);
    }

    /**
     * Trier les groupes caracteristiques
     * @param idFamille Famille parente
     */
    public async sortGroupesCaracteristiques(props: SortParams): Promise<GroupeCaracteristique[]> {
        const params = {
            rub: rub_parametres,
            p: 33,
            idFamille: props.idParent
        };

        return Axios.post<{ content: DeepRawify<GroupeCaracteristique>[] }>(
            `index.php?${qs.stringify(params)}`,
            qs.stringify({ groupes: props.items })
        )
            .then(({ data: { content: groupesCara } }) => groupesCara.map((groupeCara) => new GroupeCaracteristique(groupeCara)));
    }

    /**
     * Obtenir un groupe caracteristique
     */
    public async getGroupeCara(idGroupeCara: number): Promise<GroupeCaracteristique> {
        const params = {
            rub: rub_parametres,
            p: 34,
            idGroupeCara
        };

        return Axios.get<{ content: DeepRawify<GroupeCaracteristique> }>(
            `index.php?${qs.stringify(params)}`
        ).then(({ data: { content: groupeCara } }) => new GroupeCaracteristique(groupeCara));
    }

    public async loadCaracteristiques(idGroupeCaracteristique: number): Promise<Caracteristique[]> {
        const params = {
            rub: rub_parametres,
            p: 40,
            idGroupeCaracteristique
        };

        return Axios.get<{ content: DeepRawify<Caracteristique>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: caracteristiques } }) =>
                caracteristiques.map(caracteristique => new Caracteristique(caracteristique))
            );
    }

    public async findCaracteristique(idCaracteristique: number): Promise<Caracteristique> {
        const params = {
            rub: rub_parametres,
            p: 41,
            idCaracteristique,
        };

        return Axios.get<{ content: DeepRawify<Caracteristique> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: caracteristique } }) => new Caracteristique(caracteristique));
    }

    public async saveCaracteristique(caracteristique: Caracteristique): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_parametres,
                p: 42
            })}`,
            qs.stringify({
                caracteristique: JSON.stringify(caracteristique.toRaw())
            })
        )
            .then(() => true)
            .catch(() => false);
    }

    public async deleteCaracteristique(idCaracteristique: number): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_parametres,
                p: 43,
            })}`,
            qs.stringify({ idCaracteristique })
        )
            .then(() => true)
            .catch(() => false);
    }

    /**
     * Trier les caracteristiques au sein de leur groupe
     */
    public async sortCaracteristiques(props: SortParams): Promise<Caracteristique[]> {
        const params = {
            rub: rub_parametres,
            p: 44,
            idGroupeCaracteristique: props.idParent
        };

        return Axios.post<{ content: DeepRawify<Caracteristique>[] }>(
            `index.php?${qs.stringify(params)}`,
            qs.stringify({ caracteristiques: props.items })
        )
            .then(({ data: { content: caras } }) => caras.map((cara) => new Caracteristique(cara)));
    }

    /**
     * Trier les caracteristiques au sein de leur groupe
     */
    public async sortCaracteristiquesCle(props: SortParams): Promise<Caracteristique[]> {
        const params = {
            rub: rub_parametres,
            p: 45,
            idParent: props.idParent
        };

        return Axios.post<{ content: DeepRawify<Caracteristique>[] }>(
            `index.php?${qs.stringify(params)}`,
            qs.stringify({ items: props.items })
        )
            .then(({ data: { content: caras } }) => caras.map((cara) => new Caracteristique(cara)));
    }

    public async loadCaracteristiquesCle(idFamille: number): Promise<Caracteristique[]> {
        const params = {
            rub: rub_parametres,
            p: 46,
            idFamille
        };

        return Axios.get<{ content: DeepRawify<Caracteristique>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: caracteristiques } }) =>
                caracteristiques.map(caracteristique => new Caracteristique(caracteristique))
            );
    }

    // FICHES



    /**
     * Charge la liste des fiches au sein d'une catégorie
     * @param idCategorie identifiant de la catégorie
     * @returns liste des fiches
     */
    public async loadListFiche(filter: FicheListRequest): Promise<FicheListResult> {
        const params = {
            rub: rub_fiches,
            p: IS_ADMIN ? 1 : 3,
            ...filter
        };

        return Axios.get<{ content: DeepRawify<FicheListResult> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: ficheList } }) => {
                return {
                    liste: ficheList.liste.map(fiche => new Fiche(fiche)),
                    count: ficheList.count
                }});
    }

    /**
     * Charge une fiche 
     * @param idFiche id de la fiche à charger
     * @returns la fiche demandée (promesse)
     */
    public async findFiche(idFiche: number): Promise<FicheDetails> {
        const params = {
            rub: rub_fiches,
            p: IS_ADMIN ? 2 : 5,
            idFiche,
        };

        return Axios.get<{ content: DeepRawify<FicheDetails> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => {
                return ({
                    fiche: new Fiche(content.fiche),
                    marque: content.marque?.map(i => new Marque(i)) ?? [],
                    listeGroupesCaraL: content.listeGroupesCaraL?.map(i => new FicheLibreGroupeCara(i)) ?? [],
                    listeCaraP_Fiche: content.listeCaraP_Fiche?.map(i => new FicheCaracteristique(i)) ?? [],
                    listeProduits: content.listeProduits?.map(i => new FicheProduit(i)) ?? [],
                    listeCaraP: content.listeCaraP?.map(i => new Caracteristique(i)) ?? [],
                    listeGroupesCaraP: content.listeGroupesCaraP?.map(i => new GroupeCaracteristique(i)) ?? []
                });
            });
    }

    /**
     * Sauvegarde une fiche
     * @param fiche fiche à sauvegarder (données)
     * @returns réussite
     */
    public async saveFiche(fiche: Fiche): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_fiches,
                p: 3
            })}`,
            qs.stringify({
                fiche: JSON.stringify(fiche.toRaw())
            })
        )
            .then(() => true)
            .catch(() => false);
    }

    /**
     * Supprime une fiche
     * @param idFiche id de la fiche à supprimer
     * @returns réussite de la suppression
     */
    public async deleteFiche(idFiche: number): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_fiches,
                p: 4,
            })}`,
            qs.stringify({ idFiche })
        )
            .then(() => true)
            .catch(() => false);
    }

    /**
     * Charge la liste de toutes les caractéristiques de tous les groupes d'une famille
     * @param idFamille identifiant de la famille
     * @returns liste des caractéristiques
     */
    public async loadListCara(idFamille: number): Promise<Caracteristique[]> {
        const params = {
            rub: rub_fiches,
            p: 10,
            idFamille
        };

        return Axios.get<{ content: DeepRawify<Caracteristique>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: caraList } }) => caraList.map(cara => new Caracteristique(cara)));
    }

    /**
     * sauvegarde une liste de fiches caractéristiques
     * @param liste 
     * @returns réussite de l'enregistrement
     */
    public async saveListeFicheCara(liste: FicheCaracteristique[]): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_fiches,
                p: 11
            })}`,
            qs.stringify({
                liste: JSON.stringify(liste.map((e) => e.toRaw()))
            })
        )
            .then(() => true)
            .catch(() => false);
    }

    /**
     * charge la liste des caractéristiques d'une fiche
     * @param idFiche 
     * @returns 
     */
    public async loadListFicheCara(idFiche: number): Promise<FicheCaracteristique[]> {
        const params = {
            rub: rub_fiches,
            p: 12,
            idFiche
        };

        return Axios.get<{ content: DeepRawify<FicheCaracteristique>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: liste } }) => liste.map(fiche => new FicheCaracteristique(fiche)));
    }

    /**
     * sauvegarde une liste de fiches produit
     * @param liste 
     * @returns réussite de l'enregistrement
     */
    public async saveListeFicheProduit(liste: FicheProduit[]): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_fiches,
                p: 13
            })}`,
            qs.stringify({
                liste: JSON.stringify(liste.map((e) => e.toRaw()))
            })
        )
            .then(() => true)
            .catch(() => false);
    }

    /**
     * Charge la liste des produits d'une fiche
     * @param idFiche 
     * @returns 
     */
    public async loadListeFicheProduit(idFiche: number): Promise<FicheProduit[]> {
        const params = {
            rub: rub_fiches,
            p: 14,
            idFiche
        };

        return Axios.get<{ content: DeepRawify<FicheProduit>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: liste } }) => liste.map(produit => new FicheProduit(produit)));
    }

    /**
     * Sauvegarde la liste des produits d'une fiche
     * @param liste 
     * @returns 
     */
    public async saveListeFicheLibreCara(liste: FicheLibreGroupeCara[]): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_fiches,
                p: 15
            })}`,
            qs.stringify({
                liste: JSON.stringify(liste.map((e) => e.toRaw()))
            })
        )
            .then(() => true)
            .catch(() => false);
    }

    /**
     * charge la liste des caractéristiques (L) d'un produit
     * @param idFiche 
     * @returns 
     */
    public async loadListeFicheLibreCara(idFiche: number): Promise<FicheLibreGroupeCara[]> {
        const params = {
            rub: rub_fiches,
            p: 16,
            idFiche
        };

        return Axios.get<{ content: DeepRawify<FicheLibreGroupeCara>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: liste } }) => liste.map(produit => new FicheLibreGroupeCara(produit)));
    }

    /**
     * Création d'une nouvelle fiche (tout en un)
     * @param typeCara spéficier le type de caractéristiques
     * @param fiche données de la fiche
     * @param caraLibre liste des groupes de cara avec leur cara
     * @param produits liste des produits dans le cas d'une fiche paramétrée
     * @param cara liste des caractéristiques dans le cas d'une fiche paramétrée
     * @returns réussite de l'opération
     */
    public async createFiche(typeCara: number, fiche: Fiche, caraLibre: FicheLibreGroupeCara[], produits: FicheProduit[], cara: FicheCaracteristique[]): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_fiches,
                p: 5,
                typeCara
            })}`,
            qs.stringify({
                fiche: JSON.stringify(fiche.toRaw()),
                caraLibre: JSON.stringify(caraLibre.map((e) => e.toRaw())),
                produits: JSON.stringify(produits.map((e) => e.toRaw())),
                cara: JSON.stringify(cara.map((e) => e.toRaw()))
            })
        )
            .then(() => true)
            .catch(() => false);
    }

    /**
     * Charge la liste des fiches actives au sein d'une marque et du référentiel
     * @param idMarque identifiant de la marque
     * @returns liste des fiches
     */
    public async loadListeFicheFromMarque(filter: FicheListRequest): Promise<FicheListResult> {
        const params = {
            rub: rub_fiches,
            p: IS_ADMIN ? 1 : 4,
            ...filter
        };
        return Axios.get<{ content: DeepRawify<FicheListResult> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: ficheList } }) => {
                return {
                    liste: ficheList.liste.map(fiche => new Fiche(fiche)),
                    count: ficheList.count
                }});
    }

    public async saveNote(idFiche: number, note: number): Promise<boolean> {
        return Axios.get(`index.php?${qs.stringify({ rub: rub_parametres, p: 6, idFiche, note })}`)
            .then(() => true)
            .catch(() => false);
    }


    /**
     * Commentaires
     */

    public async listeCommentaires(idFiche: number): Promise<FicheCommentaire[]> {
        const params = {
            rub: rub_parametres,
            p: IS_ADMIN ? null : 7,
            idFiche
        };

        return Axios.get<{ content: DeepRawify<FicheCommentaire>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: commentaires } }) => commentaires.map(commentaire => new FicheCommentaire(commentaire)));
    }

    public async saveCommentaire(commentaire: FicheCommentaire): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_parametres,
                p: IS_ADMIN ? null : 8
            })}`,
            qs.stringify({
                commentaire: JSON.stringify(commentaire.toRaw())
            })
        )
            .then(() => true)
            .catch(() => false);
    }

    public async deleteCommentaire(idFicheCommentaire: number): Promise<boolean> {
        return Axios.get(
            `index.php?${qs.stringify({
                rub: rub_parametres,
                p: IS_ADMIN ? null : 9,
                idFicheCommentaire
            })}`)
            .then(() => true)
            .catch(() => false);
    }

    public loadListeCanaux(): Promise<Metier[]> {
        const params = {
            rub: rub_fiches,
            p: 17,
        };

        return Axios.get<{ content: DeepRawify<Metier>[] }>(`index.php?${qs.stringify(params)}`)
            .catch(({ response: { data: { message } } }) => {
                throw message;
            })
            .then(({ data: { content: canaux } }) => canaux.map((metier) => new Metier(metier)))
    }

    public async loadQuestions(idFiche: number): Promise<FicheQuestion[]> {
        const params = {
            rub: rub_quiz,
            p: 10,
            idFiche
        };

        return Axios.get<{ content: DeepRawify<FicheQuestion>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: questionList } }) => questionList.map(question => new FicheQuestion(question)));
    }

    public async getQuestion(idQuestion: number): Promise<FicheQuestion> {
        const params = {
            rub: rub_quiz,
            p: IS_ADMIN ? 13 : 11,
            idQuestion,
        };

        return Axios.get<{ content: DeepRawify<FicheQuestion> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: question } }) => new FicheQuestion(question));
    }

    public async saveQuestion(question: FicheQuestion): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_quiz,
                p: 11
            })}`,
            qs.stringify({
                question: JSON.stringify(question.toRaw())
            })
        )
            .then(() => true)
            .catch(() => false);
    }

    public async deleteQuestion(idQuestion: number): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_quiz,
                p: 12,
            })}`,
            qs.stringify({ idQuestion })
        )
            .then(() => true)
            .catch(() => false);
    }

    public async loadQuestionGroupes(): Promise<FicheQuestionGroupe[]> {
        const params = {
            rub: rub_quiz,
            p: 1
        };

        return Axios.get<{ content: DeepRawify<FicheQuestionGroupe>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: groupeList } }) => groupeList.map(groupe => new FicheQuestionGroupe(groupe)));
    }

    public async getQuestionGroupe(idGroupe: number): Promise<FicheQuestionGroupe> {
        const params = {
            rub: rub_quiz,
            p: 4,
            idGroupe,
        };

        return Axios.get<{ content: DeepRawify<FicheQuestionGroupe> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: groupe } }) => new FicheQuestionGroupe(groupe));
    }

    public async saveQuestionGroupe(groupe: FicheQuestionGroupe): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_quiz,
                p: 2
            })}`,
            qs.stringify({
                groupe: JSON.stringify(groupe.toRaw())
            })
        )
            .then(() => true)
            .catch(() => false);
    }

    public async deleteQuestionGroupe(idGroupe: number): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_quiz,
                p: 3,
            })}`,
            qs.stringify({ idGroupe })
        )
            .then(() => true)
            .catch(() => false);
    }

    public async loadFicheQuiz(idFiche: number): Promise<QuizInfos> {
        const params = {
            rub: rub_quiz,
            p: 10,
            idFiche,
        };

        return Axios.get<{ content: DeepRawify<QuizInfos> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content);
    }

    public async saveNextAnswer(answers: number[]): Promise<FicheQuestion> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_quiz,
                p: 12
            })}`,
            qs.stringify({
                answers: JSON.stringify(answers)
            })
        ).then(({ data: { content } }) => new FicheQuestion(content));
    }

    public async loadLastQuizStats(): Promise<QuizInfos> {
        const params = {
            rub: rub_quiz,
            p: 13,
        };

        return Axios.get<{ content: DeepRawify<QuizInfos> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content);
    }

    public async loadClassement(conf: ClassementRequest): Promise<ClassementAnswer[]> {
        const params = {
            rub: rub_quiz,
            p: 14,
            ...conf
        };
        return Axios.get<{ content: DeepRawify<ClassementAnswer[]> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map((i) => { return {
                'acteur': new Acteur(i.acteur),
                'nbPoints': i.nbPoints,
                'idClassement': i.idClassement
            }}));
    }

    public async statistiqueClickVideo(idFiche: number): Promise<void> {
        const params = {
            rub: rub_fiches,
            p: 20,
            idFiche
        };

        Axios.post(`index.php?${qs.stringify(params)}`)
    }

    public async loadStatistiques(config: FichesStatistiquesParameters): Promise<LevelUpStatistiques> {
        const params = {
            rub: rub_statistiques,
            p: 1,
            ...config
        };

        return Axios.get<{ content: DeepRawify<LevelUpStatistiques> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => ({
                ...content,
                fiches: content.fiches.map((ficheStatistiques) => ({
                    ...ficheStatistiques,
                    fiche: new Fiche(ficheStatistiques.fiche),
                    categorie: new Categorie(ficheStatistiques.categorie),
                    marque: new Marque(ficheStatistiques.marque),    
                }))
            }));
    }

    public async importFiche(fiche: Fiche): Promise<Fiche> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_fiches,
                p: IS_ADMIN ? 18 : null
            })}`,
            qs.stringify({
                fiche: JSON.stringify(fiche.toRaw())
            })
        )
            .then(({ data: { content } }) => new Fiche(content))
            .catch(() => fiche);
    }

    // LEVEL UP - Animations

    public async loadAnimations(limit: number): Promise<{liste: Animation[], total: number}> {
        const params = {
            rub: rub_animations,
            p: IS_ADMIN ? 1 : 15,
            limit
        };

        return Axios.get<{ content: {total: number, liste: DeepRawify<Animation>[]} }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => {
                return {
                    total: content.total,
                    liste: content.liste.map(animation => new Animation(animation))
                };
            });
    }

    public async loadAnimationsAccueil(): Promise<Animation[]> {
        const params = {
            rub: rub_animations,
            p: IS_ADMIN ? null : 40,
        };

        return Axios.get<{ content: DeepRawify<Animation>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map(animation => new Animation(animation)));
    }

    public async getAnimation(idAnimation: number): Promise<Animation> {
        const params = {
            rub: rub_animations,
            p: IS_ADMIN ? 4 : 17,
            idAnimation,
        };

        return Axios.get<{ content: DeepRawify<Animation> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: animation } }) => new Animation(animation));
    }

    public async saveAnimation(animation: Animation): Promise<Animation> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_animations,
                p: IS_ADMIN ? 2 : 16
            })}`,
            qs.stringify({
                animation: JSON.stringify(animation.toRaw())
            })
        )
            .then(({ data: { content: animation } }) => new Animation(animation))
            .catch(({ response: { data: { message } } }) => message);
    }

    public async deleteAnimation(idAnimation: number): Promise<boolean> {
        return Axios.get(
            `index.php?${qs.stringify({
                rub: rub_animations,
                p: IS_ADMIN ? 3 : null,
                idAnimation
            })}`)
            .then(() => true)
            .catch(() => false);
    }

    // LEVEL UP - Animations - Briques

    public async loadBriques(idAnimation: number): Promise<{liste: Brique[], total: number}> {
        const params = {
            rub: rub_animations,
            p: IS_ADMIN ? 11 : 18,
            idAnimation,
        };

        return Axios.get<{ content: {total: number, liste: DeepRawify<Brique>[]} }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => {
                return {
                    total: content.total,
                    liste: content.liste.map(brique => new Brique(brique))
                };
            });
    }

    public async getBrique(idBrique: number): Promise<Brique> {
        const params = {
            rub: rub_animations,
            p: IS_ADMIN ? 14 : null,
            idBrique,
        };

        return Axios.get<{ content: DeepRawify<Brique> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: brique } }) => new Brique(brique));
    }

    public async saveBrique(brique: Brique): Promise<Brique> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_animations,
                p: IS_ADMIN ? 12 : null
            })}`,
            qs.stringify({
                brique: JSON.stringify(brique.toRaw())
            })
        )
            .then(({ data: { content: brique } }) => new Brique(brique))
            .catch(({ response: { data: { message } } }) => message);
    }

    public async deleteBrique(idBrique: number): Promise<boolean> {
        return Axios.get(
            `index.php?${qs.stringify({
                rub: rub_animations,
                p: IS_ADMIN ? 13 : null,
                idBrique
            })}`)
            .then(() => true)
            .catch(() => false);
    }

    public async loadAnimQuizQuestions(groupes: string): Promise<FicheQuestion[]> {
        const params = {
            rub: rub_animations,
            p: IS_ADMIN ? 20 : null,
            groupes
        };

        return Axios.get<{ content: DeepRawify<FicheQuestion>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map(i => new FicheQuestion(i)));
    }

    public async loadAnimQuiz(idAnimation: number): Promise<QuizInfos> {
        const params = {
            rub: rub_quiz,
            p: 30,
            idAnimation,
        };

        return Axios.get<{ content: DeepRawify<QuizInfos> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content);
    }

    public async getBriqueQuestion(idQuestion: number): Promise<FicheQuestion> {
        const params = {
            rub: rub_quiz,
            p: 32,
            idQuestion,
        };

        return Axios.get<{ content: DeepRawify<FicheQuestion> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: question } }) => new FicheQuestion(question));
    }

    public async saveBriqueNextAnswer({answers, idBrique}: {answers: number[], idBrique: number}): Promise<FicheQuestion> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_quiz,
                p: 31,
                idBrique
            })}`,
            qs.stringify({
                answers: JSON.stringify(answers)
            })
        ).then(({ data: { content } }) => new FicheQuestion(content));
    }

    public async loadClassementAnimBrique(idBrique: number): Promise<ClassementAnswer[]> {
        const params = {
            rub: rub_quiz,
            p: 33,
            idBrique
        };
        return Axios.get<{ content: DeepRawify<ClassementAnswer[]> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map((i) => { return {
                'acteur': new Acteur(i.acteur),
                'nbPoints': i.nbPoints,
                'idClassement': i.idClassement
            }}));
    }

    public async loadClassementAnim(idAnimation: number): Promise<ClassementAnswer[]> {
        const params = {
            rub: rub_quiz,
            p: 34,
            idAnimation
        };
        return Axios.get<{ content: DeepRawify<ClassementAnswer[]> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map((i) => { return {
                'acteur': new Acteur(i.acteur),
                'nbPoints': i.nbPoints,
                'idClassement': i.idClassement
            }}));
    }

    // BATTLE

    /**
     * Charge la liste des battles 
     * @param type le type de battle qu'on veut lister (true pour battle gagné, false pour perdu, null pour en cours)
     * @returns liste des battles correspondant au type
     */
    public async loadBattlesList(type?: boolean): Promise<Battle[]> {
        const params = {
            rub: rub_battle,
            p: IS_ADMIN ? null : 2,
            type
        };

        return Axios.get<{ content: DeepRawify<Battle[]> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map(battle => new Battle(battle)))
            .catch(({ response: { data: { message } } }) => message);
    }

    /**
     * Charge la liste des battles 
     * @param type le type de battle qu'on veut lister (true pour battle gagné, false pour perdu, null pour en cours)
     * @returns liste des battles correspondant au type
     */
    public async loadInfosMesBattles(): Promise<InfosBattles> {
        const params = {
            rub: rub_battle,
            p: IS_ADMIN ? null : 1
        };

        return Axios.get<{ content: DeepRawify<InfosBattles> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => {
                return {
                    nbVictoires: content.nbVictoires,
                    nbDefaites: content.nbDefaites,
                    battlesEnCours : content.battlesEnCours.map(battle => new Battle(battle)),
                    pourcentageVictoires : content.pourcentageVictoires
                }
            })
            .catch(({ response: { data: { message } } }) => message);
    }

    /**
     * Charge la liste des adversaires potentiels pour des battles
     * @param search la recherche permettant de retrouver l'adversaire correspondant
     * @returns liste des adversaires correspondant à la recherche
     */
    public async loadOpponentsList(search? : string): Promise<Opponent[]> {
        const params = {
            rub: rub_battle,
            p: IS_ADMIN ? null : 3,
            search
        };

        return Axios.get<{ content: DeepRawify<Opponent[]> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map((opponent) => { return {
                'acteur': new Acteur(opponent.acteur),
                'canStartBattle': opponent.canStartBattle,
                'hasBattleEnCours': opponent.hasBattleEnCours
            }}))
            .catch(({ response: { data: { message } } }) => message);
    }

    /**
     * Enregistre une battle
     * @param battle la battle a enregistrer
     * @returns true si bien enregistrée, false sinon
     */
    public async saveBattle(battle: Battle): Promise<Battle> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_battle,
                p: IS_ADMIN ? null : 6
            })}`,
            qs.stringify({
                battle: JSON.stringify(battle.toRaw())
            })
        )
            .then(({ data: { content } }) => new Battle(content))
            .catch(({ response: { data: { message } } }) => message);
    }

    /**
     * Charge la question du défi à afficher pour une battle
     * @param idBattle l'identifiant de la battle dont on veut afficher le quiz
     * @returns FicheQuestion la question du quiz à afficher
     */
    public async getQuestionBattle(idBattle: number): Promise<FicheQuestion> {
        const params = {
            rub: rub_battle,
            p: IS_ADMIN ? null : 7,
            idBattle,
        };

        return Axios.get<{ content: DeepRawify<FicheQuestion> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: question } }) => new FicheQuestion(question));
    }

    /**
     * Charge la question du défi à afficher pour une battle
     * @param answers les réponses au quiz de la battle
     * @param idBattle l'identifiant de la battle dont on veut afficher le quiz
     * @returns FicheQuestion la question du quiz à afficher
     */
    public async saveNextAnswerBattle(answers: number[], idBattle: number): Promise<FicheQuestion> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub_battle,
                p: IS_ADMIN ? null : 8,
            })}`,
            qs.stringify({
                answers: JSON.stringify(answers),
                idBattle: JSON.stringify(idBattle),
            })
        ).then(({ data: { content } }) => new FicheQuestion(content));
    }

    /**
     * Charge les informations du récapitulatif d'un quiz
     * @returns les infos du récapitulatif pour le quiz
     */
    public async loadRecapQuizBattle(idBattle: number): Promise<InfosRecap> {
        const params = {
            rub: rub_battle,
            p: IS_ADMIN ? null : 9,
            idBattle
        };

        return Axios.get<{ content: DeepRawify<InfosRecap> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => { return {
                'idBattle': content.idBattle,
                'score': content.score,
                'emetteur': new Acteur(content.emetteur),
                'recepteur': new Acteur(content.recepteur),
                'nbQuestionsTotales': content.nbQuestionsTotales,
                'temps': content.temps,
                'pointsEmetteur': content.pointsEmetteur,
                'pointsRecepteur': content.pointsRecepteur,
                'scoreEmetteur': content.scoreEmetteur,
                'scoreRecepteur': content.scoreRecepteur,
                'tempsEmetteur': content.tempsEmetteur,
                'tempsRecepteur': content.tempsRecepteur,
                'dateCreation' : content.dateCreation ? moment(content.dateCreation, 'X') : null,
                'battleTerminee' : content.battleTerminee,
                'battleEgalite' : content.battleEgalite,
                'currentIsWinner' : content.currentIsWinner,
                'currentFinished' : content.currentFinished,
                'listQuestions' : content.listQuestions?.map(question => new FicheQuestion(question)),
                'estPointsAttribues' : content.estPointsAttribues,
                }
            });
    }

    /**
     * Charge le classement des battles
     * @param conf les paramètres du classement à afficher 
     * @returns le classement
     */
    public async loadBattlesClassement(conf: ClassementRequest): Promise<ClassementAnswer[]> {
        const params = {
            rub: rub_battle,
            p: 4,
            ...conf
        };
        return Axios.get<{ content: DeepRawify<ClassementAnswer[]> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map((classement) => { return {
                'acteur': new Acteur(classement.acteur),
                'nbPoints': classement.nbPoints,
            }}));
    }
}