import { createAction, createAsyncAction } from 'typesafe-actions';
import { HeaderFilter, HeaderStore } from './types';


export const setLeftComponents = createAction('HEADER_SET_LEFT_COMPONENTS')<JSX.Element[]>();

export const setPageTitle = createAction('HEADER_SET_PAGE_TITLE')<string >();

export const setRightComponents = createAction('HEADER_SET_RIGHT_COMPONENTS')<JSX.Element[]>();

export const setIsPageFilter = createAction('HEADER_SET_IS_PAGE_FILTER')<boolean>();

export const setListFilters = createAction('HEADER_SET_LIST_FILTERS')<HeaderFilter[]>();

export const setHeaderStore = createAction('HEADER_SET_HEADER_STORE')<HeaderStore>();
