import Axios from 'axios';
import { Acteur } from 'classes/Acteur.class';
import { AnimationTypes } from 'classes/animations/AnimationTypes.enum';
import { Participation } from 'classes/animations/Participation.class';
import { Question } from 'classes/animations/Question.class';
import { Quiz } from 'classes/animations/Quiz.class';
import { QuizBadge } from 'classes/animations/QuizBadge.class';
import { Rankings } from 'classes/animations/Ranking.type';
import { B64File } from 'classes/B64File.class';
import qs from 'qs';
import { rxAxios, RxAxios } from 'services/RxAxios';
import { DeepRawify } from 'types';
import { AnimationsService } from './Animations.service';

export class QuizService {
    private static instance: QuizService;

    public static getInstance(): QuizService {
        if (!QuizService.instance) {
            QuizService.instance = new QuizService();
        }

        return QuizService.instance;
    }

    private pages = {
        AJAX_ADMIN_GET_QUESTIONS: 1,
        AJAX_SAVE_QUIZ_QUESTIONS: 2,
        AJAX_SUBMIT_ACTEUR_ANSWERS: 3,
        AJAX_ADMIN_GET_RANKINGS: 3,
        AJAX_GET_RANKING: 4,
        AJAX_ADMIN_DELETE_RANKING: 4,
        AJAX_SAVE_ANIMATION: 5,
        AJAX_ADMIN_GET_RESULTS_STATISTICS: 5,
        AJAX_ADMIN_GET_NON_PARTICIPANTS: 6,
        AJAX_ADMIN_GET_ACTEUR_ANSWERS: 7,
        AJAX_ADMIN_IMPORT_QUESTIONS: 9,
        AJAX_ADMIN_GET_QUESTIONS_QUIZ_COUNT: 10,
        AJAX_ADMIN_GET_QUIZ_BADGE: 11
    };

    public animationsService = AnimationsService.getInstance();

    public find(idQuiz: number): Promise<Quiz> {
        return this.animationsService.find(idQuiz, AnimationTypes.Quiz);
    }

    // Classements
    public findRanking(idQuiz: number): Promise<Rankings> {
        return Axios.get<{ content: DeepRawify<Rankings> }>(
            `index.php?${qs.stringify({
                rub: 21,
                idQuiz,
                p: this.pages.AJAX_GET_RANKING,
            })}`
        )
            .then(({ data: { content } }) => content.map(({ acteur, participation }) => ({
                acteur: new Acteur(acteur),
                participation: new Participation(participation),
            })))
            .catch(({ response: { data: { message } } }) => message);
    }

    // Classements
    public findRankings(idQuiz: number): Promise<Rankings> {
        return Axios.get<{ content: DeepRawify<Rankings> }>(
            `index.php?${qs.stringify({
                rub: 23,
                idQuiz,
                p: this.pages.AJAX_ADMIN_GET_RANKINGS,
            })}`
        )
            .then(({ data: { content } }) => content.map(({ acteur, participation }) => ({
                acteur: new Acteur(acteur),
                participation: new Participation(participation),
            })))
            .catch(({ response: { data: { message } } }) => message);
    }

    public async save(quiz: Quiz): Promise<void> { }

    // Participations
    public findParticipation(idQuiz: number, autoCreate = false): Promise<Participation> {
        return Axios.get<{ content: DeepRawify<Participation> }>(
            `index.php?${qs.stringify({
                rub: 21,
                idQuiz,
                create: Number(autoCreate)
            })}`,
        ).then(({ data: { content: participation } }) => new Participation(participation));
    }

    public getQuestionsQuiz(quizId: number): Promise<Question[]> {
        const params = {
            rub: 23,
            p: this.pages.AJAX_ADMIN_GET_QUESTIONS,
            quizId
        };

        return Axios.get<{ content: DeepRawify<Question>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: questions } }) => questions.map((question) => new Question(question)));
    }

    public getQuestionsQuizCount(quizId: number): Promise<number> {
        const params = {
            rub: 23,
            p: this.pages.AJAX_ADMIN_GET_QUESTIONS_QUIZ_COUNT,
            quizId
        };

        return Axios.get<{ content: number }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content);
    }

    // Questions
    public saveQuestions(idQuiz: number, questions: Question[]): RxAxios<void> {
        return rxAxios({
            method: 'post',
            url: `index.php?${qs.stringify({
                rub: 23,
                p: this.pages.AJAX_SAVE_QUIZ_QUESTIONS
            })}`,
            data: qs.stringify({
                questions: JSON.stringify(questions),
                idQuiz: idQuiz
            })
        });
    }

    public deleteRanking(idParticipation: number): Promise<void> {
        const params = {
            rub: 23,
            p: this.pages.AJAX_ADMIN_DELETE_RANKING,
            idParticipation
        };

        return Axios.get(`index.php?${qs.stringify(params)}`);
    }

    public async getResultsStatistics(idQuiz: number): Promise<{
        stats: { [key: number]: number },
        reponsesTextes: { [key: number]: { reponses: Array<string>, displayMore: boolean } }
    }> {
        const params = {
            rub: 23,
            p: this.pages.AJAX_ADMIN_GET_RESULTS_STATISTICS,
            idQuiz
        };

        return Axios.get<{
            content: {
                stats: { idAnswer: number, nbrReponses: number }[],
                reponsesTextes: { idQuestion: number, answers: string }[]
            }
        }>(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => ({
            stats: content.stats.reduce((map, stat) => ({
                [stat.idAnswer]: stat.nbrReponses,
                ...map
            }), {}),
            reponsesTextes: content.reponsesTextes.reduce((map: Record<number, { reponses: string[], displayMore: boolean }>, reponse) => ({
                ...map,
                [reponse.idQuestion]: {
                    reponses: [...(map[reponse.idQuestion]?.reponses || []), reponse.answers],
                    displayMore: false,
                }
            }), {})
        }));

    }

    public async getNonParticipants(idQuiz: number): Promise<Acteur[]> {
        const params = {
            rub: 23,
            p: this.pages.AJAX_ADMIN_GET_NON_PARTICIPANTS,
            idQuiz
        };
        return await Axios.get<{ content: DeepRawify<Acteur>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: acteurs } }) => acteurs.map((acteur) => new Acteur(acteur)));

    }


    public getActeurAnswers(idParticipation: number) {
        const params = {
            rub: 23,
            p: this.pages.AJAX_ADMIN_GET_ACTEUR_ANSWERS,
            idParticipation
        };

        return Axios.get(`index.php?${qs.stringify(params)}`)
    }

    public importQuestions(idAnimation: number, file: B64File): Promise<string> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: 23,
                p: this.pages.AJAX_ADMIN_IMPORT_QUESTIONS,
            })}`,
            qs.stringify({
                animation: idAnimation,
                file: JSON.stringify(file)
            })
        )
            .then(({ data: { content } }) => content)
            .catch(({ response: { data: { message } } }) => message);
    }

    public getBadgeQuiz(idAnimation: number): Promise<QuizBadge> {
        const params = {
            rub: 23,
            p: this.pages.AJAX_ADMIN_GET_QUIZ_BADGE,
            idAnimation
        };

        return Axios.get<{ content: DeepRawify<QuizBadge> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: quizBadge } }) => new QuizBadge(quizBadge))
    }
}
