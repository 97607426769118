import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { colors } from 'Theme';

export default ({ spacing, palette }: typeof Theme) => createStyles({
    expansionPanel: {

        border: IS_ADMIN ? `1px solid ${colors.lighterGrey.light}` : 0,
        borderRadius: 5
    },
    expansionDetails: {
        display: 'flex',
        flexDirection: 'column',
        padding: `0 ${spacing(2)} ${spacing(2)} ${spacing(2)}`,
        borderRadius: 5
    },
    titleInfo: {
        fontWeight: 700,
        marginLeft: spacing(1),
        borderRadius: 5
    },
    contentTextField: {
        marginBottom: spacing(2),
        backgroundColor: 'white',
        border: `1px solid ${colors.lighterGrey.main}`,
        borderRadius: 5,
        '& fieldset': {
            border: 0
        }
    },
    informationContent: {
        fontSize: 14,
        color: colors.grey.main,

    },
    informationImage: {
        width: '100%',
        display: 'inline-block',
        position: 'relative',
        minHeight: 200,
        paddingTop: 'calc(2 * 100% / 3)',
        marginTop : spacing(1)
    },
    informationImageSrc : {
        position : 'absolute',
        top : 0,
        left : 0,
        right : 0,
        bottom : 0,
        backgroundRepeat : 'no-repeat',
        backgroundPosition : 'center center',
        backgroundSize : 'cover',
        borderRadius : 5

    }


});