import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import cloneDeep from 'lodash/cloneDeep'
import { loadStatistiquesAsync } from './actions';
import { LevelUpStatistiques } from './types';

const statistiques = createReducer<LevelUpStatistiques | null, RootAction>(null)
    .handleAction(loadStatistiquesAsync.success, (_state, action) => cloneDeep(action.payload));

const isLoadingStatistiques = createReducer<boolean, RootAction>(false)
    .handleAction([loadStatistiquesAsync.request], () => true)
    .handleAction([
        loadStatistiquesAsync.success, 
        loadStatistiquesAsync.failure,
    ], () => false);

export const levelUpStatistiquesReducers = combineReducers({
    statistiques,
    isLoadingStatistiques,
});

export default levelUpStatistiquesReducers;