import { DeepPartial } from 'redux';
import { DeepRawify } from 'types';
import {v4} from 'uuid'
import { ContenuBloc } from '../ContenuBloc.class';


export class ContenuBlocTexte extends ContenuBloc {

    public uid: string = v4();

    public texte : string;

    constructor(row?: DeepPartial<DeepRawify<ContenuBlocTexte>>) {
        super(row);

        if (row) {
            const { texte } = row;
            this.texte = texte;
        }
    }


    public toRaw() {
        return {
            texte : this.texte,
            idBloc : this.idBloc,
            laLigne : this.laLigne,
            indexBloc : this.indexBloc,
            idTypeBloc : this.idTypeBloc
        };
    }

    public save(): Promise<number> {
        return this.contenusService.saveBloc(this);
    }

    public delete(): Promise<Boolean> {
        return this.contenusService.deleteBloc(this.idBloc);
    }

    public isEmpty() : boolean {
        if (!this.texte) return true;
        let s = this.texte.replace(/\<p\>\s*\<\/p\>\n/gi, '')
        if (s == "") return true;

        else return false;
    }


}
