import { B64File } from 'classes/B64File.class';
import { Notifiable } from 'classes/notifications/Notifiable.class';
import { DeepPartial } from 'redux';
import { LevelUpService } from 'services';
import { DeepRawify } from 'types';
import {v4} from 'uuid'

export class Marque extends Notifiable {
    public getUrl(): string {
        return "/level-up/fiches/marques/" + this.idMarque + "/";
    }
    public getID(): number {
        return this.idMarque;
    }
    public notifType = 13;

    public uid: string = v4();

    /**
     * Numéro de la marque
     */
    public idMarque: number;

    /**
     * Libellé (nom) de la marque
     */
    public libelle : string;

    /**
     * Image de la marque
     */
    public picto: B64File;

    /**
     * actif ?
     */
    public enLigne : boolean;

    /**
     * Nombre de quiz associés à la catégorie (actifs & bon référentiel uniquement)
     */
    public nbQuizTotal: number;

    /**
     * Nombre de quiz effectués associés à la catégorie
     */
    public nbQuizFaits: number;
    
    private levelUpService = LevelUpService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<Marque>>) {
        super();
        if (row) {
            const { idMarque,
                libelle,
                enLigne,
                picto,
                nbQuizTotal,
                nbQuizFaits
            } = row;
            this.idMarque = idMarque;
            this.libelle = libelle;
            this.enLigne = enLigne;
            this.nbQuizTotal = nbQuizTotal;
            this.nbQuizFaits = nbQuizFaits;
            this.picto = B64File.fromDb(picto);
        }
    }

    /**
     * Formate les données pour le renvoi en back
     * @returns array pour le back
     */
    public toRaw() {
        return {
            idMarque: this.idMarque,
            libelle : this.libelle,
            enLigne : this.enLigne,
            picto: this.picto
        };
    }

    public save(): Promise<Marque> {
        return this.levelUpService.saveMarque(this);
    }

    public delete(): Promise<boolean> {
        return this.levelUpService.deleteMarque(this.idMarque);
    }

    /**
     * Importe des données depuis un fichier CSV
     * @param replace écraser toutes les données
     * @param file fichier csv
     * @returns promesse : réussite de l'opération
     */
    public static import(replace: boolean, file: B64File): Promise<boolean> {
        return LevelUpService.getInstance().importMarques(replace, file);
    }


}
