import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { colors } from 'Theme';

export default ({ spacing, palette }: typeof Theme) => createStyles({
    expansionPanel : {
        
        border : `1px solid ${colors.lighterGrey.light}`,
        borderRadius : 5
    },
    expansionDetails : {
        backgroundColor : colors.lighterGrey.light,
        display : 'flex',
        flexDirection : 'column',
        padding : spacing(4)
    },
    titleInfo : {
        fontWeight : 700,
        marginLeft : spacing(1)
    },
    contentTextField : {
        marginBottom : spacing(2),
        backgroundColor : 'white',
        border : `1px solid ${colors.lighterGrey.main}`,
        borderRadius : 5,
        '& fieldset' : {
            border : 0
        }
    }
});