

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { createTheme } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { ThemeProvider } from "@material-ui/core/styles"
import { Theme } from 'Theme'
import FixedKeyboardDateTimePicker from 'components/fixedKeyboardDateTimePicker/FixedKeyboardDateTimePicker.component';
import moment, { Moment } from "moment"
import 'moment/locale/fr'
import React, { useEffect, useState } from "react"

interface SchedulePostDialogProps {
    triggered: boolean;
    date: Moment;
    onClose: ((dateValue: Moment) => void);
}

const SchedulePostDialogComponent: React.FC<SchedulePostDialogProps> = (props: SchedulePostDialogProps) => {
    const {triggered, date, onClose} = props;

    const datePickerTheme = (mainTheme: typeof Theme) => createTheme({
        props: mainTheme.props,
        palette: mainTheme.palette
    });

    const [open, setOpen] = useState(false)
    const [dateValue, setDateValue] = useState(null)

    useEffect(() => {
        setDateValue(date);
    }, []);

    useEffect(() => {
        if (triggered && !open) {
            setOpen(true);
        }
    }, [triggered, open])

    function closeDialog(ok: boolean) {
        if (ok) {
            onClose(dateValue);
        } else {
            onClose(null);
        }

        setOpen(false);
    }

    function onChangeMoment(
        value: Moment
    ): void {
        setDateValue(value);
    }

    return (
        <Dialog fullWidth open={open} onClose={() => closeDialog(false)}>
            <DialogTitle>
                Programmer la publication
                </DialogTitle>
            <DialogContent>
                <DialogContentText> Sélectionnez la date et l'heure à laquelle vous souhaitez mettre en ligne votre publication</DialogContentText>
                <ThemeProvider theme={datePickerTheme}>
                    <Box display='flex' alignItems='center' justifyContent='center' width='75%' margin='auto'>
                        <FixedKeyboardDateTimePicker
                            required
                            autoOk
                            inputVariant="outlined"
                            ampm={false}
                            label="Date d'affichage"
                            value={dateValue}
                            format="DD/MM/YYYY HH:mm"
                            margin="normal"
                            onChange={(value) => onChangeMoment(value)}
                            minDate={moment()}
                            minDateMessage={'La date minimale ne peut pas être avant aujourd\'hui'}
                            cancelLabel={"Annuler"}
                            clearLabel={"Vider"}
                            okLabel={"Valider"}
                            todayLabel={"Aujourd'hui"}
                            invalidDateMessage='Format de date invalide'
                            fullWidth
                        />

                    </Box>
                </ThemeProvider>
            </DialogContent>
            <DialogActions>
                <Button size="large" variant="contained" color="secondary" onClick={() => closeDialog(false)}>
                    Annuler
                    </Button>
                <Button size="large" variant="contained" color="primary" disabled={(!dateValue || !dateValue.isValid())} onClick={() => closeDialog(true)}>
                    Programmer
                    </Button>
            </DialogActions>
        </Dialog>);
}

export default SchedulePostDialogComponent;