import Drawer from '@material-ui/core/Drawer'
import Fab from '@material-ui/core/Fab'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { WithStyles, withStyles } from '@material-ui/styles'
import React, { Component } from "react"
import { connect } from "react-redux"
import { RootState } from "store/types"
import style from './SignalementDrawer.style'

const stateToProps = ({ murs: { raisonsSignalement } }: RootState) => ({
    raisonsSignalement
});

const dispatchToProps = {
};

interface BaseProps {
    triggered: boolean;
    onClose: ((signalement: number, description: string) => void);
}

interface SignalementDrawerState {
    open: boolean;
    raison: number;
    description: string;
}

type SignalementDrawerProps = BaseProps
    & WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps;

class SignalementDrawerComponent extends Component<SignalementDrawerProps, SignalementDrawerState> {

    public readonly state: SignalementDrawerState = {
        open: false,
        raison: 1,
        description: ''
    };


    public componentDidUpdate(prevProps: SignalementDrawerProps): void {
        if (this.props.triggered != prevProps.triggered && this.props.triggered && !this.state.open) {
            this.setState({ open: true });
        }
    }

    public closeDrawer(raison: number, description: string) {
        this.props.onClose(raison, description);
        this.setState({
            open: false
        });
    }


    public render(): JSX.Element {
        const { classes, raisonsSignalement } = this.props;
        const { open, raison, description } = this.state;
        var raisonsKeys = Object.keys(raisonsSignalement);
        return <Drawer
            anchor="bottom"
            open={open}
            onClose={() => this.closeDrawer(null, null)}
        >
            <div className={classes.disconnectDrawer}>
                <h3>Alerter la modération</h3>
                <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                        Raison
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={raison}
                        onChange={(e) => this.setState({ raison: e.target.value as number })}
                    >
                        {
                            Object.values(raisonsSignalement).map((raison, index) =>
                                <MenuItem key={`${raisonsKeys[index]}-${index}`} value={raisonsKeys[index]}>{raison}</MenuItem>
                            )
                        }
                    </Select>
                    <TextField
                        className={classes.textFieldCommentaire}
                        placeholder="Commentaire"
                        onChange={(e) => this.setState({ description: e.target.value as string })}

                    />
                </FormControl>
                <div className={classes.drawerAction}>
                    <Fab
                        color="default"
                        size="large"
                        variant="extended"
                        onClick={() => this.closeDrawer(null, null)}
                    >
                        Annuler
                    </Fab>
                    <Fab
                        color="primary"
                        size="large"
                        variant="extended"
                        onClick={() => this.closeDrawer(raison, description)}
                    >
                        Envoyer
                    </Fab>
                </div>
            </div>
        </Drawer >;
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(SignalementDrawerComponent));