import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { RootEpic } from 'store/types';
import { isActionOf } from 'typesafe-actions';
import {
    loadApiListAsync,
    getApiAsync,
    loadMarquesAsync,
    loadCategoriesAsync,
    getCategorieAsync,
    loadFamillesAsync,
    getFamilleAsync,
    loadGroupesCaraAsync,
    sortGroupesCaraAsync,
    getGroupeCaraAsync,
    loadCaracteristiquesAsync,
    getCaracteristiqueAsync,
    sortCaraAsync,
    sortCaraCleAsync,
    loadCaracteristiquesCleAsync,
    loadCaraFromFamilleAsync,
    loadListeCanauxAsync
} from './actions';

const loadApiListEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadApiListAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadListAPI()).pipe(
                map(loadApiListAsync.success),
                catchError(message => of(loadApiListAsync.failure(message)))
            )
        ),
    );

const getApiEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(getApiAsync.request)),
        switchMap((action) =>
            from(levelUpService.findAPI(action.payload)).pipe(
                map(getApiAsync.success),
                catchError(message => of(getApiAsync.failure(message)))
            )
        ),
    );

const loadMarquesEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadMarquesAsync.request)),
        mergeMap((action) =>
            from(levelUpService.listMarques()).pipe(
                map(loadMarquesAsync.success),
                catchError(message => of(loadMarquesAsync.failure(message)))
            )
        ),
    );

const loadCategoriesEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadCategoriesAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadCategories()).pipe(
                map(loadCategoriesAsync.success),
                catchError(message => of(loadCategoriesAsync.failure(message)))
            )
        ),
    );

const getCategorieEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(getCategorieAsync.request)),
        switchMap((action) =>
            from(levelUpService.findCategorie(action.payload)).pipe(
                map(getCategorieAsync.success),
                catchError(message => of(getCategorieAsync.failure(message)))
            )
        ),
    );

const loadFamillesEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadFamillesAsync.request)),
        mergeMap((action) =>
            from(levelUpService.listFamilles(action.payload)).pipe(
                map(loadFamillesAsync.success),
                catchError(message => of(loadFamillesAsync.failure(message)))
            )
        ),
    );

const getFamilleEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(getFamilleAsync.request)),
        mergeMap((action) =>
            from(levelUpService.getFamille(action.payload)).pipe(
                map(getFamilleAsync.success),
                catchError(message => of(getFamilleAsync.failure(message)))
            )
        ),
    );

const loadGroupesCaraEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadGroupesCaraAsync.request)),
        mergeMap((action) =>
            from(levelUpService.listGroupesCaracteristiques(action.payload)).pipe(
                map(loadGroupesCaraAsync.success),
                catchError(message => of(loadGroupesCaraAsync.failure(message)))
            )
        ),
    );

const sortGroupesCaraEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(sortGroupesCaraAsync.request)),
        mergeMap((action) =>
            from(levelUpService.sortGroupesCaracteristiques(action.payload)).pipe(
                map(sortGroupesCaraAsync.success),
                catchError(message => of(sortGroupesCaraAsync.failure(message)))
            )
        ),
    );

const getGroupeCaraEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(getGroupeCaraAsync.request)),
        mergeMap((action) =>
            from(levelUpService.getGroupeCara(action.payload)).pipe(
                map(getGroupeCaraAsync.success),
                catchError(message => of(getGroupeCaraAsync.failure(message)))
            )
        ),
    );

const loadCaracteristiquesEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadCaracteristiquesAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadCaracteristiques(action.payload)).pipe(
                map(loadCaracteristiquesAsync.success),
                catchError(message => of(loadCaracteristiquesAsync.failure(message)))
            )
        ),
    );

const getCaracteristiqueEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(getCaracteristiqueAsync.request)),
        switchMap((action) =>
            from(levelUpService.findCaracteristique(action.payload)).pipe(
                map(getCaracteristiqueAsync.success),
                catchError(message => of(getCaracteristiqueAsync.failure(message)))
            )
        ),
    );

const sortCaraEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(sortCaraAsync.request)),
        mergeMap((action) =>
            from(levelUpService.sortCaracteristiques(action.payload)).pipe(
                map(sortCaraAsync.success),
                catchError(message => of(sortCaraAsync.failure(message)))
            )
        ),
    );

const sortCaraCleEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(sortCaraCleAsync.request)),
        mergeMap((action) =>
            from(levelUpService.sortCaracteristiquesCle(action.payload)).pipe(
                map(sortCaraCleAsync.success),
                catchError(message => of(sortCaraCleAsync.failure(message)))
            )
        ),
    );

const loadCaracteristiquesCleEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadCaracteristiquesCleAsync.request)),
        mergeMap((action) =>
            from(levelUpService.loadCaracteristiquesCle(action.payload)).pipe(
                map(loadCaracteristiquesCleAsync.success),
                catchError(message => of(loadCaracteristiquesCleAsync.failure(message)))
            )
        ),
    );

const loadCaraFromFamilleEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadCaraFromFamilleAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadListCara(action.payload)).pipe(
                map(loadCaraFromFamilleAsync.success),
                catchError(message => of(loadCaraFromFamilleAsync.failure(message)))
            )
        ),
    );
 
const loadListeCanauxEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadListeCanauxAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadListeCanaux()).pipe(
                map(loadListeCanauxAsync.success),
                catchError(message => of(loadListeCanauxAsync.failure(message)))
            )
        ),
    );

const LevelUpParametresEpics = combineEpics(
    loadApiListEpics,
    getApiEpic,
    loadMarquesEpics,
    loadCategoriesEpics,
    getCategorieEpic,
    loadFamillesEpics,
    getFamilleEpics,
    loadGroupesCaraEpics,
    sortGroupesCaraEpics,
    getGroupeCaraEpics,
    loadCaracteristiquesEpics,
    getCaracteristiqueEpic,
    sortCaraEpics,
    sortCaraCleEpics,
    loadCaracteristiquesCleEpics,
    loadCaraFromFamilleEpic,
    loadListeCanauxEpic
);

export default LevelUpParametresEpics;
