import { createStyles, makeStyles } from '@material-ui/styles';
import { colors } from 'Theme';

export default makeStyles(() => createStyles({
    mainContainer: {
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20,
    },

    errorMessage: {
        fontSize: '14px !important',
        margin: '0 0 0 0 !important',
    },

    errorStack: {
        maxHeight: 500,
        overflowY: 'auto',
    },

    image: {
        maxWidth: 64
    }
}));
