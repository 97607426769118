import React, { Fragment, ReactElement, ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive'

export interface MediaQueryProps  {
    children : any
}
export const Desktop = ({ children }: MediaQueryProps) => {
  const isDesktop = useMediaQuery({ minWidth: 1224 })
  return isDesktop ? children : null
}
export const DesktopAndTablet = ({ children }: MediaQueryProps) => {
  const isDesktop = useMediaQuery({ minWidth: 778 })
  return isDesktop ? children : null
}
export const Mobile = ({ children }: MediaQueryProps) => {
  const isMobile = useMediaQuery({ maxWidth: 1223 })
  return isMobile ? children : null
}
export const MobileWithoutTablet = ({ children }: MediaQueryProps) => {
  const isMobile = useMediaQuery({ maxWidth: 777 })
  return isMobile ? children : null
}
export const Default = ({ children }: MediaQueryProps) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}