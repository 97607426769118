

import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseSVG from '@material-ui/icons/Close'
import 'moment/locale/fr'
import React, { Component } from "react"
import { withRouter } from 'react-router'
import CreatePostComponent from "./CreatePost.hook.component"
import ViewPostComponent from "./ViewPost.component"
import { RouteComponentProps } from 'react-router-dom';

interface ModifyPostDialogProps {
    triggered: boolean;
    onClose: ((ok: boolean) => void);
    idPost: number,
    typeMur?: number,
    view: boolean
}

type ModifyPostDialogPropsGeneral = ModifyPostDialogProps & RouteComponentProps<{}>
interface ModifyPostDialogState {
    open: boolean;
}


class ModifyPostDialogComponent extends Component<ModifyPostDialogPropsGeneral, ModifyPostDialogState> {

    public readonly state: ModifyPostDialogState = {
        open: false,
    };


    componentDidMount() {
    }

    public componentDidUpdate(prevProps: ModifyPostDialogProps): void {
        if (prevProps.triggered !== this.props.triggered && this.props.triggered && !this.state.open) {
            this.setState({ open: true });
        }
    }

    public closeDialog(ok: boolean) {
        this.props.onClose(ok);

        this.setState({
            open: false,
        });
    }


    public render(): JSX.Element {
        const { idPost, view, typeMur } = this.props;
        return (

            <Dialog fullWidth open={this.state.open} onClose={() => this.closeDialog(false)}>
                <DialogTitle>
                    {`${view ? 'Consulter' : 'Modifier'}`} un post
                </DialogTitle>
                <DialogContent style={{ padding: '20px', textAlign: 'start' }}>
                    {
                        open &&
                        <Box borderRadius={5} >
                            {
                                view ?
                                    <ViewPostComponent tMur={typeMur} idPost={idPost} onClose={(ok: boolean) => this.closeDialog(ok)} />
                                    :
                                    <CreatePostComponent key={`create-post-in-modal-${idPost}`} modify={true} idPost={idPost} onClose={(ok: boolean) => this.closeDialog(ok)} />
                            }


                        </Box>
                    }
                </DialogContent>
                <Box position='absolute' top={0} right={0}>
                    <IconButton onClick={() => this.closeDialog(false)}>
                        <CloseSVG />
                    </IconButton>
                </Box>
            </Dialog>);
    }

}

export default withRouter(ModifyPostDialogComponent);