import { combineReducers } from "redux";
import { RootAction } from "store/types";
import { createReducer } from "typesafe-actions";
import { Metier } from "classes/referentiel/Metier.class";
import { loadCanauxAsync, loadCanalAsync, setCanal, loadNiveauxAsync, setNiveau, loadNiveauAsync } from "./actions";
import { Entite } from "classes/referentiel/Entite.class";
import { Niveau } from "classes/referentiel/Niveau.class";
import NiveauxReducer from '../niveaux/src/store/reducer'


const isLoading = createReducer<boolean, RootAction>(false)
    .handleAction([loadCanauxAsync.request,
    loadCanalAsync.request
    ], (_state, action) => true)
    .handleAction([loadCanauxAsync.success,
    loadCanauxAsync.failure,
    loadCanalAsync.success,
    loadCanalAsync.failure,
    ], (_state, action) => false);


const error = createReducer<string, RootAction>('')
    .handleAction([loadCanauxAsync.failure, loadNiveauxAsync.failure, loadCanalAsync.failure, loadNiveauAsync.failure], (_state, action) => action.payload)

const canaux = createReducer<Metier[], RootAction>([])
    .handleAction(loadCanauxAsync.success, (_state, action) => action.payload)

const canal = createReducer<Metier, RootAction>(null)
    .handleAction(setCanal, (_state, action) => action.payload)
    .handleAction(loadCanalAsync.success, (_state, action) => action.payload)


const CanauxReducer = combineReducers({
    isLoading,
    error,
    canaux,
    canal,
    niveaux : NiveauxReducer
    

});

export default CanauxReducer;
