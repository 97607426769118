import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { PropTypes } from '@material-ui/core/index';
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles } from '@material-ui/styles'
import AddPhotoIcon from "@material-ui/icons/AddAPhoto";
import CloseIconSVG from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import React, { Component, ComponentType, Fragment } from "react";
import ChoosePictureSVG from "../../features/admin/animations/assets/choose_picture.svg";
import style from "./FilePicker.style";


export interface FilePickerProps extends WithStyles<typeof style> {
    value?: File;
    directPathToFile?: string;
    onChange: (file: File) => void;
    fileType?: "image" | "file";
    variant?: "zone" | "button" | "icon" | "textfield" | "hidden";
    ButtonImg?: string | ComponentType<SvgIconProps>;
    emptyInnerZone?: boolean;
    buttonText?: string;
    buttonVariant?: "contained" | "outlined" | "text";
    buttonColor?: PropTypes.Color;
    buttonSize?: "medium" | "small";
    inputAccept?: string;
    textFieldProps?: TextFieldProps;
    round?: boolean;
    height?: string;
    maxFileSize?: number;
    open?: boolean;
    placeholder?: string;
    // Pour mettre le composant en readOnly
    readonly?: boolean;
    // Pour bloquer la suppression
    readonlyDelete?: boolean;
}

interface FilePickerState {
    file: File;
    tooLargeError: boolean;
    hoveredZone: boolean;
}

class FilePickerComponent extends Component<FilePickerProps, FilePickerState> {

    public static defaultProps: Pick<
        FilePickerProps,
        | "fileType"
        | "variant"
        | "ButtonImg"
        | "buttonText"
        | "buttonVariant"
        | "buttonColor"
        | "buttonSize"
        | "inputAccept"
        | "emptyInnerZone"
        | "round"
        | "height"
        | "maxFileSize"
        | "open"
        | "placeholder"
        | "readonly"
        | "readonlyDelete"
    > = {
            fileType: "image",
            variant: "zone",
            ButtonImg: ChoosePictureSVG,
            buttonVariant: "outlined",
            buttonColor: "default",
            buttonSize: "small",
            inputAccept: "image/*",
            emptyInnerZone: false,
            round: false,
            height: "100%",
            maxFileSize: 8 * 1e6,
            open: false,
            placeholder: "",
            readonly: false,
            readonlyDelete: false
        };

    public readonly state: FilePickerState = {
        file: null,
        tooLargeError: false,
        hoveredZone: false
    };

    private input: HTMLInputElement;

    private fileChangeHandler = this.handleFileChange.bind(this);

    constructor(props: FilePickerProps) {
        super(props);

        this.input = document.createElement("input");
        this.input.setAttribute("type", "file");
        this.input.setAttribute("accept", props.inputAccept);
    }

    public componentDidMount(): void {
        this.input.addEventListener("change", this.fileChangeHandler);
    }

    public componentWillUnmount(): void {
        this.input.removeEventListener("change", this.fileChangeHandler);
    }

    public openChooseDialog(): void {
        if(!this.props.readonly){
            this.input.click();
        }        
    }

    public async handleFileChange(): Promise<void> {
        const file: File = this.input.files.item(0);
        if (file) {
            if (file.size > this.props.maxFileSize) {

                this.setState({ tooLargeError: true, file: null });
                this.props.onChange(null);
            } else {
                this.props.onChange(file);
            }
        }
    }

    public setEmptyFile(): void {
        this.setState({ file: null });
        this.input.value = "";
        this.props.onChange(null);
    }

    public render(): JSX.Element {
        const {
            classes,
            fileType,
            variant,
            value,
            directPathToFile,
            ButtonImg,
            buttonText,
            buttonVariant,
            buttonColor,
            buttonSize,
            emptyInnerZone,
            textFieldProps,
            round,
            height,
            maxFileSize,
            open,
            placeholder,
            readonly,
            readonlyDelete
        } = this.props;
        const { tooLargeError, hoveredZone } = this.state;
        const filePicked = value !== null;

        if (open) {
            this.openChooseDialog();
        }

        if (variant === "zone") {
            const zoneStyle = filePicked && fileType === "image" ? { backgroundImage: `url('${directPathToFile ?? URL.createObjectURL(value)}')`, border: 0 } : {};

            let innerZone: JSX.Element;
            if (!emptyInnerZone) {
                if (!filePicked) {
                    innerZone = (
                        <Fragment>
                            <AddPhotoIcon color="disabled" className={classes.icon} />

                            <h3 className={classes.title}>Ajoutez votre image ici !</h3>
                            {tooLargeError ? (
                                <b>La taille du fichier ne doit pas dépaser { maxFileSize / 1e6} Mo</b>
                            ) : (
                                    <Typography color="secondary" className={classes.textIndication}>
                                        (Format recommandé : PNG ou JPEG - Max. {maxFileSize / 1e6} Mo)
                                    </Typography>
                                )}
                            <Button variant="contained" color="secondary" disabled={readonly} onClick={this.openChooseDialog.bind(this)}>
                                Choisir une image
                            </Button>
                        </Fragment>
                    );
                } else if(!readonly) {
                    innerZone = (
                        
                        <div className={classes.actionButtons}>
                            <Button color="secondary" variant="contained" onClick={this.openChooseDialog.bind(this)}>
                                <PhotoCameraIcon />
                            </Button>

                            <Button color="primary" variant="contained" onClick={this.setEmptyFile.bind(this)}>
                                <DeleteIcon />
                            </Button>
                        </div>
                    );
                }
            } else {
                innerZone = (
                    <div
                        style={{ height: "100%", width: "100%", display: "flex", cursor: "pointer", margin: 0 }}
                        onMouseEnter={() => this.setState({ hoveredZone: true })}
                        onMouseLeave={() => this.setState({ hoveredZone: false })}
                        onClick={this.openChooseDialog.bind(this)}
                    >
                        {filePicked ? (
                            <Fade in={hoveredZone}>
                                <Box
                                    onClick={e => {
                                        e.stopPropagation();
                                        this.setEmptyFile();
                                    }}
                                    bgcolor="rgba(0,0,0,0.4)"
                                    width="100%"
                                    height="100%"
                                    display="flex"
                                    margin={0}
                                    borderRadius={round ? "50%" : 5}
                                >
                                    <DeleteIcon style={{ margin: "auto", width: 48, height: 48, color: "white" }} />
                                </Box>
                            </Fade>
                        ) : (
                                <AddPhotoIcon style={{ margin: "auto" }} color="disabled" className={classes.icon} />
                            )}
                    </div>
                );
            }

            return (
                <div className={classes.container} style={{ paddingTop: height }}>
                    <div className={classes.zone} style={{ borderRadius: round ? "50%" : 5, ...zoneStyle }}>
                        {innerZone}
                    </div>
                </div>
            );
        } else if (variant === "button") {
            return (
                <Button
                    variant={buttonVariant}
                    className={buttonText ? "" : classes.buttonImg}
                    color={buttonColor}
                    component="span"
                    size={buttonSize}
                    disabled={readonly}
                    onClick={this.openChooseDialog.bind(this)}
                >
                    {typeof ButtonImg === "string" ? <img src={ButtonImg} /> : <ButtonImg />}
                    {buttonText}
                </Button>
            );
        } else if (variant === "icon") {
            return (
                <IconButton color={buttonColor} onClick={this.openChooseDialog.bind(this)}>
                    {typeof ButtonImg === "string" ? <img src={ButtonImg} /> : <ButtonImg />}
                    {buttonText}
                </IconButton>
            );
        } else if (variant === "textfield") {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                    <TextField
                        {...textFieldProps}
                        className={classes.textFieldReponse}
                        style={{ marginRight: filePicked && fileType != "file" ? 10 : 0 }}
                        value={value ? value.name : ""}
                        placeholder={placeholder}
                        InputProps={
                            value
                                ? readonlyDelete ? {
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <div className={classes.previewImage} style={{ backgroundImage: `url('${directPathToFile ?? URL.createObjectURL(value)}')` }}></div>
                                                    </InputAdornment>
                                                ),
                                                readOnly: true
                                            }
                                            : {
                                                endAdornment: (
                                                    <Tooltip title="Supprimer l'image">
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => this.setEmptyFile()} style={{ pointerEvents: "initial" }}>
                                                                <CloseIconSVG />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    </Tooltip>
                                                ),
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <div className={classes.previewImage} style={{ backgroundImage: `url('${directPathToFile ?? URL.createObjectURL(value)}')` }}></div>
                                                    </InputAdornment>
                                                ),
                                                readOnly: true
                                            }
                                : { readOnly: true }
                        }
                    ></TextField>
                    {!filePicked && (
                        <Button
                            variant={buttonVariant}
                            className={buttonText ? "" : classes.buttonImg}
                            color={buttonColor}
                            style={{ marginLeft: 20 }}
                            component="span"
                            size={buttonSize}
                            disabled={readonly}
                            onClick={this.openChooseDialog.bind(this)}
                        >
                            {typeof ButtonImg === "string" ? <img src={ButtonImg} /> : <ButtonImg />}
                            {buttonText}
                        </Button>
                    )}
                </Box>
            );
        } else if (variant === "hidden") {
            return <Fragment></Fragment>
        }
    }
}

export default withStyles(style)(FilePickerComponent);
