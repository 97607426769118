// eslint-disable-next-line import/extensions
import { DeepPartial } from 'redux';
import { DeepRawify } from 'types';
import { Question } from './Question.class';

/**
 * Section
 * Section d'une FAQ
 */
export class Section {
    /**
     * id de la section
     */
    public idSection: number;

    /**
     * idPere
     * id de la section parente
     */
    public idPere: number;

    /**
     * intitulé de la section
     */
    public nomSection: string;

    /**
     * indique si la section est active ou non
     */
    public actif: number;

    /**
     * Question associé à la section
     */
    public questions: Question[];

    /**
     * Sous section de la section
     */
    public sections: Section[];

    /**
     * Constructeur de la question
     * @param sectionObj à construire
     */
    constructor(sectionObj?: DeepPartial<DeepRawify<Section>>) {
      if (sectionObj) {
        const {
          idSection,
          idPere,
          nomSection,
          actif,
          questions,
          sections,
        } = sectionObj;
        this.idSection = idSection;
        this.idPere = idPere;
        this.nomSection = nomSection;
        this.actif = actif;
        this.questions = questions.map((question) => new Question(question));
        this.sections = sections.map((section) => new Section(section));
      }
    }
}
