import { Battle } from 'classes/level_up/battles/Battle.class';
import { cloneDeep } from 'lodash';
import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import {
    loadBattlesListAsync,
    loadOpponentsListAsync,
    getQuestionBattleAsync,
    saveNextAnswerBattleAsync,
    loadRecapQuizBattleAsync,
    loadInfosMesBattlesAsync,
    loadBattlesClassementAsync
} from './actions';
import { InfosBattles, InfosRecap, Opponent } from './types';
import { FicheQuestion } from 'classes/level_up/quiz/FicheQuestion.class';
import { ClassementAnswer } from '../../quiz/store/types';

const isLoading = createReducer<boolean, RootAction>(false)
    .handleAction([
        loadBattlesListAsync.request,
        loadOpponentsListAsync.request,
        loadInfosMesBattlesAsync.request,
        loadRecapQuizBattleAsync.request
    ], () => true)
    .handleAction([
        loadBattlesListAsync.success, 
        loadBattlesListAsync.failure,
        loadOpponentsListAsync.success,
        loadOpponentsListAsync.failure,
        loadInfosMesBattlesAsync.success,
        loadInfosMesBattlesAsync.failure,
        loadRecapQuizBattleAsync.success,
        loadRecapQuizBattleAsync.failure
    ], () => false);

const isLoadingClassement = createReducer<boolean, RootAction>(false)
    .handleAction([loadBattlesClassementAsync.request], () => true)
    .handleAction([
        loadBattlesClassementAsync.success, 
        loadBattlesClassementAsync.failure
    ], () => false);

const battles = createReducer<Battle[], RootAction>([])
    .handleAction(loadBattlesListAsync.success, (_state, action) => cloneDeep(action.payload));

const opponents = createReducer<Opponent[], RootAction>([])
    .handleAction(loadOpponentsListAsync.success, (_state, action) => cloneDeep(action.payload));

const selectedQuestion = createReducer<FicheQuestion | null, RootAction>(null)
    .handleAction([getQuestionBattleAsync.success, saveNextAnswerBattleAsync.success], (_state, action) => cloneDeep(action.payload));

const infosRecap = createReducer<InfosRecap | null, RootAction>(null)
    .handleAction([loadRecapQuizBattleAsync.success], (_state, action) => cloneDeep(action.payload));

const infosMesBattles = createReducer<InfosBattles | null, RootAction>(null)
    .handleAction([loadInfosMesBattlesAsync.success], (_state, action) => cloneDeep(action.payload));

const classement = createReducer<ClassementAnswer[], RootAction>(null)
    .handleAction([loadBattlesClassementAsync.success], (_state, action) => cloneDeep(action.payload));


export const levelUpBattlesReducers = combineReducers({
    isLoading,
    battles,
    opponents,
    selectedQuestion,
    infosRecap,
    infosMesBattles,
    classement,
    isLoadingClassement
});

export default levelUpBattlesReducers;