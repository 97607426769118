import { createAction, createAsyncAction } from 'typesafe-actions';
import { Entite } from 'classes/referentiel/Entite.class';
import { ArianeInfo, NiveauEntitiesInfos, ListRequestParameters, CreateModifyEntityInfos } from './types';
import { Niveau } from 'classes/referentiel/Niveau.class';

export const adminHeaderSetTitleAction = createAction('ADMIN_HEADER_SET_TITLE')<any>();


export const deleteEntityAsync = createAsyncAction('DELETE_ENTITY_REQUEST', 'DELETE_ENTITY_SUCCESS', 'DELETE_ENTITY_FAILURE')<
string,
boolean,
string
>();

export const loadEntitiesAsync = createAsyncAction('LOAD_ENTITIES_REQUEST', 'LOAD_ENTITIES_SUCCESS', 'LOAD_ENTITIES_FAILURE')<
number,
Entite[],
string
>();

export const loadEntityAsync = createAsyncAction('LOAD_ENTITY_REQUEST', 'LOAD_ENTITY_SUCCESS', 'LOAD_ENTITY_FAILURE')<
string,
Entite,
string
>();

export const loadArianeInfosAsync = createAsyncAction('LOAD_PARTICIPANTS_ARIANE_INFOS_REQUEST','LOAD_PARTICIPANTS_ARIANE_INFOS_SUCCESS','LOAD_PARTICIPANTS_ARIANE_INFOS_FAILURE')<
void,
ArianeInfo[],
string
>();

export const loadNiveauAsync = createAsyncAction('LOAD_PARTICIPANTS_NIVEAU_REQUEST','LOAD_PARTICIPANTS_NIVEAU_SUCCESS','LOAD_PARTICIPANTS_NIVEAU_FAILURE')<
number,
Niveau,
string
>();

export const loadNiveauEntitiesInfosAsync = createAsyncAction('LOAD_PARTICIPANTS_NIVEAU_ENTITIES_INFOS_REQUEST','LOAD_PARTICIPANTS_NIVEAU_ENTITIES_INFOS_SUCCESS','LOAD_PARTICIPANTS_NIVEAU_ENTITIES_INFOS_FAILURE')<
ListRequestParameters,
NiveauEntitiesInfos,
string
>();

export const loadNiveauEntitiesParentsInfosAsync = createAsyncAction('LOAD_PARTICIPANTS_NIVEAU_ENTITIES_PARENTS_INFOS_REQUEST','LOAD_PARTICIPANTS_NIVEAU_ENTITIES_PARENTS_INFOS_SUCCESS','LOAD_PARTICIPANTS_NIVEAU_ENTITIES_PARENTS_INFOS_FAILURE')<
ListRequestParameters,
NiveauEntitiesInfos,
string
>();

export const loadCreateModifyEntityInfosAsync = createAsyncAction('LOAD_PARTICIPANTS_CREATE_MODIFY_ENTITY_REQUEST','LOAD_PARTICIPANTS_CREATE_MODIFY_ENTITY_SUCCESS','LOAD_PARTICIPANTS_CREATE_MODIFY_ENTITY_FAILURE')<
ListRequestParameters,
CreateModifyEntityInfos,
string
>();

export const loadNbrMaxUtilisateursAtteintAsync = createAsyncAction('LOAD_NBR_MAX_UTILISATEURS_ATTEINT_REQUEST','LOAD_NBR_MAX_UTILISATEURS_ATTEINT_SUCCESS','LOAD_NBR_MAX_UTILISATEURS_ATTEINT_FAILURE')<
void,
boolean,
string
>();


export const adminSetFocusedEntity = createAction('PARTICIPANTS_SET_FOCUSED_ENTITY')<Entite>();