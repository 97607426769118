import createStyles from "@material-ui/styles/createStyles";
import { colors, Theme } from "Theme";

export default ({ spacing, palette }: typeof Theme) => createStyles({
    disconnectDrawer: {
        padding: spacing(4, 1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',

        '& img': {
            height: 120,
            marginBottom: spacing(2),
        },

        '& h3': {
            fontSize: 18,
            fontWeight: 'bold',
            margin: 0,
        },

        '& p': {
            // color: colors.grey.main,
            margin: spacing(2),
        },

        '& $drawerAction > *': {
            minWidth: 100,
            margin: spacing(0, 1),
        },
    },
    drawerAction: {},
});