import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { colors } from 'Theme';

export default ({ spacing, palette }: typeof Theme) => createStyles({
    paper : {
        borderRadius : 5,
        display : 'flex',
        justifyContent : 'space-between',
        padding : spacing(2),
        marginTop : spacing(2)
    },
    textDownload : {
        fontSize : 18,
        marginLeft : spacing(1)
    }
});