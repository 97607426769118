import { CarnetVoyage } from "classes/carnet_voyage/CarnetVoyage.class";
import { Item } from "classes/carnet_voyage/Item.class";
import { CarnetDay } from "classes/carnet_voyage/programme/CarnetDay.class";
import { createAsyncAction, createAction } from "typesafe-actions";
import { CarnetInformations } from "classes/carnet_voyage/informations/CarnetInformations.class";
import { CarnetInformationsInfos, CarnetScheduleInfos } from "./types";
import { Acteur } from "classes/Acteur.class";
import { Animation } from "classes/animations/Animation.class";
import { Edito } from "classes/carnet_voyage/edito/Edito.class";
import { Hotel } from "classes/carnet_voyage/hotel/Hotel.class";
import { B64File } from "classes/B64File.class";
import { CarnetParticipant } from "classes/carnet_voyage/participants/CarnetParticipant.class";

export const loadCarnet = createAsyncAction("LOAD_CARNET_REQUEST", "LOAD_CARNET_SUCCESS", "LOAD_CARNET_FAILURE")<void, CarnetVoyage, string>();

export const loadItems = createAsyncAction("LOAD_ITEMS_REQUEST", "LOAD_ITEMS_SUCCESS", "LOAD_ITEMS_FAILURE")<void, Item[], string>();

export const loadItem = createAsyncAction("LOAD_ITEM_REQUEST", "LOAD_ITEM_SUCCESS", "LOAD_ITEM_FAILURE")<number, Item, string>();

export const loadSchedule = createAsyncAction("LOAD_SCHEDULE_REQUEST", "LOAD_SCHEDULE_SUCCESS", "LOAD_SCHEDULE_FAILURE")<
    void,
    CarnetScheduleInfos,
    string
>();

export const saveSchedule = createAsyncAction("SAVE_SCHEDULE_REQUEST", "SAVE_SCHEDULE_SUCCESS", "SAVE_SCHEDULE_FAILURE")<
    CarnetDay[],
    void,
    string
>();

export const loadParticipants = createAsyncAction("LOAD_PARTICIPANTS_REQUEST", "LOAD_PARTICIPANTS_SUCCESS", "LOAD_PARTICIPANTS_FAILURE")<void, CarnetParticipant[], string>();

export const loadContacts = createAsyncAction("LOAD_CONTACTS_REQUEST", "LOAD_CONTACTS_SUCCESS", "LOAD_CONTACTS_FAILURE")<void, Acteur[], string>();

export const saveContacts = createAsyncAction("SAVE_CONTACTS_REQUEST", "SAVE_CONTACTS_SUCCESS", "SAVE_CONTACTS_FAILURE")<
    Acteur[],
    Acteur[],
    string
>();

export const loadCarnetQuestionnaires = createAsyncAction(
    "LOAD_CARNET_QUESTIONNAIRES_REQUEST",
    "LOAD_CARNET_QUESTIONNAIRES_SUCCESS",
    "LOAD_CARNET_QUESTIONNAIRES_FAILURE"
)<void, Animation[], string>();

export const loadEdito = createAsyncAction("LOAD_EDITO_REQUEST", "LOAD_EDITO_SUCCESS", "LOAD_EDITO_FAILURE")<void, Edito, string>();

export const saveEdito = createAsyncAction("SAVE_EDITO_REQUEST", "SAVE_EDITO_SUCCESS", "SAVE_EDITO_FAILURE")<Edito, Edito, string>();

export const loadHotel = createAsyncAction("LOAD_HOTEL_REQUEST", "LOAD_HOTEL_SUCCESS", "LOAD_HOTEL_FAILURE")<void, Hotel, string>();

export const saveHotel = createAsyncAction("SAVE_HOTEL_REQUEST", "SAVE_HOTEL_SUCCESS", "SAVE_HOTEL_FAILURE")<Hotel, Hotel, string>();

export const loadInformations = createAsyncAction("LOAD_INFORMATIONS_REQUEST", "LOAD_INFORMATIONS_SUCCESS", "LOAD_INFORMATIONS_FAILURE")<
    void,
    CarnetInformationsInfos,
    string
>();

export const saveInformations = createAsyncAction("SAVE_INFORMATIONS_REQUEST", "SAVE_INFORMATIONS_SUCCESS", "SAVE_INFORMATIONS_FAILURE")<
    CarnetInformations[],
    CarnetInformations[],
    string
>();

export const resetCarnet = createAsyncAction("RESET_CARNET_REQUEST", "RESET_CARNET_SUCCESS", "RESET_CARNET_FAILURE")<
    void,
    boolean,
    string
>();

export const setProgressSaveFiles = createAction('SET_PROGRESS_SAVE_FILES')<B64File>()

export const loadTelechargements = createAsyncAction("LOAD_TELECHARGEMENTS_REQUEST", "LOAD_TELECHARGEMENTS_SUCCESS", "LOAD_TELECHARGEMENTS_FAILURE")<void, B64File[], string>();

export const addFileTelechargements = createAsyncAction("ADD_FILE_TELECHARGEMENTS_REQUEST", "ADD_FILE_TELECHARGEMENTS_SUCCESS", "ADD_FILE_TELECHARGEMENTS_FAILURE")<B64File, B64File, string>();

export const removeFileTelechargements = createAsyncAction("REMOVE_FILE_TELECHARGEMENTS_REQUEST", "REMOVE_FILE_TELECHARGEMENTS_SUCCESS", "REMOVE_FILE_TELECHARGEMENTS_FAILURE")<B64File, any, string>();

export const setCarnet = createAction("SET_CARNET")<CarnetVoyage>();

export const setParticipant = createAction("SET_CARNET_PARTICIPANT")<CarnetParticipant>();
