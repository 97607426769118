import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import { WithStyles, withStyles } from '@material-ui/styles';
import React, { Component } from "react";
import style from './ConfirmationDrawer.style';

interface BaseProps {
    triggered: boolean;
    onClose: ((Confirmation: boolean) => void);
    title: string;
    image: string;
    text: string | JSX.Element;
    textOK?: string;
    textKO?: string;
}

interface ConfirmationDrawerState {
    open: boolean;
}

type ConfirmationDrawerProps = BaseProps & WithStyles<typeof style>;

class ConfirmationDrawerComponent extends Component<ConfirmationDrawerProps, ConfirmationDrawerState> {

    public readonly state: ConfirmationDrawerState = {
        open: false,
    };


    public componentDidUpdate(prevProps: ConfirmationDrawerProps): void {
        if (this.props.triggered != prevProps.triggered && this.props.triggered && !this.state.open) {
            this.setState({ open: true });
        }
    }

    public closeDrawer(ok: boolean) {
        this.props.onClose(ok);
        this.setState({
            open: false
        });
    }


    public render(): JSX.Element {
        const { title, text, textKO, textOK, image, classes } = this.props;
        const { open } = this.state;
        return <Drawer
            anchor="bottom"
            open={open}
            onClose={() => this.closeDrawer(false)}
        >
            <div className={classes.disconnectDrawer}>
                <img src={image} />
                <h3>{title}</h3>
                <Typography color="secondary">{text}</Typography>
                <div className={classes.drawerAction}>
                    <Fab
                        color="default"
                        size="large"
                        variant="extended"
                        onClick={() => this.closeDrawer(false)}
                    >
                        {textKO ? textKO : "Non"}
                    </Fab>
                    <Fab
                        color="primary"
                        size="large"
                        variant="extended"
                        onClick={() => this.closeDrawer(true)}
                    >
                        {textOK ? textOK : "Oui"}
                    </Fab>
                </div>
            </div>
        </Drawer>;
    }
}

export default withStyles(style)(ConfirmationDrawerComponent);