import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { colors } from 'Theme';

export default ({ spacing, palette }: typeof Theme) => createStyles({
    textFieldPost : {
        '& fieldset' : {
            borderStyle : 'none'
        },
        '& textarea::placeholder' : {
            color : colors.lighterGrey.main
        }
    },
    paperInfos : {
        marginBottom : spacing(1)
    },
    paperPost : {
        padding : spacing(1)
    },
});