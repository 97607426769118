import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'

export default ({ spacing, palette }: typeof Theme) => createStyles({
    formComment : {
        display: 'flex',
        flexDirection : 'row',
        width : '100%',
        justifyContent : 'space-between',
    },
    commentTextField : {
        backgroundColor : 'white',
        width: '100%',
        marginRight : spacing(1),
        borderRadius : 8
    },
    sendIconButton : {
        width : 54,
        minWidth: 54,
        borderRadius : 8
    }
});