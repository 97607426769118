import React from 'react';
import useStyle from './SortableList.style';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { TodoList } from 'classes/todolist/TodoList.class';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { useDispatch } from 'react-redux';
import { deleteTaskAsync, saveTaskAsync } from 'features/todolist/src/store/actions';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

interface SortableListProps{
    list: TodoList[];
}

interface SortableItemProps{
    item: TodoList;
    key: number;
}

interface DragHandleProps{
    message: string;
}

const DragHandle = SortableHandle((props: DragHandleProps): JSX.Element => {
    const classes = useStyle();

    return (
        <span className={classes.dragHandle}>{props.message}</span>
    );
});

const SortableList = SortableContainer((props: SortableListProps): JSX.Element => {
    const classes = useStyle();
    const dispatch = useDispatch();

    const SortableItem = SortableElement((props: SortableItemProps) => (
        <li className={classes.sortableItem}>
            <Box display="flex" alignItems="center" width="100%">
                <Checkbox 
                    checked={props.item.done} 
                    size="small" 
                    onClick={() => {
                        props.item.done = !props.item.done;
                        dispatch(saveTaskAsync.request(props.item));
                    }} 
                    checkedIcon={<CircleCheckedFilled />} 
                    icon={<CircleUnchecked />}
                />
                <DragHandle message={props.item.message} />
            </Box>

            <IconButton aria-label="delete" onClick={() => {
                dispatch(deleteTaskAsync.request(props.item.idTodo))
            }}>
                <CancelIcon />
            </IconButton>
        </li>
    ));

    return (
        <ul className={classes.sortableList}>
            {
                props.list.map((item, index) =>
                    <SortableItem item={item} key={index} index={index} />
                )
            }
        </ul>
    );
})

export default SortableList;