import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { RootAction } from 'store/types';
import { setBackUpLink } from './errorActions';

const error = createReducer<string, RootAction>('')

const backUpLink = createReducer<string, RootAction>('')
.handleAction(setBackUpLink, (_state, action) => action.payload)

const errorReducer = combineReducers({
    error,
    backUpLink
});

export default errorReducer;
