import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSelector } from 'store/rootStore';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { Post } from 'classes/murs/Post.class'
import EnLigneVideSVG from '../../../../../assets/visuels/en_ligne_vide.svg'
import { loadPostsAsync, loadMorePostsAsync, setListPostsLimitFilter, setListPostsMore } from '../store/actions';
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import PostComponent from './Post.hook.component'
import EmptyListComponent from 'components/EmptyList.component'
import PullToRefresh from 'react-simple-pull-to-refresh';

interface ListPostsProps {
    imgEmptyList?: string,
    subtitleEmptyList?: string,
    titleEmptyList?: string,
    listFromLocal?: Post[],
    removePost?: (index: number) => void,
    readonly?: boolean
}

const defaultPropValue: ListPostsProps = {
    imgEmptyList: EnLigneVideSVG,
    subtitleEmptyList: "Vous n'avez pas encore publié de publications",
    titleEmptyList: "C'est vide :)"
}

const ListPosts: React.FC<ListPostsProps> = (props = defaultPropValue) => {

    const {
        listFromLocal,
        removePost,
        readonly
    } = props;

    const [shouldLoadMore, setShouldLoadMore] = useState<boolean>(false)

    const containerListPostsRef = useRef(null)

    const dispatch = useDispatch();

    const [
        list,
        post,
        shouldPostsListGetUpdated,
        isLoading,
    ] = useSelector((state) => [
        state.murs.list,
        state.murs.post,
        state.murs.shouldPostsListGetUpdated,
        state.murs.isLoading,
    ], isEqual);

    useEffect(() => {
        if (!listFromLocal) {
            window.addEventListener('scroll', handleContainerScroll, true);

            if (shouldPostsListGetUpdated) {
                dispatch(loadPostsAsync.request());
            }

        }
        return function cleanup() {
            if (!listFromLocal) {
                window.removeEventListener('scroll', handleContainerScroll);
            }
        }
    }, []);

    useEffect(() => {
        setShouldLoadMore(false)
    }, [list])

    useEffect(() => {
        if (shouldLoadMore) {
            dispatch(setListPostsLimitFilter(list.length + 5))
            dispatch(setListPostsMore(true));
            dispatch(loadMorePostsAsync.request());
        }
    }, [shouldLoadMore])

    useEffect(() => {
        if (post) {
            var elmnt = document.getElementById(`post-${post.idMurPost}-${post.typeMur}`);
            if (elmnt) {
                elmnt.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            }
        }
    }, [post])

    useEffect(() => {
        if (shouldPostsListGetUpdated) {
            dispatch(setListPostsLimitFilter(list.length))
            dispatch(setListPostsMore(false));
            dispatch(loadPostsAsync.request());    
        }
    }, [shouldPostsListGetUpdated])


    function handleContainerScroll(event: Event) {
        // Nombre de pixels restants entre le bas de la zone affichée par client et le bas de la page (= hauteur de la fenêtre)
        if (containerListPostsRef.current) {
            var pxRestants = containerListPostsRef.current.getBoundingClientRect().bottom - document.documentElement.clientHeight;

            // Le nombre maximum de pixels restants à afficher, qui déclenche le chargement de nouveaux posts
            var pxLimite = 0.2 * containerListPostsRef.current.getBoundingClientRect().height;

            if (pxRestants <= pxLimite) {
                setShouldLoadMore(true)
            };
        }
    }

    const handleRefresh = () => {
        dispatch(setListPostsLimitFilter(5))
        dispatch(loadPostsAsync.request());            
        return new Promise((res) => setTimeout(() => res(""), 1000));       
    };

    return (
        <div ref={containerListPostsRef} style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
            <div id="main">
            {
                listFromLocal ?
                    <Fragment>
                        {
                            listFromLocal.map((mapPost, index) =>
                                mapPost !== undefined && 
                                    <PostComponent key={`post-${mapPost.idMurPost}-${mapPost.typeMur}`} post={mapPost} index={index} removePost={removePost} readOnly={readonly} />
                            )
                        }
                    </Fragment>

                    :
                    list &&
                    <PullToRefresh 
                        onRefresh={handleRefresh} 
                        refreshingContent={<CircularProgress style={{ margin: 'auto' }} />}
                        pullingContent=""
                    >
                        <>
                        {
                            list.map((mapPost, index) =>
                                mapPost !== undefined && 
                                    <PostComponent key={`post-${mapPost.idMurPost}-${mapPost.typeMur}`} post={mapPost} index={index} removePost={removePost} readOnly={readonly} />
                            )
                        }
                        </>
                    </PullToRefresh>
            }

            {
                isLoading &&
                <Box display='flex'>
                    <CircularProgress style={{ margin: 'auto' }} />
                </Box>
            }
            {
                !isLoading && !shouldPostsListGetUpdated && !list.length && (listFromLocal && !listFromLocal.length || !listFromLocal) &&
                <EmptyListComponent title={props.titleEmptyList} subtitle={props.subtitleEmptyList} urlImage={props.imgEmptyList} />
            }
            </div>
        </div>
    );
}

export default ListPosts;