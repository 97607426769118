import { DeepPartial } from 'redux';
import { MessagerieService } from 'services';
import { DeepRawify } from 'types';
import {v4} from 'uuid'
import { Message } from './Message.class';

/**
 * MessagePersonne
 * Message envoyé à un membre
 */
export class MessagePersonne extends Message {

    public uid: string = v4();
    public service = MessagerieService.getInstance();

    /**
     * L'identifiant du message personnel
     */
    public idMessagePersonne: number;

    /**
     * Constructeur du MessagePersonne
     */
    constructor(row: DeepPartial<DeepRawify<MessagePersonne>>) {
        super({
            ...row,
            idMessage: row['idMessagePersonne']
        });

        if (row) {
            this.idMessagePersonne = row['idMessagePersonne'];
        }
    }

    /**
     * Méthode de renvoi de l'objet en back sous la forme d'un tableau
     * @return array
     */
    public toRaw() {
        return {
            idMessagePersonne: this.idMessagePersonne,
            emetteur: this.emetteur,
            recepteur: this.recepteur,
            texte: this.texte,
            image: this.image,
            dateEnvoi: this.dateEnvoi
        };
    }

    public delete() {
        this.service.deleteMessage(null, this.idMessagePersonne);
    }
    
}
