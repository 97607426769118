import qs from 'qs';
import Axios from 'axios';
import { TableauBordData } from 'features/admin/tableau_bord/src/store/types';

export class TableauBordService {
    private static instance: TableauBordService;

    public static getInstance(): TableauBordService {
        if (!TableauBordService.instance) {
            TableauBordService.instance = new TableauBordService();
        }

        return TableauBordService.instance;
    }

    private static rubrique = 2;

    public async loadTableauBord(): Promise<TableauBordData> {
        const params = {
            rub: TableauBordService.rubrique,
            p: 10, /* Code pour avoir les données en JSON */
        };

        return Axios.get<{ content: TableauBordData }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content);
    }
}
