import qs from 'qs';
import Axios from 'axios';
import { Challenge } from 'classes/challenges/Challenge.class';
import { ChallengeEdito } from 'classes/challenges/Edito.class';
import { B64File } from 'classes/B64File.class';
import { rxAxios, RxAxios } from 'services/RxAxios';
import {
    ThemesIndexedByRubriquesLabels,
    ChallengeRubriquesAndInfos,
    ListChallengesTermines,
    ListChallengesFilterState,
    LoadClassements,
    LoadClassementSimpleActeur,
    ListClassementsEtDate,
    LoadMoreClassements,
    ChallengeAndRubrique,
    ClassementSimpleActeur,
    LoadClassementsSimpleSearch,
    InfosClassementGlobal
} from 'features/challenges/src/store/types';
import { ChallengeThemePredefini } from 'classes/challenges/ChallengeThemePredefini.class';
import { ChallengeThemePersonnalise } from 'classes/challenges/ChallengeThemePersonnalise.class';
import { ChallengeThemePersonnaliseImage } from 'classes/challenges/ChallengeThemePersonnaliseImage.class';
import { ChallengeActualite } from 'classes/challenges/ChallengeActualite.class';
import { ChallengeReglement } from 'classes/challenges/Reglement.class';
import { ChallengeRubrique } from 'classes/challenges/ChallengeRubrique.class';
import { ChallengeRubriqueInfos } from 'classes/challenges/ChallengeRubriqueInfos.class';
import { ChallengeDestinationRubrique } from 'classes/challenges/ChallengeDestinationRubrique.class';
import { ChallengeDestinationRubriqueInfos } from 'classes/challenges/ChallengeDestinationRubriqueInfos.class';
import { Video } from 'classes/videos/Video.class';
import { VideoLike } from 'classes/videos/VideoLike.class';
import { Suivi } from 'classes/challenges/suivi/Suivi.class';
import { MulticritereClassement } from 'classes/challenges/suivi/MulticritereClassement.class';
import { MulticritereCritere } from 'classes/challenges/suivi/MulticritereCritere.class';
import { DeepRawify } from 'types';
import { SuiviResultatMecaSimple } from 'classes/challenges/suivi/SuiviResultatMecaSimple.class';
import { SuiviEnfantGlobal } from 'classes/challenges/suivi/SuiviEnfantGlobal.class';
import { ClassementGlobal } from 'classes/challenges/suivi/ClassementGlobal.class';

const rub = IS_ADMIN ? 290 : 29;
export class ChallengeService {
    private static instance: ChallengeService;

    public static getInstance(): ChallengeService {
        if (!ChallengeService.instance) {
            ChallengeService.instance = new ChallengeService();
        }

        return ChallengeService.instance;
    }

    public findAll(filters: ListChallengesFilterState): Promise<Challenge[]> {
        const params = {
            rub,
            p: 3,
            filters: JSON.stringify(filters),
        };

        return Axios.get<{ content: DeepRawify<Challenge>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: challenges } }) => challenges.map((challenge) => new Challenge(challenge)));
    }

    /**
     * Charge la liste des challenges terminés
     * @returns la liste des challenges terminés
     */
    public findAllChallengesTermines(filters: ListChallengesFilterState): Promise<ListChallengesTermines> {
        const params = {
            rub,
            p: 52,
            filters: JSON.stringify(filters),
        };

        return Axios.get<{ content: DeepRawify<ListChallengesTermines> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => ({
                listChallengesTermines : content.listChallengesTermines.map((challenge) => new Challenge(challenge)),
                estListeChallTerminesOuverte : content.estListeChallTerminesOuverte,            
            }));
    }

    public find(idChallenge: number): Promise<Challenge> {
        const params = {
            rub,
            p: 2,
            idChallenge,
        };

        return Axios.get<{ content: DeepRawify<Challenge> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: challenge } }) => new Challenge(challenge));
    }

    public getChallengeAndRubrique(
        idChallenge: number,
        idRubriqueInfos: number,
    ): Promise<ChallengeAndRubrique> {
        const params = {
            rub,
            p: 49,
            idChallenge,
            idRubriqueInfos,
        };

        return Axios.get<{ content: DeepRawify<ChallengeAndRubrique> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => ({
                challenge: new Challenge(content.challenge),
                challengeRubrique: new ChallengeRubrique(content.challengeRubrique),
            }));
    }

    public save(challenge: Challenge, themeImages?: ChallengeThemePersonnaliseImage[], entities?: string[]): Promise<string> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub,
                p: 1,
            })}`,
            qs.stringify({
                challenge: JSON.stringify(challenge.toDatabaseObject()),
                themeImages: JSON.stringify(themeImages),
                entities: JSON.stringify(entities)
            }),
        )
            .then((data) => null)
            .catch(({ response: { data: { message } } }) => message);
    }

    /**
     * Enregistre une actualité
     * @param challengeActualite
     */
    public saveActualite(challengeActualite: ChallengeActualite): Promise<Boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub,
                p: 30,
            })}`,
            qs.stringify({
                actualite: JSON.stringify(challengeActualite.toDatabaseObject()),
            }),
        )
            .then(() => true)
            .catch(() => false);
    }

    /**
     * Supprime une actualité
     * @param idActualite l'id de l'actualité à supprimer
     */
    public deleteActualite(idActualite: number): Promise<Boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub,
                p: 33,
                idActualite,
            })}`,
            qs.stringify({ idActualite }),
        )
            .then(() => true)
            .catch(() => false);
    }

    /**
     * Charge la liste des actualités d'un challenge
     * @param idChallenge l'id du challenge dont on veut charger les actualités
     */
    public loadListActualites(idChallenge: number): Promise<ChallengeActualite[]> {
        const params = {
            rub,
            p: 32,
            idChallenge,
        };

        return Axios.get<{ content: DeepRawify<ChallengeActualite>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: actualites } }) => actualites.map((actualite) => new ChallengeActualite(actualite)));
    }

    /**
     * Charge les infos d'une actualité
     * @param idActualite l'id de l'activité dont on veut charger les infos
     */
    public async loadActualite(idActualite: number): Promise<ChallengeActualite> {
        const params = {
            rub,
            p: 31,
            idActualite,
        };

        return await Axios.get<{ content: DeepRawify<ChallengeActualite> }>(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                throw message;
            })
            .then(({ data: { content: actualite } }) => new ChallengeActualite(actualite));
    }

    /**
     * Ajoute un like à une actualité
     * @param challengeActualite
     */
    public addActualiteLike(idActualite: number): Promise<Boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub,
                p: 37,
            })}`,
            qs.stringify({
                idActualite: JSON.stringify(idActualite),
            }),
        )
            .then(() => true)
            .catch(() => false);
    }

    /**
     * Supprime un like à une actualité
     * @param challengeActualite
     */
    public removeActualiteLike(idActualite: number): Promise<Boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub,
                p: 38,
            })}`,
            qs.stringify({
                idActualite: JSON.stringify(idActualite),
            }),
        )
            .then(() => true)
            .catch(() => false);
    }

    public delete(idChallenge: number): Promise<boolean> {
        const params = {
            rub,
            p: 5,
            idChallenge,
        };

        return Axios.get(`index.php?${qs.stringify(params)}`).then(() => true);
    }

    public duplicate(idChallenge: number): Promise<void> {
        const params = {
            rub,
            p: 7,
            idChallenge,
        };

        return Axios.get(`index.php?${qs.stringify(params)}`);
    }

    public loadPredefinedThemes(): Promise<ThemesIndexedByRubriquesLabels> {
        const params = {
            rub,
            p: 9,
        };

        return Axios.get<{ content: DeepRawify<ThemesIndexedByRubriquesLabels> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) =>
                Object.fromEntries(Object.entries(content).map(([key, value]) => [key, value.map((theme) => 'idThemePredefini' in theme
                    ? new ChallengeThemePredefini(theme)
                    : new ChallengeThemePersonnalise(theme)
                )]))
            );
    }

    public findEdito(idChallenge: number, admin?: boolean): Promise<ChallengeEdito> {
        const params = {
            rub,
            p: 20,
            idChallenge,
        };

        return Axios.get<{ content: DeepRawify<ChallengeEdito> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: edito } }) => new ChallengeEdito(edito));
    }

    public saveEdito(edito: ChallengeEdito, admin?: boolean): Promise<Boolean> {
        const params = {
            rub,
            p: 21,
        };

        return Axios.post(`index.php?${qs.stringify(params)}`, 
        qs.stringify(
            { 
                edito: JSON.stringify(edito.toRaw()) 
            }
        )).then(()=>true)
        .catch((content)=>false);
    }

    public loadTelechargements(idChallenge: number): Promise<B64File[]> {
        const params = {
            rub,
            p: 10,
            idChallenge,
        };

        return Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => B64File.rowsToArray(content));
    }

    public addFileTelechargements(b64File: B64File, idChallenge: number): RxAxios<{ content: B64File }> {
        return rxAxios({
            method: 'post',
            url: `index.php?${qs.stringify({
                rub,
                p: 11,
            })}`,
            data: qs.stringify({
                b64File: JSON.stringify(b64File),
                idChallenge,
            }),
        });
    }

    public removeFileTelechargements(b64File: B64File): Promise<B64File> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub,
                p: 12,
            })}`,
            qs.stringify({
                b64File: JSON.stringify(b64File),
            }),
        ).then(({ data: { content } }) => B64File.rowsToFile(content));
    }

    public updateFileTelechargementsIndex(index: { oldIndex: number; newIndex: number }, challenge: Challenge): Promise<B64File[]> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub,
                p: 13,
            })}`,
            qs.stringify({
                oldIndex: index.oldIndex,
                newIndex: index.newIndex,
                idChallenge: challenge.idChallenge,
            }),
        ).then(({ data: { content } }) => B64File.rowsToArray(content));
    }

    public findReglement(idChallenge: number, admin?: boolean): Promise<ChallengeReglement> {
        const params = {
            rub,
            p: 22,
            idChallenge,
        };

        return Axios.get<{ content: DeepRawify<ChallengeReglement> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: reglement } }) => new ChallengeReglement(reglement));
    }

    /**
     * Charge la liste des rubriques de la partie destination d'un challenge
     * @param idChallenge l'id du challenge dont on veut charger les rubriques de destination
     */
    public loadDestinationRubriques(idChallenge: number): Promise<ChallengeDestinationRubrique[]> {
        const params = {
            rub,
            p: 34,
            idChallenge,
        };

        return Axios.get<{ content: DeepRawify<ChallengeDestinationRubrique>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: rubriques } }) => rubriques.map((rubrique) => new ChallengeDestinationRubrique(rubrique)));
    }

    public saveReglement(reglement: ChallengeReglement, admin?: boolean): Promise<Boolean> {
        const params = {
            rub,
            p: 23,
        };

        return Axios.post(`index.php?${qs.stringify(params)}`, qs.stringify({ reglement: JSON.stringify(reglement.toRaw()) }));
    }

    public async loadChallengeRubriquesAndInfos(idChallenge: number, rubriquesOrInfos: number): Promise<ChallengeRubriquesAndInfos> {
        const params = {
            rub,
            p: 6,
            idChallenge,
        };

        return Axios.get<{ content: DeepRawify<ChallengeRubriquesAndInfos> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => ({
                rubriques: content.rubriques.map((rubrique) => new ChallengeRubrique(rubrique)),
                infos: content.infos.map((info) => new ChallengeRubriqueInfos(info)),
            }));
    }

    public saveRubrique(rubrique: ChallengeRubrique): Promise<string> {
        const params = {
            rub,
            p: 7,
        };

        return Axios.post(`index.php?${qs.stringify(params)}`, qs.stringify({ rubrique: JSON.stringify(rubrique.toDatabaseObject()) }))
            .then(() => '')
            .catch(({ response: { data: { message } } }) => message || 'Erreur inconnue');
    }

    public deleteRubrique(rubrique: ChallengeRubrique): Promise<boolean> {
        const params = {
            rub,
            p: 4,
        };

        return Axios.post(`index.php?${qs.stringify(params)}`, qs.stringify({ rubrique: JSON.stringify(rubrique.toDatabaseObject()) }));
    }

    /**
     * Charge les informations de toutes les rubriques de la partie destination
     */
    public loadAllDestinationRubriquesInfos(): Promise<ChallengeDestinationRubriqueInfos[]> {
        const params = {
            rub,
            p: 35,
        };

        return Axios.get<{ content: DeepRawify<ChallengeDestinationRubriqueInfos>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: rubriquesInfos } }) => rubriquesInfos.map((rubriqueInfos) => new ChallengeDestinationRubriqueInfos(rubriqueInfos)));
    }

    /**
     * Enregistre les rubriques de destination d'un challenge
     * @param rubriquesDestination la liste des rubriques de destination à enregistrer
     */
    public saveRubriquesDestination(rubriquesInfos: {
        rubriquesDestination: ChallengeDestinationRubrique[];
        idChallenge: number;
    }): Promise<ChallengeDestinationRubrique[]> {
        return Axios.post<{ content: DeepRawify<ChallengeDestinationRubrique>[] }>(
            `index.php?${qs.stringify({
                rub,
                p: 36,
            })}`,
            qs.stringify({
                rubriquesDestination: JSON.stringify(rubriquesInfos.rubriquesDestination),
                idChallenge: rubriquesInfos.idChallenge,
            }),
        ).then(({ data: { content } }) => content.map((rubriqueDestination) => new ChallengeDestinationRubrique(rubriqueDestination)));
    }

    public saveVideo(idChallenge: number, video: Video): Promise<Video> {
        video.leChallenge = idChallenge;

        return Axios.post<{ content: DeepRawify<Video> }>(
            `index.php?${qs.stringify({
                rub,
                p: 14,
            })}`,
            qs.stringify({
                video: JSON.stringify(video.toDatabaseObject()),
            }),
        )
            .then(({ data: { content } }) => new Video(content))
            .catch(({ response: { data: { message } } }) => message);
    }

    public saveVideoLike(videoLike: VideoLike): Promise<string> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub,
                p: 18,
            })}`,
            qs.stringify({
                videoLike: JSON.stringify(videoLike.toDatabaseObject()),
            }),
        )
            .then(() => '')
            .catch(({ response: { data: { message } } }) => message);
    }

    public loadVideos(idChallenge: number): Promise<Video[]> {
        const params = {
            rub,
            p: 16,
            idChallenge,
        };

        return Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => Video.rowsToArray(content));
    }

    public removeVideo(idChallenge: number, video: Video): Promise<Video> {
        return Axios.post<{ content: DeepRawify<Video> }>(
            `index.php?${qs.stringify({
                rub,
                p: 17,
            })}`,
            qs.stringify({
                video: JSON.stringify(video.toDatabaseObject()),
            }),
        ).then(({ data: { content } }) => new Video(content));
    }

    public acceptRules(idChallenge: number): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub,
                p: 24,
            })}`,
            qs.stringify({
                idChallenge: idChallenge,
            }),
        )
            .then(() => true)
            .catch(() => false);
    }

    public saveSuivi(suivi: Suivi, criteresParam?: MulticritereCritere[], suivisEnfants? : SuiviEnfantGlobal[]): Promise<string> {
        const postData = {
            suivi: JSON.stringify(suivi.toDatabaseObject()),
            criteres: criteresParam ? JSON.stringify(criteresParam.map((critere) => critere.toDatabaseObject())) : null,
            suivisEnfants: suivisEnfants ? JSON.stringify(suivisEnfants.map((suiviEnfant) => suiviEnfant.toDatabaseObject())) : null,
        };

        return Axios.post<{ content: string }>(
            `index.php?${qs.stringify({
                rub,
                p: 40,
            })}`,
            qs.stringify(postData),
        )
            .then(({ data: { content } }) => content)
            .catch(({ response: { data: { message } } }) => message);
    }

    public deleteSuivi(suivi: Suivi): Promise<string> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub,
                p: 42,
            })}`,
            qs.stringify({
                idSuivi: suivi.idSuivi,
            }),
        )
            .then(() => '')
            .catch(({ response: { data: { message } } }) => message);
    }

    /**
     * Méthode permettant de dupliquer un suivi
     * @param suivi le suivi à dupliquer
     */
    public duplicateSuivi(suivi: Suivi): Promise<string> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub,
                p: 57,
            })}`,
            qs.stringify({
                idSuivi: suivi.idSuivi,
            }),
        )
            .then(() => '')
            .catch(({ response: { data: { message } } }) => message);
    }

    /**
     * Méthode permettant de generer le classement global d'un suivi
     * @param idSuivi l'identifiant du suivi dont on veut générer le classement global
     */
    public genererClassementGlobal(idSuivi: number): Promise<{ success?: string; error?: string; level?: number }> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub,
                p: 59,
            })}`,
            qs.stringify({
                idSuivi,
            }),
        )
            .then(({ data: { content } }) => content)
            .catch(({ response: { data: { message, level } } }) => ({ error: message, level }));
    }

    public loadChallengeSuivis(idChallenge: number): Promise<Suivi[]> {
        const params = {
            rub,
            p: 41,
            idChallenge,
        };

        return Axios.get<{ content: DeepRawify<Suivi>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map((suivi) => new Suivi(suivi)))
            .catch(({ response: { data: { message } } }) => message);
    }

    /**
     * Méthode de récupération des suivis pour la version mobile
     * C'est à dire uniquement les suivis actifs dans lesquels l'acteur courant participe
     * @param idChallenge 
     * @returns la liste des suivis pour la version mobile
     */
    public loadChallengeSuivisMobile(idChallenge: number): Promise<Suivi[]> {
        const params = {
            rub,
            p: 50,
            idChallenge,
        };

        return Axios.get<{ content: DeepRawify<Suivi>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map((suivi) => new Suivi(suivi)))
            .catch(({ response: { data: { message } } }) => message);
    }

    /** 
     * Méthode permettant de charger les suivis du challenge ayant le même niveau que spécifié en paramètre
     * @param idChallenge Identifiant du challenge
     * @param idEntiteNiveau Identifiant du niveau
     * @return la liste des suivis du challenge pour le niveau sélectionné par l'utilisateur
     */
    public loadSuivisByNiveau(idChallenge: number, idEntiteNiveau: number): Promise<SuiviEnfantGlobal[]> {
        const params = {
            rub: 290,
            p: 58
        };

        return Axios.post<{ content: DeepRawify<SuiviEnfantGlobal>[] }>(
            `index.php?${qs.stringify(params)}`,
            qs.stringify({
                idChallenge,
                idEntiteNiveau
            })
        )
            .then(({ data: { content } }) => content.map((suivi) => new SuiviEnfantGlobal(suivi)))
            .catch(({ response: { data: { message } } }) => message);
    }

    public loadChallengeSuivi(idSuivi: number): Promise<Suivi> {
        const params = {
            rub,
            p: 43,
            idSuivi,
        };

        return Axios.get<{ content: DeepRawify<Suivi> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: suivi } }) => new Suivi(suivi))
            .catch(({ response: { data: { message } } }) => message);
    }

    public importFile(idSuivi: number, file: B64File, sp?: number, idCritere?: number): Promise<{ success?: string; error?: string; level?: number }> {
        return Axios.post<{ content: { success: string, level: number } }>(
            `index.php?${qs.stringify({
                rub,
                p: 44,
                sp,
            })}`,
            qs.stringify({
                idSuivi,
                upload: JSON.stringify(file),
                idCritere
            }),
        )
            .then(({ data: { content } }) => content)
            .catch(({ response: { data: { message, level } } }) => ({ error: message, level }));
    }

    /**
     * Chargement des classements d'un suivi
     * @param idSuivi id du suivi dont on veut le classement
     * @returns classements de type MulticritereClassement[] et dateResultat de type number
     */
    public loadClassementsMulticritere(filter: LoadClassements): Promise<ListClassementsEtDate> {
        const params = {
            rub,
            p: 45,
            ...filter
        };

        return Axios.get<{
            content: {
                classements: DeepRawify<MulticritereClassement>[];
                classementsEntite: DeepRawify<MulticritereClassement>[];
                dateResultat: number;
            }
        }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => ({
                classements: content.classements.map((classement) => new MulticritereClassement(classement)),
                classementsEntite : content.classementsEntite.map((classement) => new MulticritereClassement(classement)),
                dateResultat: content.dateResultat,
            }))
            .catch(({ response: { data: { message } } }) => message);
    }

    /**
     * Chargement des classements d'un suivi
     * @param idSuivi id du suivi dont on veut le classement
     * @returns classements de type MulticritereClassement[] et dateResultat de type number
     */
    public loadMoreClassementsMulticritere(filter: LoadMoreClassements): Promise<ListClassementsEtDate> {
        const params = {
            rub,
            p: 51,
            ...filter
        };

        return Axios.get<{
            content: {
                classements: DeepRawify<MulticritereClassement>[];
                classementsEntite: DeepRawify<MulticritereClassement>[];
                dateResultat: number;
            }
        }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => ({
                classements: content.classements.map((classement) => new MulticritereClassement(classement)),
                classementsEntite : content.classementsEntite.map((classement) => new MulticritereClassement(classement)),
                dateResultat: content.dateResultat,
            }))
            .catch(({ response: { data: { message } } }) => message);
    }

    /**
     * Chargement du classement d'un acteur pour un suivi donné
     * @param idSuivi id du suivi dont on veut le classement pour l'acteur courant
     * @returns la liste des classements pour l'acteur courant.
     */
    public loadClassementMulticritereParActeur(idSuivi: number): Promise<MulticritereClassement> {
        const params = {
            rub,
            p: 47,
            idSuivi,
        };

        return Axios.get<{ content: DeepRawify<MulticritereClassement> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => new MulticritereClassement(content))
            .catch(({ response: { data: { message } } }) => message);
    }

    /**
     * Chargement du classement d'un acteur pour un suivi simple donné
     * @param idSuivi id du suivi simple dont on veut le classement pour l'acteur courant
     * @returns le classement pour l'acteur courant.
     */
     public loadClassementSimpleActeur(filter: LoadClassementSimpleActeur): Promise<ClassementSimpleActeur> {
        const params = {
            rub,
            p: 53,
            ...filter
        };

        return Axios.get<{ content: DeepRawify<ClassementSimpleActeur> }>(`index.php?${qs.stringify(params)}`)
        .then(({ data: { content } }) => ({
                classementActeur:  new SuiviResultatMecaSimple(content.classementActeur),
                top3 : content.top3 ? content.top3.map((classement) => new SuiviResultatMecaSimple(classement)) : null,
                position: content.position ? content.position.map((classement) => new SuiviResultatMecaSimple(classement)) : null,
            }))
            .catch(({ response: { data: { message } } }) => message);
    }

    /**
     * Méthode de récupération des classements d'un suivi
     * @param idSuivi 
     * @returns la liste des classements d'un suivi
     */
     public loadClassementsSimple(filter : LoadClassementsSimpleSearch): Promise<SuiviResultatMecaSimple[]> {
        const params = {
            rub,
            p: 54,            
            ...filter,
        };

        return Axios.get<{ content: DeepRawify<SuiviResultatMecaSimple>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map((classement) => new SuiviResultatMecaSimple(classement)))
            .catch(({ response: { data: { message } } }) => message);
    }

    /**
     * Méthode de récupération des classements d'un suivi
     * @param idSuivi 
     * @returns la liste des classements d'un suivi
     */
     public loadMonEquipe(idClassActeur : number): Promise<SuiviResultatMecaSimple[]> {
        const params = {
            rub,
            p: 55,            
            idClassActeur,
        };

        return Axios.get<{ content: DeepRawify<SuiviResultatMecaSimple>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map((classement) => new SuiviResultatMecaSimple(classement)))
            .catch(({ response: { data: { message } } }) => message);
    }

    /**
     * Méthode d'envoi de félicitations
     * @param idActeurFelicite identifiant de l'acteur à félicité
     * @param  message le message à envoyer
     * @returns boolean
     */
     public sendFelicitations(idActeurFelicite : string, message : string): Promise<boolean> {
        const params = {
            rub,
            p: 56,            
            idActeurFelicite,
            message
        };

        return Axios.get<{ content: boolean }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content)
            .catch(({ response: { data: { message } } }) => message);
    }

    /**
     * Chargement du classement global d'un suivi
     * @param idSuivi id du suivi dont on veut le classement global
     * @returns classement de type ClassementGlobal[] et dateResultat de type number
     */
    public loadClassementGlobal(filter: LoadClassements): Promise<InfosClassementGlobal> {
        const params = {
            rub,
            p: 60,
            ...filter
        };

        return Axios.get<{
            content: {
                classement: DeepRawify<ClassementGlobal>[];
                dateResultat: number;
            }
        }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => ({
                classement: content.classement?.map((ligneClass) => new ClassementGlobal(ligneClass)),
                dateResultat: content.dateResultat,
            }))
            .catch(({ response: { data: { message } } }) => message);
    }

    /**
     * Charge plus de lignes du classement global d'un suivi
     * @param idSuivi id du suivi dont on veut plus de lignes du classement global
     * @returns classement de type ClassementGlobal[] et dateResultat de type number
     */
    public loadMoreClassementGlobal(filter: LoadMoreClassements): Promise<InfosClassementGlobal> {
        const params = {
            rub,
            p: 61,
            ...filter
        };

        return Axios.get<{
            content: {
                classement: DeepRawify<ClassementGlobal>[];
                dateResultat: number;
            }
        }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => ({
                classement: content.classement.map((ligneClass) => new ClassementGlobal(ligneClass)),
                dateResultat: content.dateResultat,
            }))
            .catch(({ response: { data: { message } } }) => message);
    }

    /**
     * Chargement du classement global d'un acteur pour un suivi donné
     * @param idSuivi id du suivi global dont on veut le classement global de l'acteur courant
     * @returns le classement global de l'acteur courant.
     */
    public loadClassementGlobalActeur(idSuivi: number): Promise<ClassementGlobal|null> {
        const params = {
            rub,
            p: 63,
            idSuivi,
        };

        return Axios.get<{ content: DeepRawify<ClassementGlobal> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content != null ? new ClassementGlobal(content) : null)
            .catch(({ response: { data: { message } } }) => message);
    }
}
