import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { colors } from 'Theme';

export default ({ spacing, palette }: typeof Theme) => createStyles({
    dayImage: {
        width: '100%',
        display: 'inline-block',
        position: 'relative',
        minHeight: 200,
        paddingTop: '50%'
    },
    dayImageSrc : {
        position : 'absolute',
        top : 0,
        left : 0,
        right : 0,
        bottom : 0,
        backgroundRepeat : 'no-repeat',
        backgroundPosition : 'center center',
        backgroundSize : 'cover'
    }, 
    dayText : {
        position : 'absolute',
        bottom : 15,
        left : 15,
        color : 'white',
        fontSize : 18,
        fontWeight : 700
    },
    activityHour : {
        fontSize : 16,
        marginRight : spacing(1),
    },
    activityCategory : {
        color : 'white',
        fontSize : 12,
        borderRadius : '50vw',
        display :'inline-block',
        padding : '2px 8px'
    },
    activityTitle : {
        fontSize : 16,
        fontWeight : 700    
    },
    activityDescription : {
        fontSize : 14,
        color : colors.grey.main,
        whiteSpace: 'pre-line'
    },
    activityLocation : {
        fontSize : 12,
        marginLeft : spacing(1)
    }
    
});