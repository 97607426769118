import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import React, { Component } from "react"

interface ChoseFromListDialogProps {
    triggered: boolean;
    onClose: ((key: number) => void);
    list: {key : number, libelle : string}[];
    currentSelectedKey: number
}

interface ChoseFromListDialogState {
    open: boolean;
}

class ChoseFromListDialogComponent extends Component<ChoseFromListDialogProps, ChoseFromListDialogState> {

    public readonly state: ChoseFromListDialogState = {
        open: false,
    };

    public componentDidUpdate(prevProps: ChoseFromListDialogProps): void {
        if (prevProps.triggered !== this.props.triggered && this.props.triggered && !this.state.open) {
            this.setState({ open: true });
        }
    }

    public closeDialog(key: number) {
        if (key === null) {
            this.props.onClose(this.props.currentSelectedKey);
        } else {
            this.props.onClose(key);
        }

        this.setState({
            open: false,
        });
    }

    public render(): JSX.Element {
        const { list } = this.props;
        return (
            <Dialog fullWidth open={this.state.open} onClose={() => this.closeDialog(null)}>

                <DialogContent>
                    <List>
                        {
                            Object.values(list).map((value,i) =>
                                <ListItem key={`list-object-${i}`} onClick={() => this.closeDialog(value.key)}>
                                    <ListItemText>
                                        {value.libelle}
                                    </ListItemText>
                                </ListItem>
                            )
                        }
                    </List>
                </DialogContent>

            </Dialog>);
    }

}

export default ChoseFromListDialogComponent;