import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { colors } from 'Theme';

export default ({ spacing, palette }: typeof Theme) => createStyles({
    imgLaunch : {
        marginRight : spacing(0.5)
    },
    buttonDelete : {
        width : 56,
        height : 56,
        backgroundColor : colors.lighterGrey.light,
        color : colors.grey.main,
        minWidth : 56,
        marginLeft: spacing(1)
    },
    iconLocation : {
        color : colors.grey.main
    }
});