import { from, of } from 'rxjs';
import {
    filter, switchMap, catchError, map, mergeMap
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { combineEpics } from 'redux-observable';
import { RootEpic } from 'store/types';
import { navigationPush, navigationReplace } from './navigationActions';
import { push, replace } from 'connected-react-router';

const navigationPushEpic: RootEpic = (action$, state$, { }) => action$.pipe(
    filter(isActionOf(navigationPush)),
    mergeMap((action) => {
        return of(push({pathname : `/${state$.value.navigation.currentModule}${action.payload}`}))
    })
   
);

const navigationReplaceEpic: RootEpic = (action$, state$, { }) => action$.pipe(
    filter(isActionOf(navigationReplace)),
    mergeMap((action) => {
        return of(replace({pathname : `/${state$.value.navigation.currentModule}${action.payload}`}))
    })
   
);

const NavigationEpics = combineEpics(navigationPushEpic, navigationReplaceEpic);

export default NavigationEpics;
