import { ChallengeService } from 'services';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { B64File } from 'classes/B64File.class';
import { DeepRawify } from 'types';
import { DeepPartial } from 'redux';

// SQL columns of the corresponding table (ex : "idActeur,emailActeur,imageActeur") (press tab when you're done) : idChallenge, leReferentielMetier, titre, description, dateDebut, dateFin, statut, typeChallenge, enLigne, imageCouverture
export class ChallengeActualite {
	public idActualite : number = null
	public leChallenge : number = null
	public image : B64File = null
	public dateDebut : Moment = null
	public dateFin : Moment = null
	public titre : string = null
	public accroche : string = null
	public contenu : string = null
	public actif : boolean = null
	public nbLike : number = null
	public monLike : string = null

	private ChallengeService = ChallengeService.getInstance();
	
	// Image par défaut affichée si l'actualité n'a pas d'image
	static imageParDefaut = '/dist/assets/challenges/actualites/image_par_defaut.png';

	// Constructeur de la classe ChallengeActualite
    constructor(row? : DeepPartial<DeepRawify<ChallengeActualite>>) {
        if (row) {
            const {
				idActualite,
				leChallenge,
				image,
				titre,
				dateDebut,
				dateFin,
				accroche,
				contenu,
				actif,
				nbLike,
				monLike
            } = row;

			this.idActualite = idActualite;
			this.leChallenge = leChallenge;
			this.image = B64File.fromDb(image);
			this.titre = titre;
			this.dateDebut = moment(dateDebut, 'X');
			this.dateFin = moment(dateFin, 'X');
			this.accroche = accroche;
			this.contenu = contenu;
			this.actif = actif;
			this.nbLike = nbLike;
			this.monLike = monLike;
        }
    }

	/**
	 * Enregistre une actualité
	 */
    public save() :  Promise<Boolean> {
        return this.ChallengeService.saveActualite(this);
    }

	/**
	 * Supprime une actualité
	 */
	public delete() : Promise<Boolean> {
        return this.ChallengeService.deleteActualite(this.idActualite);
	}
	
	/**
	 * Ajoute un like à l'actualité
	 * @param idActualite 
	 */
	public async addLike(idActualite: number): Promise<Boolean> {
        return this.ChallengeService.addActualiteLike(idActualite)
    }

	/**
	 * Supprime un like à l'actualité
	 * @param idActualite 
	 */
    public async removeLike(idActualite: number): Promise<Boolean> {
        return this.ChallengeService.removeActualiteLike(idActualite)
    }

    public toDatabaseObject(): object {
        return {
			idActualite : this.idActualite,
			leChallenge : this.leChallenge,
			image : this.image,
			titre : this.titre,
			dateDebut : this.dateDebut.unix(),
			dateFin : this.dateFin.unix(),
			accroche : this.accroche,
			contenu : this.contenu,
			actif : this.actif,
			nbLike : this.nbLike
        }
    }
}