import { Box, ButtonGroup, Button, InputAdornment, TextField, Typography } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core';
import CheckCircleSVG from '@material-ui/icons/CheckCircle';
import ErrorSVG from '@material-ui/icons/Error';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import Momindum from 'components/video/Momindum.component';
import { RechercheLienInterne } from 'features/murs/src/store/types';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useState } from "react";
import ReactPlayer from 'react-player';
import { MursService } from 'services';
import { useSelector } from 'store/rootStore';
import { colors } from "Theme";

const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/;

const service = MursService.getInstance();

interface AddVideoLinkProps {
    triggered: boolean;
    onClose: ((linkVideo: string) => void);
    video: boolean;
    canaux?: string[];
}

const AddVideoLinkDialog: React.FC<AddVideoLinkProps> = props => {

    const [typeMur] = useSelector((state) => [state.murs.typeMur], isEqual);

    const [link, setLink] = useState("");
    const [typeLien, setTypeLien] = useState<"interne" | "externe">("externe");

    // Autocomplete
    const [input, setInput] = useState("");
    const [options, setOptions] = useState<RechercheLienInterne[]>([]);
    const [loading, setLoading] = useState(false);

    const valid = useMemo(() => {
        if (props.video) {
            return ReactPlayer.canPlay(link) || Momindum.canPlay(link)
        } else {
            return urlRegex.test(link)
        }
    }, [link]);

    useEffect(() => {
        const trimmedInput = input.trim();
        if (trimmedInput?.length < 2) {
            return undefined;
        }

        setLoading(true);
    
        (async () => {
            const response = await service.rechercheLienInterne(typeMur, props.canaux, trimmedInput)
            setOptions(response);
            setLoading(false);
        })();
    }, [input]);

    useEffect(() => {
        setLink("");
    }, [typeLien])

    const onClose = (ok?: boolean) => {
        ok ? props.onClose(link) : props.onClose("");
        setLink('');
    }

    const onChangeAutocomplete = (event: React.ChangeEvent<{}>, value: RechercheLienInterne) => {
        setLink(URL_SITE + (URL_SITE.endsWith('/') ? '' : '/') + (value.id2 ? 
            "#/level-up/fiches/categories/"+value.id2+"/"+value.id+"/" : 
            "#/challenge/"+value.id+"/rubrique/"
        ));
    }

    return (
        <Dialog fullWidth open={props.triggered} onClose={onClose}>
            <DialogTitle>
                <Typography variant="h6">Ajouter {props.video ? 'une vidéo' : 'un lien URL'}</Typography>
            </DialogTitle>

            <DialogContent>
                {
                    !props.video &&
                    <>
                        <Typography>
                            Choisissez le type de lien à ajouter. Un lien externe redirige vers un site Internet extérieur, tandis qu'un lien interne permet de diriger l'utilisateur vers un challenge ou une fiche de TeamUp.
                        </Typography>

                        <ButtonGroup style={{ padding: 10 }} color="primary" fullWidth>
                            <Button variant={typeLien == 'externe' ? 'contained' : 'outlined'} onClick={() => setTypeLien("externe")}>Externe</Button>
                            <Button variant={typeLien == 'interne' ? 'contained' : 'outlined'} onClick={() => setTypeLien("interne")}>Interne</Button>
                        </ButtonGroup>
                    </>
                }
                <Box style={{ padding: 10 }}>
                {
                    typeLien != 'interne' ?
                    <TextField InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {
                                    valid ?
                                        <CheckCircleSVG style={{ color : colors.green.main }} />
                                        :
                                        <ErrorSVG style={{ color : colors.yellow.main }}/>
                                }
                            </InputAdornment>
                        )
                    }} autoFocus fullWidth value={link} onChange={(e) => setLink(e.target.value)} label={`Lien ${props.video ? 'vidéo' : ''}`} />
                    :
                    <Autocomplete
                        getOptionSelected={(option, value) => option === value}
                        getOptionLabel={(option) => (option.id2 > 0 ? "Fiche - " : "Challenge - ") + option.titre}
                        options={options}
                        loading={loading} freeSolo={false}
                        onInputChange={(_, value) => setInput(value)}
                        onChange={onChangeAutocomplete}
                        renderInput={(params) => (
                            <TextField {...params} label="Chercher un contenu" fullWidth
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                }
                </Box>
                </DialogContent>

                <DialogActions>
                    <Button size="large" variant="contained" onClick={() => onClose()}>Annuler</Button>
                    <Button size="large" variant="contained" color="primary" disabled={!valid} onClick={() => onClose(true)}>Ajouter</Button>
                </DialogActions>
        </Dialog>
    );
};

AddVideoLinkDialog.defaultProps = {
    canaux: []
};

export default AddVideoLinkDialog;