import { DeepPartial } from 'redux';
import { LevelUpService } from 'services';
import { DeepRawify } from 'types';
import {v4} from 'uuid'

export class GroupeCaracteristique {

    public uid: string = v4();

    /**
     * Numéro du groupe caracteristique
     */
    public idGroupeCaracteristique: number;

    /**
     * Libellé (nom) du groupe caracteristique
     */
    public libelle : string;

    /**
     * actif ?
     */
    public enLigne : boolean;

    /**
     * Famille parente
     */
    public idFamille : number;

    /**
     * Classement des groupes
     */
    public ordre: number;

    private levelUpService = LevelUpService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<GroupeCaracteristique>>) {
        if (row) {
            const { idGroupeCaracteristique,
                libelle,
                enLigne,
                idFamille,
                ordre
            } = row;
            this.idGroupeCaracteristique = idGroupeCaracteristique;
            this.libelle = libelle;
            this.enLigne = enLigne;
            this.idFamille = idFamille;
            this.ordre = ordre;
        }
    }

    /**
     * Formate les données pour le renvoi en back
     * @returns array pour le back
     */
    public toRaw() {
        return {
            idGroupeCaracteristique : this.idGroupeCaracteristique,
            libelle : this.libelle,
            enLigne : this.enLigne,
            idFamille : this.idFamille,
            ordre : this.ordre
        };
    }

    public save(): Promise<GroupeCaracteristique> {
        return this.levelUpService.saveGroupeCaracteristique(this);
    }

    public delete(): Promise<boolean> {
        return this.levelUpService.deleteGroupeCaracteristique(this.idGroupeCaracteristique);
    }

}
