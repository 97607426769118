import { combineEpics } from 'redux-observable';
import { RootEpic } from 'store/types';
import { isActionOf } from 'typesafe-actions';
import { loadAdminParametresTypesPostInfosAsync, loadAdminTypesPostAsync, loadAdminTypePostAsync } from './actions';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

// Chargement des types de post en fonction du type de mur
const loadAdminTypesPostEpics: RootEpic = (action$, state$, { parametresService }) => action$.pipe(
    filter(isActionOf(loadAdminTypesPostAsync.request)),
    switchMap((action) => from(parametresService.loadTypesPost(action.payload)).pipe(
        map(loadAdminTypesPostAsync.success),
        catchError(message => of(loadAdminTypesPostAsync.failure(message))),
    )),
);

// Chargement des types de posts et type de mur par défaut
const loadAdminParametresTypesPostInfosEpics: RootEpic = (action$, state$, { parametresService }) => action$.pipe(
    filter(isActionOf(loadAdminParametresTypesPostInfosAsync.request)),
    switchMap(() => from(parametresService.loadTypesPostInfos()).pipe(
        map(loadAdminParametresTypesPostInfosAsync.success),
        catchError(message => of(loadAdminParametresTypesPostInfosAsync.failure(message))),
    )),
);

// Chargement d'un type de posts
const loadAdminTypePostEpics: RootEpic = (action$, state$, { parametresService }) => action$.pipe(
    filter(isActionOf(loadAdminTypePostAsync.request)),
    switchMap((action) => from(parametresService.loadTypePost(action.payload.typeMur, action.payload.idTypePost)).pipe(
        map(loadAdminTypePostAsync.success),
        catchError(message => of(loadAdminTypePostAsync.failure(message))),
    )),
);

// Combinaison des différentes Epics pour les types de post
const AdminTypesPostEpics = combineEpics(
    loadAdminTypesPostEpics,
    loadAdminParametresTypesPostInfosEpics,
    loadAdminTypePostEpics
);

export default AdminTypesPostEpics;