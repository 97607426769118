import { createAsyncAction, createAction } from 'typesafe-actions';
import * as types from './types';
import { Animation } from 'classes/animations/Animation.class';
import { Question } from 'classes/animations/Question.class';
import { AnimationTypes } from 'classes/animations/AnimationTypes.enum';
import { Quiz } from 'classes/animations/Quiz.class';
import { Participation } from 'classes/animations/Participation.class';
import { Ranking, Rankings } from 'classes/animations/Ranking.type';
import { Post } from 'classes/animations/concoursPhoto/Post.class';

/** ********* Animation List ***************** */
export const setTypeCreationAnimation = createAction('ADMIN_SET_TYPE_CREATION_ANIMATION')<AnimationTypes>();

export const adminSetCurrentAnimation = createAction('ADMIN_SET_CURRENT_ANIMATION')<Animation>();

export const adminSetHideLeftPanel = createAction('ADMIN_SET_HIDE_LEFT_PANEL')<boolean>();

// Chargement des animations
export const adminLoadAnimationsAsync = createAsyncAction(
    'ADMIN_LOAD_ANIMATIONS_REQUEST',
    'ADMIN_LOAD_ANIMATIONS_SUCCESS',
    'ADMIN_LOAD_ANIMATIONS_FAILURE',
)<void, Animation[], string>();

export const adminGetAnimationAsync = createAsyncAction(
    'ADMIN_GET_ANIMATION_REQUEST',
    'ADMIN_GET_ANIMATION_SUCCESS',
    'ADMIN_GET_ANIMATION_FAILURE'
)<number, Animation, string>();

export const adminDeleteAnimationAsync = createAsyncAction(
    'ADMIN_DELETE_ANIMATION_REQUEST',
    'ADMIN_DELETE_ANIMATION_SUCCESS',
    'ADMIN_DELETE_ANIMATION_FAILURE'
)<number, void, string>();


export const adminAnimationsListActions = {
    setTypeCreationAnimation,
    adminSetCurrentAnimation,
    adminLoadAnimationsAsync,
    adminGetAnimationAsync,
    adminDeleteAnimationAsync,
    adminSetHideLeftPanel
};

/***************** QUIZ ***********/

export const adminGetQuizAsync = createAsyncAction(
    'ADMIN_GET_QUIZ_REQUEST',
    'ADMIN_GET_QUIZ_SUCCESS',
    'ADMIN_GET_QUIZ_FAILURE'
)<number, Quiz, string>();

export const adminGetQuestionsQuizAsync = createAsyncAction(
    'ADMIN_GET_QUESTIONS_QUIZ_REQUEST',
    'ADMIN_GET_QUESTIONS_QUIZ_SUCCESS',
    'ADMIN_GET_QUESTIONS_QUIZ_FAILURE'
)<void, Question[], string>();

export const adminSaveQuestionsQuizAsync = createAsyncAction(
    'ADMIN_SAVE_QUESTIONS_QUIZ_REQUEST',
    'ADMIN_SAVE_QUESTIONS_QUIZ_SUCCESS',
    'ADMIN_SAVE_QUESTIONS_QUIZ_FAILURE'
)<Question[], void, string>();

export const adminGetRankingsQuizAsync = createAsyncAction(
    'ADMIN_GET_RANKINGS_QUIZ_REQUEST',
    'ADMIN_GET_RANKINGS_QUIZ_SUCCESS',
    'ADMIN_GET_RANKINGS_QUIZ_FAILURE'
)<void, Rankings, string>();

export const adminDeleteRankingQuizAsync = createAsyncAction(
    'ADMIN_DELETE_RANKING_QUIZ_REQUEST',
    'ADMIN_DELETE_RANKING_QUIZ_SUCCESS',
    'ADMIN_DELETE_RANKING_QUIZ_FAILURE'
)<number, void, string>();

export const setQuizModified = createAction('ADMIN_SET_QUIZ_MODIFIED')<boolean>();

export const setProgressSaveQuestions = createAction('ADMIN_SET_PROGRESS_SAVE_QUESTIONS')<number>()

export const adminQuizActions = {
    adminGetQuestionsQuizAsync,
    setQuizModified,
    adminSaveQuestionsQuizAsync,
    adminGetQuizAsync,
    setProgressSaveQuestions,
    adminGetRankingsQuizAsync,
    adminDeleteRankingQuizAsync
}

/********** CONCOURS PHOTO *********** */

export const adminLoadPostsAsync = createAsyncAction(
    'ADMIN_LOAD_POSTS_REQUEST',
    'ADMIN_LOAD_POSTS_SUCCESS',
    'ADMIN_LOAD_POSTS_FAILURE'
)<number, Post[], string>();

export const adminSavePostAsync = createAsyncAction(
    'ADMIN_SAVE_POST_REQUEST',
    'ADMIN_SAVE_POST_SUCCESS',
    'ADMIN_SAVE_POST_FAILURE'
)<Post, Post, string>();

export const adminConcoursPhotoActions = {
    adminLoadPostsAsync,
    adminSavePostAsync
}