import { QuizService } from 'services/animations/Quiz.service';
import { Animation } from './Animation.class';
import { AnimationTypes } from './AnimationTypes.enum';
import { Participation } from './Participation.class';
import { B64File } from 'classes/B64File.class';
import PlayersSVG from '../../features/admin/animations/assets/players.svg'
import ParticipationRateSVG from '../../features/admin/animations/assets/participation_rate.svg'
import AverageScoreSVG from '../../features/admin/animations/assets/average_score.svg'
import { StatisticsDisplayerBaseProps } from 'components/statisticsDisplayer/StatisticsDisplayer.component';
import { Acteur } from 'classes/Acteur.class';
import { Rankings } from './Ranking.type';
import { QuizBadge } from './QuizBadge.class';
import { DeepRawify } from 'types';
import { DeepPartial } from 'redux';

export class Quiz extends Animation {
    public notifType = 6;


    public randomQuestion: boolean = false;

    public randomQuestionCount: number = 1;

    public showResultsAfterGame: boolean = false;

    public publishResultsAfterEnd: boolean = false;

    public enableRanking: boolean = false;

    public playableQuestionCount: number;

    public scoredQuestionsCount : number;

    public editable: boolean = false;

    public hasBadge : boolean = false;

    public quizBadge : QuizBadge = new QuizBadge();

    public isCarnet : boolean = false;

    private quizService = QuizService.getInstance();

    constructor(quiz?: DeepPartial<DeepRawify<Quiz>>) {
        super(quiz);

        this.type = AnimationTypes.Quiz;

        if (quiz) {
            this.randomQuestion = quiz.randomQuestion;
            this.randomQuestionCount = quiz.randomQuestionCount;
            this.showResultsAfterGame = quiz.showResultsAfterGame;
            this.enableRanking = quiz.enableRanking;
            this.publishResultsAfterEnd = quiz.publishResultsAfterEnd;
            this.playableQuestionCount = quiz.playableQuestionCount;
            this.scoredQuestionsCount = quiz.scoredQuestionsCount;
            this.hasBadge = quiz.hasBadge;
            this.editable = quiz.editable;
            if(quiz.quizBadge){
                this.quizBadge = new QuizBadge(quiz.quizBadge);
            }
            this.isCarnet = quiz.isCarnet;
        }
    }

    public getParticipation(autoCreate = false): Promise<Participation> {
        return this.quizService.findParticipation(this.idAnimation, autoCreate);
    }

    public getRanking(): Promise<Rankings> {
        return this.quizService.findRanking(this.idAnimation);
    }

    public toObject(): object {
        return {
            ...super.toObject(),
            randomQuestion: this.randomQuestion,
            randomQuestionCount: this.randomQuestionCount,
            showResultsAfterGame: this.showResultsAfterGame,
            publishResultsAfterEnd: this.publishResultsAfterEnd,
            enableRanking: this.enableRanking,
            hasBadge : this.hasBadge,
            isCarnet : this.isCarnet,
            quizBadge : this.quizBadge.toObject()
        };
    }


    public async getStatistics(): Promise<StatisticsDisplayerBaseProps> {
        const stats = await this.animationService.getStatistics(this.idAnimation, AnimationTypes.Quiz);

        return {
            statistics: [
                {
                    icon: PlayersSVG,
                    value: stats.playersNbr,
                    text: "Joueurs"
                },
                {
                    icon: AverageScoreSVG,
                    value: stats.averageScore ? stats.averageScore : "-",
                    text: "Score moyen"
                },
                {
                    icon: ParticipationRateSVG,
                    value: stats.participationRate,
                    text: "Taux part."
                }
            ]
        }
    }




    // REQUÊTES AJAX

    public async getResultsStatistics(): Promise<{
        stats: { [key: number]: number },
        reponsesTextes: { [key: number]: { reponses : Array<string>, displayMore : boolean } }
    }> {
        return this.quizService.getResultsStatistics(this.idAnimation);
    }

    public async getNonParticipants() : Promise<Acteur[]>{
        return this.quizService.getNonParticipants(this.idAnimation);
    }

    public async getQuestionsQuizCount() : Promise<number>{
        return this.quizService.getQuestionsQuizCount(this.idAnimation);
    }

    public async importQuestions(file : B64File) : Promise<string>{
        return this.quizService.importQuestions(this.idAnimation, file);
    }

    public async getBadge() : Promise<QuizBadge> {
        return this.quizService.getBadgeQuiz(this.idAnimation);
    }
}

Quiz.TYPES_ANIMATION[AnimationTypes.Quiz] = Quiz;
