import React, { useState, useEffect } from 'react';
import useStyle from './CreateNotificationDialog.style';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Entite } from 'classes/referentiel/Entite.class';
import TextField from '@material-ui/core/TextField';
import { Notifiable } from 'classes/notifications/Notifiable.class';
import { Notification } from 'classes/notifications/Notification.class';
import { useSelector } from 'store/rootStore';
import { useDispatch } from 'react-redux';
import { listADAsync } from 'store/notifications/notificationsActions';
import { FormControl, Select, InputLabel, MenuItem, ButtonGroup, Chip, Box, Grow, CircularProgress } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import { newSnackbar, SnackbarType } from 'store/snackbars/snackBarsActions';
import { Retour, Target } from "classes/notifications/Notifiable.class";
import EmojiPicker from './EmojiPicker.component';
import TextFieldWithEmojiPicker from './TextFieldWithEmojiPicker.component';
import { Challenge } from 'classes/challenges/Challenge.class';

/**
 * Paramètres du composant
 */
interface CreateNotificationDialogProps {
    onClose: () => void;
    object: Notifiable;
    triggered: boolean;
    defaultEntite?: Entite;
    defaultTitle?: string;
    defaultBody?: string;
}

/**
 * Affichage d'une boîte de dialogue permettant à l'administrateur
 * de créer des notifications groupées pour plusieurs acteurs.
 * Il peut choisir une notification pour des AC, des acteurs ou tous.
 * @param props Paramètres du composant (obligatoire)
 */
const CreateNotificationDialog: React.FC<CreateNotificationDialogProps> = props => {
    const classes = useStyle();

    // Stocke la notification configurée par l'admin
    const [notification, setNotification] = useState<Notification>(new Notification({
        titre: props.defaultTitle ?? "",
        message: props.defaultBody ?? ""
    }));

    // Liste des entités sélectionnées
    const [listSelect, setListSelect] = useState<string[]>([]);

    // mode d'envoi (targets: tous, ad, users)
    const [targetType, setTargetType] = useState<Target>("tous");

    // sélection de l'email
    const [tmpMail, setTmpMail] = useState<string>("");

    // erreur affichée sélection e-mail
    const [error, setError] = useState<string>("");

    // est-ce que les notifs sont en cours d'envoi
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // popup de confirmation
    const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);

    // données de confirmation
    const [confirmationData, setConfirmationData] = useState<Retour>(null);

    const dispatch = useDispatch();
    
    const [listAD] = useSelector((state) => [state.notifications.listAD]);
    
    useEffect(() => {
        dispatch(listADAsync.request());

        if (props.defaultEntite) {
            setTargetType('ad');
        }
    }, []);

    useEffect(() => {
        if (props.defaultEntite) {
            setTargetType('ad');
        }
        if (props.defaultBody) {
            notification.message = props.defaultBody;
            setNotification(cloneDeep(notification))
        }
    }, [props]);

    useEffect(() => {
        setListSelect([]);
    }, [targetType]);

    const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
        if (["Enter", "Tab", ",", " "].includes(evt.key)) {
            evt.preventDefault();
        
            let value = tmpMail.trim();
        
            if (value && isValid(value)) {
                setListSelect([...listSelect, value]);
                setTmpMail('');
            }
        }
    };
    
    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setTmpMail(evt.target.value);
        setError('');
    };
    
    const handleDelete = (item: string) => {
        setListSelect(listSelect.filter(i => i !== item));
    };
    
    const handlePaste = (evt: React.ClipboardEvent<HTMLDivElement>) => {
        evt.preventDefault();
    
        let paste = evt.clipboardData.getData("text");
        let emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    
        if (emails) {
            let toBeAdded = emails.filter(email => !isInList(email));
            setListSelect([...listSelect, ...toBeAdded]);
        }
    };
    
    const isValid = (email: string) => {
        let err = null;

        if (isInList(email)) {
            err = `${email} a déjà été ajouté.`;
        }
    
        if (!isEmail(email)) {
            err = `${email} n'est pas une adresse valide.`;
        }
    
        if (err) {
            setError(err)    
            return false;
        }
    
        return true;
    };
    
    const isInList = (email: string) => {
        return listSelect.includes(email);
    };
    
    const isEmail = (email: string) => {
        return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
    };

    const notifyActeurs = async () => {

        let value = tmpMail.trim();
        let users = cloneDeep(listSelect);

        setIsLoading(true)

        if (value && isValid(value)) {
            users.push(value);
            setListSelect([...listSelect, value]);
            setTmpMail('');
        }

        if (props.defaultEntite) {
            users = [props.defaultEntite.idEntite];
        }

        let rep = await props.object.notifyActeurs(notification, targetType, users, confirmationOpen);

        if (rep.success) {
            dispatch(newSnackbar({
                type: SnackbarType.SUCCESS,
                props: {
                    open: true,
                    autoHideDuration: 5000,
                    message: `La notification a été envoyée !`,
                }
            }));
            setNotification(new Notification({
                titre: props.defaultTitle ?? "",
                message: props.defaultBody ?? ""        
            }));
            setIsLoading(false);
            setListSelect([]);
            setConfirmationOpen(false);
            setConfirmationData(null);
            props.onClose();
        } else if (rep.nbActeurs) {
            setConfirmationData(rep);
            setConfirmationOpen(true);
            setIsLoading(false);
        } else {
            dispatch(newSnackbar({
                type: SnackbarType.WARNING,
                props: {
                    open: true,
                    autoHideDuration: 5000,
                    message: `La notification n'a pas pu être envoyée.`,
                }
            }));
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Dialog onClose={() => props.onClose()} open={props.triggered}>
                <DialogTitle className={classes.root}>
                    <Typography variant="h6" className={classes.title}>Envoyer une notification</Typography>
                    <IconButton aria-label="close" onClick={() => props.onClose()} className={classes.closeButton}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    {
                        props.defaultEntite ?
                        <Typography>
                            Envoyez un message à tous les acteurs associés à {props.defaultEntite.entiteLibelle}
                        </Typography>
                        :
                        <>
                            <Typography>
                                Envoyez un message à tous les acteurs concernés ou à une population restreinte en sélectionnant des entités ou des acteurs.
                            </Typography>

                            <ButtonGroup style={{ padding: 10 }} color="primary" fullWidth>
                                <Button variant={targetType == 'tous'  ? 'contained' : 'outlined'} onClick={() => setTargetType("tous") }>Tous</Button>
                                <Button variant={targetType == 'ad'    ? 'contained' : 'outlined'} onClick={() => setTargetType("ad")   }>Entités</Button>
                                <Button variant={targetType == 'users' ? 'contained' : 'outlined'} onClick={() => setTargetType("users")}>Acteurs</Button>
                            </ButtonGroup>

                            {targetType == "ad" &&
                                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                    <InputLabel id="demo-mutiple-name-label">Sélection des entités</InputLabel>
                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={listSelect}
                                        onChange={(event) => setListSelect(event.target.value as string[])}
                                    >
                                    {
                                        props.object instanceof Challenge ? (
                                                props.object.entities.map((entite) => (
                                                    <MenuItem key={entite} value={entite}>
                                                        {entite}
                                                    </MenuItem>
                                                ))
                                            ) :
                                            listAD.map((entite) => (
                                                <MenuItem key={entite.idEntite} value={entite.idEntite}>
                                                    {entite.entiteLibelle}
                                                </MenuItem>
                                            )
                                        )
                                    }
                                    </Select>
                                </FormControl>
                            }
                            {targetType == "users" &&
                                <>
                                    <Box className={classes.chipRoot}>
                                        {
                                            listSelect.map(item => (
                                                <Chip
                                                    label={item}
                                                    onDelete={() => handleDelete(item)}
                                                />
                                            ))
                                        }
                                    </Box>
                                    <TextField 
                                        label="Adresses e-mail des acteurs" 
                                        variant="outlined"
                                        value={tmpMail}
                                        onKeyDown={handleKeyDown}
                                        onChange={handleChange}
                                        onPaste={handlePaste}
                                        error={error.length > 0}
                                        helperText={error}  
                                        fullWidth
                                        className={classes.textfield}                                     
                                    />
                                </>
                            }
                        </>
                    }
                    
                    <TextFieldWithEmojiPicker
                        value={notification.titre}
                        onChange={(e) => {
                            notification.titre = e;
                            setNotification(cloneDeep(notification))
                        }}
                        label="Titre de la notification"
                    />

                    <TextFieldWithEmojiPicker
                        value={notification.message}
                        onChange={(e) => {
                            notification.message = e;
                            setNotification(cloneDeep(notification))
                        }}
                        label="Contenu de la notification"
                        multiline
                    />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button size="large" variant="contained" color="secondary" onClick={() => props.onClose()}>
                        Annuler
                    </Button>
                    <Button size="large" variant="contained" color="primary" onClick={() => notifyActeurs()} disabled={isLoading}>
                        { isLoading ? <CircularProgress /> : "Envoyer" }
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog onClose={() => setConfirmationOpen(false)} open={confirmationOpen}>
                <DialogTitle className={classes.root}>
                    <Typography variant="h6" className={classes.title}>Confirmer la notification</Typography>
                    <IconButton aria-label="close" onClick={() => setConfirmationOpen(false)} className={classes.closeButton}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <Typography>Etes-vous certain(e) de vouloir effectuer cette action ?</Typography>
                    {
                        confirmationData?.nbActeurs &&
                        <Typography>
                            Vous êtes sur le point d'envoyer une notification à {confirmationData.nbActeurs} acteurs.
                        </Typography>
                    }
                    {
                        confirmationData?.modeTest &&
                        <Typography>
                            L'application est en mode test, cette notification ne sera envoyée qu'à un nombre restreint d'acteurs, configurés par l'administrateur.
                        </Typography>
                    }
                    {
                        confirmationData?.acteurInexistant?.length > 0 &&
                        <Typography>
                            Attention, les acteurs suivants ne sont pas enregistrés sur l'application et ne recevront pas de notification.
                            {confirmationData.acteurInexistant.map((value) => (<><br/>- {value}</>))}
                        </Typography>
                    }
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button size="large" variant="contained" color="secondary" onClick={() => setConfirmationOpen(false)}>
                        Annuler
                    </Button>
                    <Button size="large" variant="contained" color="primary" onClick={() => notifyActeurs()} disabled={isLoading}>
                        { isLoading ? <CircularProgress /> : "Envoyer" }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CreateNotificationDialog;