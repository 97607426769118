import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { Component } from "react";
import SuppressionSVG from '../../../../../../assets/visuels/suppression.svg';



interface DeleteAnimationDialogProps {
    triggered: boolean;
    onClose: ((deleteAnimation : boolean)=>void);
}

interface DeleteAnimationDialogState {
    open : boolean;
}



class DeleteAnimationDialogComponent extends Component<DeleteAnimationDialogProps, DeleteAnimationDialogState> {

    public readonly state: DeleteAnimationDialogState = {
        open: false,
    };


    public componentDidUpdate(prevProps: DeleteAnimationDialogProps): void {
        if (this.props.triggered != prevProps.triggered && this.props.triggered && !this.state.open) {
            this.setState({ open: true });
        }
    }

    public closeDialog(deleteAnimation : boolean){
        this.props.onClose(deleteAnimation);
        this.setState({
            open: false
        });
    }


    public render(): JSX.Element {
        return <Dialog open={this.state.open} onClose={() => this.closeDialog(false)}>
            <DialogTitle>Supprimer l'animation</DialogTitle>
            
            <DialogContent>
                <DialogContentText>
                    <img src={SuppressionSVG} />
                </DialogContentText>
                <DialogContentText>
                    ATTENTION : en cliquant sur <b>Supprimer</b>, cette opération effacera toutes participations liées à cette animation
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button size="large" variant="contained" color="secondary" disabled={false} onClick={() => this.closeDialog(false)}>
                    Annuler
                </Button>
                <Button size="large" variant="contained" color="primary" disabled={false} onClick={() => this.closeDialog(true)}>
                    Supprimer
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

export default DeleteAnimationDialogComponent;