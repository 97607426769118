import { B64File } from 'classes/B64File.class';
import { Challenge } from 'classes/challenges/Challenge.class';
import { ChallengeActualite } from 'classes/challenges/ChallengeActualite.class';
import { ChallengeDestinationRubrique } from 'classes/challenges/ChallengeDestinationRubrique.class';
import { ChallengeDestinationRubriqueInfos } from 'classes/challenges/ChallengeDestinationRubriqueInfos.class';
import { ChallengeRubrique } from 'classes/challenges/ChallengeRubrique.class';
import { ChallengeRubriqueInfos } from 'classes/challenges/ChallengeRubriqueInfos.class';
import { ChallengeEdito } from 'classes/challenges/Edito.class';
import { ChallengeReglement } from 'classes/challenges/Reglement.class';
import cloneDeep from 'lodash/cloneDeep';
import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import {
    addFileTelechargements,
    getChallengeAsync,
    loadChallengeActualiteAsync,
    loadChallengeActualitesAsync,
    loadChallengeEditoAsync,
    loadChallengeReglementAsync,
    loadChallengeRubriquesAndInfosAsync,
    // loadChallengeRubriquesAsync,
    // loadChallengeRubriquesInfosAsync,
    loadChallengesAsync,
    loadChallengesTerminesAsync,
    loadDestinationRubriquesAsync,
    loadDestinationRubriquesInfosAsync,
    loadPredefinedThemesAsync,
    loadTelechargements,
    removeFileTelechargements,
    saveRubriquesDestinationAsync,
    setCurrentChallenge,
    setProgressSaveFiles,
    setSelectedChallenge,
    updateFileTelechargementsIndex,
    saveVideo,
    removeVideo,
    loadVideos,
    setListChallengesIdTypeChallenge,
    loadChallengeSuivisAsync,
    loadChallengeSuivisMobileAsync,
    loadChallengeSuivisNiveauAsync,
    loadChallengeSuiviAsync,
    loadClassementsMulticritereAsync,
    loadClassementMulticritereParActeurAsync,
    setCurrentSuivi,
    getChallengeAndRubriqueAsync,
    loadMoreClassementsMulticritereAsync,
    loadClassementSimpleActeurAsync,
    loadClassementsSimpleAsync,
    loadMonEquipeAsync,
    loadMoreClassementGlobalAsync,
    loadClassementGlobalAsync,
    loadClassementGlobalActeurAsync
} from './actions';
import { ThemesIndexedByRubriquesLabels } from './types';
import { Video } from 'classes/videos/Video.class';
import { Suivi } from 'classes/challenges/suivi/Suivi.class';
import { MulticritereClassement } from 'classes/challenges/suivi/MulticritereClassement.class';
import { SuiviResultatMecaSimple } from 'classes/challenges/suivi/SuiviResultatMecaSimple.class';
import { SuiviEnfantGlobal } from 'classes/challenges/suivi/SuiviEnfantGlobal.class';
import { ClassementGlobal } from 'classes/challenges/suivi/ClassementGlobal.class';



const list = createReducer<Challenge[], RootAction>([])
    .handleAction(loadChallengesAsync.success, (state, action) => action.payload)

const listChallengesTermines = createReducer<Challenge[], RootAction>([])
    .handleAction(loadChallengesTerminesAsync.success, (state, action) => action.payload.listChallengesTermines)

const estListeChallTerminesOuverte = createReducer<boolean, RootAction>(false)
    .handleAction(loadChallengesTerminesAsync.success, (state, action) => action.payload.estListeChallTerminesOuverte)

const isLoading = createReducer<boolean, RootAction>(true)
    .handleAction([loadChallengesAsync.success,
    loadChallengesTerminesAsync.success,
    loadPredefinedThemesAsync.success,
    loadDestinationRubriquesAsync.success,
    loadDestinationRubriquesInfosAsync.success,
    saveRubriquesDestinationAsync.success,
    loadChallengeEditoAsync.success,
    loadChallengesAsync.failure,
    loadChallengesTerminesAsync.failure,
    getChallengeAsync.failure,
    getChallengeAsync.success,
    // loadChallengeRubriquesAsync.success,
    loadChallengeRubriquesAndInfosAsync.success,
    // loadChallengeRubriquesInfosAsync.success,
    // loadChallengeRubriquesAsync.failure,
    loadChallengeRubriquesAndInfosAsync.failure,
    loadChallengeSuivisAsync.failure,
    loadChallengeSuivisMobileAsync.failure,
    loadChallengeSuiviAsync.failure,
    loadChallengeSuivisAsync.success,
    loadChallengeSuivisMobileAsync.success,
    loadChallengeSuiviAsync.success,
    // loadChallengeRubriquesInfosAsync.failure,
    loadPredefinedThemesAsync.failure,
    loadChallengeActualitesAsync.success,
    loadChallengeActualiteAsync.success,
    loadMonEquipeAsync.success,
    getChallengeAndRubriqueAsync.success,
    loadChallengeActualitesAsync.failure,
    loadChallengeActualiteAsync.failure,
    loadPredefinedThemesAsync.failure,
    loadDestinationRubriquesAsync.failure,
    loadDestinationRubriquesInfosAsync.failure,
    saveRubriquesDestinationAsync.failure,
    loadChallengeEditoAsync.failure,
    getChallengeAndRubriqueAsync.failure,
    loadMonEquipeAsync.failure], (state, action) => false)
    .handleAction([loadChallengesAsync.request,
    loadChallengesTerminesAsync.request,
    getChallengeAsync.request,
    loadPredefinedThemesAsync.request,
    loadChallengeActualitesAsync.request,
    loadChallengeActualiteAsync.request,
    // loadChallengeRubriquesAsync.request,
    loadChallengeRubriquesAndInfosAsync.request,
    // loadChallengeRubriquesInfosAsync.request,
    loadDestinationRubriquesAsync.request,
    loadDestinationRubriquesInfosAsync.request,
    saveRubriquesDestinationAsync.request,
    loadChallengeEditoAsync.request,
    loadChallengeSuivisAsync.request,
    loadChallengeSuivisMobileAsync.request,
    loadChallengeSuiviAsync.request,
    getChallengeAndRubriqueAsync.request,
    loadMonEquipeAsync.request], (state, action) => true)

const isLoadingChallTermines = createReducer<boolean, RootAction>(true)
    .handleAction([
    loadChallengesTerminesAsync.success,
    loadChallengesTerminesAsync.failure,], (state, action) => false)
    .handleAction([loadChallengesTerminesAsync.request], (state, action) => true)

const isLoadingClassement = createReducer<boolean, RootAction>(false)
    .handleAction([loadClassementsMulticritereAsync.success,
    loadClassementMulticritereParActeurAsync.success,
    loadClassementSimpleActeurAsync.success,
    loadClassementsSimpleAsync.success,
    loadClassementsMulticritereAsync.failure,
    loadClassementMulticritereParActeurAsync.failure, 
    loadClassementSimpleActeurAsync.failure,
    loadClassementsSimpleAsync.failure], (state, action) => false)
    .handleAction([loadClassementsMulticritereAsync.request,
    loadClassementMulticritereParActeurAsync.request, 
    loadClassementSimpleActeurAsync.request,
    loadClassementsSimpleAsync.request], (state, action) => true)

const isLoadingMoreClassement = createReducer<boolean, RootAction>(false)
    .handleAction([loadMoreClassementsMulticritereAsync.success,
    loadMoreClassementsMulticritereAsync.failure], (state, action) => false)
    .handleAction([loadMoreClassementsMulticritereAsync.request], (state, action) => true)

const isLoadingSuivis = createReducer<boolean, RootAction>(true)
    .handleAction([
    loadChallengeSuivisAsync.failure,
    loadChallengeSuivisMobileAsync.failure,
    loadChallengeSuiviAsync.failure,
    loadChallengeSuivisAsync.success,
    loadChallengeSuivisMobileAsync.success,
    loadChallengeSuiviAsync.success], (state, action) => false)
    .handleAction([
    loadChallengeSuivisAsync.request,
    loadChallengeSuivisMobileAsync.request,
    loadChallengeSuiviAsync.request], (state, action) => true)

const isLoadingSuivisEnfants = createReducer<boolean, RootAction>(false)
    .handleAction([
        loadChallengeSuivisNiveauAsync.success,
        loadChallengeSuivisNiveauAsync.failure,], (state, action) => false)
    .handleAction([loadChallengeSuivisNiveauAsync.request], (state, action) => true)


const themesOrdered = createReducer<ThemesIndexedByRubriquesLabels, RootAction>(null)
    .handleAction([loadPredefinedThemesAsync.success], (_state, action) => action.payload)

const selectedChallenge = createReducer<Challenge, RootAction>(null)
    .handleAction([setCurrentChallenge, getChallengeAsync.success, setSelectedChallenge], (_state, action) => action.payload)
    .handleAction(getChallengeAndRubriqueAsync.success, (_state,action) => action.payload.challenge);

const edito = createReducer<ChallengeEdito, RootAction>(null)
    .handleAction([loadChallengeEditoAsync.success], (_state, action) => cloneDeep(action.payload));

const reglement = createReducer<ChallengeReglement, RootAction>(null)
    .handleAction([loadChallengeReglementAsync.success], (_state, action) => cloneDeep(action.payload));

const files = createReducer<B64File[], RootAction>([])
    .handleAction(setProgressSaveFiles, (_state, action) => _state.map(obj => action.payload.localIdFile == obj.localIdFile ? action.payload : obj))
    .handleAction(addFileTelechargements.request, (_state, action) => [..._state, action.payload.b64File])
    .handleAction(addFileTelechargements.success, (_state, action) => _state.map(obj => action.payload.localIdFile == obj.localIdFile ? action.payload : obj))
    .handleAction(removeFileTelechargements.success, (_state, action) => _state.filter(o => o.idFile != action.payload.idFile))
    .handleAction(loadTelechargements.success, (_state, action) => action.payload)
    .handleAction(updateFileTelechargementsIndex.success, (state, action) => action.payload)
    .handleAction(updateFileTelechargementsIndex.request, (state, action) => {
        if (action.payload.newIndex >= state.length) {
            var k = action.payload.newIndex - state.length + 1;
            while (k--) {
                state.push(undefined);
            }
        }

        const newState = cloneDeep(state);

        newState.splice(action.payload.newIndex, 0, newState.splice(action.payload.oldIndex, 1)[0]);

        return newState;
    });

const actualites = createReducer<ChallengeActualite[], RootAction>([])
    .handleAction([loadChallengeActualitesAsync.success], (_state, action) => action.payload)

const actualite = createReducer<ChallengeActualite, RootAction>(null)
    .handleAction([loadChallengeActualiteAsync.success], (_state, action) => action.payload)

const suivis = createReducer<Suivi[], RootAction>(null)
    .handleAction([loadChallengeSuivisAsync.success, loadChallengeSuivisMobileAsync.success], (_state, action) => action.payload)

const suivisEnfants = createReducer<SuiviEnfantGlobal[], RootAction>([])
    .handleAction([loadChallengeSuivisNiveauAsync.success], (_state, action) => action.payload)


const suivi = createReducer<Suivi, RootAction>(null)
    .handleAction([loadChallengeSuiviAsync.success, setCurrentSuivi], (_state, action) => action.payload)

const classements = createReducer<MulticritereClassement[], RootAction>(null)
    .handleAction([loadClassementsMulticritereAsync.success], (_state, action) => action.payload.classements)
    .handleAction(loadMoreClassementsMulticritereAsync.success, (_state, action) => [..._state, ...action.payload.classements])

const classementsEntite = createReducer<MulticritereClassement[], RootAction>(null)
    .handleAction([loadClassementsMulticritereAsync.success], (_state, action) => action.payload.classementsEntite)
    .handleAction(loadMoreClassementsMulticritereAsync.success, (_state, action) => [..._state, ...action.payload.classementsEntite])

const classementActeur = createReducer<MulticritereClassement, RootAction>(null)
    .handleAction([loadClassementMulticritereParActeurAsync.success], (_state, action) => action.payload)

const classementSimpleActeur = createReducer<SuiviResultatMecaSimple, RootAction>(null)
    .handleAction([loadClassementSimpleActeurAsync.success], (_state, action) => action.payload.classementActeur)

const top3 = createReducer<SuiviResultatMecaSimple[], RootAction>(null)
    .handleAction([loadClassementSimpleActeurAsync.success], (_state, action) => action.payload.top3)

const position = createReducer<SuiviResultatMecaSimple[], RootAction>(null)
    .handleAction([loadClassementSimpleActeurAsync.success], (_state, action) => action.payload.position)

const classementsSimple = createReducer<SuiviResultatMecaSimple[], RootAction>(null)
    .handleAction([loadClassementsSimpleAsync.success], (_state, action) => action.payload)

const monEquipe = createReducer<SuiviResultatMecaSimple[], RootAction>(null)
    .handleAction([loadMonEquipeAsync.success], (_state, action) => action.payload)

const dateResultat = createReducer<number, RootAction>(null)
    .handleAction([loadClassementsMulticritereAsync.success], (_state, action) => action.payload.dateResultat)
    .handleAction([loadClassementGlobalAsync.success], (_state, action) => action.payload.dateResultat)

const classementGlobal = createReducer<ClassementGlobal[], RootAction>(null)
    .handleAction([loadClassementGlobalAsync.success], (_state, action) => action.payload.classement)
    .handleAction([loadMoreClassementGlobalAsync.success], (_state, action) => action.payload.classement)

const classementGlobalActeur = createReducer<ClassementGlobal, RootAction>(null)
    .handleAction([loadClassementGlobalActeurAsync.success], (_state, action) => action.payload)

const rubriques = createReducer<ChallengeRubrique[], RootAction>(null)
    // .handleAction([loadChallengeRubriquesAsync.success], (_state, action) => action.payload)
    .handleAction(loadChallengeRubriquesAndInfosAsync.success, (_state, action) => action.payload.rubriques)

const rubriquesInfos = createReducer<ChallengeRubriqueInfos[], RootAction>(null)
    // .handleAction([loadChallengeRubriquesInfosAsync.success], (_state, action) => action.payload)
    .handleAction(loadChallengeRubriquesAndInfosAsync.success, (_state, action) => action.payload.infos)


const rubriquesDestinationByChallenge = createReducer<ChallengeDestinationRubrique[], RootAction>(null)
    .handleAction(loadDestinationRubriquesAsync.success, (state, action) => action.payload)
    .handleAction(saveRubriquesDestinationAsync.success, (state, action) => action.payload);

const allRubriquesDestinationInfos = createReducer<ChallengeDestinationRubriqueInfos[], RootAction>(null)
    .handleAction(loadDestinationRubriquesInfosAsync.success, (state, action) => action.payload);

const videos = createReducer<Video[], RootAction>([])
    .handleAction(loadVideos.success, (_state, action) => action.payload)
    .handleAction(saveVideo.success, (_state, action) => {
        let done = false;
        const newState = _state.map(
            (value: Video) => {
                if (value.idVideo == action.payload.idVideo) {
                    done = true;
                    return action.payload
                } else {
                    return value;
                }
            }
        )

        if (!done) {
            return [..._state, action.payload]
        } else {
            return newState;
        }
    })
    .handleAction(removeVideo.success, (_state, action) => _state.filter((value) => value.idVideo != action.payload.idVideo));


const currentRubrique = createReducer<ChallengeRubrique, RootAction>(null)
.handleAction(getChallengeAndRubriqueAsync.success, (_state, action) => action.payload.challengeRubrique);
/*****************************************
************ Filtres liste challenges ************* 
******************************************/

const idTypeChallenge = createReducer<number, RootAction>(-1)
    .handleAction(setListChallengesIdTypeChallenge, (_state, action) => action.payload)

const listChallengesFilterReducer = combineReducers({
    idTypeChallenge
})

export const challengeReducers = combineReducers({
    isLoading,
    isLoadingChallTermines,
    list,
    listChallengesTermines,
    estListeChallTerminesOuverte,
    themesOrdered,
    selectedChallenge,
    edito,
    files,
    actualites,
    actualite,
    reglement,
    rubriques,
    rubriquesInfos,
    rubriquesDestinationByChallenge,
    allRubriquesDestinationInfos,
    videos,
    suivis,
    suivisEnfants,
    suivi,
    classements,
    classementsEntite,
    dateResultat,
    classementActeur,
    classementSimpleActeur,
    top3,
    position,
    classementsSimple,
    monEquipe,
    currentRubrique,
    isLoadingClassement,
    isLoadingMoreClassement,
    isLoadingSuivis,    
    isLoadingSuivisEnfants,
    listChallengesFilters: listChallengesFilterReducer,
    classementGlobal,
    classementGlobalActeur
});

export default challengeReducers;