import { DeepPartial } from "redux";
import { DeepRawify } from "types";
import { v4 } from "uuid";

export class TodoList {

    public uid: string = v4();

    public idTodo: number;

    public idActeur: string;

    public message: string;

    public done: boolean;

    public priority: number;


    constructor(row?: DeepPartial<DeepRawify<TodoList>>) {
        if (row) {
            const {
                idTodo,
                idActeur,
                message,
                done,
                priority
            } = row;

            this.idTodo = idTodo;
            this.idActeur = idActeur;
            this.message = message;
            this.done = done;
            this.priority = priority;
        }
    }

    public toRaw() {
        return {
            idTodo: this.idTodo,
            idActeur: this.idActeur,
            message: this.message,
            done: this.done ? 1 : 0,
            priority: this.priority
        }
    }

}