import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { WithStyles, withStyles } from '@material-ui/styles';
import style from './QuestionnaireCarnet.style'
import AnimationsList from 'features/animations/src/animationList/AnimationsList.component';
import { loadCarnetQuestionnaires } from '../store/actions';

const stateToProps = (state : RootState) => ({

})

const dispatchToProps = {
    loadAnimations : loadCarnetQuestionnaires.request
}

interface QuestionnaireCarnetLocalProps{
}

interface QuestionnaireCarnetState{
}

type QuestionnaireCarnetProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & QuestionnaireCarnetLocalProps;

 class QuestionnaireCarnet extends Component<QuestionnaireCarnetProps, QuestionnaireCarnetState> {
     componentDidMount() {
        this.props.loadAnimations();
     }
     
    render() {
        return (
            <AnimationsList carnet />
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(QuestionnaireCarnet));