import { from, of } from 'rxjs';
import {
    filter, switchMap, catchError, map,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { combineEpics } from 'redux-observable';
import { RootEpic } from 'store/types';
import { loadCurrentActeurAsync, loadActeurAsync, loadActeurInfosAsync, loadCurrentActeurInfosAsync } from './acteurActions';

const loadCurrentActeurEpic: RootEpic = (action$, state$, { referentielService }) => action$.pipe(
    filter(isActionOf(loadCurrentActeurAsync.request)),
    switchMap(() => from(referentielService.findCurrentActeur()).pipe(
        map(loadCurrentActeurAsync.success),
        catchError(message => of(loadCurrentActeurAsync.failure(message))),
    )),
);

const loadActeurEpic: RootEpic = (action$, state$, { referentielService }) => action$.pipe(
    filter(isActionOf(loadActeurAsync.request)),
    switchMap((action) => from(referentielService.findActeur(action.payload)).pipe(
        map(loadActeurAsync.success),
        catchError((message) => of(loadActeurAsync.failure(message))),
    ))
);

const loadActeurInfosEpic: RootEpic = (action$, state$, { referentielService }) => action$.pipe(
    filter(isActionOf(loadActeurInfosAsync.request)),
    switchMap((action) => from(referentielService.loadActeurInfos(action.payload)).pipe(
        map(loadActeurInfosAsync.success),
        catchError((message) => of(loadActeurInfosAsync.failure(message))),
    ))
);

const loadCurrentActeurInfosEpic: RootEpic = (action$, state$, { referentielService }) => action$.pipe(
    filter(isActionOf(loadCurrentActeurInfosAsync.request)),
    switchMap((action) => from(referentielService.loadCurrentActeurInfos()).pipe(
        map(loadCurrentActeurInfosAsync.success),
        catchError((message) => of(loadCurrentActeurInfosAsync.failure(message))),
    ))
);

const ActeurEpics = combineEpics(loadCurrentActeurEpic, loadActeurEpic, loadActeurInfosEpic, loadCurrentActeurInfosEpic);

export default ActeurEpics;
