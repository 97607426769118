import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { ThemeProvider } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import { WithStyles, withStyles } from '@material-ui/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle'
import DeleteSVG from '@material-ui/icons/Delete'
import SaveSVG from '@material-ui/icons/Save'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { B64File } from 'classes/B64File.class'
import { CarnetActivity } from 'classes/carnet_voyage/programme/CarnetActivity.class'
import { CarnetDay } from 'classes/carnet_voyage/programme/CarnetDay.class'
import B64FilePickerComponent from 'components/filePicker/B64FilePicker.component'
import LigneHautTableau from 'components/LigneHautTableau.component'
import { Pacman } from 'components/pacman'
import { loadSchedule, saveSchedule } from 'features/carnet_voyage/src/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from "lodash/isEqual"
import moment, { Moment } from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'store/types'
import { blueTheme, colors, greenTheme, whiteTheme, yellowTheme } from 'Theme'
import ProgrammeActivity from './ProgrammeActivity.component'
import style from './ProgrammeSettingsCarnet.style'




const stateToProps = ({ carnetVoyage: { schedule, scheduleCategories, isLoading } }: RootState) => ({
    schedule,
    scheduleCategories,
    isLoading
})

const dispatchToProps = {
    loadSchedule: loadSchedule.request,
    saveSchedule: saveSchedule.request
}

interface ProgrammeSettingsCarnetLocalProps {
}

interface ProgrammeSettingsCarnetState {
    allowSave: boolean
    stateSchedule: CarnetDay[],
    currentDay: number,
    value: Moment,
    otherValue: B64File,
    alreadyLoaded: boolean,
    validToSave: boolean
}

type ProgrammeSettingsCarnetProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & ProgrammeSettingsCarnetLocalProps;

class ProgrammeSettingsCarnet extends Component<ProgrammeSettingsCarnetProps, ProgrammeSettingsCarnetState> {


    readonly state: ProgrammeSettingsCarnetState = {
        allowSave: false,
        stateSchedule: [],
        value: moment(),
        otherValue: null,
        alreadyLoaded: false,
        currentDay: 0,
        validToSave: false
    }

    componentDidMount() {
        const { loadSchedule, schedule } = this.props;
        loadSchedule();
    }

    componentDidUpdate(prevProps: ProgrammeSettingsCarnetProps, prevState: ProgrammeSettingsCarnetState) {
        const { loadSchedule, schedule, isLoading } = this.props;
        const { stateSchedule, currentDay, allowSave } = this.state;
        if (!isLoading) {
            if (!allowSave && !!prevState.stateSchedule && Array.isArray(prevState.stateSchedule) && prevState.stateSchedule.length > 0 && !isEqual(prevState.stateSchedule, stateSchedule)) {
                this.setState({
                    allowSave: true
                })
            }

            if (schedule.length === 0 && stateSchedule.length === 0) {
                this.state.stateSchedule.push(new CarnetDay());
            }
            if (stateSchedule.length === 0 && stateSchedule.length != schedule.length) {
                this.setState({
                    stateSchedule: cloneDeep(schedule) as CarnetDay[]
                })
            }
            if (stateSchedule.length > 0 && stateSchedule[currentDay].activities.length === 0) {
                const newStateSchedule = cloneDeep(this.state.stateSchedule);

                newStateSchedule[currentDay].activities.push(new CarnetActivity());

                this.setState({
                    stateSchedule: newStateSchedule
                })
            }

            let validToSave = true;
            this.state.stateSchedule.map((day, index) => {
                validToSave = validToSave && !!day.image && !!day.day;

                day.activities.map((activity, index) => {
                    validToSave = validToSave && !!activity.hour && !!activity.idCategory && !!activity.title;
                });
            })

            if (this.state.validToSave != validToSave) {
                this.setState({
                    validToSave: validToSave
                })
            }
        }
    }

    addActivity() {
        const { currentDay } = this.state;
        const newStateSchedule = cloneDeep(this.state.stateSchedule);
        newStateSchedule[currentDay].activities.push(new CarnetActivity());

        this.setState({
            stateSchedule: newStateSchedule
        })
    }

    addDay() {
        const { currentDay } = this.state;
        const newStateSchedule = cloneDeep(this.state.stateSchedule);
        newStateSchedule.push(new CarnetDay());

        this.setState({
            stateSchedule: newStateSchedule,
            currentDay: this.state.stateSchedule.length - 1
        });
    }

    removeActivity(index: number) {
        const { currentDay } = this.state;
        const newStateSchedule = cloneDeep(this.state.stateSchedule);

        newStateSchedule[currentDay].activities.splice(index, 1);

        this.setState({
            stateSchedule: newStateSchedule
        })
    }


    removeDay(index: number) {
        const newStateSchedule = cloneDeep(this.state.stateSchedule);

        this.state.currentDay--;
        newStateSchedule.splice(index, 1);

        this.setState({
            stateSchedule: newStateSchedule,
            currentDay: this.state.currentDay - 1
        })
    }


    saveAllDays() {
        this.props.saveSchedule(this.state.stateSchedule);

        this.setState({
            allowSave: false
        })
    }

    render() {
        const { classes } = this.props;
        const { stateSchedule, value, otherValue, currentDay, allowSave } = this.state;
        
        const componentsLigneHaut = [
            <ThemeProvider theme={greenTheme}>
                <Button
                    disabled={!this.state.validToSave || !allowSave}
                    className={classes.buttonLaunch}
                    color='primary'
                    variant="contained"
                    size="large"
                    onClick={() => this.saveAllDays()}
                >
                    <SaveSVG className={classes.imgLaunch} />
                    Enregistrer
                </Button>
            </ThemeProvider>
        ]
        
        if (!this.state.stateSchedule[currentDay]) {
            return <Pacman />;
        }
        return (
            <Box display='flex' flexDirection='column'>
                <LigneHautTableau className={classes.bold} title="Programme" components={componentsLigneHaut} />
                <Box display='flex' flexDirection='row' marginBottom={1} flexWrap='wrap'>
                    {
                        stateSchedule.map((day, index) => {
                            if (index === currentDay) {
                                return <Box display='flex'
                                    alignItems='center'
                                    justifyContent='center'
                                    color='white'
                                    width={index === 0 ? 120 : 150}
                                    height={50}
                                    borderRadius={5}
                                    marginRight={1}
                                    key={`jour-${index}`}
                                    bgcolor={colors.blue.dark}
                                    fontSize={18}
                                    marginBottom={1}
                                    paddingLeft={index === 0 ? 0 : 2}>
                                    <Box display='flex' alignContent='center' alignItems='center' justifyContent='center'>
                                        JOUR {index + 1}
                                        {index !== 0 &&
                                            <Tooltip title='Supprimer la journée'>
                                                <IconButton style={{ color: 'white' }} onClick={() => this.removeDay(index)}>
                                                    <DeleteSVG />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </Box>
                                </Box>
                            } else {
                                return (<ThemeProvider key={`jour-${index}`} theme={blueTheme}>
                                    <Button className={classes.buttonDay} variant='contained' color='primary' size='large' onClick={() => this.setState({ currentDay: index })}>JOUR {index + 1}</Button>
                                </ThemeProvider>);
                            }

                        }
                        )
                    }
                    <ThemeProvider theme={whiteTheme}>
                        <Tooltip title='Ajouter un jour'>
                            <Button onClick={() => this.addDay()} style={{ color: colors.grey.main, width: 50, height: 50, minWidth: 50 }} variant='contained' color='primary' size='large'>
                                <AddCircleIcon />
                            </Button>
                        </Tooltip>
                    </ThemeProvider>
                </Box>
                <Box>
                    <Paper elevation={0}>
                        <Box display='flex' flexDirection='column' width='100%' borderRadius={5}>
                            <Box padding={4} display='flex' flexDirection='row' width='100%' bgcolor={colors.grey.main} justifyContent='space-around'>
                                <B64FilePickerComponent
                                    onChange={(f) => {
                                        const newStateSchedule = cloneDeep(this.state.stateSchedule);

                                        newStateSchedule[currentDay].image = f;

                                        this.setState({
                                            stateSchedule: newStateSchedule
                                        })
                                    }}
                                    value={this.state.stateSchedule[currentDay].image || null}
                                    variant='textfield'
                                    textFieldProps={{ fullWidth: true, required: true }}
                                    buttonColor='inherit' />
                                <KeyboardDatePicker
                                    onFocus={() => { }}
                                    onBlur={() => { }}
                                    inputVariant="outlined"
                                    disableToolbar
                                    format="dddd D MMMM"
                                    placeholder={'Date'}
                                    value={this.state.stateSchedule[currentDay].day || null}
                                    margin="normal"
                                    onChange={(value) => {
                                        const newStateSchedule = cloneDeep(this.state.stateSchedule);

                                        newStateSchedule[currentDay].day = value;

                                        this.setState({
                                            stateSchedule: newStateSchedule
                                        })
                                    }}
                                    cancelLabel={"Annuler"}
                                    clearLabel={"Vider"}
                                    okLabel={"Valider"}
                                    todayLabel={"Aujourd'hui"}
                                    invalidDateMessage='Format de date invalide'
                                    showTodayButton
                                    style={{ width: '100%' }}
                                    className={classes.dateTextField}
                                    inputProps={{ required: true, style: { textTransform: 'capitalize' } }}
                                />
                                {/* <TextField fullWidth value={this.state.stateSchedule[currentDay].day} onChange={(e) => { this.state.stateSchedule[currentDay].day = e.target.value; this.forceUpdate() }} className={classes.dateTextField} placeholder='Date' /> */}
                            </Box>
                            <Box paddingX={4} paddingBottom={4} paddingTop={2} display='flex' flexDirection='column'>
                                {
                                    stateSchedule.length > 0 && stateSchedule[currentDay].activities.map((activity, index) =>
                                        <ProgrammeActivity
                                            updateParent={() => {
                                                this.forceUpdate();
                                                this.setState({
                                                    allowSave: true
                                                })
                                            }}
                                            index={index}
                                            removeActivity={this.removeActivity.bind(this, index)}
                                            key={`${currentDay}-${index}`}
                                            activity={activity} />
                                    )
                                }

                                <ThemeProvider theme={yellowTheme}>
                                    <Button onClick={() => this.addActivity()} className={classes.addActivityButton} variant='contained' color='primary' size='large'>
                                        <AddCircleIcon className={classes.imgLaunch} />
                                        Ajouter une activité
                                    </Button>
                                </ThemeProvider>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            </Box>
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(ProgrammeSettingsCarnet));