import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { ThemeProvider } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { withStyles, WithStyles } from '@material-ui/styles'
import SaveSVG from '@material-ui/icons/Save';
import { Edito } from "classes/carnet_voyage/edito/Edito.class";
import B64FilePickerComponent from "components/filePicker/B64FilePicker.component";
import LigneHautTableauComponent from "components/LigneHautTableau.component";
import { Pacman } from "components/pacman";
import cloneDeep from "lodash/cloneDeep";
import isEqual from 'lodash/isEqual';
import React, { ChangeEvent, Component } from "react";
import { connect } from "react-redux";
import { RootState } from "store/types";
import { greenTheme } from "Theme";
import { loadEdito, saveEdito } from "../../../../../carnet_voyage/src/store/actions";
import style from "./EditoSettingsCarnet.style";

export type FieldName = keyof Pick<Edito, "uid" | "idEdito" | "titre" | "accroche" | "descriptif" | "nom" | "prenom" | "role" | "avatar">;
export type StringFieldName = Extract<FieldName, "titre" | "accroche" | "descriptif" | "nom" | "role">;

const stateToProps = ({ carnetVoyage: { edito, isLoading } }: RootState) => ({
    edito,
    isLoading
});

const dispatchToProps = {
    loadEdito: loadEdito.request,
    saveEdito: saveEdito.request
};

interface EditoSettingsCarnetLocalProps { }

interface EditoSettingsCarnetState {
    allowSave: boolean;
    alreadyLoaded: boolean;
    stateEdito: Edito;
}

type EditoSettingsCarnetProps = WithStyles<typeof style> & ReturnType<typeof stateToProps> & typeof dispatchToProps & EditoSettingsCarnetLocalProps;

class EditoSettingsCarnet extends Component<EditoSettingsCarnetProps, EditoSettingsCarnetState> {
    readonly state: EditoSettingsCarnetState = {
        allowSave: false,
        alreadyLoaded: false,
        stateEdito: null
    };
    componentDidMount() {
        this.props.loadEdito();
        this.setState({ alreadyLoaded: true });
    }

    componentDidUpdate(prevProps: EditoSettingsCarnetProps, prevState: EditoSettingsCarnetState) {
        const { alreadyLoaded, stateEdito, allowSave } = this.state;
        const { isLoading, edito } = this.props;
        if (!isLoading) {
            if (!allowSave && !!prevState.stateEdito && !isEqual(prevState.stateEdito, stateEdito)) {
                this.setState({
                    allowSave: true
                })
            }

            if (alreadyLoaded && !stateEdito) {
                this.setState({
                    stateEdito: edito ? cloneDeep(edito) : new Edito()
                });
            }
        }
    }

    generateTextField(title: string, field: StringFieldName, rows?: string, defaultValue?: string) {
        return (
            <TextField
                placeholder={title}
                label={title}
                defaultValue={defaultValue}
                rows={rows}
                multiline={rows != undefined}
                InputProps={title === 'Titre' ? { style: { fontWeight: 700 } } : {}}
                style={{ marginBottom: 30, fontWeight: title === 'Titre' ? 700 : 400 }}
                onChange={(e) => this.onChangeString(field, e)}
            />
        );
    }

    private onChangeString(
        fieldName: StringFieldName,
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ): void {
        const newStateEdito = cloneDeep(this.state.stateEdito);
        newStateEdito[fieldName] = event.target.value;
        this.setState({
            stateEdito: newStateEdito
        })
    }

    render() {
        const { classes, saveEdito } = this.props;
        const { stateEdito, allowSave } = this.state;
        const componentsLigneHaut = [
            <ThemeProvider theme={greenTheme}>
                <Button
                    disabled={!allowSave}
                    className={classes.buttonSave}
                    color='primary'
                    variant="contained"
                    size="large"
                    onClick={() => {
                        saveEdito(stateEdito), this.setState({ allowSave: false })
                    }}
                >
                    <SaveSVG className={classes.saveSVG} />
                    Enregistrer
                </Button>
            </ThemeProvider>
        ]

        if (!stateEdito) {
            return <Pacman />;
        }
        return (
            <Box display="flex" flexDirection="column">
                <LigneHautTableauComponent className={classes.bold} title="Edito" components={componentsLigneHaut} />

                <Paper elevation={0}>
                    <Box className={classes.root} display="flex" flexDirection="column">
                        <p style={{ margin: "20px 0 10px" }}>Image</p>
                        <Box style={{ marginBottom: 30, width: "50%" }}>
                            <B64FilePickerComponent
                                value={stateEdito.cover}
                                onChange={(f) => {
                                    const newStateEdito = cloneDeep(this.state.stateEdito);
                                    newStateEdito.cover = f;
                                    this.setState({
                                        stateEdito: newStateEdito
                                    })
                                }}
                                variant="textfield" />
                        </Box>
                        {this.generateTextField("Titre", 'titre', undefined, stateEdito.titre)}
                        {this.generateTextField("Accroche", 'accroche', undefined, stateEdito.accroche)}
                        {this.generateTextField("Texte", 'descriptif', "10", stateEdito.descriptif)}
                        <Box display="flex" width="50%">
                            <Box width="142px" style={{ marginRight: 30 }}>
                                <B64FilePickerComponent
                                    value={stateEdito.avatar}
                                    onChange={(f) => {
                                        const newStateEdito = cloneDeep(this.state.stateEdito);
                                        newStateEdito.avatar = f;
                                        this.setState({
                                            stateEdito: newStateEdito
                                        })
                                    }}
                                    round emptyInnerZone />
                            </Box>
                            <Box display="flex" flexDirection="column" width="calc(100% - 172px)">
                                {this.generateTextField("Nom", 'nom', undefined, stateEdito.nom)}
                                {this.generateTextField("Rôle", 'role', undefined, stateEdito.role)}
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(EditoSettingsCarnet));
