import { createAction } from 'typesafe-actions';
import { SnackbarProps } from '@material-ui/core/Snackbar';

export enum SnackbarType {
    INFO,
    WARNING,
    SUCCESS,
    ERROR
}

export const newSnackbar = createAction('NEW_SNACKBAR')<{ type: SnackbarType, props: SnackbarProps }>();

export const newSnackbars = createAction('NEW_SNACKBARS')<{ type: SnackbarType, props: SnackbarProps }[]>();

export const removeTopSnackbar = createAction('REMOVE_SNACKBAR')<void>();
