import { Fiche } from 'classes/level_up/fiches/Fiche.class';
import { FicheCaracteristique } from 'classes/level_up/fiches/FicheCaracteristique.class';
import { FicheCommentaire } from 'classes/level_up/fiches/FicheCommentaire.class';
import { FicheLibreGroupeCara } from 'classes/level_up/fiches/FicheLibreGroupeCara.class';
import { FicheProduit } from 'classes/level_up/fiches/FicheProduit.class';
import { FilterOptions } from 'components/tables/TeamUpTable.component';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { FicheDetails, FicheListRequest, FicheListResult } from './types';

// Charge la liste des fiches
export const loadFicheListAsync = createAsyncAction(
    'LOAD_FICHE_LIST_REQUEST',
    'LOAD_FICHE_LIST_SUCCESS',
    'LOAD_FICHE_LIST_FAILURE',
)<FicheListRequest, FicheListResult, string>();

// Charge une fiche
export const getFicheAsync = createAsyncAction(
    'LOAD_FICHE_REQUEST',
    'LOAD_FICHE_SUCCESS',
    'LOAD_FICHE_FAILURE',
)<number, FicheDetails, string>();

// Fiche en cours de modification
export const setSelectedFiche = createAction('SET_SELECTED_FICHE')<Fiche>();

// Liste des caractéristiques (P) d'une fiche
export const loadListFicheCaraAsync = createAsyncAction(
    'LOAD_LIST_FICHE_CARA_REQUEST',
    'LOAD_LIST_FICHE_CARA_SUCCESS',
    'LOAD_LIST_FICHE_CARA_FAILURE',
)<number, FicheCaracteristique[], string>();

// Liste des produits d'une fiche
export const loadListFicheProduitAsync = createAsyncAction(
    'LOAD_LIST_FICHE_PRODUIT_REQUEST',
    'LOAD_LIST_FICHE_PRODUIT_SUCCESS',
    'LOAD_LIST_FICHE_PRODUIT_FAILURE',
)<number, FicheProduit[], string>();

// Liste des caractéristiques (L) d'une fiche
export const loadListFicheLibreCaraAsync = createAsyncAction(
    'LOAD_LIST_FICHE_LIBRE_CARA_REQUEST',
    'LOAD_LIST_FICHE_LIBRE_CARA_SUCCESS',
    'LOAD_LIST_FICHE_LIBRE_CARA_FAILURE',
)<number, FicheLibreGroupeCara[], string>();

// Liste des fiches actives au sein d'une marque et du référentiel
export const loadListFicheFromMarqueAsync = createAsyncAction(
    'LOAD_LIST_FICHE_FROM_MARQUE_REQUEST',
    'LOAD_LIST_FICHE_FROM_MARQUE_SUCCESS',
    'LOAD_LIST_FICHE_FROM_MARQUE_FAILURE',
)<FicheListRequest, FicheListResult, string>();

// Commentaires d'une fiche
export const loadCommentairesAsync = createAsyncAction(
    'LOAD_COMMENTAIRES_REQUEST',
    'LOAD_COMMENTAIRES_SUCCESS',
    'LOAD_COMMENTAIRES_FAILURE',
)<number, FicheCommentaire[], string>();

export const statistiqueClickVideo = createAsyncAction(
    'STATISTIQUE_CLICK_VIDEO_REQUEST',
    'STATISTIQUE_CLICK_VIDEO_SUCCESS',
    'STATISTIQUE_CLICK_VIDEO_FAILURE',
)<number, void, string>();

// Stockage de la page actuelle (front)
export const setCurrentPage = createAction('SET_CURRENT_PAGE')<number>();

// Stockage des paramètres d'affichage du tableau (back)
export const setTableParams = createAction('SET_TABLE_PARAMS')<FilterOptions>();
