import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { colors } from '../Theme';

export default ({ spacing, palette }: typeof Theme) => createStyles({
    root: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        margin: '0 auto',
        padding: spacing(5, 2),
        maxWidth: 350,
        
        '& img': {
            display: 'inline',
            maxWidth: 120,
            margin: '0 auto',
        },
    },

    titleText: {
        display: 'block',
        color: 'black',
        fontSize: '20px',
        fontWeight: 700,
        margin: spacing(2, 0, 1),
    },

    subtitleText: {
        display: 'inline-block',
        color: colors.grey.main,
        fontSize: 16,
        fontWeight: 400,
    },
});
