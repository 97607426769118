import { WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import style from './EmptyList.style';

interface BaseProps {
    urlImage: string;
    classNameImage?: string;
    title: string;
    subtitle: string;
    subtitleClassName?: string;
}

type EmptyListProps = BaseProps & WithStyles<typeof style>;

class EmptyList extends React.Component<EmptyListProps> {
    static defaultProps = {
        classNameImage: '',
        urlImage: '',
        title: '',
        subtitle: '',
        subtitleClassName: '',
    };

    render(): JSX.Element {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <img className={this.props.classNameImage} src={this.props.urlImage} />

                <div className={classes.titleText}>{this.props.title}</div>
                <div className={classes.subtitleText}>{this.props.subtitle}</div>
            </div>
        );
    }
}

export default withStyles(style)(EmptyList);
