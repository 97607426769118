import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseSVG from '@material-ui/icons/Close'
import { Commentaire } from "classes/murs/Commentaire.class"
import { Post } from "classes/murs/Post.class"
import { Pacman } from "components/pacman"
import React, { Component, Fragment } from "react"
import CommentaireComponent from "./Commentaire.component"

interface ViewCommentsPostDialogProps {
    triggered: boolean;
    onClose: ((ok: boolean) => void);
    post?: Post,
    typeMur: number
}

interface ViewCommentsPostDialogState {
    open: boolean;
    commentaires: Commentaire[]
}

class ViewCommentsPostDialogDialogComponent extends Component<ViewCommentsPostDialogProps, ViewCommentsPostDialogState> {

    public readonly state: ViewCommentsPostDialogState = {
        open: false,
        commentaires: []
    };


    public componentDidMount() {
        
    }
    

    public componentDidUpdate(prevProps: ViewCommentsPostDialogProps): void {
        if (prevProps.triggered !== this.props.triggered && this.props.triggered && !this.state.open) {
            this.openedDialog();

        }
    }

    async openedDialog() {
        this.setState({
            open: true,
            commentaires: await this.props.post.getCommentaires(this.props.post ? this.props.post.typeMur : this.props.typeMur)
        });
    }

    public closeDialog(ok: boolean) {
        this.props.onClose(ok);
        this.setState({
            open: false,
        });
    }

    public render(): JSX.Element {
        const { post } = this.props;
        const { commentaires } = this.state;
        return (
            <Dialog fullWidth open={this.state.open} onClose={() => this.closeDialog(false)}>
                <DialogTitle>Commentaires</DialogTitle>
                <DialogContent>
                    {
                        commentaires.length > 0 ?
                            <Fragment>
                                {
                                    commentaires.map((commentaire, index) =>
                                        <Box margin={2}>
                                            <CommentaireComponent post={post} readOnly commentAdd={() => { }} key={commentaire.idMurCommentaire} comment={commentaire} index={index} commentDeleted={() => { }} />
                                        </Box>
                                    )
                                }
                            </Fragment>
                            :
                            <Pacman />
                    }
                </DialogContent>
                <Box position='absolute' top={0} right={0}>
                    <IconButton onClick={() => this.closeDialog(false)}>
                        <CloseSVG />
                    </IconButton>
                </Box>
            </Dialog>);
    }

}

export default ViewCommentsPostDialogDialogComponent;