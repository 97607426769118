import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { colors } from 'Theme';

export default ({ spacing, palette }: typeof Theme) => createStyles({
    buttonLaunch : {
        borderRadius : 5
    },
    imgLaunch : {
        marginRight : spacing(0.5)
    },
    texteLigneHautEditeur : {
        color : 'white',
        fontWeight : 700
    },
    texteLigneHautInformations : {
        fontWeight : 700
    },
    paperEdition : {
        marginTop : spacing(0.5)
    }
    
});