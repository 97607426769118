import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { RootAction } from 'store/types';
import { loadThemeAsync } from './themeActions';
import { TeamUpTheme } from './themeTypes';

const isLoading = createReducer<boolean, RootAction>(true)
    .handleAction([loadThemeAsync.request], () => true)
    .handleAction([loadThemeAsync.success, loadThemeAsync.failure], () => false);

const theme = createReducer<TeamUpTheme, RootAction>(null)
    .handleAction([loadThemeAsync.success], (_state, action) => action.payload);

const themeReducer = combineReducers({
    isLoading,
    theme
});

export default themeReducer;
