import { B64File } from 'classes/B64File.class';
import { DeepPartial } from 'redux';
import { DeepRawify } from 'types';
import { v4 } from 'uuid'

export class CaracteristiquePicto {

    public uid: string = v4();

    /**
     * L'identifiant du picto
     */
    public idCaracteristiquePicto: number;

    /**
     * L'identifiant de la caractéristique auquelle est liée le picto
     */
    public idCaracteristique: number;

    /**
     * Le libellé du picto
     */
    public libelle: string;

    /**
     * L'image du picto
     */
    public picto: B64File;

    constructor(row?: DeepPartial<DeepRawify<CaracteristiquePicto>>) {
        if (row) {
            const { idCaracteristiquePicto, idCaracteristique, libelle, picto } = row;

            this.idCaracteristiquePicto = idCaracteristiquePicto;
            this.idCaracteristique = idCaracteristique;
            this.libelle = libelle;
            this.picto = B64File.fromDb(picto);
        }
    }


    public toRaw() {
        return {
            idCaracteristiquePicto: this.idCaracteristiquePicto,
            idCaracteristique: this.idCaracteristique,
            libelle: this.libelle,
            picto: this.picto,
        };
    }
}
