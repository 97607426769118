import { from, of } from 'rxjs';
import {
    filter, switchMap, catchError, map,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { combineEpics } from 'redux-observable';
import { RootEpic } from 'store/types';
import { loadThemeAsync } from './themeActions';

const loadThemeEpic: RootEpic = (action$, state$, { themeService }) => action$.pipe(
    filter(isActionOf(loadThemeAsync.request)),
    switchMap(() => from(themeService.getTheme()).pipe(
        map(loadThemeAsync.success),
        catchError(message => of(loadThemeAsync.failure(message))),
    )),
);

const ThemeEpics = combineEpics(loadThemeEpic);

export default ThemeEpics;
