import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { RootEpic } from 'store/types';
import { isActionOf } from 'typesafe-actions';
import { loadAnimationsAsync, 
    getAnimationAsync, 
    loadAnimationsAccueilAsync,
    loadBriquesAsync, 
    getBriqueAsync, 
    loadAnimQuizAsync,
    getNextQuestionAsync,
    saveNextAnswerAsync,
    loadClassementAnimAsync,
    loadClassementAnimBriqueAsync
} from './actions';

const loadAnimationsEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadAnimationsAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadAnimations(action.payload)).pipe(
                map(loadAnimationsAsync.success),
                catchError(message => of(loadAnimationsAsync.failure(message)))
            )
        ),
    );

const loadAnimationsAccueilEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadAnimationsAccueilAsync.request)),
        switchMap(() =>
            from(levelUpService.loadAnimationsAccueil()).pipe(
                map(loadAnimationsAccueilAsync.success),
                catchError(message => of(loadAnimationsAccueilAsync.failure(message)))
            )
        ),
    );

const getAnimationEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(getAnimationAsync.request)),
        switchMap((action) =>
            from(levelUpService.getAnimation(action.payload)).pipe(
                map(getAnimationAsync.success),
                catchError(message => of(getAnimationAsync.failure(message)))
            )
        ),
    );

const loadBriquesEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadBriquesAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadBriques(action.payload)).pipe(
                map(loadBriquesAsync.success),
                catchError(message => of(loadBriquesAsync.failure(message)))
            )
        ),
    );

const getBriqueEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(getBriqueAsync.request)),
        switchMap((action) =>
            from(levelUpService.getBrique(action.payload)).pipe(
                map(getBriqueAsync.success),
                catchError(message => of(getBriqueAsync.failure(message)))
            )
        ),
    );

const loadAnimQuizEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadAnimQuizAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadAnimQuiz(action.payload)).pipe(
                map(loadAnimQuizAsync.success),
                catchError(message => of(loadAnimQuizAsync.failure(message)))
            )
        ),
    );

const getNextQuestionEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(getNextQuestionAsync.request)),
        switchMap((action) =>
            from(levelUpService.getBriqueQuestion(action.payload)).pipe(
                map(getNextQuestionAsync.success),
                catchError(message => of(getNextQuestionAsync.failure(message)))
            )
        ),
    );

const loadClassementAnimEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadClassementAnimAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadClassementAnim(action.payload)).pipe(
                map(loadClassementAnimAsync.success),
                catchError(message => of(loadClassementAnimAsync.failure(message)))
            )
        ),
    );

const loadClassementAnimBriqueEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadClassementAnimBriqueAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadClassementAnimBrique(action.payload)).pipe(
                map(loadClassementAnimBriqueAsync.success),
                catchError(message => of(loadClassementAnimBriqueAsync.failure(message)))
            )
        ),
    );

const saveNextAnswerEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(saveNextAnswerAsync.request)),
        switchMap((action) =>
            from(levelUpService.saveBriqueNextAnswer(action.payload)).pipe(
                map(saveNextAnswerAsync.success),
                catchError(message => of(saveNextAnswerAsync.failure(message)))
            )
        ),
    );

const LevelUpQuizEpics = combineEpics(
    loadAnimationsEpics,
    loadAnimationsAccueilEpics,
    getAnimationEpic,
    loadBriquesEpics,
    getBriqueEpic,
    loadAnimQuizEpic,
    getNextQuestionEpics,
    saveNextAnswerEpic,
    loadClassementAnimEpic,
    loadClassementAnimBriqueEpic
);

export default LevelUpQuizEpics;
