import { B64File } from "classes/B64File.class";
import moment, { Moment } from "moment";
import { DeepPartial } from "redux";
import { DeepRawify } from "types";

export class QuizBadge {

    public idBadge: number;

    public idAnimation: number;

    public title: string;

    public description: string;

    public imageUrl: B64File;

    public unlockedDate: Moment;

    public alreadyUnlocked: boolean;

    constructor(badge?: DeepPartial<DeepRawify<QuizBadge>>) {

        if (badge) {
            this.idBadge = badge.idBadge;
            this.idAnimation = badge.idAnimation;
            this.title = badge.title;
            this.description = badge.description;
            this.imageUrl = B64File.fromDb(badge.imageUrl);
            if (badge.unlockedDate) {
                this.unlockedDate = moment(badge.unlockedDate, 'X');
            }
        }
    }

    public toObject(): object {
        return {
            idBadge: this.idBadge,
            idAnimation: this.idAnimation,
            title: this.title,
            description: this.description,
            imageUrl: this.imageUrl,
        };
    }

}