import cloneDeep from 'lodash/cloneDeep';
import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import { navigationSetCurrentModule, popHistory, pushHistory, resetHistory } from './navigationActions';
import { Module } from './navigationTypes';


const currentModule = createReducer<Module, RootAction>('fil-info')
    .handleAction(navigationSetCurrentModule, (state, action) => action.payload)

// Historique de navigation custom
// Dans les participants BO, sert à gérer le bouton retour
// On enregistre la liste des URL visitées, pour pouvoir retourner en arrière
const history = createReducer<string[], RootAction>([])
    .handleAction(pushHistory, (state, action) => {state.push(action.payload); return cloneDeep(state);})
    .handleAction(popHistory, (state, action) => cloneDeep(state.slice(0, -1)))
    .handleAction(resetHistory, (state, action) => cloneDeep([]));

const navigationReducers = {
    currentModule,
    history
}

const navigationReducer = combineReducers(navigationReducers);


export default navigationReducer;
