import { MessagerieService } from 'services';
import { v4 } from 'uuid'
import { B64File } from 'classes/B64File.class';
import { DeepRawify } from 'types';
import { DeepPartial } from 'redux';

/**
 * Message
 * Super classe, sert à définir et normaliser une interface entre
 * les messages de groupe et les messages personnels 
 */
export abstract class Message
{

    public uid: string = v4();
    public service = MessagerieService.getInstance();

    /**
     * L'identifiant du message, il est issu de l'identifiant
     * du message personnel ou du message de groupe.
     */
    public idMessage: number;
  
    /**
     * Membre ayant envoyé le message
     */
    public emetteur: string;

    /**
     * Destinataire du message (Groupe ou Membre)
     */
    public recepteur: number | string;

    /**
     * Contenu du message
     */
    public texte: string;

    /**
     * Image contenue dans le message
     */
    public image: B64File;

    /**
     * Date d'envoi du message (timestamp unix)
     */
    public dateEnvoi: number;

    /**
     * Constructeur du Message
     */
    constructor(row?: DeepPartial<DeepRawify<Message>>) {
        if (row) {
            const { idMessage,
                emetteur,
                recepteur,
                texte,
                dateEnvoi,
                image
            } = row;
            this.idMessage = idMessage;
            this.emetteur = emetteur;
            this.recepteur = recepteur;
            this.texte = texte;
            this.dateEnvoi = dateEnvoi;
            this.image = B64File.fromDb(image);
        }
    }

    /**
     * Méthode de renvoi de l'objet en front sous la forme d'un tableau
     * @return array
     */
    public abstract toRaw(): {};
    
    /**
     * Méthode de suppression du message en BDD
     */
    public abstract delete(): void;

}
