import { combineReducers } from "redux";
import { RootAction } from "store/types";
import { createReducer } from "typesafe-actions";
import { loadSignalementsAsync, loadSignalementsFiltersAsync, loadSignalementsCountAsync } from "./actions";
import { Signalement } from "classes/signalements/Signalement.class";
import { SignalementsFiltersInfos, SignalementsFilterState } from "./types";

const isLoading = createReducer<boolean, RootAction>(false)
    .handleAction([loadSignalementsAsync.request], (_state, action) => true)
    .handleAction([loadSignalementsAsync.success, loadSignalementsAsync.failure], (_state, action) => false);


const signalements = createReducer<Signalement[], RootAction>([])
    .handleAction([loadSignalementsAsync.success], (_state, action) => action.payload)

const filtersInfos = createReducer<SignalementsFiltersInfos, RootAction>(null)
    .handleAction(loadSignalementsFiltersAsync.success, (_state, action) => action.payload)

const previousFiltersState = createReducer<SignalementsFilterState, RootAction>(null)
    .handleAction(loadSignalementsAsync.request, (_state,action) => action.payload)

const signalementsCount = createReducer<number, RootAction>(0)
    .handleAction(loadSignalementsCountAsync.success, (_state, action) => action.payload)
    
const SignalementReducer = combineReducers({
    isLoading,
    signalements,
    filtersInfos,
    previousFiltersState,
    signalementsCount
});

export default SignalementReducer;
