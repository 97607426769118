import Box from '@material-ui/core/Box'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { WithStyles, withStyles } from '@material-ui/styles';
import { Post } from 'classes/murs/Post.class'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'store/types'
import { colors } from 'Theme'
import AVoteSondageDrawerComponent from './AVoteSondageDrawer.component'
import style from './Sondage.style'

const stateToProps = ({ murs: { typeMur } }: RootState) => ({
    typeMur
})

const dispatchToProps = {

}

interface SondageLocalProps {
    post: Post,
    readOnly: boolean
}

interface SondageState {
    totalNbrOfAnswers: number,
    value: number,
    openAVoteDrawer: boolean,
    idReponse: number
}

type SondageProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & SondageLocalProps;

class Sondage extends Component<SondageProps, SondageState> {

    readonly state: SondageState = {
        totalNbrOfAnswers: 0,
        value: 0,
        openAVoteDrawer: false,
        idReponse: 0
    }

    componentDidMount() {
        const { post } = this.props;
        var newValue: number = post.maReponseSondage ? post.maReponseSondage : 0;
        this.setState({
            totalNbrOfAnswers: post.nbReponse1 + post.nbReponse2 + post.nbReponse3,
            value: newValue
        });
    }

    componentDidUpdate() {
    }

    async handleRadioChange(event: React.ChangeEvent<HTMLInputElement>, value: string) {
        var newValue: number = Number.parseInt(value);

        //TODO : Mettre à jour l'ensemble des nombres de réponses à partir de la BDD?
        this.props.post.nbReponses[newValue - 1] = await this.props.post.sendReponseSondage(this.props.typeMur, newValue);
        var newTotalNbrOfAnswers = this.state.totalNbrOfAnswers;
        if (this.state.value) {
            this.props.post.nbReponses[this.state.value - 1] -= 1;
        } else {
            newTotalNbrOfAnswers += 1;
        }
        this.props.post.maReponseSondage = newValue;
        this.setState({ value: newValue, totalNbrOfAnswers: newTotalNbrOfAnswers });
    }

    handleClickVotantsReponse(event: React.MouseEvent<HTMLElement, MouseEvent>, value: number) {
        if (this.props.post.nbReponses[value - 1] > 0) {
            this.setState({
                idReponse: value,
                openAVoteDrawer: true
            });
        }

    }

    handleCloseDrawer() {
        this.setState({
            idReponse: 0,
            openAVoteDrawer: false
        })
    }

    render() {
        const { classes, post, typeMur, readOnly } = this.props;
        const { totalNbrOfAnswers, value, idReponse, openAVoteDrawer } = this.state;
        const blue = colors.blue.light;
        return (
            <Fragment>
            <RadioGroup className={classes.formControl} value={value} onChange={(e, v) => { this.handleRadioChange(e, v) }}>
                    {post.reponses.map((reponse, index) =>
                        <Box className={classes.boxReponse} key={`${post.idMurPost}-reponse-${index}`}>
                            <FormControlLabel 
                                value={index + 1} 
                                classes={{ label: classes.label}} 
                                control={<Radio disabled={readOnly} />} 
                                className={classes.formControlLabel} 
                                label={
                                    <Box style={{ background: `linear-gradient(90deg, ${blue}FF 0%, ${blue}FF ${post.nbReponses[index] / totalNbrOfAnswers * 100}%, rgba(255,255,255,0) ${post.nbReponses[index] / totalNbrOfAnswers * 100}%)`, borderRadius: 5, paddingLeft: 5, width: '100%', display: 'flex', alignItems: 'center' }}>
                                        {reponse}
                                    </Box>
                                }
                            />

                            <Box className={classes.boxNbrReponses} onClick={(e) => this.handleClickVotantsReponse(e, index + 1)}>
                                {post.nbReponses[index]}
                            </Box>
                        </Box>
                    )}
                </RadioGroup>

                <AVoteSondageDrawerComponent triggered={openAVoteDrawer} onClose={this.handleCloseDrawer.bind(this)} post={post} idReponse={idReponse} />
            </Fragment>
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(Sondage));