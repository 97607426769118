import React, { useState, useEffect } from 'react';
import useStyle from './CreateNotificationDialog.style';
import TextField from '@material-ui/core/TextField';
import EmojiPicker from './EmojiPicker.component';
import { IconButton, Popover } from '@material-ui/core';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';

/**
 * Paramètres du composant
 */
interface TextFieldWithEmojiPickerProps {
    onChange: (value: string) => void;
    value: string;
    label: string;
    multiline?: boolean;
}

/**
 * Affichage d'un TextField avec un picker d'émojis
 * @param props Paramètres du composant (obligatoire)
 */
const TextFieldWithEmojiPicker: React.FC<TextFieldWithEmojiPickerProps> = props => {

    const classes = useStyle();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const addEmoji = (emoji: string) => {
        props.onChange(props.value + emoji + " ");
        handleClose();
    };
    
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;  

    return (
        <>
            <TextField 
                variant='outlined'
                value={props.value ?? ""}
                onChange={(e) => props.onChange(e.target.value)}
                fullWidth
                className={classes.textfield}
                label={props.label}
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={handleClick}>
                            <InsertEmoticonIcon />
                        </IconButton>
                    )
                }}
                multiline={props.multiline}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <EmojiPicker onClick={addEmoji} />
            </Popover>
        </>
    );
}

export default TextFieldWithEmojiPicker;