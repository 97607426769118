import { WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import style from './LigneHautTableau.style';


interface BaseProps {
    title: string;
    className?: string;
    classNameAction?: string;
    components? : JSX.Element[];
    alignLeft?: boolean;

}

type LigneHautTableauProps = BaseProps & WithStyles<typeof style>;

class LigneHautTableau extends React.Component<LigneHautTableauProps> {
    public static defaultProps: BaseProps = {
        title: '',
        className: '',
        classNameAction: null,
        alignLeft: false
    };

    render(): JSX.Element {
        const { classes } = this.props;
        return (
            <div className={this.props.alignLeft ? classes.lineActionLeft : classes.ligneHautTableau}>           
                <h3 className={this.props.className}>
                    {this.props.title}
                    {' '}
                </h3>
                <div className={this.props.classNameAction ?? classes.lineAction}>
                    {this.props.components
                        && this.props.components.map((component, index) => (
                            <div key={index}>
                                {component}
                            </div>

                            
                        ))}
                </div>
            </div>
        );
    }
}

export default withStyles(style)(LigneHautTableau);
