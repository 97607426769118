import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { colors } from 'Theme';

export default ({ spacing, palette }: typeof Theme) => createStyles({

    formControl: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    divSondage: {
        height: 40,
        background: colors.lighterGrey.light,
        display: 'inline-block',
        // border: `1px solid ${colors.grey.dark}`,
        paddingLeft: 10,
        width: 'calc(100% - 60px - 30px)',
        lineHeight: '42px',
        textAlign: 'left',
        fontSize: 12,
        position: 'relative',
        zIndex: 0,
        overflow: 'hidden',
        borderRadius: 5
    },
    divPercentSondage: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: 40,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        width: 0,
        backgroundColor: colors.blue.light,
        zIndex: -1,
        transitionDuration: '0.5s',
    },
    boxReponse: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: spacing(0.5)
    },
    boxNbrReponses: {
        minWidth: 42,
        height: 42,
        fontSize: 12,
        lineHeight: '42px',
        textAlign: 'center',
        color: 'black',
        backgroundColor: colors.lighterGrey.light,
        borderRadius: 5,
        marginLeft : spacing(0.5)
    },

    formControlLabel : {
        width : '100%',
        marginRight : 0,
    },
    label: {
        width : '100%',
        height : '100%',
        display : 'flex',
        position : 'relative',
        backgroundColor : colors.lighterGrey.light,
        borderRadius : 5,
        fontSize : 14,
    },
    boxGaugeVotes: {
        transition : 'background-image 5s',
        borderRadius: 5
    }
});