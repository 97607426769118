/* eslint-disable import/extensions */
import Axios from 'axios';
import { combineEpics } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import {
  catchError, filter, map, mergeMap, switchMap, takeUntil, tap,
} from 'rxjs/operators';
import { RootEpic } from 'store/types';
import { isActionOf } from 'typesafe-actions';
import { loadFAQAsync } from './actions';

const loadFAQEpics: RootEpic = (action$, state$, { FAQService }) => action$.pipe(
  filter(isActionOf(loadFAQAsync.request)),
  switchMap(() => from(FAQService.loadFAQAsync()).pipe(
    map(loadFAQAsync.success),
    catchError((message) => of(loadFAQAsync.failure(message))),
  )),
);

const FAQEpics = combineEpics(
  loadFAQEpics,
);

export default FAQEpics;
