import { DeepPartial } from 'redux';
import { LevelUpService } from 'services';
import { DeepRawify } from 'types';
import { v4 } from 'uuid'
import { B64File } from 'classes/B64File.class';

/**
 * Sauvearde les photos d'une fiche  
 */
export class FichePhoto
{

    /**
     * L'identifiant de l'association
     * @var int
     */
    public idFichePhoto: number;

    /**
     * L'identifiant de la fiche
     * @var int
     */
    public idFiche: number;

    /**
     * Lien de la photo
     * @var string
     */
    public lienPhoto: B64File;

    /**
     * Ordre d'affichage
     * @var int
     */
    public ordre: number;

    private levelUpService = LevelUpService.getInstance();

    public uid: string = v4();

    /**
     * 
     *  Constructeur de la fiche
     * @param  mixed row
     * @return void
     */
    constructor(row?: DeepPartial<DeepRawify<FichePhoto>>) {
        if (row) {
            this.idFichePhoto = row['idFichePhoto'] ?? null;
            this.idFiche = row['idFiche'] ?? null;
            this.ordre = row['ordre'] ?? null;
            this.lienPhoto = B64File.fromDb(row['lienPhoto']);
        }
    }

    public toRaw()
    {
        return {
            idFichePhoto: this.idFichePhoto,
            idFiche: this.idFiche,
            lienPhoto: this.lienPhoto,
        };
    }


}
