import Box from '@material-ui/core/Box'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { WithStyles, withStyles } from '@material-ui/styles'
import { CarnetInformationsItem } from 'classes/carnet_voyage/informations/CarnetInformationsItem.class'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'store/types'
import { Theme } from 'Theme'
import { loadInformations } from '../store/actions'
import style from './InformationsCarnet.style'

const stateToProps = ({ carnetVoyage: { isLoading, informations } }: RootState) => ({
    isLoading,
    informations
})

const dispatchToProps = {

    loadInformations: loadInformations.request,

}

interface InformationsCarnetLocalProps {
}

interface InformationsCarnetState {
}

type InformationsCarnetProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & InformationsCarnetLocalProps;

class InformationsCarnet extends Component<InformationsCarnetProps, InformationsCarnetState> {

    private expansionPanelSummaryTheme = (theme: typeof Theme) => {
        // debugger;
        return createTheme({
            ...theme,
            overrides: {
                MuiAccordion : {
                    root : {}
                },
                MuiAccordionSummary: {
                    root: {
                        padding: '10px',
                        minHeight: 56,
                        height : 56,
                        borderRadius : 5,
                        '&$expanded': {
                            minHeight: 56,
                            height : 56,
                            borderRadius : 5
                        },
                    },
                    content: {

                        margin: '12px 10px',
                        '&$expanded': {
                            margin: '12px 10px',
                        },
                    },
                    expanded: {}
                }
            }
        });
    };

    componentDidMount() {
        this.props.loadInformations();
    }

    render() {
        const { informations, classes } = this.props;
        return (
            <Box display='flex' flexDirection='column' padding={1}>
                {
                    informations.map((information, index) =>
                        <Box marginBottom={1}>
                            <Accordion elevation={0} className={classes.expansionPanel}  style={{borderRadius : 5}}>
                                <ThemeProvider theme={this.expansionPanelSummaryTheme}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <img src={CarnetInformationsItem.informationsItemImage[information.idInformationItem]} />
                                        <Typography className={classes.titleInfo}>{CarnetInformationsItem.informationsItemContent[information.idInformationItem]}</Typography>
                                    </AccordionSummary>
                                </ThemeProvider>
                                <AccordionDetails className={classes.expansionDetails}>
                                    <Typography style={{whiteSpace: 'pre-line'}} className={classes.informationContent}>
                                        {information.content}
                                    </Typography>
                                    {
                                        information.image &&
                                        <Box className={classes.informationImage}>
                                            <Box className={classes.informationImageSrc} style={{ backgroundImage: `url('${information.image.getSrc()}')` }} />
                                        </Box>

                                    }
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    )
                }
            </Box>
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(InformationsCarnet));