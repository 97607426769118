import { createAsyncAction, createAction } from "typesafe-actions";
import { SignalementsFilterState, SignalementsFiltersInfos } from "./types";
import { Signalement } from "classes/signalements/Signalement.class";


export const loadSignalementsAsync = createAsyncAction('LOAD_SIGNALEMENTS_REQUEST', 'LOAD_SIGNALEMENTS_SUCCESS', 'LOAD_SIGNALEMENTS_FAILURE')<
SignalementsFilterState,
Signalement[],
string
>();

export const loadSignalementsFiltersAsync = createAsyncAction('LOAD_SIGNALEMENTS_FILTERS_REQUEST', 'LOAD_SIGNALEMENTS_FILTERS_SUCCESS', 'LOAD_SIGNALEMENTS_FILTERS_FAILURE')<
void,
SignalementsFiltersInfos,
string
>();

export const loadSignalementsCountAsync = createAsyncAction('LOAD_SIGNALEMENTS_COUNT_REQUEST', 'LOAD_SIGNALEMENTS_COUNT_SUCCESS', 'LOAD_SIGNALEMENTS_COUNT_FAILURE')<
void,
number,
string
>();
