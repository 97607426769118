import { DeepPartial } from "redux";
import { DeepRawify } from "types";

export class MulticritereSnapshot {

    public idSnapshot : number = null;

    public idSuivi : number = null;

    public idEntite : string = null;

    public libelle : string = null;

    public idPere : string = null;

    public niveau : number = null;

    constructor(row? : DeepPartial<DeepRawify<MulticritereSnapshot>>) {

        if (row) {

            const {
                idSnapshot,
                idSuivi,
                idEntite,
                libelle,
                idPere,
                niveau
            } = row;

            this.idSnapshot = idSnapshot;

            this.idSuivi = idSuivi;

            this.idEntite = idEntite;

            this.libelle = libelle;

            this.idPere = idPere;

            this.niveau = niveau;
        }
    }

    public toDatabaseObject(): object {
        return {
            idSnapshot: this.idSnapshot,
            idSuivi: this.idSuivi,
            idEntite: this.idEntite,
            libelle: this.libelle,
            idPere: this.idPere,
            niveau: this.niveau
        }
    }
}