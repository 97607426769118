import { DeepPartial } from "redux";
import { MursService } from "services";
import { DeepRawify } from "types";

export class TypePost {

    public idTypePost: number = null;

    public libelle: string = null;

    public icone: string = null;

    public couleur: string = null;

    public actif: boolean = null;

    public listeRole: number = null;

    public bloque: boolean = null;

    public dateCreation: string = null;

    // Date de création timestamp mise au format DD/MM/AAAA
    public dateCreationFormatDate: string = null;

    private MursService = MursService.getInstance();

    constructor(newTypePost?: DeepPartial<DeepRawify<TypePost>>) {

        if (newTypePost) {
            const {
                idTypePost,
                libelle,
                icone,
                couleur,
                actif,
                listeRole,
                bloque,
                dateCreation,
                dateCreationFormatDate
            } = newTypePost;

            this.idTypePost = idTypePost
            this.libelle = libelle
            this.icone = icone
            this.couleur = couleur
            this.actif = actif
            this.listeRole = listeRole
            this.bloque = bloque
            this.dateCreation = dateCreation
            this.dateCreationFormatDate = dateCreationFormatDate
        }
    }

    public save(typeMur : number): Promise<Boolean> {
		return this.MursService.saveTypePost(typeMur, this);
	}

    public delete(typeMur : number): Promise<Boolean> {
		return this.MursService.deleteTypePost(typeMur, this.idTypePost);
	}

    public toDatabaseObject(): object {
        return {
			id_type_post : this.idTypePost,
			libelle : this.libelle,
			icone : this.icone,
			couleur : this.couleur,
			actif : this.actif,
			liste_role : this.listeRole,
			bloque : this.bloque,
			date_creation : this.dateCreation
        }
    }
}