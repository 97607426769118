import { NotificationsService } from "services";
import { Notification } from "./Notification.class";

export type Target = 'tous' | 'ad' | 'users';

export type Retour = {
    success?: boolean;
    nbActeurs?: number;
    modeTest?: boolean;
    acteurInexistant?: string[];
}

/**
 * Permet l'envoi de notifications groupées 
 */
export abstract class Notifiable {

    private service = NotificationsService.getInstance();

    /**
     * Envoie une notification groupée
     * @param title Titre de la notification
     * @param body Contenu de la notification
     * @param targetType Type d'envoi (à tous, à des AD, à des utilisateurs)
     * @param targets Cibles pour l'envoi (Acteurs et Entites)
     * @return Succès de l'envoi
     */
    public notifyActeurs(notif: Notification, targetType: Target, targets: string[], confirm: boolean): Promise<Retour> {
        notif.url = this.getUrl();
        notif.id_pere = this.getID();
        notif.id_type = this.notifType;
        return this.service.notifyActeurs(notif, targetType, targets, confirm);
    }

    /**
     * Renvoie l'URL de la notification
     * @return URL de la notification
     */
    public abstract getUrl(): string;

    /**
     * Renvoie l'identifiant de l'objet
     * @return identifiant de l'objet
     */
    public abstract getID(): number;

    /**
     * Identifiant du type de la notification
     */
    public abstract notifType: number;

}