import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
import { Theme } from 'Theme';

export default makeStyles(({ spacing, palette }: typeof Theme) =>
    createStyles({
        root: {
            margin: spacing(1),
            maxWidth: 1000
        },

        "@media (min-width: 1000px)": {
            root: {
                margin: "auto",
                marginTop: spacing(1),
                marginBottom: spacing(1)
            }
        }
    }
    ));