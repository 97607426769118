import { createStyles, makeStyles } from '@material-ui/styles';
import { colors, Theme } from 'Theme';

export default makeStyles(({ spacing, palette }: typeof Theme) => createStyles({
    animationsList: {
        display: 'flex',
        justifyContent: 'center',
        padding: 10,
        flexWrap: 'wrap',
        minHeight: '100%'
    },

    emptyListContainer: {
        position: 'absolute',
        top: 55,
        bottom: 0,
        left: 0,
        right: 0,
    },

    animation: {
        margin: spacing(1,1,0,1),
        position: 'relative',
        flexBasis: 350,
        cursor: 'pointer',
        '&:last-child' : {
            margin: spacing(1)
        }
    },
    animationCardImage: {
        height: 180,
        backgroundSize: 'cover',
        backgroundRepeat : 'no-repeat',
        backgroundPosition: 'center center',
    },

    image: {
        height: 180,
        backgroundSize: 'auto 100%',
        backgroundRepeat : 'no-repeat',
        backgroundPosition: 'center',
    },

    description: {
        padding: spacing(2),
    },

    typeName: {
        textTransform: 'uppercase',
        fontSize: 12,
    },

    title: {
        margin: spacing(0.5, 0),
        fontSize: 16,
        fontWeight: 700
    },

    date: {
        fontSize: 14,
        color: colors.grey.main,
    },

    statusLabel: {
        color: 'white',
        position: 'absolute',
        height: spacing(2),
        margin: spacing(1),
        top: 0,
        left: 0,
    },
}));
