import createStyles from "@material-ui/styles/createStyles";
import { colors, Theme } from "Theme";

export default ({ spacing, palette }: typeof Theme) =>
    createStyles({
        expansionPanel: {
            border: `1px solid ${colors.lighterGrey.light}`,
            borderRadius: 5
        },
        expansionDetails: {
            backgroundColor: colors.lighterGrey.light,
            display: "flex",
            flexDirection: "column",
            padding: spacing(4)
        },
        titleInfo: {
            fontWeight: 700,
            marginLeft: spacing(1)
        },
        contentTextField: {
            marginBottom: spacing(2),
            backgroundColor: "white",
            border: `1px solid ${colors.lighterGrey.main}`,
            borderRadius: 5,
            "& fieldset": {
                border: 0
            }
        },
        textFieldMobile: {
            marginRight: spacing(4)
        },
        textFieldPrenom: {
            marginRight: spacing(1)
        }
    });
