import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { colors } from 'Theme';

export default ({ spacing, palette }: typeof Theme) => createStyles({
    textFieldPost: {
        '& fieldset': {
            borderStyle: 'none'
        },
        '& textarea::placeholder': {
            color: colors.lighterGrey.main
        }
    },
    paperInfos: {
        marginBottom: spacing(1)
    },
    paperPost: {
        padding: spacing(1)
    },
    paperPhotoVideo: {
        marginTop: spacing(1),
        padding: spacing(1)
    },
    addElementButton: {
        padding: 0
    },
    buttonPublish: {
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0
    },
    buttonCustomPublish: {
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        width: 45,
        minWidth: 45

    },
    paperDocument: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: spacing(2),
        marginTop: spacing(1),
        alignItems: 'center'
    },
    paperUrl: {
        padding: spacing(1),
        marginTop: spacing(1)
    },
    selectsTypeCanal: {
        width: '100%',
        maxWidth: 200,
        backgroundColor: 'white',
        '& > fieldset': {
            border: 0
        },
    },
    inputsTypeCanal: {
        padding: spacing(0.5),
        paddingLeft: spacing(1),
        backgroundColor: 'white',
        display: 'flex',
        height: 40,
        alignItems: 'center',
        '&:focus': {
            backgroundColor: 'white'
        }
    },
    typePostIcon: {
        height: 30,
        width: 30,
        borderRadius: '50%',
        marginRight: '20px !important',
        padding: spacing(0.6)
    },

    avatarMenuItem: {
        display: 'flex',
        '& img': {
            margin: 0
        }
    },
    paperLienUrl: {
        padding: spacing(2),
        marginTop: spacing(1),
        display: 'flex',
        position: 'relative'
    },
    iconLienUrl: {
        color: colors.grey.main,
        marginRight: spacing(1)
    },
    badgeTypePost: {
        maxHeight: 20,
        whiteSpace: 'nowrap',
        marginRight: spacing(0.5)
    },
    pathSvgCancel: {
        '& path': {
            backgroundColor: 'white'
        }
    },
    canalSpan: {
        color: palette.primary.main,
        cursor: 'pointer'
    },
    arrowButton: {
        '&:disabled': {
            backgroundColor: colors.lighterGrey.light
        }
    },
    progressBar: {
        maxWidth: 300,
        width: '100%',
        height: 24,
        borderRadius: 50,
        backgroundColor: colors.white.main
    },
    textFieldPostSpan: {
        '& span': {
            padding: 0
        }
    }
});