import { DeepPartial } from 'redux';
import { DeepRawify } from 'types';
import { v4 } from 'uuid'
import { ContenuBloc } from '../ContenuBloc.class';

export class ContenuBlocVideo extends ContenuBloc {

    public uid: string = v4();

    public video : string = null;

    constructor(row?: DeepPartial<DeepRawify<ContenuBlocVideo>>) {
        super(row);

        if (row) {
            const { video } = row;
            this.video = video;
        }
    }
    
    public toRaw() {
        return {
            video : this.video,
            idBloc : this.idBloc,
            laLigne : this.laLigne,
            indexBloc : this.indexBloc,
            idTypeBloc : this.idTypeBloc
        };
    }

    public save(): Promise<number> {
        return this.contenusService.saveBloc(this);
    }

    public delete(): Promise<Boolean> {
        return this.contenusService.deleteBloc(this.idBloc);
    }

    public isEmpty() : boolean {
        if (!this.video) return true;
        else return false;
    }

}
