import { combineReducers } from "redux";
import { RootAction } from "store/types";
import { createReducer } from "typesafe-actions";
import { loadTableauBordAsync } from "./actions";
import cloneDeep from 'lodash/cloneDeep'
import { TableauBordData } from "./types";

const isLoading = createReducer<boolean, RootAction>(false)
    .handleAction([loadTableauBordAsync.request], (_state, action) => true)
    .handleAction([loadTableauBordAsync.success, loadTableauBordAsync.failure], (_state, action) => false);

const tableauBord = createReducer<TableauBordData, RootAction>(null)
    .handleAction([loadTableauBordAsync.success], (_state, action) => cloneDeep(action.payload));

const TableauBordReducer = combineReducers({
    isLoading,
    tableauBord
});

export default TableauBordReducer;


