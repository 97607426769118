import { from, of } from "rxjs";
import { filter, switchMap, catchError, map, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { combineEpics } from "redux-observable";
import { RootEpic } from "store/types";
import { loadPostsAsync, loadPostsFiltersAsync } from "./actions";

const loadPostsEpics: RootEpic = (action$, state$, { rechercheService }) => action$.pipe(
    filter(isActionOf(loadPostsAsync.request)),
    switchMap((action) => from(rechercheService.loadPosts(action.payload)).pipe(
        map(loadPostsAsync.success),
        catchError(message => of(loadPostsAsync.failure(message))),
    )),
);

const loadPostsInfosEpics: RootEpic = (action$, state$, { rechercheService }) => action$.pipe(
    filter(isActionOf(loadPostsFiltersAsync.request)),
    switchMap(() => from(rechercheService.loadPostsInfos()).pipe(
        map(loadPostsFiltersAsync.success),
        catchError(message => of(loadPostsFiltersAsync.failure(message))),
    )),
);

const RechercheEpics = combineEpics(
    loadPostsEpics,
    loadPostsInfosEpics
);

export default RechercheEpics;
