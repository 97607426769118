import moment, { Moment } from 'moment';
import Axios from 'axios';
import qs from 'qs';
import { v4 } from 'uuid';
import { B64File } from 'classes/B64File.class';
import { DeepRawify } from 'types';
import { DeepPartial } from 'redux';

export class CarnetParticipantActivity {
    private static readonly AVATAR_FOLDER: string = '/dist/assets/avatar/';

    public idActeur: string;

    public team: string;

    public activity: string;

    constructor(row?: DeepPartial<DeepRawify<CarnetParticipantActivity>>) {
        if (row) {
            const { idActeur, team, activity } = row;
            this.idActeur = idActeur;
            this.team = team;
            this.activity = activity;
        }
    }

    public toRaw() {
        return {
            idActeur: this.idActeur,
            team: this.team,
            activity: this.activity,
        };
    }
}
