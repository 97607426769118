import { withStyles, WithStyles } from '@material-ui/styles'
import AdminAnimationListComponent from 'features/admin/animations/src/animationList/AdminAnimationList.component';
import { loadCarnetQuestionnaires } from 'features/carnet_voyage/src/store/actions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import style from './QuestionnaireSettingsCarnet.style';

const stateToProps = (state : RootState) => ({

})

const dispatchToProps = {
    loadCarnetQuestionnaires : loadCarnetQuestionnaires.request
}

interface QuestionnaireSettingsCarnetLocalProps{
}

interface QuestionnaireSettingsCarnetState{
}

type QuestionnaireSettingsCarnetProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & QuestionnaireSettingsCarnetLocalProps;

 class QuestionnaireSettingsCarnet extends Component<QuestionnaireSettingsCarnetProps, QuestionnaireSettingsCarnetState> {
     componentDidMount() {
         this.props.loadCarnetQuestionnaires();
     }
     
    render() {
        return (
            <AdminAnimationListComponent carnet />
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(QuestionnaireSettingsCarnet));