import { createReducer } from 'typesafe-actions';
import { SnackbarProps } from '@material-ui/core/Snackbar';
import { newSnackbar, removeTopSnackbar, SnackbarType, newSnackbars } from './snackBarsActions';
import { RootAction } from 'store/types';
import { v4 as uuid } from 'uuid';

const snackbarsReducer = createReducer<Array<{ id : string, type: SnackbarType, props: SnackbarProps }>, RootAction>([])
    .handleAction(newSnackbar, (state, action) => [
        ...state,
        { id: uuid(), type: action.payload.type, props: action.payload.props }
    ])
    .handleAction(removeTopSnackbar, (state) =>
        state.slice(1)
    )
    .handleAction(newSnackbars, (state, action) => state.concat(action.payload.map((snack) => {return {id : uuid(), type : snack.type, props : snack.props}})));

export default snackbarsReducer;
