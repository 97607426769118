import { AnimationTypes } from 'classes/animations/AnimationTypes.enum';
import { Quiz } from 'classes/animations/Quiz.class';
import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, first, map, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { RootEpic } from 'store/types';
import { isActionOf } from 'typesafe-actions';
import { loadCurrentQuestionAsync, loadParticipationAsync, submitAnswerAsync, unlockQuizBadgeAsync } from './actions';

const loadParticipationEpics: RootEpic = (action$, state$, { quizService }) => action$.pipe(
    filter(isActionOf(loadParticipationAsync.request)),
    switchMap((action) => of(state$.value.animations.currentAnimation).pipe(
        map((currentAnimation) => {
            if (currentAnimation.type !== AnimationTypes.Quiz) {
                throw new Error('The current animation is not a quiz');
            }

            return currentAnimation as Quiz;
        }),
        switchMap(currentQuiz => from(currentQuiz.getParticipation(action.payload.create)).pipe(
            map(loadParticipationAsync.success),
            catchError(message => of(loadParticipationAsync.failure(message))),
        )),
    ))
);

const loadCurrentQuestionEpic: RootEpic = (action$, state$) => action$.pipe(
    filter(isActionOf(loadCurrentQuestionAsync.request)),
    switchMapTo(state$.pipe(first())),
    switchMap(state => from(state.animations.quiz.participation.fetchCurrentQuestion()).pipe(
        map((payload) => {
            if (!payload) {
                return loadParticipationAsync.request({});
            } else {
                return loadCurrentQuestionAsync.success(payload);
            }
        }),
        catchError(message => of(loadCurrentQuestionAsync.failure(message))),
    )),
);


const submitAnswerEpic: RootEpic = (action$, state$) => action$.pipe(
    filter(isActionOf(submitAnswerAsync.request)),
    switchMapTo(state$.pipe(first())),
    switchMap(state => from(state.animations.quiz.acteurAnswer.submitPlayerAnswers()).pipe(
        map(loadCurrentQuestionAsync.request),
        catchError(message => of(submitAnswerAsync.failure(message))),
    )),
);

const unlockQuizBadgeEpic: RootEpic = (action$, state$) => action$.pipe(
    filter(isActionOf(unlockQuizBadgeAsync.request)),
    switchMapTo(state$.pipe(first())),
    switchMap(state => from(state.animations.quiz.participation.unlockQuizBadge()).pipe(
        map(unlockQuizBadgeAsync.success),
        catchError(message => of(unlockQuizBadgeAsync.failure(message))),
    ))
    // switchMap((action) => from(state$.animations.quiz.participation.unlockQuizBadge()).pipe(
        
    // ))
);

const AnimationsQuizEpics = combineEpics(loadParticipationEpics, loadCurrentQuestionEpic, submitAnswerEpic, unlockQuizBadgeEpic);

export default AnimationsQuizEpics;
