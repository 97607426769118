import { DeepPartial } from 'redux';
import { ChallengeService } from 'services';
import { DeepRawify } from 'types';
import {v4} from 'uuid'

export class ChallengeReglement {

    public uid: string = v4();

    public leChallenge : number;

    public texte : string;

    public validationRequise : boolean;

    private challengesService = ChallengeService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<ChallengeReglement>>) {
        if (row) {
            const { leChallenge,
                texte,
                validationRequise
            } = row;
            this.leChallenge = leChallenge;
            this.texte = texte;
            this.validationRequise = validationRequise;
        }
    }


    public toRaw() {
        return {
            leChallenge : this.leChallenge,
            texte : this.texte,
            validationRequise : this.validationRequise,
        };
    }

    public save(): Promise<Boolean> {
        return this.challengesService.saveReglement(this);
    }


}
