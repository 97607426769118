import createStyles from "@material-ui/styles/createStyles";
import { colors, Theme } from "Theme";

export default ({ spacing, palette }: typeof Theme) =>
    createStyles({
        section: {
            display: "flex",
            flexDirection: "column",
            padding: spacing(2),
            marginBottom: spacing(1),
            maxWidth: 1000,
            margin: "auto",
            width: "100%",
            borderRadius: 0
        },
        carousel: {
            maxWidth: 1000,
            margin: "auto",
            width: "100%"
        },
        topSection: {
            alignItems: "center",
        },
        title: {
            fontSize: 14,
            fontWeight: 700
        },
        description: {
            fontSize: 12,
            color: colors.grey.main,
            marginTop: spacing(1),
            textAlign: "center",
            whiteSpace: 'pre-line'

        },
        eachInfo: {
            marginTop: spacing(2)
        },
        infoIcon: {
            color: colors.lighterGrey.main,
            marginRight: spacing(1),
            width: 24,
            height: 24
        },
        infoText: {
            fontSize: 14
        },
        equipments: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: spacing(1)
        },
        eachEquipment: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 48,
            height: 48,
            borderRadius: "50%",
            border: `1px solid ${colors.lighterGrey.light}`,
            color: colors.grey.main,
            marginTop: spacing(1),
            marginRight: spacing(1),
            '& img': {
                width: 24,
                height: 24
            }
        },
        imgEquipmentIcon: {
            width: 24,
            height: 24
        }
    });
