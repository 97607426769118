import { DeepPartial } from 'redux';
import { DeepRawify } from 'types';
import { v4 } from 'uuid'

export class CaracteristiqueCheminAPI {

    public uid: string = v4();

    /**
     * L'identifiant du chemin API
     */
    public idCaracteristiqueCheminApi: number;

    /**
     * L'identifiant de la caractéristique auquelle est liée le chemin d'API
     */
    public idCaracteristique: number;

    /**
     * Le chemin d'API
     */
    public cheminApi: string;

    constructor(row?: DeepPartial<DeepRawify<CaracteristiqueCheminAPI>>) {
        if (row) {
            const { idCaracteristiqueCheminApi, idCaracteristique, cheminApi } = row;

            this.idCaracteristiqueCheminApi = idCaracteristiqueCheminApi;
            this.idCaracteristique = idCaracteristique;
            this.cheminApi = cheminApi;
        }
    }


    public toRaw() {
        return {
            idCaracteristiqueCheminApi: this.idCaracteristiqueCheminApi,
            idCaracteristique: this.idCaracteristique,
            cheminApi: this.cheminApi,
        };
    }
}
