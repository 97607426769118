
import Axios, { CancelTokenSource } from 'axios';
import { Acteur } from 'classes/Acteur.class';
import { Commentaire } from 'classes/murs/Commentaire.class';
import { Like } from 'classes/murs/Like.class';
import { Post } from 'classes/murs/Post.class';
import { TypePost } from 'classes/murs/TypePost.class';
import { ElementLiteral, MurInfos, TypesPostAssociative, ListPostsFilterState, PostandMessage, RechercheLienInterne } from 'features/murs/src/store/types';
import qs from 'qs';
import { Metier } from 'classes/referentiel/Metier.class';
import { rxAxios, RxAxios } from 'services/RxAxios';
import { DeepRawify } from 'types';

export class MursService {
    private static instance: MursService;

    public static getInstance(): MursService {
        if (!MursService.instance) {
            MursService.instance = new MursService();
        }

        return MursService.instance;
    }

    private static rubriqueMur = IS_ADMIN ? 240 : 24;

    public loadPosts(typeMur: number, listPostFilters: ListPostsFilterState): Promise<Post[]> {
        const params = {
            rub: MursService.rubriqueMur,
            typeMur,
            filters: JSON.stringify(listPostFilters),
            p: 1
        };

        return Axios.get<{ content: DeepRawify<Post>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: posts } }) => posts.map((post) => new Post(post)))
            .catch(({ response: { data: { message } } }) => message)
    }



    public loadElements(typeMur: number): Promise<ElementLiteral> {
        const params = {
            rub: MursService.rubriqueMur,
            typeMur,
            p: 2
        };

        return Axios.get<{ content: ElementLiteral }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content)
            .catch(({ response: { data: { message } } }) => message);
    }

    public addLike(typeMur: number, typeLike: number, idMurPost: number): Promise<number> {
        return Axios.post<{ content: number }>(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 3,
                typeMur
            })}`,
            qs.stringify({ typeLike, idMurPost })
        )
            .then(({ data: { content } }) => content)
            .catch(({ response: { data: { message } } }) => message);
    }

    public removeLike(typeMur: number, idMurPost: number): Promise<number> {
        return Axios.post<{ content: number }>(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 4,
                typeMur
            })}`,
            qs.stringify({ idMurPost })
        )
            .then(({ data: { content } }) => content)
            .catch(({ response: { data: { message } } }) => message);
    }

    public removePost(typeMur: number, idMurPost: number): Promise<boolean> {
        return Axios.post<{ content: boolean }>(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 5,
                typeMur
            })}`,
            qs.stringify({ idMurPost })
        )
            .then(({ data: { content } }) => content)
            .catch(({ response: { data: { message } } }) => message);
    }

    public getPost(typeMur: number, idMurPost: number, index?: number): Promise<Post> {
        const params = {
            rub: MursService.rubriqueMur,
            typeMur,
            idMurPost,
            p: 6
        };

        return Axios.get<{ content: DeepRawify<Post> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => {
                const post = new Post(content);
                post.index = index;

                return post;
            })
            .catch(({ response: { data: { message } } }) => message);
    }

    public addComment(commentaire: string, idPere: number, typeMur: number, typeComment: number, idMurPost: number): Promise<number> {
        return Axios.post<{ content: number }>(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 7,
                typeMur
            })}`,
            qs.stringify({ commentaire, idPere, idMurPost, typeComment })
        )
            .then(({ data: { content } }) => content)
            .catch(({ response: { data: { message } } }) => message);
    }

    public loadCommentaires(typeMur: number, idMurPost: number): Promise<Commentaire[]> {
        const params = {
            rub: MursService.rubriqueMur,
            typeMur,
            idMurPost,
            p: 8
        };

        return Axios.get<{ content: DeepRawify<Commentaire>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map((commentaire) => new Commentaire(commentaire)))
            .catch(({ response: { data: { message } } }) => message);
    }

    public loadLikes(typeMur: number, idMurPost: number): Promise<Like[]> {
        const params = {
            rub: MursService.rubriqueMur,
            typeMur,
            idMurPost,
            p: 17
        };

        return Axios.get<{ content: DeepRawify<Like>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map((like) => new Like(like)))
            .catch(({ response: { data: { message } } }) => message);
    }

    public deleteCommentaire(typeMur: number, idMurCommentaire: number): Promise<number> {
        return Axios.post<{ content: number }>(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 9,
                typeMur
            })}`,
            qs.stringify({ idMurCommentaire })
        )
            .then(({ data: { content } }) => content)
            .catch(({ response: { data: { message } } }) => message);
    }

    public saveCommentaireText(typeMur: number, idMurCommentaire: number, commentaire: string): Promise<number> {
        return Axios.post<{ content: number }>(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 18,
                typeMur
            })}`,
            qs.stringify({
                idMurCommentaire,
                comment: commentaire
            })
        )
            .then(({ data: { content } }) => content)
            .catch(({ response: { data: { message } } }) => message);
    }

    public loadTypesPost(typeMur: number): Promise<TypesPostAssociative> {
        const params = {
            rub: MursService.rubriqueMur,
            typeMur,
            p: 10
        };

        return Axios.get<{ content: DeepRawify<TypePost>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map((typePost) => new TypePost(typePost)))
            .catch(({ response: { data: { message } } }) => message);
    }

    public saveTypePost(typeMur: number, typePost: TypePost): Promise<Boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 21,
                typeMur
            })}`,
            qs.stringify({
                typePost: JSON.stringify(typePost.toDatabaseObject()),
            }),
        )
            .then(() => true)
            .catch(() => false);
    }
    /**
     * Supprime un type de post
     * @param idTypePost l'id du type de post à supprimer
     * @param typeMur le type du mur sur lequel est le type de post à supprimer
     */
     public deleteTypePost(typeMur: number, idTypePost: number): Promise<Boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 22,
                typeMur,
            })}`,
            qs.stringify({ idTypePost }),
        )
            .then(() => true)
            .catch(() => false);
    }

    public loadMurInfos(typeMur: number, listPostFilters: ListPostsFilterState): Promise<MurInfos> {
        const params = {
            rub: MursService.rubriqueMur,
            typeMur,
            filters: JSON.stringify(listPostFilters),
            p: 11
        };

        return Axios.get<{ content: DeepRawify<MurInfos & { typesPost?: TypePost[], canaux?: Metier[] }> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => ({
                list: content.list.map((post) => new Post(post)),
                typesPost: content.typesPost
                    ? Object.fromEntries(content.typesPost.map((typePost) => [typePost.idTypePost, new TypePost(typePost)]))
                    : {},
                canaux: Object.fromEntries(content.canaux.map((canal) => [canal.idReferentielMetier, new Metier(canal)])),
                elements: {
                    ...content.elements,
                    cm: new Acteur(content.elements.cm)
                },
                acteur: new Acteur(content.acteur),
                statistics: content.statistics,
                referentielMetier: new Metier(content.referentielMetier),
                raisonsSignalement: content.raisonsSignalement,
                title: content.title,
                nbChallenges: content.nbChallenges
            }))
            .catch(({ response: { data: { message } } }) => message);
    }

    public sendReponseSondage(typeMur: number, idReponse: number, idMurPost: number): Promise<number> {
        return Axios.post<{ content: number }>(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 13,
                typeMur
            })}`,
            qs.stringify({ idReponse, idMurPost })
        )
            .then(({ data: { content } }) => content)
            .catch(({ response: { data: { message } } }) => message);
    }

    public publishPost(typeMur: number, post: Post, envoiNotif: boolean): Promise<PostandMessage> {
        return Axios.post<{ content: DeepRawify<PostandMessage> }>(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 15,
                typeMur,
                envoiNotif
            })}`,
            qs.stringify({
                post: JSON.stringify(post.toDatabaseObject())
            })
        )
            .then(({ data: { content } }) => ({
                post : content.post ? new Post(content.post) : null,
                message : content.message,
                level : content.level
            }))
            .catch(({ response: { data } }) => ({
                message: "Attention : " + data?.message,
                level : data?.level ? data?.level : 2
            }));
    }

    public epinglerPost(typeMur: number, idMurPost: number, dateDebut?: string, dateFin?: string): Promise<Post> {
        return Axios.post<{ content: DeepRawify<Post> }>(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 16,
                typeMur
            })}`,
            qs.stringify({ idMurPost, dateDebut, dateFin })
        )
            .then(({ data: { content } }) => new Post(content))
            .catch(({ response: { data: { message } } }) => message);
    }

    public favoriPost(typeMur: number, idMurPost: number): Promise<Post> {
        return Axios.post<{ content: DeepRawify<Post> }>(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 19,
                typeMur
            })}`,
            qs.stringify({ idMurPost })
        )
            .then(({ data: { content } }) => new Post(content))
            .catch(({ response: { data: { message } } }) => message);
    }

    public rechercheLienInterne(typeMur: number, canaux: string[], search: string): Promise<RechercheLienInterne[]> {
        return Axios.get<{ content: DeepRawify<RechercheLienInterne> }>(
            `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 23,
                typeMur,
                canaux: canaux.join(","),
                search
            })}`)
            .then(({ data: { content } }) => content)
            .catch(({ response: { data: { message } } }) => message);
    }

    private source: CancelTokenSource = null;

    public preUploadVideo(typeMur: number, file: File): RxAxios<{ content: any }> {
        const formData = new FormData();
        formData.append('file', file);

        this.source = Axios.CancelToken.source();

        return rxAxios({
            method: 'post',
            url: `index.php?${qs.stringify({
                rub: MursService.rubriqueMur,
                p: 20,
                typeMur
            })}`,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data'
            },
            cancelToken: this.source.token
        });
    }

    public cancelPreUploadVideo() {
        this.source?.cancel("Operation canceled by the user.")
    }
}
