import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import { WithStyles, withStyles } from '@material-ui/styles';
import ArrowBackSVG from '@material-ui/icons/ArrowBack'
import SendSVG from '@material-ui/icons/Send'
import { Commentaire } from 'classes/murs/Commentaire.class'
import { goBack, push, replace } from 'connected-react-router'
import { navigationPush } from 'store/navigation/navigationActions';
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { RootState } from 'store/types'
import { getPostAsync, resetError, setShouldPostsListGetUpdated, getPostWithTypeMurAsync } from '../store/actions'
import CommentaireComponent from './Commentaire.component'
import Post from './Post.hook.component'
import style from './ViewPost.style'
import { setHeaderStore } from 'features/header/src/store/actions'
import { withRouter } from 'react-router-dom';

const stateToProps = ({ murs: { isLoading, typeMur, post, error, elements }, router: { location: { pathname } }, header : { pageTitle, isPageFilter } }: RootState) => ({
    isLoading,
    typeMur,
    post,
    error,
    elements,
    pathname,
    pageTitle,
    isPageFilter
})

const dispatchToProps = {
    getPost: getPostAsync.request,
    resetError: resetError,
    replace,
    navigationPush,
    push,
    goBack,
    setShouldPostsListGetUpdated,
    getPostWithTypeMur: getPostWithTypeMurAsync.request,
    setHeaderStore
}

interface ViewPostLocalProps {
    idPost?: number;
    onClose?: (ok: boolean) => void;
    tMur?: number
}

interface ViewPostState {
    commentaires: Commentaire[],
    commentaire: string,
    loadingNewPost: boolean,
    postingComment: boolean
}

type ViewPostProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & ViewPostLocalProps
    & RouteComponentProps<{ id: string }>;

class ViewPost extends Component<ViewPostProps, ViewPostState> {

    public readonly state: ViewPostState = {
        commentaires: null,
        commentaire: "",
        loadingNewPost: false,
        postingComment: false
    };

    componentDidMount() {
        const { post, typeMur, getPost, elements, match, error, isLoading, navigationPush, idPost, onClose, tMur, getPostWithTypeMur } = this.props;
        const { commentaires } = this.state;
        var id: number = idPost ? idPost : Number.parseInt(match.params.id);
        if (isNaN(id) || !id) {
            this.redirectFromComponent();

        }

        if (!error.success && !isLoading) {
            this.redirectFromComponent();
        }


        if ((post === null || (post != null && post.idMurPost != id)) && !isLoading && error.success) {
            if (typeMur != -1) {
                getPost(id);
                this.setState({ loadingNewPost: true });
            } else if (tMur) {
                getPostWithTypeMur({ idPost: id, typeMur: tMur });
                this.setState({ loadingNewPost: true });
            }
        }
        if (post != null && post.idMurPost == id && !isLoading) {
            if (post.actif === 0 || (post.actif === 2 && !elements['sauvegarder'])) {
                if (onClose) {
                    onClose(true);
                } else {
                    navigationPush('/');
                }
            } else if (commentaires === null && !isLoading) {
                this.getCommentairesPost();
            }
        }

        if (!onClose) {
            this.props.setHeaderStore({
                pageTitle: 'Publication',
                leftComponents: [
                    <ArrowBackSVG onClick={() => this.goBackToPreviousComponent('view-post')} style={{ height: 24, width: 24, color: 'white', margin : 'auto', cursor: 'pointer'  }} />
                ],
                rightComponents: [],
                isPageFilter: false
            });
        }
    }

    async getCommentairesPost() {
        this.setState({
            commentaires: await this.props.post.getCommentaires(this.props.typeMur === -1 ? this.props.post.typeMur : this.props.typeMur)
        })
    }

    goBackToPreviousComponent(strToPrev: string) {
        const { pathname, push } = this.props;
        push(pathname.substring(0, pathname.indexOf(strToPrev)));
    }

    componentDidUpdate() {
        const { post, typeMur, getPost, history, elements, match, error, resetError, onClose, isLoading, navigationPush, idPost, getPostWithTypeMur, tMur, isPageFilter, pageTitle } = this.props;
        const { commentaires, loadingNewPost } = this.state;

        var id: number = idPost ? idPost : Number.parseInt(match.params.id);
        if (isNaN(id) || !id) {
            this.redirectFromComponent();
        }

        if (!error.success && !isLoading) {
            this.redirectFromComponent();
        }
        if ((post === null || (post != null && post.idMurPost != id)) && !isLoading && error.success) {
            if (typeMur != -1) {
                getPost(id);
                this.setState({ loadingNewPost: true });
            } else if (tMur) {
                getPostWithTypeMur({ idPost: id, typeMur: tMur });
                this.setState({ loadingNewPost: true });
            }
        }
        if (post != null && post.idMurPost == id && !isLoading && loadingNewPost) {
            this.setState({ loadingNewPost: false })
        }
        if (post != null && post.idMurPost == id && !isLoading && !loadingNewPost) {
            if (post.actif === 0 || (post.actif === 2 && !elements['sauvegarder'])) {
                if (onClose) {
                    onClose(true);
                } else {
                    navigationPush('/');
                }
            } else if ((commentaires === null || (commentaires.length > 0 && commentaires[0].idMurPost != id)) && !isLoading) {
                this.getCommentairesPost();
            }
        }

        if(!onClose && (pageTitle != 'Publication' || isPageFilter)) {
            this.props.setHeaderStore({
                pageTitle: 'Publication',
                leftComponents: [
                    <ArrowBackSVG onClick={() => this.goBackToPreviousComponent('view-post')} style={{ height: 24, width: 24, color: 'white', margin : 'auto', cursor: 'pointer'  }} />
                ],
                rightComponents: [],
                isPageFilter: false
            }); 
        }

    }

    redirectFromComponent() {
        const { onClose, navigationPush } = this.props;
        if (onClose) {
            onClose(false);
        } else {
            navigationPush('/');
        }
        this.setState({ commentaire: '', commentaires: null });
    }
    async handleSendCommentClick() {
        const { commentaire } = this.state;
        const { post, typeMur, getPost, setShouldPostsListGetUpdated } = this.props;
        if (commentaire != "") {
            //TODO : Un tableau dans commentaire pour savoir quel type de commentaire on supprime?
            // 1 : commentaire de post
            this.setState({ postingComment: true });
            post.nbCommentaire = await post.addComment(commentaire, post.idMurPost, typeMur, 1);
            this.setState({ postingComment: false });

            setShouldPostsListGetUpdated(true);
            getPost(post.idMurPost);
            this.setState({ commentaire: "" }),
                this.getCommentairesPost();
        }
    }

    commentDeleted(index: number) {
        const { commentaires } = this.state;
        // this.setState(
        //     {
        //         commentaires: commentaires.slice(0, index).concat(commentaires.slice(index + 1))
        //     }
        // )

        this.getCommentairesPost();
        // this.props.getPost(this.props.post.idMurPost);
    }


    render() {
        const { classes, post, elements, isLoading, onClose } = this.props;
        const { commentaire, commentaires, loadingNewPost, postingComment } = this.state;
        if (post === null || loadingNewPost) {

            return <Box display='flex'><CircularProgress style={{ margin: 'auto' }} /></Box>;
        }
        return (
            <Fragment>
                <Post post={post} onClose={this.props.onClose} />
                {(
                    ((elements[`${IS_ADMIN ? 'admin_' : ''}commenter_public`] && post.idReferentielMetier === -1)
                        ||
                        (elements[`${IS_ADMIN ? 'admin_' : ''}commenter`] && post.idReferentielMetier !== -1))

                ) &&
                    <FormControl className={classes.formComment}>
                        <TextField value={commentaire} inputProps={{ maxLength: 2000 }} onChange={(event) => this.setState({ commentaire: event.target.value })} className={classes.commentTextField} placeholder='Écrire un commentaire...' color='secondary' />
                        <Button disabled={postingComment} onClick={this.handleSendCommentClick.bind(this)} variant='contained' className={classes.sendIconButton} color='primary'><SendSVG /></Button>
                    </FormControl>
                }
                {commentaires
                    &&
                    <Box marginTop={3}>
                        {
                            commentaires.map((commentaire, index) =>
                                (
                                    <CommentaireComponent post={post} commentAdd={this.getCommentairesPost.bind(this)} key={commentaire.idMurCommentaire} comment={commentaire} index={index} commentDeleted={this.commentDeleted.bind(this)} />
                                ))
                        }
                    </Box>
                }

            </Fragment>
        );
    }
}

export default withRouter(withStyles(style)(connect(stateToProps, dispatchToProps)(ViewPost)));