import { combineEpics } from "redux-observable";
import MonProfilEpics from "../monProfil/store/epics";
import ContactEpics from "../contact/store/epics";
import FAQEpics from "../faq/store/epics";


const ParametresEpics = combineEpics(
    MonProfilEpics,
    ContactEpics,
    FAQEpics
);

export default ParametresEpics;
