import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles } from '@material-ui/styles'
import KeyboardArrowRightSVG from '@material-ui/icons/KeyboardArrowRight';
import { CarnetParticipant } from 'classes/carnet_voyage/participants/CarnetParticipant.class';
import AvatarComponent from 'components/avatar/Avatar.component';
import { push } from 'connected-react-router';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { loadCurrentActeurAsync } from 'store/acteur/acteurActions';
import { RootState } from 'store/types';
import { loadParticipants, setParticipant } from '../store/actions';
import style from './ParticipantsCarnet.style';

const stateToProps = ({ carnetVoyage: { participants }, acteur: { current } }: RootState) => ({
    participants,
    moi: current
})

const dispatchToProps = {
    loadParticipants: loadParticipants.request,
    setParticipant,
    push,
    loadCurrentActeur: loadCurrentActeurAsync.request
}

interface ParticipantsCarnetLocalProps {
}

interface ParticipantsCarnetState {

}

type ParticipantsCarnetProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & ParticipantsCarnetLocalProps;

class ParticipantsCarnet extends Component<ParticipantsCarnetProps, ParticipantsCarnetState> {

    componentDidMount() {
        this.props.loadParticipants();
        if (!this.props.moi) {
            this.props.loadCurrentActeur();
        }
    }
    generateParticipants() {
        const { participants, classes, moi } = this.props;

        let content: JSX.Element[] = [];
        let character = '';
        participants.map((participant) => {
            let nameChar = participant.nom.charAt(0).toUpperCase();
            if (moi.idActeur === participant.idReferentielActeur) {
                content.splice(0, 0, <ListSubheader className={classes.subheaderLetter}>Moi</ListSubheader>);
                content.splice(1, 0, <ListItem onClick={() => this.handleListItemClick(participant)} className={classes.listItemParticipant}>
                    <ListItemIcon className={classes.listItemIconAvatar}>
                        <AvatarComponent acteur={participant} />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ className: classes.listItemTextParticipant }}>
                        {participant.prenom} {participant.nom}
                    </ListItemText>
                    <ListItemIcon className={classes.listItemLeftIcon}>
                        <KeyboardArrowRightSVG />
                    </ListItemIcon>
                </ListItem>);
            } else {
                if (nameChar !== character) {
                    character = nameChar;
                    content.push(<ListSubheader className={classes.subheaderLetter}>{nameChar}</ListSubheader>);
                }

                content.push(<ListItem onClick={() => this.handleListItemClick(participant)} className={classes.listItemParticipant}>
                    <ListItemIcon className={classes.listItemIconAvatar}>
                        <AvatarComponent acteur={participant} />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ className: classes.listItemTextParticipant }}>
                        {participant.prenom} {participant.nom}
                    </ListItemText>
                    <ListItemIcon className={classes.listItemLeftIcon} >
                        <KeyboardArrowRightSVG />
                    </ListItemIcon>
                </ListItem>);
            }
        })
        return content;
    }

    handleListItemClick(participant: CarnetParticipant) {
        const { setParticipant, push } = this.props;
        setParticipant(participant);
        push('/participant');

    }

    render() {
        const { participants, classes, moi } = this.props;
        return (
            <Fragment>
                <Paper elevation={0} className={classes.paperNbrParticipants}>
                    <Typography className={classes.nbrParticipantsText}><b>{participants.length}</b> Participants</Typography>
                </Paper>
                <List className={classes.listParticipants}>
                    {moi && this.generateParticipants()}
                </List>
            </Fragment>

        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(ParticipantsCarnet));