import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { RootEpic } from 'store/types';
import { isActionOf } from 'typesafe-actions';
import {
    loadFicheListAsync,
    getFicheAsync,
    loadListFicheCaraAsync,
    loadListFicheProduitAsync,
    loadListFicheLibreCaraAsync,
    loadListFicheFromMarqueAsync,
    loadCommentairesAsync,
    statistiqueClickVideo
} from './actions';

const loadFicheListEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadFicheListAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadListFiche(action.payload)).pipe(
                map(loadFicheListAsync.success),
                catchError(message => of(loadFicheListAsync.failure(message)))
            )
        ),
    );

const getFicheEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(getFicheAsync.request)),
        switchMap((action) =>
            from(levelUpService.findFiche(action.payload)).pipe(
                map(getFicheAsync.success),
                catchError(message => of(getFicheAsync.failure(message)))
            )
        ),
    );

const loadListFicheCaraEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadListFicheCaraAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadListFicheCara(action.payload)).pipe(
                map(loadListFicheCaraAsync.success),
                catchError(message => of(loadListFicheCaraAsync.failure(message)))
            )
        ),
    );

const loadListFicheProduitEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadListFicheProduitAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadListeFicheProduit(action.payload)).pipe(
                map(loadListFicheProduitAsync.success),
                catchError(message => of(loadListFicheProduitAsync.failure(message)))
            )
        ),
    );

const loadListFicheLibreCaraEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadListFicheLibreCaraAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadListeFicheLibreCara(action.payload)).pipe(
                map(loadListFicheLibreCaraAsync.success),
                catchError(message => of(loadListFicheLibreCaraAsync.failure(message)))
            )
        ),
    );
    
const loadListFicheFromMarqueEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadListFicheFromMarqueAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadListeFicheFromMarque(action.payload)).pipe(
                map(loadListFicheFromMarqueAsync.success),
                catchError(message => of(loadListFicheFromMarqueAsync.failure(message)))
            )
        ),
    );
 
const loadCommentairesEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadCommentairesAsync.request)),
        switchMap((action) =>
            from(levelUpService.listeCommentaires(action.payload)).pipe(
                map(loadCommentairesAsync.success),
                catchError(message => of(loadCommentairesAsync.failure(message)))
            )
        ),
    );

const statistiqueClickVideoEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(statistiqueClickVideo.request)),
        switchMap((action) =>
            from(levelUpService.statistiqueClickVideo(action.payload)).pipe(
                map(statistiqueClickVideo.success),
                catchError(message => of(statistiqueClickVideo.failure(message)))
            )
        ),
    );

const LevelUpFichesEpics = combineEpics(
    loadFicheListEpics,
    getFicheEpic,
    loadListFicheCaraEpic,
    loadListFicheProduitEpic,
    loadListFicheLibreCaraEpic,
    loadListFicheFromMarqueEpic,
    loadCommentairesEpic,
    statistiqueClickVideoEpic,
);

export default LevelUpFichesEpics;
