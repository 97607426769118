import { createStyles, makeStyles } from '@material-ui/styles';
import { colors, Theme } from 'Theme';

export default makeStyles(({ spacing, palette }: typeof Theme) => createStyles({
    centerDiv : {
        display : "flex",
        alignItems : "center",
        flexDirection : "column",
        justifyContent : "center"
    },
    innerDiv : {
        maxWidth : 500
    },
    drawerTitle : {
        marginTop : spacing(4),
        marginBottom : spacing(2.5),
        color : 'black',
        fontSize: 18,
        fontWeight: 700,
        textAlign : 'center'
    },
    drawerValidate : {
        marginRight : spacing(2),
        marginLeft : spacing(0.5),
    },
    drawerCancel : {
        marginLeft : spacing(2),
        marginRight : spacing(0.5),
        color : colors.grey.main
    },
    drawerInfosLabel : {
        fontSize : 12,
        fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
        marginBottom : 10,
    },
    drawerTextfield : {
        marginBottom : 10,
    },
    drawerButton : {
        borderRadius : spacing(5),
        fontSize : 18,
        marginTop : 10,
    },
    drawerButtons : {
        marginTop : spacing(4),
        marginBottom : spacing(4),
    }

}));