import Axios from "axios";
import qs from "qs";
import { LoadStatsState, Murs, StatsGlobales } from "features/admin/statistiques/src/store/types";
import { Post } from "classes/murs/Post.class";
import { DeepRawify } from "types";
import { ChartSettings } from "features/admin/statistiques/src/StatsGlobales.component";
import { StatsMurs } from "features/admin/tableau_bord/src/store/types";
import { StatsUsers } from "features/admin/statistiques/src/StatsUsersChart.component";

export class StatistiquesService {
    private static instance: StatistiquesService;

    public static getInstance(): StatistiquesService {
        if (!StatistiquesService.instance) {
            StatistiquesService.instance = new StatistiquesService();
        }

        return StatistiquesService.instance;
    }

    private static rubriqueRecherche = 304;

    public async loadStats(filters: LoadStatsState): Promise<Post[]> {
        const params = {
            rub: StatistiquesService.rubriqueRecherche,
            p: 1, /* Code pour avoir les données en JSON */
            mur: filters.mur,
            limit: filters.limit
        };

        return Axios.get<{ content: DeepRawify<Post>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: posts } }) => posts.map((post) => new Post(post)));
    }

    public async loadMurs(): Promise<Murs[]> {
        const params = {
            rub: StatistiquesService.rubriqueRecherche,
            p: 2, /* Code pour avoir les données en JSON */
        };

        return Axios.get<{ content: Murs[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: murs } }) => murs);
    }

    public async loadStatsGlobales(): Promise<StatsGlobales[]> {
        const params = {
            rub: StatistiquesService.rubriqueRecherche,
            p: 3, 
        };

        return Axios.get<{ content: StatsGlobales[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: stats } }) => stats);
    }

    public async loadStatsMatomo(chartSettings: ChartSettings): Promise<any> {
        const params = {
            rub: StatistiquesService.rubriqueRecherche,
            p: 4, 
            ...chartSettings
        };

        return Axios.get<{ content: any }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: chartData } }) => JSON.parse(chartData));
    }

    public async loadStatsMurs(chartSettings: ChartSettings): Promise<StatsMurs[]> {
        const params = {
            rub: StatistiquesService.rubriqueRecherche,
            p: 5, 
            ...chartSettings
        };

        return Axios.get<{ content: any }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: chartData } }) => chartData);
    }

    public async loadStatsUsers(chartSettings: ChartSettings): Promise<StatsUsers[]> {
        const params = {
            rub: StatistiquesService.rubriqueRecherche,
            p: 6, 
            ...chartSettings
        };

        return Axios.get<{ content: any }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: chartData } }) => chartData);
    }
}
