import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import { loadAnimationsAsync, loadAnimationsAccueilAsync, getAnimationAsync, setPage,
    setSelectedAnimation, setPageFront, loadBriquesAsync, getBriqueAsync, setBriquesPage,
    setSelectedBrique, loadAnimQuizAsync, getNextQuestionAsync, saveNextAnswerAsync, loadClassementAnimAsync, loadClassementAnimBriqueAsync } from './actions';
import cloneDeep from 'lodash/cloneDeep'
import { Animation } from 'classes/level_up/animations/Animation.class';
import { Brique } from 'classes/level_up/animations/Brique.class';
import { ClassementAnswer, QuizInfos } from '../../quiz/store/types';
import { FicheQuestion } from 'classes/level_up/quiz/FicheQuestion.class';

const animations = createReducer<Animation[], RootAction>([])
    .handleAction(loadAnimationsAsync.success, (_state, action) => cloneDeep(action.payload.liste))
    .handleAction(loadAnimationsAccueilAsync.success, (_state, action) => cloneDeep(action.payload));

const isLoadingAnimation = createReducer<boolean, RootAction>(false)
    .handleAction([loadAnimationsAsync.request, getAnimationAsync.request], () => true)
    .handleAction([
        loadAnimationsAsync.success,
        loadAnimationsAsync.failure, 
        getAnimationAsync.success, 
        getAnimationAsync.failure,         
        loadAnimationsAccueilAsync.success,
        loadAnimationsAccueilAsync.failure,
        loadClassementAnimAsync.success,
        loadClassementAnimAsync.failure,
        loadClassementAnimBriqueAsync.success,
        loadClassementAnimBriqueAsync.failure,
    ], () => false);

const selectedAnimation = createReducer<Animation | null, RootAction>(null)
    .handleAction(setSelectedAnimation, (_state, action) => action.payload)
    .handleAction([getAnimationAsync.success], (_state, action) => cloneDeep(action.payload));

const page = createReducer<number, RootAction>(20)
    .handleAction(setPage, (_state, action) => action.payload);

const pageFront = createReducer<number, RootAction>(5)
    .handleAction(setPageFront, (_state, action) => action.payload);

const total = createReducer<number, RootAction>(0)
    .handleAction(loadAnimationsAsync.success, (_state, action) => action.payload.total);

// Briques

const briques = createReducer<Brique[], RootAction>([])
    .handleAction(loadBriquesAsync.success, (_state, action) => cloneDeep(action.payload.liste));

const classement = createReducer<ClassementAnswer[], RootAction>([])
    .handleAction([loadClassementAnimAsync.success, loadClassementAnimBriqueAsync.success], (_state, action) => cloneDeep(action.payload));

const isLoadingBrique = createReducer<boolean, RootAction>(false)
    .handleAction([loadBriquesAsync.request, getBriqueAsync.request], () => true)
    .handleAction([
        loadBriquesAsync.success, 
        loadBriquesAsync.failure, 
        getBriqueAsync.success, 
        getBriqueAsync.failure, 
    ], () => false);

const isLoadingQuiz = createReducer<boolean, RootAction>(false)
    .handleAction([loadAnimQuizAsync.request], () => true)
    .handleAction([
        loadAnimQuizAsync.success, 
        loadAnimQuizAsync.failure
    ], () => false);

const selectedBrique = createReducer<Brique | null, RootAction>(null)
    .handleAction(setSelectedBrique, (_state, action) => action.payload)
    .handleAction([getBriqueAsync.success], (_state, action) => cloneDeep(action.payload));

const pageBriques = createReducer<{order: string, limit: number}, RootAction>({order: "idBrique", limit: 20})
    .handleAction(setBriquesPage, (_state, action) => action.payload);

const totalBriques = createReducer<number, RootAction>(0)
    .handleAction(loadBriquesAsync.success, (_state, action) => action.payload.total);

const quizInfos = createReducer<QuizInfos | null, RootAction>(null)
    .handleAction([loadAnimQuizAsync.success], (_state, action) => cloneDeep(action.payload));

const selectedQuestion = createReducer<FicheQuestion | null, RootAction>(null)
    .handleAction([getNextQuestionAsync.success, saveNextAnswerAsync.success], (_state, action) => cloneDeep(action.payload));

export const levelUpAnimationsReducers = combineReducers({
    animations,
    isLoadingAnimation,
    selectedAnimation,
    page,
    pageFront,
    total,
    briques,
    isLoadingBrique,
    selectedBrique,
    pageBriques,
    totalBriques,
    quizInfos,
    selectedQuestion,
    isLoadingQuiz,
    classement
});

export default levelUpAnimationsReducers;