import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { colors } from 'Theme';

export default ({ spacing, palette }: typeof Theme) => createStyles({
    paperTitreNotifications : {
        width : '100%',
        backgroundColor : colors.grey.main,
        color : 'white',
        fontSize : 20,
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center'
    },
    paperNotifications : {
        width: '100%',
    },
    buttonClear : {
        width : 50,
        minWidth : 50,
        height : 50,
        marginLeft : spacing(1)
    },
    notificationTitle : {
        color : colors.grey.dark,
        fontWeight : 700,
        fontSize : 12
    },
    notificationMessage : {
        fontSize : 12,
        color : palette.primary.main
    },
    notificationDate : {
        fontSize : 10,
        color : palette.primary.main
    },
    notificationClose : {
        width : 12,
        height : 12,
        color : colors.grey.main,
        cursor : 'pointer',
    }
});