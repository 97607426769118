import { combineReducers } from "redux";
import { RootAction } from "store/types";
import { createReducer } from "typesafe-actions";
import CanauxReducer from "../canaux/src/store/reducer";
import AdminParametresTypesPostReducer from "../typesPost/store/reducer";
import AdminParametresLogsReducer from "../log/store/reducer";


const isLoading = createReducer<boolean, RootAction>(false)
    .handleAction([], (_state, action) => true)
    .handleAction([], (_state, action) => false);


const error = createReducer<string, RootAction>('')

const ParametresReducer = {
    isLoading,
    error,
    canaux : CanauxReducer,
    typesPost : AdminParametresTypesPostReducer,
    logs : AdminParametresLogsReducer
};

export default ParametresReducer;
