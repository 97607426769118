import createStyles from '@material-ui/styles/createStyles';
import { colors, Theme } from 'Theme';

const style = ({ spacing }: typeof Theme) => createStyles({
    container: {
        width: '100%',
        position: 'relative'
    },

    zone: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        border: `2px dashed ${colors.lighterGrey.main}`,
        borderRadius: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat : 'no-repeat',
        textAlign: 'center',

        '& > *': {
             margin: spacing(1),
        }
    },

    icon: {
        fontSize: 42,
    },

    title: {
        fontWeight: 'normal',
        fontSize: 16,
        margin: 0,
    },

    textIndication: {
        fontSize: 14,
    },

    actionButtons: {
        position: 'absolute',
        top: 0,
        right: 0,
        display: 'flex',
        
        '& > *': {
            marginLeft: spacing(1)
        }
    },
    buttonImg : {
        height: 56,
        minWidth: 56,
        padding: 0,
        color: 'white',
        backgroundColor : 'white'
    },
    previewImage: {
        width: 30,
        height: 30,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    textFieldReponse : {
        flex : '1 1 0',
        pointerEvents : 'none',
        backgroundColor : 'white',
        border : `1px solid ${colors.lighterGrey.main}`,
        borderRadius : 5,
        '& fieldset' : {
            border : 0
        }
    }
});

export default style;