import { API } from 'classes/level_up/API';
import { Caracteristique } from 'classes/level_up/Caracteristique';
import { Categorie } from 'classes/level_up/Categorie';
import { Famille } from 'classes/level_up/Famille.class';
import { GroupeCaracteristique } from 'classes/level_up/GroupeCaracteristique.class';
import { Marque } from 'classes/level_up/Marque.class';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { SortParams } from './types';
import { Metier } from 'classes/referentiel/Metier.class';

export const loadApiListAsync = createAsyncAction(
    'LOAD_API_LIST_REQUEST',
    'LOAD_API_LIST_SUCCESS',
    'LOAD_API_LIST_FAILURE',
)<void, API[], string>();

export const getApiAsync = createAsyncAction(
    'LOAD_API_REQUEST',
    'LOAD_API_SUCCESS',
    'LOAD_API_FAILURE',
)<number, API, string>();

export const setSelectedAPI = createAction('SET_SELECTED_API')<API>();

// Chargement de la liste des marques
export const loadMarquesAsync = createAsyncAction(
    'LOAD_MARQUES_REQUEST',
    'LOAD_MARQUES_SUCCESS',
    'LOAD_MARQUES_FAILURE',
)<void, Marque[], string>();

export const loadCategoriesAsync = createAsyncAction(
    'LOAD_CATEGORIE_LIST_REQUEST',
    'LOAD_CATEGORIE_LIST_SUCCESS',
    'LOAD_CATEGORIE_LIST_FAILURE',
)<void, Categorie[], string>();

export const getCategorieAsync = createAsyncAction(
    'LOAD_CATEGORIE_REQUEST',
    'LOAD_CATEGORIE_SUCCESS',
    'LOAD_CATEGORIE_FAILURE',
)<number, Categorie, string>();

export const setSelectedCategorie = createAction('SET_SELECTED_CATEGORIE')<Categorie>();

// Chargement de la liste des familles
export const loadFamillesAsync = createAsyncAction(
    'LOAD_FAMILLES_REQUEST',
    'LOAD_FAMILLES_SUCCESS',
    'LOAD_FAMILLES_FAILURE',
)<number, Famille[], string>();

// Chargement d'une famille à partir de son ID
export const getFamilleAsync = createAsyncAction(
    'GET_FAMILLE_REQUEST',
    'GET_FAMILLE_SUCCESS',
    'GET_FAMILLE_FAILURE',
)<number, Famille, string>();

// Chargement de la liste des groupes de caractéristiques
export const loadGroupesCaraAsync = createAsyncAction(
    'LOAD_GROUPE_CARA_REQUEST',
    'LOAD_GROUPE_CARA_SUCCESS',
    'LOAD_GROUPE_CARA_FAILURE',
)<number, GroupeCaracteristique[], string>();

// Tri des groupes de caractéristiques
export const sortGroupesCaraAsync = createAsyncAction(
    'SORT_GROUPE_CARA_REQUEST',
    'SORT_GROUPE_CARA_SUCCESS',
    'SORT_GROUPE_CARA_FAILURE',
)<SortParams, GroupeCaracteristique[], string>();

// Chargement d'un groupe de caractéristiques à partir de son ID
export const getGroupeCaraAsync = createAsyncAction(
    'GET_GROUPE_CARA_REQUEST',
    'GET_GROUPE_CARA_SUCCESS',
    'GET_GROUPE_CARA_FAILURE',
)<number, GroupeCaracteristique, string>();

export const setSelectedGroupeCaracteristique = createAction('SET_SELECTED_GROUPE_CARA')<GroupeCaracteristique>();

export const loadCaracteristiquesAsync = createAsyncAction(
    'LOAD_CARACTERISTIQUE_LIST_REQUEST',
    'LOAD_CARACTERISTIQUE_LIST_SUCCESS',
    'LOAD_CARACTERISTIQUE_LIST_FAILURE',
)<number, Caracteristique[], string>();

export const loadCaraFromFamilleAsync = createAsyncAction(
    'LOAD_CARA_FROM_FAMILLE_LIST_REQUEST',
    'LOAD_CARA_FROM_FAMILLE_LIST_SUCCESS',
    'LOAD_CARA_FROM_FAMILLE_LIST_FAILURE',
)<number, Caracteristique[], string>();

export const getCaracteristiqueAsync = createAsyncAction(
    'LOAD_CARACTERISTIQUE_REQUEST',
    'LOAD_CARACTERISTIQUE_SUCCESS',
    'LOAD_CARACTERISTIQUE_FAILURE',
)<number, Caracteristique, string>();

export const setSelectedCaracteristique = createAction('SET_SELECTED_CARACTERISTIQUE')<Caracteristique>();

// Tri des caractéristiques dans leur groupe
export const sortCaraAsync = createAsyncAction(
    'SORT_CARA_REQUEST',
    'SORT_CARA_SUCCESS',
    'SORT_CARA_FAILURE',
)<SortParams, Caracteristique[], string>();

// Tri des caractéristiques clé
export const sortCaraCleAsync = createAsyncAction(
    'SORT_CARA_CLE_REQUEST',
    'SORT_CARA_CLE_SUCCESS',
    'SORT_CARA_CLE_FAILURE',
)<SortParams, Caracteristique[], string>();

export const loadCaracteristiquesCleAsync = createAsyncAction(
    'LOAD_CARACTERISTIQUE_CLE_LIST_REQUEST',
    'LOAD_CARACTERISTIQUE_CLE_LIST_SUCCESS',
    'LOAD_CARACTERISTIQUE_CLE_LIST_FAILURE',
)<number, Caracteristique[], string>();

// Liste des référentiels métier
export const loadListeCanauxAsync = createAsyncAction(
    'LOAD_LISTE_CANAUX_REQUEST',
    'LOAD_LISTE_CANAUX_SUCCESS',
    'LOAD_LISTE_CANAUX_FAILURE',
)<void, Metier[], string>();
