import { createTheme } from '@material-ui/core/styles';
import shadows from '@material-ui/core/styles/shadows';
import DocIcon from "./assets/doc.svg";
import ImgIcon from "./assets/img.svg";
import PdfIcon from "./assets/pdf.svg";
import PptIcon from "./assets/ppt.svg";
import XlsIcon from "./assets/xls.svg";
import UnknownIcon from "./assets/unknown.svg";
import { frFR } from '@material-ui/core/locale'

export const colors = {
    orange: { main: '#FF7900', contrastText: '#FFFFFF' },
    grey: { light: '#D6D6D6', main: '#8f8f8f', dark: '#595959' },
    green: { light: '#B8EBD6', main: '#50BE87', dark: '#0A6E31', contrastText: '#FFFFFF' },
    yellow: { light: '#DDF200', main: '#FFB400', dark: '#ffa000', contrastText: '#FFFFFF' },
    pink: { light: '#FFE8F7', main: '#FFB4E6', dark: '#ff8AD4', contrastText: '#FFFFFF' },
    purple: { light: '#D9C2F0', main: '#A885D8', dark: '#492191', contrastText: '#FFFFFF' },
    blue: { light: '#B5E8F7', main: '#4bb4e6', dark: '#085ebd', contrastText: '#FFFFFF' },
    brown: { main: "#714e46" },
    white: { main: '#FFFFFF' },
    lighterGrey: { light: '#EEEEEE', main: '#D6D6D6', dark: '#8F8F8F', contrastText: '#000000' },
    black: { main: '#000000' }
};

export const filesIcons: { [key: string]: string } = {
    pdf: PdfIcon,
    doc: DocIcon,
    docx: DocIcon,
    ppt: PptIcon,
    pptx: PptIcon,
    xls: XlsIcon,
    xlsx: XlsIcon,
    png: ImgIcon,
    jpg: ImgIcon,
    jpeg: ImgIcon,
    unknown: UnknownIcon
};

export const Theme = createTheme({
    spacing: 10,

    palette: {
        primary: colors.orange,
        secondary: colors.grey,
        grey: {
            300: colors.lighterGrey.light
        },
        text: {
            secondary: colors.grey.main,
        },
        contrastThreshold: 2,
    },


    props: {
        MuiGrid: {
            spacing: 10,
        },

        MuiTextField: {
            variant: 'outlined',
        },

        MuiButton: {
            className: 'MuiButton',
        }
    },

    typography: {
        fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'",

        button: {
            textTransform: 'initial',
            fontSize: 18,
            fontWeight: 400,
        },

        h6: {
            fontWeight: 'bold',
            fontSize: 16
        },

        subtitle1: {
            lineHeight: 1.4,
            fontSize: 14
        },

    },

    overrides: {


        MuiSwitch: {

            root: {
                width: 40,
                height: 20,
                padding: 0,
                borderRadius: '100vw',
                display: 'flex',

                '& input': {
                    margin: 'auto',
                },

            },
            switchBase: {
                padding: 2,
                color: 'white',
                "&$checked": {
                    left: '100%',
                    transform: 'translateX(-100%)'
                }
            },
            thumb: {
                width: 16,
                height: 16,
                boxShadow: 'none',
                color: 'white'
            },
            track: {
                borderRadius: 16 / 2,
                opacity: 1,
                backgroundColor: colors.grey.main
            },
            colorSecondary: {
                '&$checked': {
                    '& + $track': {
                        opacity: 1,
                        backgroundColor: colors.green.main
                    }
                }
            },
            checked: {}
        },

        MuiFormControlLabel: {
            root: {
                justifyContent: 'space-between',
            }
        },

        MuiLinearProgress: {
            bar: {
                borderRadius: '100vw'
            }
        },

        MuiPaper: {
            rounded: { borderRadius: 8 },
            root: {
                overflow: 'hidden'
            }
        },

        MuiButton: {
            root: {
                fontSize: 15,
            },

            contained: {
                '&, &:hover': {
                    boxShadow: shadows[0]
                },
            },


            sizeLarge: {
                height: 45,
            },
        },

        MuiIconButton: {
            label: {
                // Annuler l'effet de materialize.css
                "& [type='checkbox']:not(:checked), & [type='checkbox']:checked ": {
                    left: 0
                }
            }
        },


        MuiFab: {
            extended: {
                height: 50,
                boxShadow: shadows[0],

                '& svg': {
                    marginRight: 10
                }
            }
        },

        MuiSvgIcon: {
            root: {
                verticalAlign: 'unset'
            }
        },
        MuiSnackbarContent: {
            message: {
                '& svg': {
                    marginRight: 10
                }
            }
        },
        MuiCardContent: {
            root: {
                '&:last-child': {
                    paddingBottom: 0
                }
            }
        },
        MuiTab: {
            root: {
                fontSize: 14,
                textTransform: 'uppercase',
            }
        },

        MuiTypography: {
            root: {
                color: 'black'
            }
        },

        MuiListItem: {
            root: {
                backgroundColor: 'white',
            },
            divider: {
                borderBottom: `1px solid ${colors.lighterGrey.light}`,
            },

            container: {
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white',
            }
        },
        MuiListItemIcon: {
            root: {
                height: 30,
                width: 30,
            }
        },
        MuiListItemSecondaryAction: {
            root: {
                '& svg ': {
                    color: colors.grey.main
                }
            }
        },

        MuiDialog: {
            root: {
                '& .MuiPickersModal-dialog' : {
                    overflowY : 'unset'
                },
                textAlign: 'center',
                '& iframe': {
                    borderRadius: 5
                }
            },

            paperFullWidth: {
                width: 'calc(100% - 60px)',
                margin: 30
            },
        },
        MuiDialogContent: {
            root: {
                '& img + .MuiDialogContentText-root': {
                    marginTop: 30
                },
                minHeight: 70
            }
        },
        MuiDialogContentText: {
            root: {
                color: 'black',
                maxWidth: '80%',
                margin: '0 auto',
                marginBottom: 30,
            },
        },
        MuiDialogTitle: {
            root: {
                paddingTop: 30,
                color: 'black'
            },
        },
        MuiOutlinedInput: {
            notchedOutline: {
                borderColor: colors.lighterGrey.main
            },
            root: {
                '&$disabled': {
                    borderColor: colors.lighterGrey.main
                }
            }
        },
        MuiSelect: {
            select: {
                '&:focus': {
                    borderRadius: 4
                }
            }
        },
        MuiCheckbox: {
            root: {
                padding: 5,
            }
        }
    }
}, frFR);

Theme.overrides.MuiDialogActions = {
    root: {
        padding: '8px 20px 20px',

        // [Theme.breakpoints.up('md')]: {

        // },
        paddingBottom: 40,
        justifyContent: 'center',
        '& .MuiButton-containedSecondary': {
            backgroundColor: colors.lighterGrey.light,
            color: colors.grey.dark
        }
    }
};

Theme.overrides.MuiMenuItem = {
    root: {
        '& img': {
            marginRight: Theme.spacing(1)
        }
    }
}

export const greenTheme = (theme: typeof Theme) => {
    return createTheme({
        ...theme,
        palette:
        {
            primary: colors.green
        }
    });
};

export const whiteTheme = (theme: typeof Theme) => {
    return createTheme({
        ...theme,
        palette:
        {
            primary: colors.white
        }
    });
};

export const blackTheme = (theme: typeof Theme) => {
    return createTheme({
        ...theme,
        palette:
        {
            primary: colors.black,
            secondary: colors.white
        }
    });
};

export const blueTheme = (theme: typeof Theme) => {
    return createTheme({
        ...theme,
        palette:
        {
            primary: colors.blue
        }
    });
};

export const greyTheme = (theme: typeof Theme) => {
    return createTheme({
        ...theme,
        palette:
        {
            primary: colors.grey
        }
    });
};

export const yellowTheme = (theme: typeof Theme) => {
    return createTheme({
        ...theme,
        palette:
        {
            primary: colors.yellow
        }
    });
};

export const pinkTheme = (theme: typeof Theme) => {
    return createTheme({
        ...theme,
        palette:
        {
            primary: colors.pink
        }
    });
};

export const purpleTheme = (theme: typeof Theme) => {
    return createTheme({
        ...theme,
        palette:
        {
            primary: colors.purple
        }
    });
};

export const lighterGreyTheme = (theme: typeof Theme) => {
    return createTheme({
        ...theme,
        palette:
        {
            primary: colors.lighterGrey
        }
    });
};

export const whiteBackgroundTheme = (theme: typeof Theme) => {
    return createTheme({
        ...theme,
        palette:
        {
            primary: colors.orange,
            secondary: colors.grey,
            grey: {
                300: colors.lighterGrey.light
            },
            text: {
                secondary: colors.grey.main,
            },
            contrastThreshold: 2,
            background: {
                default: `${colors.white.main} !important`,
            },
        }
    });
};
