import { ChallengeService } from "services";
import { B64File } from "classes/B64File.class";
import { v4 as uuidv4 } from 'uuid';
import ImageDefautCritereSVG from '/front/react/features/admin/challenges/assets/rubrique_classements.svg'
import cloneDeep from 'lodash/cloneDeep'
import { DeepRawify } from "types";
import { DeepPartial } from "redux";
export class MulticritereCritere {

    static typeClassementToLibelle : {[key : number] : string} =
    {
        0 : 'Résultat/Objectif',
        1 : 'Ratio',
        2 : 'Volume pondéré',
        3 : 'Volume avec décrémentation',
    }

    // Types de classement autorisés lorsque l'option suivi en euros est sélectionnée
    static typeClassementEuroToLibelle : {[key : number] : string} =
    {
        2 : 'Volume pondéré'
    }

    static typeSeuilLibelle : {[key : number] : string} =
    {
        0 : 'Attribution d\'un nombre de points',
        1 : 'Soustraction d\'un nombre de points',
        2 : 'Division d\'un nombre de points'
    }
    
    public idCritere : number = null;

    public idChallenge : number = null;

    public idSuivi : number = null;

    public idTypeClassement : number = null;

    public urlImage : B64File = null;
    
    public libelle : string = null;

    public nbMaxPoint : number = null;

    public decrementation : number = null;

    public seuilMini : number = null;

    // Type de seuil pour les seuils paramètrables
    public typeSeuil : number = null;

    // Opérande pour les seuils paramètrables
    public operandeSeuil : number = null;

    public coefficientPonderation : number = null;

    // Attributs utile pour l'affichage des classements en front office
    public pointsCritere : number = null;

    public objectifCritere : number = null;

    public resultatCritere : number = null;

    public rsuroCritere : number = null;

    public estTotal : number = null;

    // Est-ce que le critère est par paliers ou par points
    public estParPaliers : number = null;

    public hasPaliersImported : number = null;

    // Est-ce que le critère est à afficher en vue détaillée dans le classement
    public estVueDetaillee : number = null;
    
    public ordre : number = null;

    public ordreSuivi : number = null;
    
    /**
     * Valeur de seuil plancher à atteindre
     * Exemple : sur un classement en décrémentation qui part de 500 pts avec un pas de 5.
     * Quand on arrive à 200 pts, on applique 50 pts
     * Ici le seuil plancher à atteindre sera 200 pts
     */
    public seuilPlancherAAtteindre : number = null;    

    /**
     * Valeur à appliquer pour le seuil plancher
     * Exemple : sur un classement en décrémentation qui part de 500 pts avec un pas de 5.
     * Quand on arrive à 200 pts, on applique 50 pts
     * Ici le seuil plancher à appliquer sera 50 pts
     */
    public seuilPlancherAAppliquer : number = null;

    public uuid : string = uuidv4(); 

    private challengeService = ChallengeService.getInstance();

    constructor(row? : DeepPartial<DeepRawify<MulticritereCritere>>) {

        if (row) {

            const {
                idCritere,
                idChallenge,
                idSuivi,
                idTypeClassement,
                urlImage,
                libelle,
                nbMaxPoint,
                decrementation,
                seuilMini,
                typeSeuil,
                operandeSeuil,
                coefficientPonderation,
                pointsCritere,
                objectifCritere,
                resultatCritere,
                rsuroCritere,
                estTotal,
                estParPaliers,
                hasPaliersImported,
                estVueDetaillee,
                ordre,
                seuilPlancherAAtteindre,
                seuilPlancherAAppliquer,
            } = row;

            this.idCritere = idCritere;

            this.idChallenge = idChallenge;

            this.idSuivi = idSuivi;

            this.idTypeClassement = idTypeClassement;

            this.urlImage = urlImage ? (typeof urlImage === 'string' ? B64File.fromDb(urlImage) : cloneDeep(urlImage)) : null

            this.libelle = libelle;

            this.nbMaxPoint = nbMaxPoint;

            this.decrementation = decrementation;

            this.seuilMini = seuilMini;

            this.typeSeuil = typeSeuil;

            this.operandeSeuil = operandeSeuil;

            this.coefficientPonderation = coefficientPonderation;

            this.pointsCritere = pointsCritere;

            this.objectifCritere = objectifCritere;

            this.resultatCritere = resultatCritere;

            this.rsuroCritere = rsuroCritere;

            this.estTotal = estTotal;

            this.estParPaliers = estParPaliers;

            this.hasPaliersImported = hasPaliersImported;

            this.estVueDetaillee = estVueDetaillee;

            this.ordre = ordre;

            this.ordreSuivi = ordre;

            this.seuilPlancherAAtteindre = seuilPlancherAAtteindre;

            this.seuilPlancherAAppliquer = seuilPlancherAAppliquer;

        }
    }

    public toDatabaseObject(): object {
        return {
            idCritere: this.idCritere,
            idChallenge: this.idChallenge,
            idSuivi: this.idSuivi,
            idTypeClassement: this.idTypeClassement,
            urlImage: this.urlImage,
            libelle: this.libelle,
            nbMaxPoint: this.nbMaxPoint,
            decrementation: this.decrementation,
            seuilMini: this.seuilMini,
            typeSeuil: this.typeSeuil,
            operandeSeuil: this.operandeSeuil,
            coefficientPonderation: this.coefficientPonderation,
            estParPaliers: this.estParPaliers,
            hasPaliersImported: this.hasPaliersImported,
            estVueDetaillee: this.estVueDetaillee,
            ordre: this.ordre,
            seuilPlancherAAtteindre: this.seuilPlancherAAtteindre,
            seuilPlancherAAppliquer: this.seuilPlancherAAppliquer,
        }
    }

    public getCritereImage() : string
    {
        return this.urlImage ? this.urlImage.getSrc() : ImageDefautCritereSVG
    }
}