import { createAsyncAction, createAction } from 'typesafe-actions';
import { TypePost } from 'classes/murs/TypePost.class';
import { AdminParametresTypesPostInfos, AdminParametresLoadTypePost } from './types';

// Action pour charger un type de post
export const loadAdminTypePostAsync = createAsyncAction("LOAD_ADMIN_TYPE_POST_REQUEST", "LOAD_ADMIN_TYPE_POST_SUCCESS", "LOAD_ADMIN_TYPE_POST_FAILURE")<
    AdminParametresLoadTypePost,
    TypePost,
    string
>();

// Action pour charger les types de posts en fonction du type de mur
export const loadAdminTypesPostAsync = createAsyncAction("LOAD_ADMIN_TYPES_POST_REQUEST", "LOAD_ADMIN_TYPES_POST_SUCCESS", "LOAD_ADMIN_TYPES_POST_FAILURE")<
    number,
    TypePost[],
    string
>();

// Action pour charger le type de mur et les types de posts affichés par défaut lors de l'initialisation de la page
export const loadAdminParametresTypesPostInfosAsync = createAsyncAction("LOAD_ADMIN_PARAMETRES_TYPES_POST_INFOS_REQUEST", "LOAD_ADMIN_PARAMETRES_TYPES_POST_INFOS_SUCCESS", "LOAD_ADMIN_PARAMETRES_TYPES_POST_INFOS_FAILURE")<
void,
AdminParametresTypesPostInfos,
string
>();

export const setTypePost = createAction('SET_TYPE_POST')<TypePost>();