import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { RootAction } from 'store/types';
import cloneDeep from 'lodash/cloneDeep';

import {
    searchPersonne,
    loadMessagerie,
    loadGroupe,
    loadPersonne,
    sendMessagePersonne,
    setGroupRead,
    deleteDiscussion,
    deleteGroup,
    deleteMesagePersonne,
    deleteMessageGroup,
    addGroupMembers,
    kickGroupMember,
    addBlocage,
    removeBlocage,
    toggleBlocage,
    receiveMessageGroup,
    receiveMessagePersonne,
    setConversationRead,
    loadJWTAsync
} from './actions';
import { Groupes, JWTResult, Personnes } from './types';
import { Personne } from 'classes/messagerie/Personne.class';

const personnes = createReducer<Personnes, RootAction>({})
    .handleAction([searchPersonne.success], (_state, action) => {
        let newState = cloneDeep(_state);
        action.payload.map((pers) => {
            if (!newState[pers.idActeur])
                newState[pers.idActeur] = pers;
        });
        return cloneDeep(newState);
    })
    .handleAction([loadMessagerie.success], (_state, action) => {
        let newState = cloneDeep(_state);
        action.payload.personnes.map((pers) => {
            if (!newState[pers.idActeur])
                newState[pers.idActeur] = pers;
        });

        newState[action.payload.me.idActeur] = 
            action.payload.me;
        return cloneDeep(newState);
    })
    .handleAction([loadPersonne.success], (_state, action) => {
        let newState = cloneDeep(_state);
        newState[action.payload.idActeur] = action.payload;
        return cloneDeep(newState);
    })
    .handleAction(receiveMessagePersonne, (_state, action) => {
        let newState = cloneDeep(_state);
        if (!newState[action.payload.emetteur]) {
            newState[action.payload.emetteur] = new Personne({
                idActeur: action.payload.emetteur,
                unreadMessages: 1
            });
            newState[action.payload.emetteur].lastMessages = [action.payload]
        } else {
            newState[action.payload.emetteur].lastMessages.unshift(action.payload)
            newState[action.payload.emetteur].unreadMessages += 1
        }
        return cloneDeep(newState);
    })
    .handleAction(sendMessagePersonne.success, (_state, action) => {
        let newState = cloneDeep(_state);
        if (newState[action.payload.recepteur]) 
            newState[action.payload.recepteur].lastMessages.unshift(action.payload)
        return cloneDeep(newState);
    })
    .handleAction(deleteDiscussion, (_state, action) => {
        _state[action.payload] = null;
        let newState = cloneDeep(_state);
        return newState;
    })
    .handleAction(deleteMesagePersonne, (_state, action) => {
        let newState = cloneDeep(_state);
        if (newState[action.payload.recepteur]) 
            newState[action.payload.recepteur].lastMessages.filter((val) => val.idMessage != action.payload.idMessage)
        return cloneDeep(newState);
    })
    .handleAction([addBlocage, removeBlocage], (_state, action) => {
        let newState = cloneDeep(_state);
        if (newState[action.payload]) 
            newState[action.payload].vousBloque = !newState[action.payload].vousBloque
        return cloneDeep(newState);
    })
    .handleAction(toggleBlocage, (_state, action) => {
        let newState = cloneDeep(_state);
        if (newState[action.payload]) 
            newState[action.payload].estBloquee = !newState[action.payload].estBloquee
        return cloneDeep(newState)
    })
    .handleAction(setConversationRead.success, (_state, action) => {
        let newState = cloneDeep(_state);
        if (newState[action.payload]) 
            newState[action.payload].unreadMessages = 0
        return cloneDeep(newState)
    });

const groupes = createReducer<Groupes, RootAction>({})
    .handleAction([loadMessagerie.success], (_state, action) => {
        let newState = cloneDeep(_state);
        action.payload.groupes.map((gpe) => {
            if (!newState[gpe.idGroupe])
                newState[gpe.idGroupe] = gpe;
        });
        return cloneDeep(newState);
    })
    .handleAction([loadGroupe.success, addGroupMembers], (_state, action) => {
        let newState = cloneDeep(_state);
        if (!newState[action.payload.idGroupe])
            newState[action.payload.idGroupe] = action.payload;
        return cloneDeep(newState);
    })
    .handleAction(receiveMessageGroup, (_state, action) => {
        let newState = cloneDeep(_state);
        if (newState[action.payload.recepteur]) 
            newState[action.payload.recepteur].lastMessages.unshift(action.payload)
        
        newState[action.payload.recepteur].unreadMessages += 1
        return cloneDeep(newState);
    })
    .handleAction([deleteGroup, kickGroupMember], (_state, action) => {
        _state[action.payload] = null;
        return cloneDeep(_state);
    })
    .handleAction(deleteMessageGroup, (_state, action) => {
        let newState = cloneDeep(_state);
        if (newState[action.payload.recepteur]) 
            newState[action.payload.recepteur].lastMessages.filter((val) => val.idMessageGroupe != action.payload.idMessageGroupe)
        return cloneDeep(newState);
    })
    .handleAction(setGroupRead.success, (_state, action) => {
        let newState = cloneDeep(_state);
        if (newState[action.payload]) 
            newState[action.payload].unreadMessages = 0
        return cloneDeep(newState)
    });

const me = createReducer<string, RootAction>(null)
    .handleAction([loadMessagerie.success], (_state, action) => cloneDeep(action.payload.me.idActeur));

const isLoading = createReducer<boolean, RootAction>(false)
    .handleAction([loadMessagerie.success, loadMessagerie.failure], (_state, action) => false)
    .handleAction([loadMessagerie.request], (_state, action) => true)
    .handleAction([loadPersonne.success, loadPersonne.failure], (_state, action) => false)
    .handleAction([loadPersonne.request], (_state, action) => true);

const jwt = createReducer<JWTResult, RootAction>(null)
    .handleAction([loadJWTAsync.success], (_state, action) => action.payload);

export const messagerieReducers = combineReducers({
    personnes,
    groupes,
    me,
    isLoading,
    jwt
});

export default messagerieReducers;
