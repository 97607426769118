import { createAsyncAction, createAction } from "typesafe-actions";
import { Niveau } from "classes/referentiel/Niveau.class";
import { NiveauInfos, RawDroit, RoleAndRightsToSave, SaveNiveauxChampsParameters } from "./types";
import { NiveauChamp } from "classes/referentiel/NiveauChamp.class";
import { NiveauCaracteristique } from "classes/referentiel/NiveauCaracteristique.class";
import { Role } from "classes/referentiel/Role.class";

export const loadNiveauxAsync = createAsyncAction("LOAD_NIVEAUX_REQUEST", "LOAD_NIVEAUX_SUCCESS", "LOAD_NIVEAUX_FAILURE")<
    number,
    Niveau[],
    string
>();

export const loadCurrentRefNiveauxAsync = createAsyncAction("LOAD_CURRENT_REF_NIVEAUX_REQUEST", "LOAD_CURRENT_REF_NIVEAUX_SUCCESS", "LOAD_CURRENT_REF_NIVEAUX_FAILURE")<
    void,
    Niveau[],
    string
>();

export const loadNiveauAsync = createAsyncAction("LOAD_NIVEAU_REQUEST", "LOAD_NIVEAU_SUCCESS", "LOAD_NIVEAU_FAILURE")<
    number,
    Niveau,
    string
>();


export const loadNiveauInfosAsync = createAsyncAction("LOAD_NIVEAU_INFOS_REQUEST", "LOAD_NIVEAU_INFOS_SUCCESS", "LOAD_NIVEAU_INFOS_FAILURE")<
    number,
    NiveauInfos,
    string
>();

export const saveNiveauChampsAsync = createAsyncAction("SAVE_NIVEAUX_CHAMPS_INFOS_REQUEST", "SAVE_NIVEAUX_CHAMPS_INFOS_SUCCESS", "SAVE_NIVEAUX_CHAMPS_INFOS_FAILURE")<
    SaveNiveauxChampsParameters,
    boolean,
    string
>();

export const saveNiveauxCaracteristiquesAsync = createAsyncAction("SAVE_NIVEAUX_CARACTERISTIQUES_REQUEST", "SAVE_NIVEAUX_CARACTERISTIQUES_SUCCESS", "SAVE_NIVEAUX_CARACTERISTIQUES_FAILURE")<
    NiveauCaracteristique[],
    boolean,
    string
>();

export const loadNiveauRolesAsync = createAsyncAction("LOAD_NIVEAU_ROLES_REQUEST", "LOAD_NIVEAU_ROLES_SUCCESS", "LOAD_NIVEAU_ROLES_FAILURE")<
    number,
    Role[],
    string
>();

export const loadNiveauRoleAsync = createAsyncAction("LOAD_NIVEAU_ROLE_REQUEST", "LOAD_NIVEAU_ROLE_SUCCESS", "LOAD_NIVEAU_ROLE_FAILURE")<
    number,
    Role,
    string
>();

export const loadRoleListeDroitsAsync = createAsyncAction("LOAD_ROLE_LISTE_DROITS_REQUEST", "LOAD_ROLE_LISTE_DROITS_SUCCESS", "LOAD_ROLE_LISTE_DROITS_FAILURE")<
    void,
    RawDroit[],
    string
>();

// export const saveRoleWithRightsAsync = createAsyncAction("SAVE_ROLE_WITH_RIGHTS_REQUEST", "SAVE_ROLE_WITH_RIGHTS_SUCCESS", "SAVE_ROLE_WITH_RIGHTS_FAILURE")<
// RoleAndRightsToSave,
// boolean,
// string
// >();




export const setNiveau = createAction('SET_NIVEAU')<Niveau>();


export const setRole = createAction('SET_ROLE')<Role>();