import { Animation } from 'classes/animations/Animation.class';
import { createReducer, action } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { AdminAnimationsListStateType, AdminAnimationsListAction, AdminQuizStateType, AdminQuizAction } from './types';
import {
    adminGetAnimationAsync,
    adminGetQuestionsQuizAsync,
    setTypeCreationAnimation,
    adminLoadAnimationsAsync,
    adminSetCurrentAnimation,
    setQuizModified,
    adminSaveQuestionsQuizAsync,
    setProgressSaveQuestions,
    adminDeleteAnimationAsync,
    adminGetRankingsQuizAsync,
    adminDeleteRankingQuizAsync,
    adminSetHideLeftPanel,
    adminLoadPostsAsync,
    adminSavePostAsync,
} from './actions';
import { loadCarnetQuestionnaires } from 'features/carnet_voyage/src/store/actions';
import { RootAction } from 'store/types';
import { Post } from 'classes/animations/concoursPhoto/Post.class';
import cloneDeep from 'lodash/cloneDeep';

/*********************** QUIZ **********************/

const QuizInitialState: AdminQuizStateType = {
    questions: [],
    isLoading: true,
    quizModified: false,
    error: '',
    progressUpload: 0,
    isSaving: false,
    results: [],
    areRankingsLoading: true,
};

const Quiz = createReducer<AdminQuizStateType, AdminQuizAction>(QuizInitialState)
    .handleAction(adminGetQuestionsQuizAsync.success, (state, action) => ({
        ...state,
        questions: action.payload,
        isLoading: false,
    }))
    .handleAction(adminGetQuestionsQuizAsync.request, (state, action) => ({
        ...state,
        isLoading: true,
    }))
    .handleAction(setQuizModified, (state, action) => ({
        ...state,
        quizModified: action.payload,
    }))
    .handleAction(adminGetQuestionsQuizAsync.failure, (state, action) => ({
        ...state,
        error: action.payload,
    }))
    .handleAction(setProgressSaveQuestions, (state, action) => ({
        ...state,
        progressUpload: action.payload,
    }))
    .handleAction(adminSaveQuestionsQuizAsync.request, (state, action) => ({
        ...state,
        isSaving: true,
    }))
    .handleAction(adminSaveQuestionsQuizAsync.success, (state, action) => ({
        ...state,
        isSaving: false,
        progressUpload: 0,
    }))
    .handleAction(adminGetRankingsQuizAsync.request, (state) => ({
        ...state,
        areRankingsLoading: true,
    }))
    .handleAction(adminGetRankingsQuizAsync.success, (state, action) => ({
        ...state,
        areRankingsLoading: false,
        results: action.payload,
    }))
    .handleAction(adminDeleteRankingQuizAsync.request, (state) => ({
        ...state,
        areRankingsLoading: true,
    }));
// .handleAction(adminSaveQuestionsQuizAsync.success, (state, action) =>
// ({
//     ...state,
//     questions: action.payload
// }))
// .handleAction(adminSaveQuestionsQuizAsync.failure, (state, action) =>
// ({
//     ...state,
//     error: action.payload
// }));

/** ************* AnimationList *************** */

const AnimationListInitialState: AdminAnimationsListStateType = {
    isLoading: false,
    modalNewAnimationOpened: false,
    list: [],
    typeCreationAnimation: 1,
    currentAnimation: null,
    error: '',
    hideLeftPanel: false,
};

const isLoading = createReducer<typeof AnimationListInitialState.isLoading, RootAction>(AnimationListInitialState.isLoading)
    .handleAction(
        [
            adminGetAnimationAsync.success,
            adminLoadAnimationsAsync.success,
            adminDeleteAnimationAsync.success,
            loadCarnetQuestionnaires.success,
            adminGetAnimationAsync.failure,
            adminLoadAnimationsAsync.failure,
            adminDeleteAnimationAsync.failure,
            loadCarnetQuestionnaires.failure,
        ],
        () => false,
    )
    .handleAction(
        [adminGetAnimationAsync.request, adminLoadAnimationsAsync.request, adminDeleteAnimationAsync.request, loadCarnetQuestionnaires.request],
        () => true,
    );

const list = createReducer<typeof AnimationListInitialState.list, RootAction>(AnimationListInitialState.list)
    .handleAction(adminLoadAnimationsAsync.success, (state, action) => action.payload)
    .handleAction(loadCarnetQuestionnaires.success, (state, action) => action.payload);
// .handleAction(adminDeleteAnimationAsync.success, (state, action));

const typeCreationAnimation = createReducer<typeof AnimationListInitialState.typeCreationAnimation, AdminAnimationsListAction>(
    AnimationListInitialState.typeCreationAnimation,
).handleAction(setTypeCreationAnimation, (state, action) => action.payload);

const currentAnimation = createReducer<typeof AnimationListInitialState.currentAnimation, AdminAnimationsListAction>(
    AnimationListInitialState.currentAnimation,
).handleAction(adminSetCurrentAnimation, (state, action) => action.payload);

const hideLeftPanel = createReducer<typeof AnimationListInitialState.hideLeftPanel, AdminAnimationsListAction>(
    AnimationListInitialState.hideLeftPanel,
).handleAction(adminSetHideLeftPanel, (state, action) => action.payload);
// .handleAction(adminGetAnimationAsync.success, (state, action) =>
//     action.payload
// );

/************ CONCOURS PHOTO ************/

const concoursPhotoPosts = createReducer<Post[], RootAction>([]).handleAction(adminLoadPostsAsync.success, (_state, action) => action.payload);

const isLoadingConcours = createReducer<boolean, RootAction>(false)
    .handleAction([adminLoadPostsAsync.success, adminSavePostAsync.success, adminLoadPostsAsync.failure, adminSavePostAsync.failure], (_state, action) => false)
    .handleAction([adminLoadPostsAsync.request, adminSavePostAsync.request], (_state, action) => true);

const error = createReducer<typeof AnimationListInitialState.error, AdminAnimationsListAction>(AnimationListInitialState.error).handleAction(
    adminGetAnimationAsync.failure,
    (state, action) => action.payload,
);

export const animationsReducer = combineReducers({
    isLoading,
    currentAnimation,
    list,
    typeCreationAnimation,
    quiz: Quiz,
    hideLeftPanel,
    concoursPhotoPosts,
    isLoadingConcours,
});

/***************** CONCOURS PHOTO *****************/

/****************** TOP REDUCER ******************/

export const adminAnimationsReducer = combineReducers({
    animations: animationsReducer,
});

export default adminAnimationsReducer;
