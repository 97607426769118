import axios from "axios";
import { DeepRawify } from "types";

export class B64File {
    public idFile: string;
    public localIdFile: string;
    public namespace: string;
    public progress: number;

    constructor(public name: string, private data?: string) {

    }

    //TODO : Trouver une autre solution pour la séparation admin / FO?
    public getSrc(passByServer: boolean = false): string {
        if (this.data) {
            return this.data;
        } else if (passByServer && axios.defaults.baseURL) {
            return axios.defaults.baseURL + `/index.php?rub=${(IS_ADMIN ? 50 : 5)}&file=${encodeURIComponent(`${this.namespace}/${this.idFile}/${this.name}`)}`;
        } else if (passByServer) {
            return `index.php?rub=${(IS_ADMIN ? 50 : 5)}&file=${encodeURIComponent(`${this.namespace}/${this.idFile}/${this.name}`)}`;
        } else if (this.idFile && axios.defaults.baseURL) {
            return axios.defaults.baseURL + "/uploads/" + this.namespace + "/" + this.idFile + '_' + encodeURIComponent(this.name);
        } else if (this.idFile) {
            return "/uploads/" + this.namespace + "/" + this.idFile + '_' + encodeURIComponent(this.name);
        }
    }

    public hasData(): boolean {
        return !!this.data;
    }

    public minusData(): B64File {
        let newFile = new B64File(this.name);
        newFile.namespace = this.namespace;
        newFile.idFile = this.idFile;
        newFile.localIdFile = this.localIdFile;
        newFile.progress = this.progress;

        return newFile;
    }

    public getFileTypeFromName(): string {
        const typeRegex = /.+\.([^\.]+)$/g;
        return this.name.replace(typeRegex, "$1");
    }

    public static fromDb(field: string): B64File {
        if (field === null || field === "" || field === undefined) {
            return null;
        }
        const [namespace, id, name] = field.split("/");
        const file = new B64File(name);

        file.idFile = id;
        file.namespace = namespace;

        return file;
    }

    public getAsFile(): File {
        if (this.data) {
            return this.dataURLtoFile(this.data, this.name);
        } else {
            return new File([], this.name);
        }
    }

    private dataURLtoFile(dataurl: string, filename: string) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    public static async fromFormFile(file: File): Promise<B64File> {
        return new Promise(resolve => {
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                resolve(new B64File(file.name, reader.result.toString()));
            });

            reader.readAsDataURL(file);
        });
    }

    public static rowsToFile(row?: DeepRawify<B64File>): B64File {
        if (row) {
            const b64File: B64File = new B64File(row.name);
            b64File.idFile = row.idFile;
            b64File.namespace = row.namespace;
            return b64File;
        } else {
            return null;
        }
    }

    public static rowsToArray(rows?: DeepRawify<B64File>[]): B64File[] {
        const array: B64File[] = [];

        for (let i = 0; i < rows?.length; i++) {
            const row = rows[i];

            const b64File: B64File = new B64File(row.name);
            b64File.idFile = row.idFile;
            b64File.namespace = row.namespace;
            array.push(b64File);
        }

        return array;
    }
}
