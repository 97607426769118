import { ActeurAnswer } from 'classes/animations/ActeurAnswer.class';
import { Participation } from 'classes/animations/Participation.class';
import { QuizBadge } from 'classes/animations/QuizBadge.class';
import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import { loadAnimationsAsync } from '../../store/actions';
import { loadCurrentQuestionAsync, loadParticipationAsync, setCheckedAnswers, submitAnswerAsync, unlockQuizBadgeAsync } from './actions';

const isLoading = createReducer<boolean>(true)
    .handleAction([loadParticipationAsync.request, loadCurrentQuestionAsync.request], () => true)
    .handleAction(
        [loadParticipationAsync.success, loadCurrentQuestionAsync.success],
        () => false,
    );

const participation = createReducer<Participation, RootAction>(null)
    .handleAction(loadAnimationsAsync.request, () => null)
    .handleAction(loadParticipationAsync.success, (_state, action) => action.payload);

const acteurAnswer = createReducer<ActeurAnswer, RootAction>(null)
    .handleAction(loadCurrentQuestionAsync.success, (_state, action) => action.payload);

const checkedAnswers = createReducer<Array<number | string>, RootAction>([])
    .handleAction(loadCurrentQuestionAsync.success, () => [])
    .handleAction(setCheckedAnswers, (state, action) => [...action.payload]);

const error = createReducer<string, RootAction>(null)
    .handleAction(
        submitAnswerAsync.failure,
        (_state, action) => {
            if (action.payload.response.data.success === false) {
                return action.payload.response.data.message;
            } else if (!action.payload.response.status) {
                return 'Veuillez vérifier votre connection internet';
            } else {
                return 'Une erreur inconnue s\'est produite';
            }
        }
    );

const badge = createReducer<QuizBadge, RootAction>(null)
        .handleAction(
            unlockQuizBadgeAsync.success,
            (_state, action) => action.payload
        )

const quizReducer = combineReducers({
    isLoading,
    participation,
    acteurAnswer,
    checkedAnswers,
    error,
    badge
});

export default quizReducer;
