import { B64File } from 'classes/B64File.class';
import { Post } from 'classes/murs/Post.class';
import { combineReducers } from 'redux';
import { RootAction, storeError } from 'store/types';
import { createReducer } from 'typesafe-actions';
import { resetMursStore, getPostAsync, getPostFromIndexAsync, loadElementsAsync, loadMorePostsAsync, loadMurInfos, loadPostsAsync, loadTypesPostAsync, preUploadVideoAsync, removePostFromList, resetError, resetPreUploadVideo, setListPostsActeurAuteurFilter, setListPostsActeurFilter, setListPostsActifFilter, setListPostsFavoriFilter, setListPostsFilterToBrouillons, setListPostsFilterToEnLigne, setListPostsFilterToFavoris, setListPostsFilterToProgramme, setListPostsLimitFilter, setListPostsMore, setListPostsProgrammeFilter, setListPostsTypePostFilter, setPost, setProgressPreUploadedVideo, setShouldPostsListGetUpdated, setTypeMur, setListPostsFilterToMesPosts, setList, getPostWithTypeMurAsync } from './actions';
import { ElementLiteral, MetierAssociative, RaisonSignalement, TypesPostAssociative } from './types';
import cloneDeep from 'lodash/cloneDeep'
import { loadAdminParametresTypesPostInfosAsync, loadAdminTypePostAsync, loadAdminTypesPostAsync } from 'features/admin/parametres/src/store/actions';
import { TypePost } from 'classes/murs/TypePost.class';

// import { loadAnimationsAsync, setCurrentAnimation } from './actions
const isLoading = createReducer(false)
    .handleAction(loadPostsAsync.request, () => true)
    .handleAction(loadElementsAsync.request, () => true)
    .handleAction(getPostAsync.request, () => true)
    .handleAction(getPostWithTypeMurAsync.request, () => true)
    .handleAction(getPostFromIndexAsync.request, () => true)
    .handleAction(loadMorePostsAsync.request, () => true)
    .handleAction(loadTypesPostAsync.request, () => true)
    .handleAction(loadMurInfos.request, () => true)
    .handleAction(loadPostsAsync.success, () => false)
    .handleAction(loadElementsAsync.success, () => false)
    .handleAction(loadMorePostsAsync.success, () => false)
    .handleAction(getPostAsync.success, () => false)
    .handleAction(getPostWithTypeMurAsync.success, () => false)
    .handleAction(getPostFromIndexAsync.success, () => false)
    .handleAction(loadTypesPostAsync.success, () => false)
    .handleAction(loadMurInfos.success, () => false)
    .handleAction(loadPostsAsync.failure, () => false)
    .handleAction(loadElementsAsync.failure, () => false)
    .handleAction(getPostAsync.failure, () => false)
    .handleAction(getPostWithTypeMurAsync.failure, () => false)
    .handleAction(getPostFromIndexAsync.failure, () => false)
    .handleAction(loadMorePostsAsync.failure, () => false)
    .handleAction(loadTypesPostAsync.failure, () => false)
    .handleAction(loadMurInfos.failure, () => false);

const isListPostsLoading = createReducer(false)
    .handleAction(loadPostsAsync.request, () => true)
    .handleAction(loadMurInfos.request, () => true)
    .handleAction(loadPostsAsync.success, () => false)
    .handleAction(loadMurInfos.success, () => false)
    .handleAction(loadPostsAsync.failure, () => false)
    .handleAction(loadMurInfos.failure, () => false);

const list = createReducer<Post[], RootAction>([])
    .handleAction([loadPostsAsync.success, setList], (_state, action) => cloneDeep(action.payload))
    .handleAction(loadMorePostsAsync.success, (_state, action) => [..._state, ...action.payload])
    .handleAction(removePostFromList, (_state, action) => [..._state.slice(0, action.payload), ..._state.slice(action.payload + 1)])
    .handleAction(loadMurInfos.success, (_state, action) => action.payload.list)
    .handleAction(getPostFromIndexAsync.success, (_state, action) => [..._state.slice(0, action.payload.index), action.payload, ..._state.slice(action.payload.index + 1)])
    .handleAction(resetMursStore, () => [])

const elements = createReducer<ElementLiteral, RootAction>({})
    .handleAction(loadElementsAsync.success, (_state, action) => action.payload)
    .handleAction(loadMurInfos.success, (_state, action) => action.payload.elements)
    .handleAction(resetMursStore, () => {return {};});

const typeMur = createReducer<number, RootAction>(-1)
    .handleAction(setTypeMur, (_state, action) => action.payload)
    .handleAction(resetMursStore, () => -1);

const post = createReducer<Post, RootAction>(null)
    .handleAction(setPost, (_state, action) => action.payload)
    .handleAction([getPostAsync.success, getPostFromIndexAsync.success, getPostWithTypeMurAsync.success], (_state, action) => action.payload)
    .handleAction(resetMursStore, () => null);


const canaux = createReducer<MetierAssociative, RootAction>(null)
    .handleAction(loadMurInfos.success, (_state, action) => action.payload.canaux)
    .handleAction(resetMursStore, () => null)

// Reducer pour les types de posts
const typesPost = createReducer<TypesPostAssociative, RootAction>(null)
    .handleAction([loadTypesPostAsync.success, loadAdminTypesPostAsync.success], (_state, action) => action.payload)
    .handleAction([loadMurInfos.success, loadAdminParametresTypesPostInfosAsync.success], (_state, action) => action.payload.typesPost ? action.payload.typesPost : null)
    .handleAction(resetMursStore, () => null);

// Reducer pour un type de post
const typePost = createReducer<TypePost, RootAction>(null)
    .handleAction([loadAdminTypePostAsync.success], (_state, action) => action.payload);

const error = createReducer<storeError, RootAction>({ success: true, message: '' })
    .handleAction([resetError,resetMursStore], (_state, action) => { return { success: true, message: '' } })
    .handleAction([getPostAsync.failure, getPostWithTypeMurAsync.failure], (_state, action) => action.payload)

const shouldPostsListGetUpdated = createReducer<boolean, RootAction>(false)
    .handleAction(setShouldPostsListGetUpdated, (_state, action) => action.payload)
    .handleAction([loadPostsAsync.success, resetMursStore], (_state, action) => false)

const titleMur = createReducer<string, RootAction>('')
    .handleAction(loadMurInfos.success, (_state, action) => action.payload.title)
    .handleAction(resetMursStore, () => '')

const nbChallenges = createReducer<number, RootAction>(null)
    .handleAction(loadMurInfos.success, (_state, action) => action.payload.nbChallenges)
    .handleAction(resetMursStore, () => null)

const raisonsSignalement = createReducer<RaisonSignalement, RootAction>({})
    .handleAction(loadMurInfos.success, (_state, action) => action.payload.raisonsSignalement ? action.payload.raisonsSignalement : {})
    .handleAction(resetMursStore, () => {return {};})

const statistics = createReducer<{ [label: string]: string }, RootAction>({})
    .handleAction(loadMurInfos.success, (_state, action) => action.payload.statistics)
    .handleAction(resetMursStore, () => {return {};})

/*****************************************
************ ListPostFilters ************* 
******************************************/

const idTypePost = createReducer<number, RootAction>(-1)
    .handleAction(setListPostsTypePostFilter, (_state, action) => action.payload)
    .handleAction([setListPostsFilterToProgramme, setListPostsFilterToBrouillons, setListPostsFilterToEnLigne, setListPostsFilterToFavoris, setListPostsFilterToMesPosts, resetMursStore], (_state, action) => -1)

const limitPosts = createReducer<number, RootAction>(5)
    .handleAction(setListPostsLimitFilter, (_state, action) => action.payload)
    .handleAction([setListPostsFilterToProgramme, setListPostsFilterToBrouillons, setListPostsFilterToEnLigne, setListPostsFilterToFavoris, setListPostsFilterToMesPosts, resetMursStore], (_state, action) => 5)

const more = createReducer<boolean, RootAction>(false)
    .handleAction(setListPostsMore, (_state, action) => action.payload)
    .handleAction([setListPostsFilterToProgramme, setListPostsFilterToBrouillons, setListPostsFilterToEnLigne, setListPostsFilterToFavoris, setListPostsFilterToMesPosts, resetMursStore], (_state, action) => false)

const actif = createReducer<number, RootAction>(1)
    .handleAction(setListPostsActifFilter, (_state, action) => action.payload)
    .handleAction(setListPostsFilterToBrouillons, (_state, action) => 2)
    .handleAction([setListPostsFilterToProgramme, setListPostsFilterToEnLigne, setListPostsFilterToFavoris, setListPostsFilterToMesPosts, resetMursStore], (_state, action) => 1)

const acteurAuteur = createReducer<string, RootAction>('')
    .handleAction([setListPostsActeurAuteurFilter, setListPostsFilterToMesPosts], (_state, action) => action.payload)
    .handleAction([setListPostsFilterToProgramme, setListPostsFilterToEnLigne, setListPostsFilterToFavoris, resetMursStore], (_state, action) => '')

const acteur = createReducer<string, RootAction>('')
    .handleAction([setListPostsFilterToProgramme, setListPostsFilterToEnLigne, setListPostsFilterToFavoris, resetMursStore], (_state, action) => '')
    .handleAction([setListPostsActeurFilter, setListPostsFilterToMesPosts], (_state, action) => action.payload)

const programme = createReducer<boolean, RootAction>(false)
    .handleAction(setListPostsProgrammeFilter, (_state, action) => action.payload)
    .handleAction(setListPostsFilterToProgramme, (_state, action) => true)
    .handleAction([setListPostsFilterToBrouillons, setListPostsFilterToEnLigne, setListPostsFilterToFavoris, setListPostsFilterToMesPosts, resetMursStore], (_state, action) => false)

const favori = createReducer<boolean, RootAction>(false)
    .handleAction(setListPostsFavoriFilter, (_state, action) => action.payload)
    .handleAction(setListPostsFilterToFavoris, (_state, action) => true)
    .handleAction([setListPostsFilterToBrouillons, setListPostsFilterToEnLigne, setListPostsFilterToProgramme, setListPostsFilterToMesPosts, resetMursStore], (_state, action) => false)

    /********************
     ********************
     ********************
     ********************
     ********************/

const preUploadVideoProgress = createReducer<number, RootAction>(100)
    .handleAction(preUploadVideoAsync.request, (_state, action) => 0) // Regarder les actions, le back ne renvoie plus le fichier mais un idFile (de la vidéo) que
    .handleAction(preUploadVideoAsync.success, (_state, action) => 100) // l'on doit renvoyer ensuite lors de publishPost // IF 100 => on envoie le post (si videoIdFile on envoie avec sinon rien, le back fait iesset(_POST['idFile']))
    .handleAction(setProgressPreUploadedVideo, (_state, action) => action.payload)
    .handleAction(resetPreUploadVideo, (_state, action) => 100)
    .handleAction(preUploadVideoAsync.cancel, (_state, action) => 100)

const preUploadedVideoB64File = createReducer<B64File, RootAction>(null)
    .handleAction(preUploadVideoAsync.request, (_state, action) => null) // Regarder les actions, le back ne renvoie plus le fichier mais un idFile (de la vidéo) que
    .handleAction(preUploadVideoAsync.success, (_state, action) => B64File.rowsToFile(action.payload))
    .handleAction(resetPreUploadVideo, (_state, action) => null)
    .handleAction(preUploadVideoAsync.cancel, (_state, action) => null)

const listPostsFilterReducer = combineReducers({
    idTypePost,
    limitPosts,
    more,
    actif,
    acteurAuteur,
    acteur,
    programme,
    favori,


})

/*******************************************/

const mursReducer = combineReducers({
    isLoading,
    isListPostsLoading,
    list,
    elements,
    typeMur,
    post,
    error,
    typesPost,
    typePost,
    shouldPostsListGetUpdated,
    raisonsSignalement,
    statistics,
    canaux,
    titleMur,
    nbChallenges,
    preUploadVideoProgress,
    preUploadedVideoB64File,
    listPostsFilters: listPostsFilterReducer
});


export default mursReducer;
