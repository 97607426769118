import { DeepPartial } from "redux";
import { ParticipantsService } from "services";
import { DeepRawify } from "types";

export class ActeurEntiteRole {

    public idReferentielActeur : string = null;

    public idReferentielEntite : string = null;

    public idReferentielRole : string = null;

    public idReferentielMetier : string = null;

    public idActeurEntiteRole : string = null;

    private participantsService = ParticipantsService.getInstance();

    constructor(row? : DeepPartial<DeepRawify<ActeurEntiteRole>>) {

        if (row) {

            const {
                idReferentielActeur,
                idReferentielEntite,
                idReferentielRole,
                idReferentielMetier,
                idActeurEntiteRole
            } = row;

            this.idReferentielActeur = idReferentielActeur;

            this.idReferentielEntite = idReferentielEntite;

            this.idReferentielRole = idReferentielRole;

            this.idReferentielMetier = idReferentielMetier;

            this.idActeurEntiteRole = idActeurEntiteRole;

        }
    }

    // public save : () => Promise<boolean> = () => {
    //     return this.participantsService.saveActeurEntiteRole(this);
    // }

    public toDatabaseObject(): object {
        return {
            id_referentiel_acteur: this.idReferentielActeur,
            id_referentiel_entite: this.idReferentielEntite,
            id_referentiel_role: this.idReferentielRole,
            id_referentiel_metier: this.idReferentielMetier,
            id_acteur_entite_role: this.idActeurEntiteRole
        }
    }
}