import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import { clearPcPosts, loadPcPostsAsync, publishPcPost } from './actions';
import { Post } from "classes/animations/concoursPhoto/Post.class";
import { PostLike } from "classes/animations/concoursPhoto/PostLike.class";

const posts = createReducer<Post[], RootAction>([])
    .handleAction(loadPcPostsAsync.success, (_state, action) => [...action.payload])
    .handleAction(clearPcPosts, (_state, action) => []);
    
const publishedPost = createReducer<Post, RootAction>(null)
    .handleAction(publishPcPost, (_state, action) => action.payload);

const isLoading = createReducer<boolean, RootAction>(false)
    .handleAction([
        loadPcPostsAsync.request
    ], (_state, action) => true)
    .handleAction([
        loadPcPostsAsync.failure,
        loadPcPostsAsync.success
    ], (_state, action) => false)
    

const concoursPhotoReducer = combineReducers({
    posts,
    publishedPost,
    isLoading
});

export default concoursPhotoReducer;