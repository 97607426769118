import { DeepPartial } from "redux";
import { ChallengeService } from "services";
import { DeepRawify } from "types";

export class MulticritereGroupe {

    public idGroupe : number = null;

    public idChallenge : number = null;

    public idSuivi : number = null;

    public idEntite : string = null;

    public libelle : string = null;

    private challengeService = ChallengeService.getInstance();

    constructor(row? : DeepPartial<DeepRawify<MulticritereGroupe>>) {

        if (row) {

            const {
                idGroupe,
                idChallenge,
                idSuivi,
                idEntite,
                libelle
            } = row;

            this.idGroupe = idGroupe;

            this.idChallenge = idChallenge;

            this.idSuivi = idSuivi;

            this.idEntite = idEntite;

            this.libelle = libelle;

        }
    }

    // public save : () => Promise<boolean> = () => {
    //     return this.MulticritereGroupeService.saveMulticritereGroupe(this);
    // }

    public toDatabaseObject(): object {
        return {
            idGroupe: this.idGroupe,
            idChallenge: this.idChallenge,
            idSuivi: this.idSuivi,
            idEntite: this.idEntite,
            libelle: this.libelle
        }
    }
}