import _ from 'lodash';
import { useState, useEffect } from 'react';
import { InfiniteScrollType } from './types';

/**
 * Fonction général permettant d'implémenter un scroll infini
 * @param callback fonction appelé lorsqu'un utilisateur scroll jusqu'à atteindre le bas de la page
 * @returns 
 */
const useInfiniteScroll = (callback : Function) : InfiniteScrollType => {

    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {
        // On utilise throttle pour éviter trop "d'écoutes" successives pouvant causer des pb de mémoires
        const throttledScroll = _.throttle(handleScroll, 5);
        // On se met à "écouter" si on scroll
        window.addEventListener('scroll', throttledScroll);
        return () => window.removeEventListener('scroll', throttledScroll);
    }, []);

    useEffect(() => {
        if (isScrolling){
            callback();
        }
    }, [isScrolling]);

    /**
     * Méthode permettant de savoir si un scroll est en court sur la page
     * @returns true 
     */
    function handleScroll() {

        // Si on est sur Iphone et que la fin de la fenêtre est atteinte
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent) && (window.innerHeight + Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) !== document.documentElement.offsetHeight || isScrolling)) {
            return;
        }
        else if(!(/iPhone|iPad|iPod/i.test(navigator.userAgent)) && window.innerHeight + document.documentElement.scrollTop!==document.documentElement.offsetHeight){
            return;
        }
        setIsScrolling(true);
    }

    return {isScrolling : isScrolling, setIsScrolling : setIsScrolling };

};

export default useInfiniteScroll;