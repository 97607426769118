import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SpeedDial, { CloseReason, OpenReason } from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { Desktop, Mobile } from 'components/mediaQueries/MediaQueries.component';
import NotificationsPaperComponent from 'components/notifications/NotificationsPaper.component';
import { push } from 'connected-react-router';
import isEqual from 'lodash/isEqual';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { useSelector } from 'store/rootStore';
import BrouillonsVideSVG from '../../../../assets/visuels/brouillons_vide.svg';
import EnLigneVideSVG from '../../../../assets/visuels/en_ligne_vide.svg';
import ProgrammeesVideSVG from '../../../../assets/visuels/programmees_vide.svg';
import useStyle from './DisplayPosts.style';
import LeftPanelMurComponent from './LeftPanelMur.component';
import CreatePostComponent from './posts/CreatePost.hook.component';
import ListPostsComponent from './posts/ListPosts.component';
import { loadPostsAsync, setListPostsActeurAuteurFilter, setListPostsActeurFilter, setListPostsFilterToBrouillons, setListPostsFilterToEnLigne, setListPostsFilterToProgramme, setListPostsTypePostFilter, setPost } from './store/actions';
import { setHeaderStore } from 'features/header/src/store/actions'
import { colors } from 'Theme'
import cloneDeep from 'lodash/cloneDeep'
import { navigationPush } from 'store/navigation/navigationActions';
import useTeamUpStyle from 'TeamUp.style'
import TypePostIcon from './posts/TypePostIcon.component'
import { TypePost } from 'classes/murs/TypePost.class';
import clsx from 'clsx';
import { HeaderFilter } from 'features/header/src/store/types';

interface DisplayPostsProps {
    noLeftPanel?: boolean
}

const tabToImg: {
    [key: number]: {
        img: string,
        subtitle: string
    }
} =
{
    0: { img: EnLigneVideSVG, subtitle: "Vous n'avez pas encore publié de publications" },
    1: { img: ProgrammeesVideSVG, subtitle: "Vous n'avez pas encore programmé de publications" },
    2: { img: BrouillonsVideSVG, subtitle: "Vous n'avez pas encore sauvegardé de publications" }
}


const DisplayPosts: React.FC<DisplayPostsProps> = props => {
    const classes = useStyle();
    const teamUpStyle = useTeamUpStyle();

    const [typesPostOpen, setTypesPostOpen] = useState<boolean>(false)
    const [filterDisplayed, setFilterDisplayed] = useState<boolean>(false)
    const [currentTab, setCurrentTab] = useState<number>(0)



    const dispatch = useDispatch();

    const [
        elements,
        typesPost,
        idTypePost,
        listPostsFilters,
        titleMur,
        typeMur,
        moi,
        pageTitle,
        listFilters,
        pathname
    ] = useSelector((state) => [
        state.murs.elements,
        state.murs.typesPost,
        state.murs.listPostsFilters.idTypePost,
        state.murs.listPostsFilters,
        state.murs.titleMur,
        state.murs.typeMur,
        state.acteur.current,
        state.header.pageTitle, ,
        state.header.listFilters,
        state.router.location.pathname
    ], isEqual);

    const idTypePostRef = useRef(idTypePost);

    useEffect(() => {
        if (typesPost) {
            let listFilters: HeaderFilter[] = [
                {
                    callback: () => handleTypePostFiltreClick(-1),
                    title: 'Tout',
                    color: colors.black.main,
                    bgcolor: colors.white.main,
                }
            ];
            Object.values(typesPost).map((typePost, index) => {
                listFilters.push({
                    callback: () => handleTypePostFiltreClick(typePost.idTypePost),
                    title: typePost.libelle,
                    color: colors.white.main,
                    className: typePost.couleur
                })
            })
            dispatch(setHeaderStore({
                listFilters: listFilters
            }))
        }

    }, [typesPost]);

    useEffect(() => {
        dispatch(setHeaderStore({
            pageTitle: titleMur,
            isPageFilter: true,
            leftComponents: [],
            rightComponents: []
        }))
    }, [pathname, titleMur])

    useEffect(() => {
        idTypePostRef.current = cloneDeep(idTypePost);
    }, [idTypePost]);


    function handleTypePostFiltreClick(newIdTypePost: number) {
        if (idTypePostRef.current === newIdTypePost) {
            dispatch(setListPostsTypePostFilter(-1));
        } else {
            dispatch(setListPostsTypePostFilter(newIdTypePost));
        }
        dispatch(loadPostsAsync.request());
    }


    function handleTabChange(tab: number) {
        if (tab != currentTab) {
            switch (tab) {
                case 0:
                    dispatch(setListPostsFilterToEnLigne());
                    break;
                case 1:
                    dispatch(setListPostsFilterToProgramme());
                    break;
                case 2:
                    dispatch(setListPostsFilterToBrouillons());
                    dispatch(setListPostsActeurAuteurFilter(moi.idActeur));
                    dispatch(setListPostsActeurFilter(moi.idActeur));
                    break;
            }
            dispatch(loadPostsAsync.request());
            setCurrentTab(tab);
        }
    }

    function openTypePost(event: React.MouseEvent<HTMLElement, MouseEvent> | React.SyntheticEvent<{}, Event>, reason?: OpenReason) {
        event.stopPropagation();
        event.preventDefault();
        setTypesPostOpen(true)
    }

    function closeTypePost(event: React.MouseEvent<HTMLElement, MouseEvent> | React.SyntheticEvent<{}, Event>, reason?: CloseReason) {
        event.stopPropagation();
        event.preventDefault();
        setTypesPostOpen(false);
    }

    return (
        <Box marginTop={filterDisplayed && "48px"} style={{ transitionDuration: "0.5s" }}>
            <Collapse style={{ position: 'fixed', top: 56, left: 0, width: '100%', zIndex: 10 }} in={filterDisplayed} timeout={500}>
                <Box padding="0 14px 20px" width='100%' display='flex' bgcolor='black' alignItems='center' overflow='auto'>
                    <span onClick={(e) => handleTypePostFiltreClick(-1)} className={`${classes.typePostFilter} ${teamUpStyle.badgeAstuce} ${idTypePost == -1 ? classes.allBadge : classes.disabledFilter}`}>Tout</span>
                    {typesPost && Object.values(typesPost).map((typePost: TypePost, index) => {
                        return(
                            <span 
                                key={`filtre-${typePost.idTypePost}-${index}`} 
                                onClick={(e) => handleTypePostFiltreClick(typePost.idTypePost)} 
                                style={{ 
                                    backgroundColor: (typePost.idTypePost == idTypePost ? `${typePost.couleur}` : colors.grey.dark),
                                    color: (typePost.idTypePost == idTypePost ? colors.white.main : colors.lighterGrey.main),
                                }}
                                className={clsx(classes.typePostFilter, teamUpStyle.badgeAstuce)}>{typePost.libelle}</span>
                        )
                    })}
                </Box>
            </Collapse>
            {
                IS_ADMIN ?
                    <Box display='flex' flexDirection='row'>
                        {
                            !props.noLeftPanel &&
                            <Box display='flex' flexDirection='column' minWidth={350} >
                                <LeftPanelMurComponent />
                            </Box>
                        }

                        <Box display='flex' flexDirection='column' minWidth='35%' maxWidth='75%' width='100%' marginX={2}>

                            {
                                (elements['admin_create_post'] || elements['admin_create_post_public']) &&
                                <Box marginBottom={2}>
                                    <CreatePostComponent modify={false} key={`create-post-not-in-modal`} />
                                </Box>
                            }

                            <Paper className={classes.paperListPostsDesktop} elevation={0}>
                                <Tabs
                                    onChange={(_event, value) => handleTabChange(value)}
                                    centered
                                    textColor="primary"
                                    indicatorColor="primary" style={{ marginBottom: 2 }}
                                    value={currentTab} >
                                    <Tab label='En ligne' value={0} className={classes.tab} />
                                    {
                                        elements['programmer'] &&
                                        <Tab label='Programmés' value={1} className={classes.tab} />
                                    }
                                    {
                                        elements['sauvegarder'] &&
                                        <Tab label='Brouillons' value={2} className={classes.tab} />
                                    }
                                </Tabs>
                                <Box paddingY={2}>
                                    <ListPostsComponent imgEmptyList={tabToImg[currentTab].img} subtitleEmptyList={tabToImg[currentTab].subtitle} />
                                </Box>
                            </Paper>
                        </Box>
                        <Desktop>
                            <Box display='flex' flexDirection='column' minWidth={350}>
                                <NotificationsPaperComponent />
                            </Box>
                        </Desktop>
                    </Box>
                    :

                    <Fragment>
                        <Desktop>
                            <Box display='flex' flexDirection='row' padding={4}>
                                {
                                    !props.noLeftPanel &&
                                    <Box display='flex' flexDirection='column' minWidth={350}>
                                        <LeftPanelMurComponent />
                                    </Box>
                                }
                                <Box display='flex' flexDirection='column' minWidth='35%' maxWidth='75%' width='100%' marginX={2}>
                                    {
                                        (elements['create_post'] || elements['create_post_public']) &&
                                        <Box marginBottom={2}>
                                            <CreatePostComponent modify={false} />
                                        </Box>
                                    }
                                    <Paper className={classes.paperListPostsDesktop} elevation={0}>
                                        <Tabs
                                            onChange={(_event, value) => handleTabChange(value)}
                                            centered
                                            textColor="primary"
                                            indicatorColor="primary" style={{ marginBottom: 2 }}
                                            value={currentTab} >
                                            <Tab label='En ligne' value={0} className={classes.tab} />
                                            {
                                                elements['programmer'] &&
                                                <Tab label='Programmés' value={1} className={classes.tab} />

                                            }
                                            {
                                                elements['sauvegarder'] &&
                                                <Tab label='Brouillons' value={2} className={classes.tab} />

                                            }
                                        </Tabs>
                                        <Box paddingY={2}>
                                            <ListPostsComponent imgEmptyList={tabToImg[currentTab].img} subtitleEmptyList={tabToImg[currentTab].subtitle} />
                                        </Box>
                                    </Paper>
                                </Box>
                                <Box display='flex' flexDirection='column' minWidth={350}>
                                    <NotificationsPaperComponent />
                                </Box>
                            </Box>
                        </Desktop>
                        <Mobile>
                            <ListPostsComponent imgEmptyList={tabToImg[currentTab].img} subtitleEmptyList={tabToImg[currentTab].subtitle} />
                        </Mobile>
                    </Fragment>
            }
            {
                !IS_ADMIN && (elements['create_post'] || elements['create_post_public']) &&
                <Mobile>
                    <Fade in={typesPostOpen}>
                        <Box onClick={(e) => closeTypePost(e)} position='fixed' top={0} left={0} width='100%' height='100%' zIndex={2} bgcolor={'rgba(0,0,0,0.7)'} />
                    </Fade>
                    <SpeedDial
                        ariaLabel='Speed Dial Types Post'
                        icon={<SpeedDialIcon icon={<EditIcon />} openIcon={<CloseIcon />} />}
                        onClose={(e, r) => closeTypePost(e, r)}
                        onOpen={(e, r) => openTypePost(e, r)}
                        open={typesPostOpen}
                        direction='up'
                        className={classes.speedDialTypesPosts}
                    >
                        {typesPost && Object.values(typesPost).map((typePost: TypePost, index) => {
                            return <SpeedDialAction
                                key={typePost.idTypePost + '-' + index}
                                icon={<TypePostIcon icon={typePost.icone} color={typePost.couleur} class={classes.typePostIcon} />}
                                tooltipOpen
                                classes={{ staticTooltipLabel: classes.tooltipLabel }}
                                tooltipTitle={typePost.libelle}
                                FabProps={{ size: 'small', classes: { label: classes.fabLabel }, style: { backgroundColor: typePost.couleur} }}
                                onClick={() => { dispatch(setPost(null)); dispatch(navigationPush(`/create-post/${typePost.idTypePost}`)) }}
                            />
                        })}
                    </SpeedDial>
                </Mobile>
            }
        </Box>
    );
}

export default DisplayPosts;