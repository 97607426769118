import Axios, { AxiosRequestConfig } from "axios";
import { Observable, Subject } from "rxjs";

export interface RxAxios<T> {
    progress: Observable<number>;
    response: Observable<T>;
}

export function rxAxios<T>(options: AxiosRequestConfig): RxAxios<T> {
    const progressSubject = new Subject<number>();
    const responseSubject = new Subject<T>();

    options.onUploadProgress = (event) => progressSubject.next(Math.round((event.loaded * 100) / event.total));

    Axios(options).then(response => {
        responseSubject.next(response.data);
        responseSubject.complete();
        progressSubject.complete();
    })
    .catch(error => {
        responseSubject.error(error);
        progressSubject.complete();

    });

    return {
        progress: progressSubject.asObservable(),
        response: responseSubject.asObservable()
    };
}