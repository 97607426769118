import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import {
    loadGroupesAsync,
    getGroupeAsync,
    loadQuestionsAsync,
    getQuestionAsync,
    setSelectedGroupe,
    setSelectedQuestion,
    loadFicheQuizAsync,
    saveNextAnswerAsync,
    loadLastQuizStatsAsync,
    loadClassementAsync
} from './actions';
import cloneDeep from 'lodash/cloneDeep'
import { FicheQuestionGroupe } from 'classes/level_up/quiz/FicheQuestionGroupe.class';
import { FicheQuestion } from 'classes/level_up/quiz/FicheQuestion.class';
import { QuizInfos, ClassementAnswer } from './types';

const groupes = createReducer<FicheQuestionGroupe[], RootAction>([])
    .handleAction(loadGroupesAsync.success, (_state, action) => cloneDeep(action.payload));

const isLoadingGroupe = createReducer<boolean, RootAction>(false)
    .handleAction([loadGroupesAsync.request, getGroupeAsync.request], () => true)
    .handleAction([
        loadGroupesAsync.success, 
        loadGroupesAsync.failure, 
        getGroupeAsync.success, 
        getGroupeAsync.failure, 
    ], () => false);

const selectedGroupe = createReducer<FicheQuestionGroupe | null, RootAction>(null)
    .handleAction(setSelectedGroupe, (_state, action) => action.payload)
    .handleAction(getGroupeAsync.success, (_state, action) => cloneDeep(action.payload));

const questions = createReducer<FicheQuestion[], RootAction>([])
    .handleAction(loadQuestionsAsync.success, (_state, action) => cloneDeep(action.payload));

const isLoadingQuestion = createReducer<boolean, RootAction>(false)
    .handleAction([loadQuestionsAsync.request, getQuestionAsync.request], () => true)
    .handleAction([
        loadQuestionsAsync.success, 
        loadQuestionsAsync.failure, 
        getQuestionAsync.success, 
        getQuestionAsync.failure, 
    ], () => false);

const isLoadingQuiz = createReducer<boolean, RootAction>(false)
    .handleAction([loadFicheQuizAsync.request], () => true)
    .handleAction([
        loadFicheQuizAsync.success, 
        loadFicheQuizAsync.failure
    ], () => false);

const selectedQuestion = createReducer<FicheQuestion | null, RootAction>(null)
    .handleAction(setSelectedQuestion, (_state, action) => action.payload)
    .handleAction([getQuestionAsync.success, saveNextAnswerAsync.success], (_state, action) => cloneDeep(action.payload));

const quizInfos = createReducer<QuizInfos | null, RootAction>(null)
    .handleAction([loadFicheQuizAsync.success, loadLastQuizStatsAsync.success], (_state, action) => cloneDeep(action.payload));

const classement = createReducer<ClassementAnswer[], RootAction>([])
    .handleAction(loadClassementAsync.success, (_state, action) => cloneDeep(action.payload));

const isLoadingClassement = createReducer<boolean, RootAction>(false)
    .handleAction(loadClassementAsync.request, (_state, action) => true)
    .handleAction([loadClassementAsync.success, loadClassementAsync.failure], (_state, action) => false);

export const levelUpQuizReducers = combineReducers({
    groupes,
    isLoadingGroupe,
    selectedGroupe,
    questions,
    isLoadingQuestion,
    selectedQuestion,
    quizInfos,
    classement,
    isLoadingClassement,
    isLoadingQuiz
});

export default levelUpQuizReducers;