import { Groupe } from 'classes/messagerie/Groupe.class';
import { MessageGroupe } from 'classes/messagerie/MessageGroupe.class';
import { MessagePersonne } from 'classes/messagerie/MessagePersonne.class';
import { Personne } from 'classes/messagerie/Personne.class';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { LoadMessagerieResult, JWTResult } from './types';

/**
 * Utilisés par le front office et lors de la réception d'une action via le WS
 */
export const deleteDiscussion = createAction("DELETE_DISCUSSION")<number|string>();

export const deleteGroup = createAction("DELETE_GROUP")<number>();

export const toggleBlocage = createAction("TOGGLE_BLOCAGE")<number|string>();

/* Utilisés pour mettre à jour le state lors de 
   la réception d'une action via le Websocket */

export const receiveMessageGroup = createAction("RECEIVE_MESSAGE_GROUP")<MessageGroupe>();

export const receiveMessagePersonne = createAction("RECEIVE_MESSAGE_PERSONNE")<MessagePersonne>();

// Réception d'un blocage : une personne vient de bloquer l'utilisateur
export const addBlocage = createAction("ADD_BLOCAGE")<number>();

export const removeBlocage = createAction("REMOVE_BLOCAGE")<number>();

export const kickGroupMember = createAction("KICK_GROUP_MEMBER")<number>();

export const addGroupMembers = createAction("ADD_GROUP_MEMBERS")<Groupe>();

export const deleteMessageGroup = createAction("DELETE_MESSAGE_GROUP")<MessageGroupe>();

export const deleteMesagePersonne = createAction("DELETE_MESSAGE_PERSONNE")<MessagePersonne>();


export const loadPersonne = createAsyncAction(
    'LOAD_PERSONNE_REQUEST',
    'LOAD_PERSONNE_SUCCESS',
    'LOAD_PERSONNE_FAILURE',
)<string, Personne, string>();

export const loadGroupe = createAsyncAction(
    'LOAD_GROUPE_REQUEST',
    'LOAD_GROUPE_SUCCESS',
    'LOAD_GROUPE_FAILURE',
)<number, Groupe, string>();

export const searchPersonne = createAsyncAction(
    'SEARCH_PERSONNE_REQUEST',
    'SEARCH_PERSONNE_SUCCESS',
    'SEARCH_PERSONNE_FAILURE',
)<string, Personne[], string>();

export const loadMessagerie = createAsyncAction(
    'LOAD_MESSAGERIE_REQUEST',
    'LOAD_MESSAGERIE_SUCCESS',
    'LOAD_MESSAGERIE_FAILURE',
)<void, LoadMessagerieResult, string>();

export const sendMessageGroup = createAsyncAction(
    'SEND_MESSAGE_GROUPE_REQUEST',
    'SEND_MESSAGE_GROUPE_SUCCESS',
    'SEND_MESSAGE_GROUPE_FAILURE',
)<MessageGroupe, MessageGroupe, string>();

export const sendMessagePersonne = createAsyncAction(
    'SEND_MESSAGE_PERS_REQUEST',
    'SEND_MESSAGE_PERS_SUCCESS',
    'SEND_MESSAGE_PERS_FAILURE',
)<MessagePersonne, MessagePersonne, string>();

export const setConversationRead = createAsyncAction(
    'SET_CONVERSATION_READ_REQUEST',
    'SET_CONVERSATION_READ_SUCCESS',
    'SET_CONVERSATION_READ_FAILURE',
)<number|string, number, string>();

export const setGroupRead = createAsyncAction(
    'SET_GROUPE_READ_REQUEST',
    'SET_GROUPE_READ_SUCCESS',
    'SET_GROUPE_READ_FAILURE',
)<number, number, string>();

export const loadJWTAsync = createAsyncAction(
    'LOAD_JWT_REQUEST',
    'LOAD_JWT_SUCCESS',
    'LOAD_JWT_FAILURE',
)<void, JWTResult, string>();
