import { combineEpics } from 'redux-observable';
import { RootEpic } from 'store/types';
import { isActionOf } from 'typesafe-actions';
import { loadAdminLogsAsync } from './actions';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

// Chargement des logs
const loadAdminLogsEpics: RootEpic = (action$, state$, { parametresService }) => action$.pipe(
    filter(isActionOf(loadAdminLogsAsync.request)),
    switchMap(() => from(parametresService.loadLogs()).pipe(
        map(loadAdminLogsAsync.success),
        catchError(message => of(loadAdminLogsAsync.failure(message))),
    )),
);


// Combinaison des différentes Epics pour les logs
const AdminLogsEpics = combineEpics(
    loadAdminLogsEpics
);

export default AdminLogsEpics;