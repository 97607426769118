import { createStyles, makeStyles } from '@material-ui/styles';
import { colors, Theme } from 'Theme';

export default makeStyles(({ spacing, palette }: typeof Theme) => createStyles({
    formControl: {
        margin: spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: spacing(1),
        top: spacing(1),
        color: colors.grey.main,
    },
    title: {
        textAlign: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        maxWidth: 500
    },
    dialogActions : {
        padding: spacing(3),
    },
    root: {
        padding: spacing(2),
    },
    textfield: {
        margin: spacing(1)
    },
    chipRoot: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: spacing(0.5),
        },
        margin: spacing(1)
    }
}));