import { createTheme } from '@material-ui/core/styles';
import React, { useEffect }  from 'react';
import { useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { useSelector } from './store/rootStore';
import { ThemeProvider } from '@material-ui/styles';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { colors, Theme } from 'Theme';
import { loadThemeAsync } from 'store/theme/themeActions';

interface ThemeCustomParams {
    children: JSX.Element
}



/**
 * Création d'un thème custom à partir des informations récupérées depuis le fichier de config
 */
const ThemeCustom : React.FC<ThemeCustomParams> = props => {
    
    const [themeState] = useSelector((state) => [
        state.theme.theme
    ], isEqual);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadThemeAsync.request());
    }, []);

    const customTheme = useMemo(
        () =>
        createTheme({
            ...Theme,
            palette: {
                ...Theme.palette,
                primary: { main: themeState && themeState.primaryColor ? themeState.primaryColor : colors.orange.main, contrastText: '#FFFFFF'},
            },
        }),
        [themeState],
    );

    /*if (!themeState) {
        return <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" flexDirection="column">
            <CircularProgress />
            <Typography variant="h6">Chargement en cours</Typography>
        </Box>
    }*/

    return (
        <ThemeProvider theme={customTheme}>
            {props.children}
        </ThemeProvider>
    );

}

export default ThemeCustom;