import Axios from "axios";
import qs from 'qs';
import { TeamUpTheme } from "store/theme/themeTypes";

export class ThemeService {

    private static instance: ThemeService;

    public static getInstance(): ThemeService {
        if (!ThemeService.instance) {
            ThemeService.instance = new ThemeService();
        }

        return ThemeService.instance;
    }

    public async getTheme(): Promise<TeamUpTheme> {
        return Axios.get<{ content: TeamUpTheme }>(`index.php?${qs.stringify({
            rub: IS_ADMIN ? 10 : 1,
            p: 1
        })}`).then(({ data: { content } }) => content);
    }

    public async saveError(error: Error): Promise<void> {
        console.log(error)
        return Axios.post(`index.php?${qs.stringify({
            rub: IS_ADMIN ? 10 : 1,
            p: 2
        })}`, qs.stringify({
            error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
            user: localStorage.getItem('access_token') ?? 'not logged in'
        }));
    }
}
