import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { colors } from 'Theme';

export default ({ spacing, palette }: typeof Theme) => createStyles({
    imgGroupeLike : {
        width : 20,
        height : 20,
        marginRight : spacing(1),
        display : 'inline'
    },
    textActeur : {
        fontSize : 14,
        fontWeight : 700,
    },
    textDate : {
        color : colors.grey.main,
        fontSize : 14
    },
    avatar : {
        marginLeft : spacing(1)
    },
    drawer : {
        maxHeight : '90%'
    }
});