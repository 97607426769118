import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import SnackbarSpawner from 'components/snackbarSpawner/SnackbarSpawner.component';
import { ConnectedRouter, push } from 'connected-react-router';
import moment, { Moment } from 'moment';
import 'moment/locale/fr';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import RootStore, { history } from 'store/rootStore';
import { Theme } from 'Theme';
import Root from './Root.component'
import { AuthProvider, AuthContext } from './Auth';
import axios from 'axios';
import { ErrorBoundary } from "react-error-boundary";
import { Error } from 'components/error/Error.component';
import { Capacitor } from '@capacitor/core';
import AppUrlListener from 'components/AppUrlListener.component';

class LocalizedUtils extends MomentUtils {
    getDateTimePickerHeaderText(date: Moment) {
        return this.getDatePickerHeaderText(date);
    }

    getDatePickerHeaderText(date: Moment) {
        return date.format('Do MMM');
    }
}

let startApp = () => {
    moment.locale("fr");

    const element = document.getElementById('root');
    if (element) {
        render(
            <ErrorBoundary FallbackComponent={Error}>
            <Provider store={RootStore}>
                <AuthProvider>
                    <MuiPickersUtilsProvider utils={LocalizedUtils} locale='fr'>
                        <ThemeProvider theme={Theme}>
                            <ConnectedRouter history={history}>
                                <AppUrlListener />
                                <SnackbarSpawner />
                                <Root />
                            </ConnectedRouter>
                        </ThemeProvider>
                    </MuiPickersUtilsProvider>
                </AuthProvider>
            </Provider>
            </ErrorBoundary>,
            element,
        );
    }
}

if (!Capacitor.isNativePlatform()) {
    axios.defaults.baseURL = '';
    startApp()
} else {
    // Préfixe des requêtes Axios (aussi utilisé pour les uploads)
    axios.defaults.baseURL = URL_SITE;

    // Nécessité de Cordova : le chargement de la page est asynchrone
    document.addEventListener('deviceready', startApp, false);
}