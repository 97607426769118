import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import IconButton from '@material-ui/core/IconButton';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIconSVG from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DragIconSVG from '@material-ui/icons/OpenWith';
import { withStyles, WithStyles } from '@material-ui/styles';
import { CarnetInformations } from 'classes/carnet_voyage/informations/CarnetInformations.class';
import { CarnetInformationsItem } from 'classes/carnet_voyage/informations/CarnetInformationsItem.class';
import B64FilePickerComponent from 'components/filePicker/B64FilePicker.component';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { RootState } from 'store/types';
import { colors, Theme } from 'Theme';
import style from './SortableInformation.style';


const DragHandle = SortableHandle(() => <DragIconSVG />);

const stateToProps = (state: RootState) => ({

})

const dispatchToProps = {

}

interface SortableInformationLocalProps {
    information: CarnetInformations;
    class: string;
    removeItem: (() => void);
    updateParent?: () => void
}

interface SortableInformationState {
}

type SortableInformationProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & SortableInformationLocalProps;

class SortableInformation extends Component<SortableInformationProps, SortableInformationState> {

    private expansionPanelSummaryTheme = (theme: typeof Theme) => {
        // debugger;
        return createTheme({
            ...theme,
            overrides: {
                MuiAccordionSummary: {
                    root: {
                        padding : '0 24px 0 0',
                        minHeight: 50,
                        '&$expanded': {
                            minHeight: 50,
                        },
                    },
                    content: {
                        '&$expanded': {
                            margin: '12px 0',
                        },
                    },
                    expanded: {}
                }
            }
        });
    };

    render() {
        const { information, classes, removeItem } = this.props;
        return (
            <Box marginBottom={2}>
                <Accordion elevation={0} className={classes.expansionPanel} >
                    <ThemeProvider theme={this.expansionPanelSummaryTheme}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            IconButtonProps={{ style: { marginRight: 30 } }}
                        >
                            <Box marginLeft={1} marginRight={2} width={24} height={24} color={colors.grey.main}>
                                <DragHandle />
                            </Box>
                            <img src={CarnetInformationsItem.informationsItemImage[information.idInformationItem]} />
                            <Typography className={classes.titleInfo}>{CarnetInformationsItem.informationsItemContent[information.idInformationItem]}</Typography>
                            <Box position='absolute' right={1} top={1}>
                                <IconButton onClick={(e) => {e.stopPropagation(); removeItem()} }>
                                    <CloseIconSVG />
                                </IconButton>
                            </Box>
                        </AccordionSummary>
                    </ThemeProvider>
                    <AccordionDetails className={classes.expansionDetails}>
                        <TextField className={classes.contentTextField} placeholder='Contenu' onChange={(e) => {this.props.information.content = e.target.value; this.props.updateParent()}} multiline rows={5} style={{ marginBottom: '20px' }} value={information.content} />
                        <B64FilePickerComponent variant='textfield' onChange={(f) => {this.props.information.image = f; this.props.updateParent()}} value={this.props.information.image} />
                    </AccordionDetails>
                </Accordion>
            </Box>
        );
    }
}

export default SortableElement(withStyles(style)(connect(stateToProps, dispatchToProps)(SortableInformation)));