import { createAsyncAction, createAction } from "typesafe-actions";
import { RechercheFilterState, RechercheFiltersInfos } from "./types";
import { Post } from "classes/murs/Post.class";


export const loadPostsAsync = createAsyncAction('LOAD_POSTS_RECHERCHE_REQUEST', 'LOAD_POSTS_RECHERCHE_SUCCESS', 'LOAD_POSTS_RECHERCHE_FAILURE')<
RechercheFilterState,
Post[],
string
>();

export const loadPostsFiltersAsync = createAsyncAction('LOAD_POSTS_RECHERCHE_FILTERS_REQUEST', 'LOAD_POSTS_RECHERCHE_FILTERS_SUCCESS', 'LOAD_POSTS_RECHERCHE_FILTERS_FAILURE')<
void,
RechercheFiltersInfos,
string
>();
