import { combineReducers } from "redux";
import { RootAction } from "store/types";
import { createReducer } from "typesafe-actions";
import { Metier } from "classes/referentiel/Metier.class";
import { loadNiveauxAsync, setNiveau, loadNiveauAsync, loadNiveauInfosAsync, saveNiveauChampsAsync, setRole, loadNiveauRolesAsync, loadNiveauRoleAsync, loadRoleListeDroitsAsync, loadCurrentRefNiveauxAsync } from "./actions";
import { Entite } from "classes/referentiel/Entite.class";
import { Niveau } from "classes/referentiel/Niveau.class";
import { Role } from "classes/referentiel/Role.class";
import { NiveauTypeChamp } from "classes/referentiel/NiveauTypeChamp.class";
import { NiveauChamp } from "classes/referentiel/NiveauChamp.class";
import { NiveauCaracteristique } from "classes/referentiel/NiveauCaracteristique.class";
import { RawDroit } from "./types";
import { loadCreateModifyEntityInfosAsync, loadNiveauEntitiesInfosAsync } from "features/admin/participants/src/store/actions";


const isLoading = createReducer<boolean, RootAction>(false)
    .handleAction([
        loadNiveauxAsync.request,
        loadCurrentRefNiveauxAsync.request,
        loadNiveauAsync.request,
        saveNiveauChampsAsync.request,
        loadNiveauInfosAsync.request,
        loadRoleListeDroitsAsync.request,
    ], (_state, action) => true)
    .handleAction([
        loadNiveauxAsync.success,
        loadNiveauxAsync.failure,
        loadCurrentRefNiveauxAsync.success,
        loadCurrentRefNiveauxAsync.failure,
        loadNiveauAsync.success,
        loadNiveauAsync.failure,
        loadNiveauInfosAsync.success,
        loadNiveauInfosAsync.failure,
        saveNiveauChampsAsync.success,
        saveNiveauChampsAsync.failure,
        loadRoleListeDroitsAsync.success,
        loadRoleListeDroitsAsync.failure,
    ], (_state, action) => false);


const error = createReducer<string, RootAction>('')
    .handleAction([loadNiveauxAsync.failure, loadNiveauAsync.failure, loadNiveauInfosAsync.failure, saveNiveauChampsAsync.failure], (_state, action) => action.payload)

const niveaux = createReducer<Niveau[], RootAction>([])
    .handleAction([loadNiveauxAsync.success, loadCurrentRefNiveauxAsync.success], (_state, action) => action.payload)

const niveau = createReducer<Niveau, RootAction>(null)
    .handleAction(setNiveau, (_state, action) => action.payload)
    .handleAction(loadNiveauAsync.success, (_state, action) => action.payload)
    .handleAction(loadNiveauInfosAsync.success, (_state, action) => action.payload.niveau)

const role = createReducer<Role, RootAction>(null)
    .handleAction(setRole, (_state, action) => action.payload)
    .handleAction(loadNiveauRoleAsync.success, (_state, action) => action.payload)

const niveauRoles = createReducer<Role[], RootAction>(null)
    .handleAction(loadNiveauInfosAsync.success, (_state, action) => action.payload.roles)
    .handleAction(loadNiveauRolesAsync.success, (_state, action) => action.payload)
    .handleAction(loadCreateModifyEntityInfosAsync.success, (_state, action) => action.payload.roles)
    .handleAction(loadNiveauEntitiesInfosAsync.success, (_state, action) => action.payload.roles)

const listeDroits = createReducer<RawDroit[], RootAction>(null)
    .handleAction(loadRoleListeDroitsAsync.success, (_state, action) => action.payload)
// const caracteristiques = createReducer<Caracteristique[], RootAction>([])

/**
 * Niveau champs
 */

const champsInEntite = createReducer<NiveauTypeChamp[], RootAction>([])
    .handleAction(loadNiveauInfosAsync.success, (_state, action) => action.payload.champsInEntite)

const champsOutEntite = createReducer<NiveauTypeChamp[], RootAction>([])
    .handleAction(loadNiveauInfosAsync.success, (_state, action) => action.payload.champsOutEntite)

const champsInInfosEntite = createReducer<NiveauChamp[], RootAction>([])
    .handleAction(loadNiveauInfosAsync.success, (_state, action) => action.payload.champsInInfosEntite)

const champsInActeur = createReducer<NiveauTypeChamp[], RootAction>([])
    .handleAction(loadNiveauInfosAsync.success, (_state, action) => action.payload.champsInActeur)

const champsOutActeur = createReducer<NiveauTypeChamp[], RootAction>([])
    .handleAction(loadNiveauInfosAsync.success, (_state, action) => action.payload.champsOutActeur)

const champsInInfosActeur = createReducer<NiveauChamp[], RootAction>([])
    .handleAction(loadNiveauInfosAsync.success, (_state, action) => action.payload.champsInInfosActeur)

const caracteristiques = createReducer<NiveauCaracteristique[], RootAction>([])
    .handleAction(loadNiveauInfosAsync.success, (_state, action) => action.payload.caracteristiques)


const NiveauxReducer = combineReducers({
    isLoading,
    error,
    niveaux,
    niveau,
    niveauRoles,
    champsInEntite,
    champsOutEntite,
    champsInInfosEntite,
    champsInActeur,
    champsOutActeur,
    champsInInfosActeur,
    role,
    listeDroits,
    caracteristiques
});

export default NiveauxReducer;
