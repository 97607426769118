import { DeepPartial } from "redux";
import { ParticipantsService } from "services";
import { DeepRawify } from "types";

export class EntiteChampValue {

    public idEntite : string = null;

    public idNiveauTypeChamp : number = null;

    public idNiveau : number = null;

    public value : string = null;

    public title : string = null;



    private participantsService = ParticipantsService.getInstance();

    constructor(row? : DeepPartial<DeepRawify<EntiteChampValue>>) {

        if (row) {

            const {
                idEntite,
                idNiveau,
                idNiveauTypeChamp,
                value,
                title,
            } = row;

            this.idEntite = idEntite;

            this.idNiveau = idNiveau;

            this.idNiveauTypeChamp = idNiveauTypeChamp;

            this.value = value;

            this.title = title;



        }
    }

    // public save : () => Promise<boolean> = () => {
    //     return this.EntiteChampValueService.saveEntiteChampValue(this);
    // }

    public toDatabaseObject(): object {
        return {
            idEntite: this.idEntite,
            idNiveau: this.idNiveau,
            idNiveauTypeChamp: this.idNiveauTypeChamp,
            value: this.value,
        }
    }
}