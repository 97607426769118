import Box from '@material-ui/core/Box';
import { withStyles, WithStyles } from '@material-ui/styles'
import { Pacman } from "components/pacman";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "store/types";
import { loadEdito } from "../store/actions";
import style from "./EditoCarnet.style";

const stateToProps = ({ carnetVoyage: { edito } }: RootState) => ({
    edito
});

const dispatchToProps = {
    loadEdito: loadEdito.request
};

interface EditoCarnetLocalProps { }

interface EditoCarnetState { }

type EditoCarnetProps = WithStyles<typeof style> & ReturnType<typeof stateToProps> & typeof dispatchToProps & EditoCarnetLocalProps;

class EditoCarnet extends Component<EditoCarnetProps, EditoCarnetState> {
    componentDidMount() {
        this.props.loadEdito();
    }

    render() {
        const { classes, edito } = this.props;

        if (!edito) {
            return <Pacman />;
        }

        return (
            <Box margin="auto" width="100%" height='100%' display="flex" flexDirection="column" justifyContent="center" alignItems="center" padding={3} bgcolor='white'>
                {
                    edito.cover &&
                        <div className={classes.cover} style={{ backgroundImage : `url('${edito.cover.getSrc()}')`}}/>
                }
                <p className={classes.titre}>{edito.titre || null}</p>
                <p className={classes.accroche}>{edito.accroche || null}</p>
                <p className={classes.descriptif}>{edito.descriptif || null}</p>
                {
                    edito.avatar &&
                    <img className={classes.avatar} src={edito.avatar.getSrc() || null} />
                }
                <p className={classes.nom}>{edito.nom || null}</p>
                <p className={classes.role}>{edito.role || null}</p>
            </Box >
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(EditoCarnet));
