import { createAsyncAction, createAction } from 'typesafe-actions';
import { Post } from 'classes/murs/Post.class';
import { MonProfilInfos } from './types';

export const loadMonProfilInfosAsync = createAsyncAction('LOAD_MON_PROFIL_INFOS_REQUEST', 'LOAD_MON_PROFIL_INFOS_SUCCESS', 'LOAD_MON_PROFIL_INFOS_FAILURE')<
    void,
    MonProfilInfos,
    string
>();

export const setFavoris = createAction('SET_MON_PROFIL_FAVORIS')<Post[]>();

export const setMesPosts = createAction('SET_MON_PROFIL_MES_POSTS')<Post[]>();
