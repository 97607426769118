
import { createStyles, makeStyles } from '@material-ui/styles';
import { colors, Theme } from 'Theme';

export default makeStyles(({ spacing, palette }: typeof Theme) => createStyles({
    root: {
        padding: spacing(1),

        '& h2': {
            margin: spacing(2, 0, 1),
        },
    },

    category: {
        fontSize: 16,
        fontWeight: 'bold',
        color: palette.primary.main,

        '&$level2': {
            paddingLeft: spacing(1),
            color: colors.blue.dark
        },
        '&$level3': {
            paddingLeft: spacing(2),
            color: colors.blue.main
        }
    },

    card: {
        margin: spacing(1, 0),
        padding: spacing(1),
    },

    question: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    level1: {},
    level2: {},
    level3: {},

    content: {
        fontSize: 14,
    },

    paper : {
        padding : spacing(2,4,4,4)
    },
    expansionPanel : {
        border : `1px solid ${colors.lighterGrey.light}`,
        borderRadius : 5,
        marginBottom : spacing(1)
    }
}));
