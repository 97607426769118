import { DeepPartial } from 'redux';
import { LevelUpService } from 'services';
import { DeepRawify } from 'types';
import { v4 } from 'uuid'
import { CaracteristiqueCheminAPI } from './CaracteristiqueCheminAPI';
import { CaracteristiquePicto } from './CaracteristiquePicto';

export enum TypeCaracteristique {
    GLOBALE = 1,
    PRODUIT = 2
}

export enum TypeElementGraphique {
    AUCUNE = 0,
    PICTO = 1,
    COULEUR = 2
}

export class Caracteristique {

    public uid: string = v4();

    /**
     * L'identifiant de la caractéristique
     */
    public idCaracteristique: number;

    /**
     * L'identifiant du groupe auquelle appartient la caractéristique
     */
    public idGroupeCaracteristique: number;

    /**
     * Le libellé de la caractéristique
     */
    public libelle: string;

    /**
     * Type de la caractéristique
     */
    public typeCaracteristique: TypeCaracteristique;

    /**
     * Statut d'élément charnière de la caractéristique
     */
    public elementCharniere: number;

    /**
     * Valeur de l'élément graphique de la caractéristique
     */
    public elementGraphique: TypeElementGraphique;

    /**
     * L'identifiant de l'API liée à la caractéristique
     */
    public idApi: number;

    /**
     * Statue d'activation de la caractéristique
     */
    public enLigne: boolean;

    /**
     * Ordre dans le classement des caractéristiques
     */
    public ordre: number;

    /**
     * Ordre dans les caractéristiques clé
     */
    public ordreCaracteristiquesCle: number;

    /**
    * Statue caractéristique clé
    */
    public caracteristiqueCle: boolean;

    /**
     * Liste des chemins API
     */
    public listeCheminsAPI: CaracteristiqueCheminAPI[];

    /**
     * Liste des pictos
     */
    public listePictos: CaracteristiquePicto[];

    private levelUpService = LevelUpService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<Caracteristique>>) {
        if (row) {
            const { idCaracteristique,
                idGroupeCaracteristique,
                libelle,
                typeCaracteristique,
                elementCharniere,
                elementGraphique,
                idApi,
                enLigne,
                ordre,
                caracteristiqueCle,
                listeCheminsAPI,
                listePictos,
                ordreCaracteristiquesCle
            } = row;

            this.idCaracteristique = idCaracteristique;
            this.idGroupeCaracteristique = idGroupeCaracteristique;
            this.libelle = libelle;
            this.typeCaracteristique = typeCaracteristique;
            this.elementCharniere = Number(elementCharniere);
            this.elementGraphique = elementGraphique;
            this.idApi = idApi;
            this.enLigne = enLigne;
            this.ordre = ordre;
            this.caracteristiqueCle = caracteristiqueCle;
            this.listeCheminsAPI = listeCheminsAPI.map(cheminAPI => new CaracteristiqueCheminAPI(cheminAPI));
            this.listePictos = listePictos.map(picto => new CaracteristiquePicto(picto));
            this.ordreCaracteristiquesCle = ordreCaracteristiquesCle;
        }
    }


    public toRaw() {
        return {
            idCaracteristique: this.idCaracteristique,
            idGroupeCaracteristique: this.idGroupeCaracteristique,
            libelle: this.libelle,
            typeCaracteristique: this.typeCaracteristique,
            elementCharniere: this.elementCharniere,
            elementGraphique: this.elementGraphique,
            idApi: this.idApi,
            enLigne: this.enLigne,
            ordre: this.ordre,
            caracteristiqueCle: this.caracteristiqueCle,
            listeCheminsAPI: this.listeCheminsAPI.map(cheminAPI => cheminAPI.toRaw()),
            listePictos: this.listePictos.map(picto => picto.toRaw()),
            ordreCaracteristiquesCle: this.ordreCaracteristiquesCle
        };
    }

    public save(): Promise<boolean> {
        return this.levelUpService.saveCaracteristique(this);
    }

    public delete: () => Promise<boolean> = () => {
        return this.levelUpService.deleteCaracteristique(this.idCaracteristique);
    }
}
