import { DeepPartial } from 'redux';
import { LevelUpService } from 'services';
import { DeepRawify } from 'types';
import { v4 } from 'uuid'
import moment, { Moment } from 'moment';

/**
 * Sauvearde les caracteristiques d'une fiche  
 */
export class FicheCaracteristique
{

    /**
     * L'identifiant de la valeur caractéristique
     * @var int
     */
    public idFicheCaracteristique: number;

    /**
     * L'identifiant de la caractéristique
     * @var int
     */
    public idCaracteristique: number;

    /**
     * L'identifiant de la fiche
     * @var int
     */
    public idFiche: number;

    /**
     * Valeur de la caractéristique
     * @var string
     */
    public valeur: string;

    /**
     * Identifiant du picto associé
     * @var int
     */
    public idCaracteristiquePicto: number;

    /**
     * Couleur pour l'affichage
     * @var string
     */
    public codeCouleur: string;

    /**
     * Est-ce une caractéristique clé
     * @var bool
     */
    public caracteristiqueCle: boolean;

    /**
     * Dernière MàJ
     * @var int
     */
    public dateMiseAJour: Moment;

    private levelUpService = LevelUpService.getInstance();


    /**
     * __construct
     *  Constructeur de la fiche
     * @param  mixed row
     * @return void
     */
    constructor(row?: DeepPartial<DeepRawify<FicheCaracteristique>>) {
        if (row) {
            this.idFicheCaracteristique = row['idFicheCaracteristique'] ?? null;
            this.idFiche = row['idFiche'] ?? null;
            this.idCaracteristique = row['idCaracteristique'] ?? null;
            this.valeur = row['valeur'] ?? null;
            this.idCaracteristiquePicto = row['idCaracteristiquePicto'] ?? null;
            this.codeCouleur = row['codeCouleur'] ?? null;
            this.caracteristiqueCle = row['caracteristiqueCle'] ?? null;
            this.dateMiseAJour = row['dateMiseAJour'] ? moment(row['dateMiseAJour'], 'X') : moment();
        } 
    }

    public toRaw() {
        return {
            idFicheCaracteristique: this.idFicheCaracteristique,
            idCaracteristique: this.idCaracteristique,
            idFiche: this.idFiche,
            valeur: this.valeur,
            idCaracteristiquePicto: this.idCaracteristiquePicto,
            codeCouleur: this.codeCouleur,
            caracteristiqueCle: this.caracteristiqueCle,
            dateMiseAJour: this.dateMiseAJour && this.dateMiseAJour.unix(),
        };
    }

}
