/* eslint-disable import/extensions */
import cloneDeep from 'lodash/cloneDeep';
import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import { Section } from 'classes/faq/Section.class';
import { loadFAQAsync } from './actions';

const sections = createReducer<Section[], RootAction>([])
  .handleAction(loadFAQAsync.success, (state, action) => action.payload);

export const FAQReducers = combineReducers({
  sections,
});

export default FAQReducers;
