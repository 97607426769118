import { DeepPartial } from 'redux';
import { MessagerieService } from 'services';
import { DeepRawify } from 'types';
import { v4 } from 'uuid'
import { Message } from './Message.class';

/**
 * MessageGroupe
 * Message envoyé dans un Groupe
 */
export class MessageGroupe extends Message {

    public uid: string = v4();
    public service = MessagerieService.getInstance();

    /**
     * L'identifiant du message de groupe
     */
    public idMessageGroupe: number;

    /**
     * Constructeur du MessageGroupe
     */
    constructor(row?: DeepPartial<DeepRawify<MessageGroupe>>) {
        super(row);

        if (row) {
            const { idMessageGroupe } = row;
            this.idMessageGroupe = idMessageGroupe;
            this.idMessage = idMessageGroupe;
        }
    }


    /**
     * Méthode de renvoi de l'objet en back sous la forme d'un tableau
     * @return array
     */
    public toRaw() {
        return {
            idMessageGroupe: this.idMessageGroupe,
            emetteur: this.emetteur,
            recepteur: this.recepteur,
            texte: this.texte,
            image: this.image && this.image,
            dateEnvoi: this.dateEnvoi
        };
    }

    public delete() {
        this.service.deleteMessage(this.idMessageGroupe);
    }

}
