import { DeepPartial } from 'redux';
import { LevelUpService } from 'services';
import { DeepRawify } from 'types';
import {v4} from 'uuid'

export class Famille {

    public uid: string = v4();

    /**
     * Numéro de la famille
     */
    public idFamille: number;

    /**
     * Libellé (nom) de la famille
     */
    public libelle : string;

    /**
     * actif ?
     */
    public enLigne : boolean;

    /**
     * Chemin de l'API si une API est active
     */
    public cheminApi : string;

    /**
     * Catéogorie parente
     */
    public idCategorie : number;

    /**
     * API associée à la famille
     */
    public idApi : number;

    private levelUpService = LevelUpService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<Famille>>) {
        if (row) {
            const { idFamille,
                libelle,
                enLigne,
                cheminApi,
                idCategorie,
                idApi
            } = row;
            this.idFamille = idFamille;
            this.libelle = libelle;
            this.enLigne = enLigne;
            this.cheminApi = cheminApi;
            this.idCategorie = idCategorie;
            this.idApi = idApi;
        }
    }

    /**
     * Formate les données pour le renvoi en back
     * @returns array pour le back
     */
    public toRaw() {
        return {
            idFamille : this.idFamille,
            libelle : this.libelle,
            enLigne : this.enLigne,
            cheminApi : this.cheminApi,
            idCategorie : this.idCategorie,
            idApi : this.idApi
        };
    }

    public save(): Promise<Famille> {
        return this.levelUpService.saveFamille(this);
    }

    public delete(): Promise<boolean> {
        return this.levelUpService.deleteFamille(this.idFamille);
    }

}
