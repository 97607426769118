import { ChallengeService } from "services";
import moment, { Moment } from "moment";
import { MulticritereCritere } from "./MulticritereCritere.class";
import { Acteur } from "classes/Acteur.class";
import { MulticritereSnapshot } from "./MulticritereSnapshot.class";
import { DeepRawify } from "types";
import { DeepPartial } from "redux";
import { SuiviEnfantGlobal } from "./SuiviEnfantGlobal.class";

export class ClassementGlobal {

    public idClassementGlobal: number = null;

    public idSuiviGlobal: number = null;
    
    public idSuiviEnfant: number = null;

    public idChallenge: number = null;

    public idEntite: string = null;

    public dateTraitement: Moment = null;

    public dateFraicheur: Moment = null;

    public joursRestants: number = null;

    public joursRestantsPourcentage: number = null;

    public idGroupe: number = null;

    public libelleGroupe: string = null;

    public points: number = null;

    public pointsPourcent: number = null;

    public rang: number = null;

    public rangMoinsUn: number = null;

    public rangMaxClassement: number = null;

    public progressionRang: number = null;

    public idPere: string = null;

    public infosActeur: Acteur = null;

    public infosPere: MulticritereSnapshot = null;

    public infosSuivisEnfants: SuiviEnfantGlobal[] = [];

    private challengeService = ChallengeService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<ClassementGlobal>>) {

        if (row) {
            const {
                idClassementGlobal,
                idSuiviGlobal,
                idSuiviEnfant,
                idChallenge,
                idEntite,
                dateTraitement,
                dateFraicheur,
                joursRestants,
                joursRestantsPourcentage,
                idGroupe,
                libelleGroupe,
                points,
                pointsPourcent,
                rang,
                rangMoinsUn,
                rangMaxClassement,
                progressionRang,
                idPere,
                infosActeur,
                infosPere,
                infosSuivisEnfants,
            } = row;

            this.idClassementGlobal = idClassementGlobal;

            this.idSuiviGlobal = idSuiviGlobal;
            
            this.idSuiviEnfant = idSuiviEnfant;

            this.idChallenge = idChallenge;            

            this.idEntite = idEntite;

            this.dateTraitement = moment(dateTraitement, 'X');

            this.dateFraicheur = moment(dateFraicheur, 'X');

            this.joursRestants = joursRestants;

            this.joursRestantsPourcentage = joursRestantsPourcentage;

            this.idGroupe = idGroupe;

            this.libelleGroupe = libelleGroupe;

            this.points = points;

            this.pointsPourcent = pointsPourcent;

            this.rang = rang;

            this.rangMoinsUn = rangMoinsUn;

            this.rangMaxClassement = rangMaxClassement;

            this.progressionRang = progressionRang;

            this.idPere = idPere;

            this.infosActeur = new Acteur(infosActeur);

            this.infosPere = new MulticritereSnapshot(infosPere);

            if(infosSuivisEnfants) {
                this.infosSuivisEnfants = infosSuivisEnfants.map(infoSuiviEnfant => new SuiviEnfantGlobal(infoSuiviEnfant));
            }

        }
    }

    /**
     * Match les champs en BDD avec les champs de l'objet ClassementGlobal
     * @returns l'objet avec les noms adéquats 
     */
    public toDatabaseObject(): object {
        return {
            idClassementGlobal: this.idClassementGlobal,
            idSuiviGlobal: this.idSuiviGlobal,
            idSuiviEnfant: this.idSuiviEnfant,
            idChallenge: this.idChallenge,
            idEntite: this.idEntite,
            dateTraitement: this.dateTraitement.unix(),
            dateFraicheur: this.dateFraicheur.unix(),
            idGroupe: this.idGroupe,
            points: this.points,
            rang: this.rang,
            rangMoinsUn: this.rangMoinsUn,
            idPere: this.idPere,
        }
    }
}