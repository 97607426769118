import { Battle } from 'classes/level_up/battles/Battle.class';
import { createAsyncAction } from 'typesafe-actions';
import { InfosBattles, InfosRecap, Opponent } from './types';
import { FicheQuestion } from 'classes/level_up/quiz/FicheQuestion.class';
import { AnswersBattle, ClassementAnswer, ClassementRequest } from '../../quiz/store/types';

// Charge les stats de l'utilisateur sur ses battles
export const loadInfosMesBattlesAsync = createAsyncAction(
    'LOAD_INFOS_MES_BATTLES_REQUEST',
    'LOAD_INFOS_MES_BATTLES_SUCCESS',
    'LOAD_INFOS_MES_BATTLES_FAILURE',
)<void, InfosBattles, string>();

// Charge la liste des battles
export const loadBattlesListAsync = createAsyncAction(
    'LOAD_BATTLES_LIST_REQUEST',
    'LOAD_BATTLES_LIST_SUCCESS',
    'LOAD_BATTLES_LIST_FAILURE',
)<boolean, Battle[], string>();

// Charge la liste des adversaires potentiels
export const loadOpponentsListAsync = createAsyncAction(
    'LOAD_OPPONENTS_LIST_REQUEST',
    'LOAD_OPPONENTS_LIST_SUCCESS',
    'LOAD_OPPONENTS_LIST_FAILURE',
)<string, Opponent[], string>();

// Charge la question a afficher pour une battle donnée
export const getQuestionBattleAsync = createAsyncAction(
    'GET_QUESTION_BATTLE_REQUEST',
    'GET_QUESTION_BATTLE_SUCCESS',
    'GET_QUESTION_BATTLE_FAILURE',
)<number, FicheQuestion, string>();

// Sauvegarde la réponse suivante
export const saveNextAnswerBattleAsync = createAsyncAction(
    'SAVE_NEXT_ANSWER_BATTLE_REQUEST',
    'SAVE_NEXT_ANSWER_BATTLE_SUCCESS',
    'SAVE_NEXT_ANSWER_BATTLE_FAILURE',
)<AnswersBattle, FicheQuestion, string>();

// Charge les infos du recap d'un quiz sur les battles
export const loadRecapQuizBattleAsync = createAsyncAction(
    'LOAD_RECAP_QUIZ_BATTLE_REQUEST',
    'LOAD_RECAP_QUIZ_BATTLE_SUCCESS',
    'LOAD_RECAP_QUIZ_BATTLE_FAILURE',
)<number, InfosRecap, string>();

// Charge le classement des battles
export const loadBattlesClassementAsync = createAsyncAction(
    'LOAD_BATTLES_CLASSEMENT_REQUEST',
    'LOAD_BATTLES_CLASSEMENT_SUCCESS',
    'LOAD_BATTLES_CLASSEMENT_FAILURE',
)<ClassementRequest, ClassementAnswer[], string>();
