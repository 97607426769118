import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { colors } from 'Theme';

export default ({ spacing, palette }: typeof Theme) => createStyles({
    paperAvatar: {
        background: "linear-gradient(to bottom, " + palette.primary.main + " 100px, #fff 100px)",
        padding : spacing(4),
        display : 'flex',
        flexDirection : 'column',
        alignItems : 'center',
        alignContent : 'center',
        marginBottom : spacing(2)
    },
    textRole : {
        fontSize : 12,
        color : colors.grey.main,
        marginBottom : spacing(2)
    },
    statistics: {
        width : '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems : 'center',
        flexDirection: 'row',
        textAlign : 'center'
    },
    iconStatistics : {
        width : 40,
        height : 40
    },
    calendar : {
        textTransform : 'capitalize'
    },
    paperNbChallenges : {
        display : 'flex',
        padding : spacing(2),
        alignItems : 'center',
        justifyContent : 'center',
        marginBottom : spacing(2)
    },
    imgChallenge : {
        marginRight : spacing(1)
    }
});