import { combineReducers } from "redux";
import { RootAction } from "store/types";
import { createReducer } from "typesafe-actions";
import { loadMailContactAsync } from "./actions";


const mailContact = createReducer<string, RootAction>('')
    .handleAction([loadMailContactAsync.success], (_state, action) => action.payload)


const ContactReducer = combineReducers({
    mailContact
});

export default ContactReducer;
