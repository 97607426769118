import { createStyles, makeStyles } from '@material-ui/styles';
import { colors, Theme } from 'Theme';

export default makeStyles(({ spacing, palette }: typeof Theme) => createStyles({
    containerListPosts: {
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        transition: '0.5s',
        paddingX: spacing(1),

        '@media (min-width: 576px)': {
            maxWidth: 540
        },
        '@media (min-width: 768px)': {
            maxWidth: 720
        },
        '@media (min-width: 992px)': {
            maxWidth: 960
        },
        '@media (min-width: 1200px)': {
            maxWidth: 1140
        }
    },
    disabledFilter: {
        backgroundColor: colors.grey.dark,
        color: colors.lighterGrey.main
    },
    containerFilterDisplayed: {
        paddingTop: 48
    },
    fabAddPost: {
        position: 'fixed',
        bottom: spacing(1.5),
        right: spacing(1.5),
        backgroundColor: palette.primary.main
    },
    typePostIcon: {
        height: 24,
        width: 24,
        borderRadius: '50%'
    },
    typePostText: {
        backgroundColor: 'white',
        borderRadius: 8,
        verticalAlign: 'center',
        padding: 5
    },
    paperListPostsDesktop: {
        padding: spacing(2)
    },
    tab: {
        // minWidth : 0
        width: '100%',
        maxWidth: '33%',
        minWidth: 0
    },
    typePostFilter: {
        marginRight: spacing(1),
        padding: "2px 10px",
        fontSize: 15,
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        lineHeight: "25px"
    },
    fabLabel: {
        height: '100%'
    },
    tooltipLabel: {
        whiteSpace: 'nowrap',
        fontSize: 14,
        color: 'black',
        borderRadius: 20
    },
    allBadge: {
        backgroundColor: 'white',
        color: 'black',
    },
    speedDialTypesPosts: {
        position: 'fixed',
        right: spacing(1.5),
        bottom: spacing(1.5)
    }
}));