import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'

export default ({ spacing, palette }: typeof Theme) => createStyles({
    disconnectDrawer: {
        padding: spacing(4, 1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent : 'space-between',
        height : 300,

        '& img': {
            height: 120,
            marginBottom: spacing(1),
        },

        '& h3': {
            fontSize: 18,
            fontWeight: 'bold',
            margin: 0,
        },

        '& p': {
            // color: colors.grey.main,
            margin: spacing(2),
        },

        '& $drawerAction > *': {
            minWidth: 100,
            margin: spacing(0, 1),
        },
    },
    drawerAction: {},
    textFieldCommentaire: {
        marginTop: spacing(1)
    }
});