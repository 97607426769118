import { Acteur } from "classes/Acteur.class";
import Axios from "axios";
import qs from 'qs';
import { ActeurInfos, Droits } from "store/acteur/acteurTypes";
import { Role } from "classes/referentiel/Role.class";
import cloneDeep from 'lodash/cloneDeep'
import { DeepRawify } from "types";
import { NiveauCaracteristique } from "classes/referentiel/NiveauCaracteristique.class";

export class ReferentielService {
    private static instance: ReferentielService;

    public static getInstance(): ReferentielService {
        if (!ReferentielService.instance) {
            ReferentielService.instance = new ReferentielService();
        }

        return ReferentielService.instance;
    }

    public async findCurrentActeur(): Promise<Acteur> {
        return Axios.get<{ content: DeepRawify<Acteur> }>(`index.php?${qs.stringify({
            rub: IS_ADMIN ? 685530 : 68553,
            p: 10
        })}`).then(({ data: { content } }) => new Acteur(content));
    }


    public async findActeur(idActeur: string): Promise<Acteur> {
        return Axios.get<{ content: DeepRawify<Acteur> }>(`index.php?${qs.stringify({
            rub: 270,
            p: 8,
            idActeur
        })}`).then(({ data: { content } }) => new Acteur(content));
    }

    public async loadActeurInfos(idActeur: string): Promise<ActeurInfos> {
        return Axios.get<{ content: DeepRawify<ActeurInfos> }>(`index.php?${qs.stringify({
            rub: 270,
            p: 10,
            idActeur
        })}`).then(({ data: { content } }) => ({
            acteur: new Acteur(content.acteur),
            statistics: content.statistics,
            currentIsMe: content.currentIsMe,
            monRole: new Role(content.monRole),
            niveauCaracteristiques: cloneDeep(content.niveauCaracteristiques).map(niveauCaracteristique => new NiveauCaracteristique(niveauCaracteristique)),
            droits: {}
        }));
    }

    public async loadCurrentActeurInfos(): Promise<ActeurInfos> {
        return Axios.get<{ content: DeepRawify<ActeurInfos> }>(`index.php?${qs.stringify({
            rub: IS_ADMIN ? 685530 : 68553,
            p: 20
        })}`).then(({ data: { content } }) => ({
            acteur: new Acteur(content.acteur),
            statistics: content.statistics,
            currentIsMe: true,
            monRole: new Role(content.monRole),
            niveauCaracteristiques: cloneDeep(content.niveauCaracteristiques).map(niveauCaracteristique => new NiveauCaracteristique(niveauCaracteristique)),
            droits: content.droits
        }));
    }

    public async getMesDroits(): Promise<Droits> {
        return Axios.get(`index.php?${qs.stringify({
            rub: IS_ADMIN ? 685530 : 68553,
            p: 19,
        })}`).then(({ data: { content } }) => {
            return {};
        });
    }
}
