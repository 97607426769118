import { Moment } from 'moment';
import { CarnetVoyageService } from 'services';
import { B64File } from 'classes/B64File.class';
import { v4 } from 'uuid'
import { DeepRawify } from 'types';
import { DeepPartial } from 'redux';
export class Edito {

    public uid: string = v4();

    public idEdito: number;

    public titre: string;

    public accroche: string;

    public descriptif: string;

    public nom: string;

    public prenom: string;

    public role: string;

    public avatar: B64File;

    public cover: B64File;

    private carnetVoyageService = CarnetVoyageService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<Edito>>) {
        if (row) {
            const { idEdito,
                titre,
                accroche,
                descriptif,
                avatar,
                nom,
                prenom,
                role,
                cover
            } = row;
            this.idEdito = idEdito;
            this.titre = titre;
            this.accroche = accroche;
            this.descriptif = descriptif;
            this.nom = nom;
            this.prenom = prenom;
            this.role = role;
            this.avatar = B64File.fromDb(avatar);
            this.cover = B64File.fromDb(cover);
        }
    }


    public toRaw() {
        return {
            idEdito: this.idEdito,
            accroche: this.accroche,
            titre: this.titre,
            descriptif: this.descriptif,
            avatar: this.avatar,
            cover: this.cover,
            nom: this.nom,
            prenom: this.prenom,
            role: this.role
        };
    }


}
