import { DeepPartial } from 'redux';
import { LevelUpService } from 'services';
import { DeepRawify } from 'types';
import { v4 } from 'uuid'
import moment, { Moment } from 'moment';

/**
 * Sauvegarde les valeurs des caractéristiques d'une fiche libre
 */
export class FicheLibreCaracteristique
{
    public uid: string = v4();

    /**
     * L'identifiant de la relation
     * @var int
     */
    public idFicheLibreCaracteristique: number;

    /**
     * L'identifiant du groupe
     * @var int
     */
    public idFicheLibreGroupeCaracteristique: number;

    /**
     * libellé
     * @var string
     */
    public libelle: string;

    /**
     * valeur
     * @var string
     */
    public valeur: string;

    /**
     * date de mise à jour
     * @var int
     */
    public dateMiseAJour: Moment;

    /**
     * Ordre d'affichage
     * @var int
     */
    public ordre: number;

    private levelUpService = LevelUpService.getInstance();


    /**
     * 
     *  Constructeur de la fiche
     * @param  mixed row
     * @return void
     */
    constructor(row?: DeepPartial<DeepRawify<FicheLibreCaracteristique>>) {
        if (row) {
            this.idFicheLibreCaracteristique = row['idFicheLibreCaracteristique'] ?? null;
            this.idFicheLibreGroupeCaracteristique = row['idFicheLibreGroupeCaracteristique'] ?? null;
            this.libelle = row['libelle'] ?? null;
            this.valeur = row['valeur'] ?? null;
            this.dateMiseAJour = row['dateMiseAJour'] ? moment(row['dateMiseAJour'], 'X') : moment();
            this.ordre = row['ordre'] ?? null;
        } else {
            this.dateMiseAJour = moment();
        }
    }

    /**
     * Méthode de renvoi de l'objet en front sous la forme d'un tableau
     * La majeure partie cette méthode va contenir toutes les variables 
     * de la classe avec en plus des informations supplémentaires propres
     * à l'utilisation en front
     * C'est ici que la gestion des droits s'effectue la plupart du temps
     * @return array
     */
    public toRaw()
    {
        return {
            idFicheLibreCaracteristique: this.idFicheLibreCaracteristique,
            idFicheLibreGroupeCaracteristique: this.idFicheLibreGroupeCaracteristique,
            libelle: this.libelle,
            dateMiseAJour: this.dateMiseAJour && this.dateMiseAJour.unix(),
            ordre: this.ordre,
            valeur: this.valeur
        };
    }

}
