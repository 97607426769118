import { Moment } from 'moment';
import { ChallengeService } from 'services';
import { B64File } from 'classes/B64File.class';
import {v4} from 'uuid'
import { DeepRawify } from 'types';
import { DeepPartial } from 'redux';
export class ChallengeEdito {

    public uid: string = v4();

    public leChallenge : number;

    public titre : string;

    public accroche : string;

    public texte : string;

    public nomAuteur : string;

    public intituleAuteur : string;

    public imageAuteur : B64File = null;

    public image : B64File = null;

    private challengesService = ChallengeService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<ChallengeEdito>>) {
        if (row) {
            const { leChallenge,
                titre,
                accroche,
                texte,
                imageAuteur,
                nomAuteur,
                intituleAuteur,
                image
            } = row;
            this.leChallenge = leChallenge;
            this.titre =titre;
            this.accroche = accroche;
            this.texte =texte;
            this.nomAuteur = nomAuteur;
            this.intituleAuteur = intituleAuteur;
            this.imageAuteur = B64File.fromDb(imageAuteur);
            this.image = B64File.fromDb(image);
        }
    }


    public toRaw() {
        return {
            leChallenge : this.leChallenge,
            accroche : this.accroche,
            titre : this.titre,
            texte : this.texte,
            imageAuteur : this.imageAuteur,
            image : this.image,
            nomAuteur : this.nomAuteur,
            intituleAuteur : this.intituleAuteur
        };
    }

    public save(): Promise<Boolean> {
        return this.challengesService.saveEdito(this);
    }


}
