import { loadMurInfos } from 'features/murs/src/store/actions';
import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import { Metier } from 'classes/referentiel/Metier.class';


const referentiel = createReducer<Metier, RootAction>(null)
    .handleAction(loadMurInfos.success, (_state, action) => action.payload.referentielMetier);

const referentielMetierReducer = combineReducers({
    referentiel
});

export default referentielMetierReducer;
