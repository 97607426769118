import { DeepPartial } from "redux";
import { ParticipantsService } from "services";
import { DeepRawify } from "types";

export class EntiteCaracteristiqueValue {

    public idEntite : string = null;
    
    public idNiveauCaracteristique : number = null;

    public idNiveauCaracteristiqueChoix : number = null;

    public libelle : string = null;

    private participantsService = ParticipantsService.getInstance();

    constructor(row? : DeepPartial<DeepRawify<EntiteCaracteristiqueValue>>) {

        if (row) {

            const {
                idEntite,
                idNiveauCaracteristique,
                idNiveauCaracteristiqueChoix,
                libelle,
            } = row;

            this.idEntite = idEntite;

            this.idNiveauCaracteristique = idNiveauCaracteristique;

            this.idNiveauCaracteristiqueChoix = idNiveauCaracteristiqueChoix;

            this.libelle = libelle;

        }
    }

    // public save : () => Promise<boolean> = () => {
    //     return this.EntiteCaracteristiqueValueService.saveEntiteCaracteristiqueValue(this);
    // }

    public toDatabaseObject(): object {
        return {
            idEntite: this.idEntite,
            idNiveauCaracteristique: this.idNiveauCaracteristique,
            idNiveauCaracteristiqueChoix: this.idNiveauCaracteristiqueChoix,
        }
    }
}